import React from 'react';

import s from './styles.module.scss';
import DoctorStatusTag from '../status-tag';

import type { Doctor } from 'infrastructure/classes/doctor/info';

interface IProps {
  info: Doctor;
}

const DoctorHeaderInfo: React.FC<IProps> = (props) => {
  const { info } = props;

  return (
    <div className={s.header}>
      <span className={s.name}>{info.fullName}</span>
      <DoctorStatusTag status={info?.status} />
    </div>
  );
};

export default DoctorHeaderInfo;
