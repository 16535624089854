import { type FC } from 'react';
import ExpandableFilterPanel from 'components/molecules/expandable-filter-panel';

import Body from './body';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  appendFilters: AppendFilters;
  filters: Filters;
  filtersCount: number;
  resetFilters: () => void;
}

const CcmAgentDashboardFilters: FC<Props> = ({
  filtersCount,
  appendFilters,
  resetFilters,
  filters,
}) => {
  return (
    <ExpandableFilterPanel onReset={resetFilters} filtersCount={filtersCount}>
      <Body filters={filters} appendFilters={appendFilters} />
    </ExpandableFilterPanel>
  );
};

export default CcmAgentDashboardFilters;
