import React from 'react';
import { Route } from 'react-router-dom';
import AuthProvider from 'infrastructure/providers/auth-provider';

import { ProtectedRoute } from './utils/protected-route';
import { AccessRouteComponent } from './utils/access-route';

import type { ILayoutRoute } from './types';

const wrapProtect = (component: React.FC) => {
  return <ProtectedRoute component={component} />;
};
const wrapAccess = (component: React.FC, path: string) => {
  return <AccessRouteComponent component={component} path={path} />;
};

export const renderRoutes = (mainRoutes: ILayoutRoute[]) => {
  return (
    <Route element={<AuthProvider />}>
      {mainRoutes.map((layoutParams, index) => {
        const { layout: Layout, routes, isProtected } = layoutParams;
        const layout = isProtected ? wrapProtect(Layout) : <Layout />;

        return (
          <Route key={index} element={layout}>
            {routes.map((routeParams) => {
              const { component: Page, path, isAccessible } = routeParams;
              const page = isAccessible ? wrapAccess(Page, path) : <Page />;
              return <Route key={path} element={page} path={path} />;
            })}
          </Route>
        );
      })}
    </Route>
  );
};
