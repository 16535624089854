import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { IEmergencyModalProps } from './types';
import type { FC } from 'react';

const EmergencyModal: FC<IEmergencyModalProps> = (props) => {
  const { openEmergencyModal, onCancel, onSave } = props;
  const { t } = useTranslation();

  return (
    <BaseModal
      open={openEmergencyModal}
      width="552px"
      title={t('rpmAlertAssessment.emergencyModalTitle')}
      zIndex={1051}
      smallBodyPaddingWithFooter
      onCancel={onCancel}
      withFooter={[
        <BaseButton
          dataCy="cancel-button"
          key="cancel"
          type="secondary"
          label={t('controls.no')}
          onClick={onCancel}
        />,
        <BaseButton
          dataCy="save-button"
          key="save"
          type="primary"
          label={t('controls.yes')}
          onClick={onSave}
        />,
      ]}
    >
      <div className={s['emergency-body']}>
        <p>{t('rpmAlertAssessment.emergencyBody')}</p>
        <p className={s.red}>{t('rpmAlertAssessment.emergencyBodyQuestion')}</p>
      </div>
    </BaseModal>
  );
};

export default EmergencyModal;
