import type { RpmStatuses } from 'infrastructure/enums';
import type { IPatientSelect } from 'infrastructure/interfaces';

export class PatientSelect {
  guid: string;
  label: string;
  status: RpmStatuses;
  birthDate?: Date;

  constructor(data: IPatientSelect) {
    this.guid = data.guid;
    this.label = data.label;
    this.status = data.record.status;
    this.birthDate = data.record.birthDate
      ? new Date(data.record.birthDate)
      : undefined;
  }
}
