import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import BaseSelectBox from 'components/atoms/base-select-box';
import forms from 'constants/forms';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import useUser from 'utils/useUser';
import PatientSelectBox from 'components/molecules/patient-select-box';
import ReadingTypeSelectBox from 'components/molecules/reading-type-select-box';
import AssigneeSelectBox from 'components/molecules/assignee-select-box';
import ClinicSelectBox from 'components/molecules/clinic-select-box';
import DoctorSelectBox from 'components/molecules/doctor-select-box';
import StateSelectBox from 'components/molecules/states-types-select-box';
import BooleanSelectBox from 'components/molecules/boolean-select-box';
import ActionTimesQuotaSelectBox from 'components/molecules/action-times-quota-select-box';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';
import ItemWrapper from '../item-wrapper';

import type { TDashboardFilter } from '../../hooks/use-dashboard-filter';
import type { FC } from 'react';

interface BodyProps
  extends Omit<
    TDashboardFilter,
    'onResetFilter' | 'countOfChangedFilterField' | 'refetchInsuranceType'
  > {}

const DEFAULT_INSURANCE_TYPE = forms.clinicInsuranceTypeSelect[0];

const Body: FC<BodyProps> = (props) => {
  const {
    dateValues,
    values,
    insuranceTypes,
    onDateChange,
    handleChangeValue,
  } = props;
  const { t } = useTranslation();
  const { isPractice, isAtRiskRole } = useUser();

  return (
    <div>
      <div className={s.row}>
        <ItemWrapper label={t('labels.patientFilter')}>
          <PatientSelectBox
            doctorGuid={values.doctorGuid}
            clinicGuid={values.clinicGuid}
            placeholder={t('labels.searchPatient')}
            value={values.patientGuid}
            onChange={(v) => handleChangeValue('patientGuid', v)}
            allowClear
            labelRender={(el) => {
              const date = DateFormatter({
                date: el.record?.birthDate,
                format: DateFormats['MM-dd-yyyy'],
              });
              return `${el.label} ${date}`;
            }}
          />
        </ItemWrapper>
        <div className={s['combine-cell']}>
          <ItemWrapper label={t('labels.readingType')}>
            <ReadingTypeSelectBox
              value={values.readingType}
              onChange={(v) => handleChangeValue('readingType', v)}
              allowClear
              withAll
            />
          </ItemWrapper>
          {isAtRiskRole && (
            <ItemWrapper label={t('labels.time')}>
              <ActionTimesQuotaSelectBox
                value={values.actionTimesQuota}
                onChange={(v) => handleChangeValue('actionTimesQuota', v)}
                allowClear
              />
            </ItemWrapper>
          )}
        </div>
        <div className={s['combine-cell']}>
          {!isPractice && (
            <ItemWrapper label={t('labels.assignee')}>
              <AssigneeSelectBox
                value={values.assignee}
                onChange={(v) => handleChangeValue('assignee', v)}
                allowClear
              />
            </ItemWrapper>
          )}
          <ItemWrapper label={t('labels.activitiesType')}>
            <BooleanSelectBox
              trueLabel={t('labels.actioned')}
              falseLabel={t('labels.unaction')}
              value={values.reviewed}
              onChange={(v) => handleChangeValue('reviewed', v)}
              allowClear
            />
          </ItemWrapper>
        </div>
        <ItemWrapper label={t('labels.clinicState')}>
          <StateSelectBox
            allowClear
            withAll
            value={values.clinicState}
            onChange={(v) => handleChangeValue('clinicState', v)}
          />
        </ItemWrapper>
      </div>
      <div className={s.row}>
        <ItemWrapper label={t('labels.dateRange')}>
          <BaseRangeDatepicker
            onCalendarChange={onDateChange}
            value={dateValues}
            withPresets
            disabledDate={(current) => {
              return current && current > new Date();
            }}
            isMaxDayToday
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.provider')}>
          <DoctorSelectBox
            clinicGuid={values.clinicGuid}
            placeholder={t('labels.search')}
            value={values.doctorGuid}
            onChange={(v) => handleChangeValue('doctorGuid', v)}
            allowClear
            mode="multiple"
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.clinic')}>
          <ClinicSelectBox
            placeholder={t('labels.search')}
            value={values.clinicGuid}
            onChange={(v) => handleChangeValue('clinicGuid', v)}
            allowClear
          />
        </ItemWrapper>
        <ItemWrapper label={t('labels.insuranceProvider')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={
              !values.clinicGuid && t('messages.disableInsuranceProviderFilter')
            }
          >
            <div>
              <BaseSelectBox
                options={insuranceTypes}
                defaultValue={DEFAULT_INSURANCE_TYPE}
                value={values.insuranceType}
                onChange={(v) => handleChangeValue('insuranceType', v)}
                disabled={!values.clinicGuid}
                allowClear
              />
            </div>
          </Tooltip>
        </ItemWrapper>
      </div>
    </div>
  );
};
export default Body;
