import { useTranslation } from 'react-i18next';
import EvelynIcon from 'components/atoms/icons/evelyn-monochrome.svg?react';
import BookmarkIcon from 'components/atoms/icons/bookmark-monochrome.svg?react';
import CommentIcon from 'components/atoms/icons/comment-monochrome.svg?react';
import CommentHyperPressure from 'components/atoms/icons/comment-hyper-pressure.svg?react';
import CommentHypoPressure from 'components/atoms/icons/comment-hypo-pressure.svg?react';
// import CommentHyperGlucose from 'components/atoms/icons/comment-hyper-glucose.svg?react';
// import CommentHypoGlucose from 'components/atoms/icons/comment-hypo-glucose.svg?react';
import CommentLowSpo2 from 'components/atoms/icons/comment-low-spo2.svg?react';
import CommentTachycardia from 'components/atoms/icons/comment-tachycardia.svg?react';
import CommentBradycardia from 'components/atoms/icons/comment-bradycardia.svg?react';
import SendCommentIcon from 'components/atoms/icons/send-comment.svg?react';
import classNames from 'classnames';
import { type FC } from 'react';
import BaseModal from 'components/atoms/base-modal';
import CommentBookmarkList from 'components/molecules/comment-bookmark/list';
import RpmCommentModal from 'components/templates/rpm/patients/patient/activity/modal';
import BaseIconButton from 'components/atoms/base-icon-button';
import useUser from 'utils/useUser';
import { ReadingType } from 'infrastructure/enums';
import RpmAlertAssessmentModals from 'components/organisms/rpm-alert-assessment-modal';

import s from './styles.module.scss';
import { useCommentColumn } from './hooks/use-comment-column';

import type { ReadingStatus } from 'infrastructure/enums';
import type {
  IAiCommentResponse,
  TAddCommentFn,
  TGenAiCommentFn,
} from 'infrastructure/hooks/rpm';
import type { IPatientThresholds } from 'infrastructure/interfaces';
import type { Value } from 'infrastructure/interfaces/i-rpm-patient-activity';

interface CommentColumnProps {
  addComment: TAddCommentFn;
  genAiComment: TGenAiCommentFn;
  deviceId: string;
  readingId: string;
  idx: number | string;
  createdAt: string;
  hideAiButton: boolean;
  currentInteractionStatus?: boolean;
  patientGuid: string;
  genAiCommentLoading: boolean;
  isCommentGenerationLimitReached: boolean;
  aiData?: IAiCommentResponse;
  values: Value;
  thresholds: IPatientThresholds;
  readingType: ReadingType;
  globalStatus: ReadingStatus;
}

const CommentColumn: FC<CommentColumnProps> = ({
  addComment,
  genAiComment,
  deviceId,
  readingId,
  idx,
  createdAt,
  hideAiButton,
  currentInteractionStatus,
  patientGuid,
  genAiCommentLoading,
  isCommentGenerationLimitReached,
  aiData,
  thresholds,
  values,
  readingType,
  globalStatus,
}) => {
  const { t } = useTranslation();
  const {
    openBookmarkModal,
    openTemplateModal,
    templateValues,
    showBookmarkBtn,
    disabled,
    aiButtonTitle,
    updateAction,
    loading,
    message,
    submitCommentLockByAi,
    sendCommentButtonTitle,
    openPressureMinModal,
    openGlucoseModal,
    openSpo2Modal,
    openPressureModal,
    openPulseModal,
    onSubmitComment,
    setTemplateValues,
    toggleOpenBookmark,
    toggleOpenTemplate,
    onApplyBookmark,
    toggleOpenGlucose,
    toggleOpenPressure,
    toggleOpenPressureMin,
    checkReadingMinMaxType,
    isBloodPressureMinCritical,
    isBloodPressureMaxCritical,
    toggleOpenSpo2,
    toggleOpenPulse,
    isPulseMinCritical,
    isPulseMaxCritical,
    isSpo2MinCritical,
    getPulseCriticalType,
  } = useCommentColumn({
    addComment,
    deviceId,
    idx,
    readingId,
    currentInteractionStatus,
    patientGuid,
    aiData,
    isCommentGenerationLimitReached,
  });

  const { isAgencyDoctor } = useUser();

  let bloodPressurePulsCriticalType: 'both' | 'max' | 'min' | null = null;
  const readingCriticalType = checkReadingMinMaxType(
    readingType,
    globalStatus,
    values,
    thresholds,
  );

  const bloodPressureMaxCritical = isBloodPressureMaxCritical(
    globalStatus,
    values,
    thresholds,
  );

  const bloodPressureMinCritical = isBloodPressureMinCritical(
    globalStatus,
    values,
    thresholds,
  );

  if (readingType === ReadingType.BloodPressure) {
    bloodPressurePulsCriticalType = getPulseCriticalType(values, thresholds);
  }

  const pulseMinCritical = isPulseMinCritical(values, thresholds);
  const pulseMaxCritical = isPulseMaxCritical(values, thresholds);
  const spo2MinCritical = isSpo2MinCritical(values, thresholds);

  const isShowCommentIcon = !(
    readingCriticalType &&
    isAgencyDoctor &&
    (readingType === ReadingType.BloodPressure ||
      readingType === ReadingType.SpO2)
  );
  // (readingType === ReadingType.BloodPressure ||
  //   readingType === ReadingType.BloodGlucose ||
  //   readingType === ReadingType.SpO2)

  return (
    <>
      <div className={s.comment} data-cy="comment-input">
        <textarea
          name="message"
          placeholder={t('controls.typeYourComment')}
          value={message ?? ''}
          onChange={(e) => {
            updateAction({ idx, action: { message: e.target.value } });
          }}
          disabled={disabled}
        />
        <div className={s['action-btns']}>
          <div>
            {hideAiButton && (
              <BaseIconButton
                onClick={() =>
                  genAiComment({
                    deviceId,
                    readingId,
                  })
                }
                title={aiButtonTitle}
                disabled={
                  isCommentGenerationLimitReached ||
                  genAiCommentLoading ||
                  disabled
                }
                loading={genAiCommentLoading}
                icon={
                  <EvelynIcon
                    width={24}
                    height={24}
                    className={s.icon}
                    data-disabled={genAiCommentLoading || disabled}
                  />
                }
              />
            )}
            {showBookmarkBtn && (
              <BaseIconButton
                onClick={toggleOpenBookmark}
                title={t('labels.bookmarkedComments')}
                disabled={disabled || openBookmarkModal}
                icon={
                  <BookmarkIcon
                    width={20}
                    height={20}
                    className={s.icon}
                    data-disabled={disabled}
                  />
                }
              />
            )}
            {isShowCommentIcon && (
              <BaseIconButton
                onClick={toggleOpenTemplate}
                title={
                  openTemplateModal
                    ? t('controls.onlyUseButtonOncePerComment')
                    : t('controls.openTemplateModal', { type: '' })
                }
                disabled={disabled || openTemplateModal}
                icon={
                  <CommentIcon
                    width={18}
                    height={18}
                    className={s.icon}
                    data-disabled={disabled}
                  />
                }
              />
            )}
            {readingType === ReadingType.BloodPressure &&
              bloodPressureMaxCritical && (
                <BaseIconButton
                  onClick={toggleOpenPressure}
                  title={t('controls.openTemplateModal', {
                    type: 'Hypertension',
                  })}
                  disabled={disabled || openTemplateModal}
                  icon={
                    <CommentHyperPressure
                      width={18}
                      height={18}
                      className={s.icon}
                      data-disabled={disabled}
                    />
                  }
                />
              )}
            {readingType === ReadingType.BloodPressure &&
              bloodPressureMinCritical && (
                <BaseIconButton
                  onClick={toggleOpenPressureMin}
                  title={t('controls.openTemplateModal', {
                    type: 'Hypotension',
                  })}
                  disabled={disabled || openTemplateModal}
                  icon={
                    <CommentHypoPressure
                      width={18}
                      height={18}
                      className={s.icon}
                      data-disabled={disabled}
                    />
                  }
                />
              )}
            {/* {readingType === ReadingType.BloodGlucose &&
              readingCriticalType && (
                <BaseIconButton
                  onClick={toggleOpenGlucose}
                  title={t('controls.openTemplateModal', { type: 'Glucose' })}
                  disabled={disabled || openTemplateModal}
                  icon={
                    readingCriticalType === 'max' ? (
                      <CommentHyperGlucose
                        width={18}
                        height={18}
                        className={s.icon}
                        data-disabled={disabled}
                      />
                    ) : (
                      <CommentHypoGlucose
                        width={18}
                        height={18}
                        className={s.icon}
                        data-disabled={disabled}
                      />
                    )
                  }
                />
              )} */}

            {readingType === ReadingType.SpO2 && spo2MinCritical && (
              <BaseIconButton
                onClick={toggleOpenSpo2}
                title={t('controls.openTemplateModal', { type: 'SpO2' })}
                disabled={disabled || openTemplateModal}
                icon={
                  <CommentLowSpo2
                    width={18}
                    height={18}
                    className={s.icon}
                    data-disabled={disabled}
                  />
                }
              />
            )}

            {pulseMaxCritical &&
              (bloodPressurePulsCriticalType || readingCriticalType) && (
                <BaseIconButton
                  onClick={toggleOpenPulse}
                  title={t('controls.openTemplateModal', {
                    type: 'Tachycardia',
                  })}
                  disabled={disabled || openTemplateModal}
                  icon={
                    <CommentTachycardia
                      width={18}
                      height={18}
                      className={s.icon}
                      data-disabled={disabled}
                    />
                  }
                />
              )}

            {pulseMinCritical &&
              (bloodPressurePulsCriticalType || readingCriticalType) && (
                <BaseIconButton
                  onClick={toggleOpenPulse}
                  title={t('controls.openTemplateModal', {
                    type: 'Bradycardia',
                  })}
                  disabled={disabled || openTemplateModal}
                  icon={
                    <CommentBradycardia
                      width={18}
                      height={18}
                      className={s.icon}
                      data-disabled={disabled}
                    />
                  }
                />
              )}
          </div>
          <BaseIconButton
            dataCy="submit-reading-comment-button"
            onClick={onSubmitComment}
            title={sendCommentButtonTitle}
            disabled={disabled || submitCommentLockByAi}
            loading={loading}
            icon={
              <SendCommentIcon
                width={24}
                height={24}
                className={classNames(s.icon, s['send-icon'], {
                  [s.disabled]: disabled,
                })}
              />
            }
          />
        </div>
      </div>
      {openBookmarkModal && (
        <BaseModal
          open={openBookmarkModal}
          onCancel={toggleOpenBookmark}
          width="1000px"
          title="Bookmarked comments"
        >
          <CommentBookmarkList onApply={onApplyBookmark} />
        </BaseModal>
      )}
      {openTemplateModal && (
        <RpmCommentModal
          isOpen={openTemplateModal}
          closeModal={toggleOpenTemplate}
          activityProps={{
            activity: {
              id: readingId,
              createdAt,
            },
            setValues: setTemplateValues,
            values: templateValues,
            handleSubmit: (msg: string) => {
              updateAction({ idx, action: { message: msg } });
            },
          }}
        />
      )}

      <RpmAlertAssessmentModals
        readingId={readingId}
        createdAt={createdAt}
        idx={idx}
        patientGuid={patientGuid}
        values={values}
        readingCriticalType={readingCriticalType}
        bloodPressurePulsCriticalType={bloodPressurePulsCriticalType}
        openPressureModal={openPressureModal}
        openPressureMinModal={openPressureMinModal}
        openGlucoseModal={openGlucoseModal}
        openSpo2Modal={openSpo2Modal}
        openPulseModal={openPulseModal}
        pulseMaxCritical={pulseMaxCritical}
        toggleOpenPressure={toggleOpenPressure}
        toggleOpenPressureMin={toggleOpenPressureMin}
        toggleOpenGlucose={toggleOpenGlucose}
        toggleOpenSpo2={toggleOpenSpo2}
        toggleOpenPulse={toggleOpenPulse}
      />
    </>
  );
};
export default CommentColumn;
