import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import BaseSelect from './base';

import type { Option } from './base';

interface Props {
  value?: Option | null;
  width?: string;
  onChange: (e: Option | null) => void;
}
const options = [
  {
    id: 'enrolled',
    value: 'enrolled',
    label: 'Enrolled',
  },
  {
    id: 'ccm_onboarding',
    value: 'ccm_onboarding',
    label: 'CCM Onboarding',
  },
];

const CcmStatusAgentDashboardSelect: FC<Props> = ({
  onChange,
  value,
  width,
}) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      width={width}
      label={t('labels.ccmStatus')}
      placeholder={t('labels.both')}
      options={options}
      value={value}
      onChange={(newValue) => {
        if (!newValue) {
          return onChange(null);
        }
        const items = Array.isArray(newValue) ? newValue : [newValue];
        onChange(items[0]);
      }}
    />
  );
};

export default CcmStatusAgentDashboardSelect;
