import user from './user';
import patient from './patient';
import sms from './sms';
import patientStatusLogs from './patientStatusLogs';
import patientCcmStatusLogs from './patientStatusLogs/ccm';
import patientHoldingStatusLogs from './patientStatusLogs/holdingStatus';
import comments from './comments';
import clinics from './clinics';
import discharge from './discharge';
import appointment from './appointment';
import orderConsumable from './orderConsumable';
import activity from './activity';
import doctor from './doctor';
import medicare from './medicare';
import onboardingStatus from './onboardingStatus';
import ccmOnboardingStatus from './ccmOnboardingStatus';
import compliance from './compliance';
import adherenceLast30Days from './adherence/last30DaysReport';
import clinicalMetrics from './clinicalMetrics';
import connect from './connect';
import servicePlans from './servicePlans';
import practiceOverview from './practiceOverview';
import adminPanelPatients from './adminPanelPatients';
import adminPanelUsers from './adminPanelUsers';
import adminPanelAgencyDoctors from './adminPanelAgencyDoctors';
import adminPanelClinics from './adminPanelClinics';
import adminPanelAgencies from './adminPanelAgencies';
import covid from './covid';
import ccmPatients from './ccm/patientsActivity';
import ccmPatientDetails from './ccm/patientDetails';
import clinicalEscalation from './clinicalEscalation';
import hospitalPrevention from './hospitalPrevention';
import clinicRunBookLogs from './clinicRunBookLogs';
import ccmInteractiveTime from './ccmInteractiveTime';
import manualInteractiveTime from './manualInteractiveTime';
import attachments from './attachments';
import smsDashboard from './smsDashboard';
import patientClaimStatusLogs from './patientStatusLogs/claimStatus';
import patientCCMClaimStatusLogs from './patientStatusLogs/ccmClaimStatus';
import socket from './socket';
import queryKeys from './queryKeys';
import activityActions from './patient-activity-actions';
import modalWindows from './modal-windows';
import awsRum from './awsRum';

import type { Models } from '@rematch/core';

export interface RootModel extends Models<RootModel> {
  user: typeof user;
  socket: typeof socket;
  smsDashboard: typeof smsDashboard;
  queryKeys: typeof queryKeys;
  patientClaimStatusLogs: typeof patientClaimStatusLogs;
  patientCCMClaimStatusLogs: typeof patientCCMClaimStatusLogs;
  activityActions: typeof activityActions;
  modalWindows: typeof modalWindows;
  awsRum: typeof awsRum;
}

export const models: RootModel = {
  user,
  patient,
  doctor,
  sms,
  patientStatusLogs,
  patientClaimStatusLogs,
  patientCCMClaimStatusLogs,
  patientCcmStatusLogs,
  patientHoldingStatusLogs,
  comments,
  clinics,
  discharge,
  appointment,
  hospitalPrevention,
  clinicalEscalation,
  orderConsumable,
  activity,
  medicare,
  onboardingStatus,
  ccmOnboardingStatus,
  compliance,
  adherenceLast30Days,
  clinicalMetrics,
  servicePlans,
  connect,
  practiceOverview,
  adminPanelPatients,
  adminPanelUsers,
  adminPanelAgencyDoctors,
  adminPanelClinics,
  covid,
  adminPanelAgencies,
  ccmPatients,
  ccmPatientDetails,
  clinicRunBookLogs,
  ccmInteractiveTime,
  manualInteractiveTime,
  attachments,
  smsDashboard,
  socket,
  queryKeys,
  activityActions,
  modalWindows,
  awsRum,
};
