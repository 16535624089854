import i18next from 'i18next';
import { languagesDataSource } from 'infrastructure/data-sources/languages';
import { DateFormatter } from 'infrastructure/functions';

import type { Nullable } from 'infrastructure/types';

export const pathMap = {
  [i18next.t('labels.personalInfo')]: [
    'firstName',
    'lastName',
    'middleName',
    'mobileNumber',
    'workNumber',
    'email',
    'birthDate',
    'primaryLanguage',
    'secondaryLanguage',
    'title',
  ],
  [i18next.t('labels.address')]: [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zipPostalCode',
    'timezone',
  ],
  [i18next.t('labels.professionInfo')]: ['clinics', 'professionType', 'roles'],
  [i18next.t('labels.registration')]: ['createdAt', 'email'],
  [i18next.t('labels.emrDetails')]: [
    'emrName',
    'emrId1',
    'emrId2',
    'supervisingProviderId',
    'renderingProviderId',
    'emrBilling',
    'emrBillingCycle',
    'emrReadingsFlag',
    'emrReports',
  ],
  [i18next.t('labels.irs')]: ['npiNumber', 'alertEmail', 'alertSms'],
};

export const fieldMap: Record<string, string> = {
  firstName: i18next.t('labels.firstName'),
  lastName: i18next.t('labels.lastName'),
  middleName: i18next.t('labels.middleName'),
  mobileNumber: i18next.t('labels.phoneNumber'),
  workNumber: i18next.t('labels.workNumber'),
  email: i18next.t('labels.email'),
  birthDate: i18next.t('labels.dob'),
  primaryLanguage: i18next.t('labels.primaryLanguage'),
  secondaryLanguage: i18next.t('labels.secondaryLanguage'),
  title: i18next.t('labels.title'),

  addressLine1: i18next.t('labels.addressLine1'),
  addressLine2: i18next.t('labels.addressLine2'),
  city: i18next.t('labels.city'),
  state: i18next.t('labels.state'),
  zipPostalCode: i18next.t('labels.zip'),
  timezone: i18next.t('labels.timezone'),

  clinics: i18next.t('labels.clinics'),
  professionType: i18next.t('labels.profession'),
  roles: i18next.t('labels.roles'),

  createdAt: i18next.t('labels.registeredAt'),

  emrName: i18next.t('labels.emrName'),
  emrId1: i18next.t('labels.emrId') + 1,
  emrId2: i18next.t('labels.emrId') + 2,
  supervisingProviderId: i18next.t('labels.supervisingProvider'),
  renderingProviderId: i18next.t('labels.renderingProvider'),
  emrBilling: i18next.t('labels.billing'),
  emrBillingCycle: i18next.t('labels.billingCycle'),
  emrReadingsFlag: i18next.t('labels.readings'),
  emrReports: i18next.t('labels.report'),

  npiNumber: i18next.t('labels.npi'),
  alertEmail: i18next.t('labels.alertEmail'),
  alertSms: i18next.t('labels.alertSms'),

  status: i18next.t('labels.status'),
};

export const findKeyByValue = (value: string) =>
  Object.entries(pathMap).find(([_, values]) => values.includes(value))?.[0] ??
  '';

export const checkValue = (
  field: string,
  value: Nullable<string | string[]>,
) => {
  if (Array.isArray(value)) {
    let arrayValue = value.join(', ');

    if (field === 'roles') {
      arrayValue = arrayValue.replace('doctor', 'physician');
    }
    return value.length === 0 ? i18next.t('labels.bannerNoData') : arrayValue;
  }

  if (!value || value === 'null') return i18next.t('labels.bannerNoData');

  if (value === 'true') return i18next.t('controls.yes');
  if (value === 'false') return i18next.t('controls.no');
  if (field === 'birthDate') return DateFormatter({ date: value });
  if (field.toLowerCase().includes('language'))
    return languagesDataSource().find((el) => el.id === value)?.name ?? value;

  return value;
};
