import React, { useEffect, useState } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate, useParams } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import EditButton from 'components/atoms/controls/edit-button';
import { isValidGuid } from 'infrastructure/functions/is-valid-guid';
import { showResult } from 'infrastructure/hooks/utils';
import { useAgencyDoctorInfo } from 'infrastructure/hooks/agency-doctor/use-agency-doctor-info';
import AgencyDoctorViewForm from 'components/molecules/agency-doctor/view-form';

import type { AgencyDoctor } from 'infrastructure/classes/agency-doctor';

const AdminAgencyDoctorViewPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agentGuid } = useParams<{
    agentGuid: string;
  }>();

  useEffect(() => {
    if (!agentGuid || !isValidGuid(agentGuid)) {
      showResult(t('errors.invalidGuid'));
      navigate('/404', { replace: true });
    }
  }, [agentGuid, navigate]);
  if (!agentGuid || !isValidGuid(agentGuid)) {
    return null;
  }

  const { agencyDoctorInfoLoading, agencyDoctorInfo } = useAgencyDoctorInfo({
    agentGuid,
  });

  const [agency, setAgency] = useState<AgencyDoctor>({} as AgencyDoctor);

  const onEdit = async () => {
    navigate(Routes.adminAgencyUserEdit(agentGuid!));
  };

  const controls = <EditButton width="160px" onClick={onEdit} />;

  useEffect(() => {
    if (agencyDoctorInfo) {
      setAgency(agencyDoctorInfo);
    }
  }, [agencyDoctorInfo]);

  return (
    <BaseSpinWrapper spinning={agencyDoctorInfoLoading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminAgencyUsers}
          title={t('labels.agencyUserInformation')}
          controls={controls}
        />
        <AgencyDoctorViewForm info={agency} />
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminAgencyDoctorViewPage;
