import i18next from 'i18next';
import { splitByUppercase } from 'infrastructure/functions/split-by-uppercase';
import ChevronRightIcon from 'components/atoms/icons/chevron-right.svg?react';
import { parse } from 'date-fns';
import { convertUTCToLocal } from 'utils/date';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { Nullable } from 'infrastructure/types';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { EditorLog } from 'infrastructure/classes/logs/editor-log';

const checkValue = (value: Nullable<string>) =>
  !value || value === 'null' ? i18next.t('labels.bannerNoData') : value;

const changesToMapper: { [key: string]: string } = {
  ccmManager: i18next.t('historyLog.ccmManager'),
  onboardingManager: i18next.t('historyLog.onboardingManager'),
  patManager: i18next.t('historyLog.patManager'),
  ccmPatManager: i18next.t('historyLog.ccmPatManager'),
  ccmOnboardingAgent: i18next.t('historyLog.ccmOnboardingAgent'),
};

const changesToKeys = Object.keys(changesToMapper);

export const editorLogColumns = (): IBaseTableColumns<EditorLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '170px',
      render(value) {
        const date = parse(value, 'MM-dd-yyyy | hh:mm a', new Date());
        const localDate = convertUTCToLocal(date.toString());
        let formattedDate;

        if (localDate) {
          formattedDate = DateFormatter({
            date: localDate,
            format: DateFormats['MM-dd-yyyy | h:mm a'],
          });
        }

        return <span className={s.date}>{formattedDate}</span>;
      },
    },
    {
      label: i18next.t('labels.section'),
      key: 'section',
      width: '150px',
      render(value) {
        return (
          <span className={s.date}>{splitByUppercase(value).join(' ')}</span>
        );
      },
    },
    {
      label: i18next.t('labels.operation'),
      key: 'operation',
      width: '100px',
      render(value) {
        return <span className={s.date}>{value}</span>;
      },
    },
    {
      label: i18next.t('labels.changes'),
      key: 'eventTitle',
      render(_, record) {
        return record.changes.map((el, index) => {
          return (
            <div key={index} className={s.change}>
              <span className={s.date}>{el.field}</span>
              <div className={s.info}>
                <span className={s.from}>
                  {`${i18next.t('labels.from')}: ${
                    record.section === i18next.t('labels.patientCareAgents') &&
                    changesToKeys.includes(el.from)
                      ? changesToMapper[el.from]
                      : checkValue(el.from)
                  }`}
                </span>
                <ChevronRightIcon />
                <span className={s.to}>
                  {` ${i18next.t('labels.to')}: ${
                    record.section === i18next.t('labels.patientCareAgents') &&
                    changesToKeys.includes(el.to)
                      ? changesToMapper[el.to]
                      : checkValue(el.to)
                  }`}
                </span>
              </div>
            </div>
          );
        });
      },
    },
    {
      label: i18next.t('labels.person'),
      key: 'person',
      width: '250px',
      render(value) {
        return <span className={s.date}>{value}</span>;
      },
    },
    {
      label: i18next.t('labels.userType'),
      key: 'userType',
      width: '100px',
      render(value) {
        return <span className={s.date}>{value}</span>;
      },
    },
  ];
};
