export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const hypertensionSymptoms = [
  { label: 'Fruity-smelling breath', value: 'breath', required: true },
  { label: 'Nausea/vomiting', value: 'vomiting', required: true },
  { label: 'Diarrhea', value: 'diarrhea', required: true },
  { label: 'Abdominal pain', value: 'pain', required: true },
  {
    label: 'Shortness of breath',
    value: 'shortnessOfBreath',
    required: true,
  },
  { label: 'Confusion', value: 'confusion', required: true },
  { label: 'Loss of consciousness', value: 'consciousness', required: true },
  { label: 'Headaches', value: 'headaches' },
  { label: 'Blurred vision', value: 'blurredVision' },
  { label: 'Fatigue', value: 'fatigue' },
  { label: 'Frequent urination', value: 'frequentUrination' },
  { label: 'Excessive thirst', value: 'excessiveThirst' },
  { label: 'Weight loss', value: 'weightLoss' },
];

export const hypotensionSymptoms = [
  {
    label: 'Mental confusion/unresponsiveness',
    value: 'confusion',
    required: true,
  },
  { label: 'Seizures', value: 'seizures', required: true },
  {
    label: 'Loss of coordination',
    value: 'lossCoordination',
    required: true,
  },
  { label: 'Muscle weakness', value: 'muscleWeakness', required: true },
  {
    label: 'Inability to eat or drin',
    value: 'inabilityEatDrin',
    required: true,
  },
  {
    label: 'Difficulty speaking',
    value: 'difficultySpeaking',
    required: true,
  },
  {
    label: 'Dizziness/lightheadedness',
    value: 'dizziness',
    required: true,
  },
  { label: 'Increased hunger', value: 'increaseHunger' },
  { label: 'Shakiness', value: 'shakiness' },
  { label: 'Clammy skin/sweating', value: 'clammySkinSweating' },
  { label: 'Sleepiness', value: 'sleepiness' },
];

const factorsAssessment = [
  {
    label: 'Dietary changes',
    value: 'dietaryChanges',
  },
  {
    label: 'Physical activity changes',
    value: 'activity',
  },
  {
    label: 'Medication non-adherence (Why? Cost, access, etc)',
    value: 'nonAdherence',
  },
  {
    label: 'Recent illness or infection',
    value: 'recentIllness',
  },
  {
    label: 'Recent steroid use (oral or inhaled)',
    value: 'recentSteroid',
  },
  {
    label: 'Recent injury/surgery',
    value: 'recentInjury',
  },
  {
    label: 'Stress/psychosocial distress',
    value: 'stress',
  },
  {
    label: 'Device needs QC',
    value: 'needQc',
  },
];

export const assessmentBloodGlucoseForm = [
  {
    title: '#1 Did patient take routine medications as prescribed?',
    type: 'checkbox',
    required: false,
    name: 'prescribed',
    options: [
      { value: 'oral', label: 'Oral antidiabetic' },
      { value: 'injectable', label: 'Antidiabetic injectable' },
      { value: 'insulinLong', label: 'Insulin - long acting' },
      { value: 'insulinShort', label: 'Insulin - short acting' },
      { value: 'insulinPump', label: 'Insulin - Pump' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#2 Was blood sugar fasting or non-fasting?',
    type: 'radio',
    required: false,
    name: 'bloodSugar',
    options: [
      { value: 'fasting', label: 'Fasting' },
      { value: 'non-fasting', label: 'Non-fasting' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#3 Factors Assessment',
    type: 'radio-list',
    required: false,
    options: factorsAssessment,
    name: 'factorsAssessment',
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#4 Patient is able to take another reading at time of call?',
    subtitle: 'Is the reading still out of bounds?',
    type: 'radio',
    required: true,
    name: 'isOneMoreReading',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    extra: [
      {
        type: 'radio',
        name: 'bounds',
        label: 'Is the reading still out of bounds?',
        options: booleanOptions,
        divider: true,
      },
      {
        type: 'input',
        name: 'repeatReadingWas',
        options: [
          { label: 'The repeat reading was:', value: 'repeatReadingWas' },
        ],
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#5 Signs and Symptoms of HYPERglycemia',
    type: 'radio-list',
    name: 'symptoms',
    required: true,
    options: hypertensionSymptoms,
    extra: [
      {
        name: 'symptomsOnset',
        type: 'text',
        label: 'Date of onset/duration',
      },
    ],
    readingTypeFor: ['max'],
  },
  {
    title: '#5 Signs and Symptoms of HYPOglycemia',
    type: 'radio-list',
    name: 'symptoms',
    required: true,
    options: hypotensionSymptoms,
    extra: [
      {
        name: 'symptomsOnset',
        type: 'text',
        label: 'Date of onset/duration',
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#6 Nursing Intervention',
    type: 'checkbox',
    name: 'nursingIntervention',
    required: true,
    options: [
      {
        value: 'emergencyRoom',
        label: 'Emergency room/urgent care/911 assist',
      },
      {
        value: 'escalation',
        label: 'Escalation to clinic',
      },
      {
        value: 'nursingEducation',
        label: 'Nursing education',
      },
    ],
    extra: [
      {
        type: 'checkbox',
        name: 'notEscalated',
        options: [
          {
            value: 'notEscalatedRepeat',
            label: 'Not escalated (Repeat reading recovered)',
          },
          {
            value: 'notEscalatedError',
            label: 'Not escalated (Device/User error)',
          },
          {
            value: 'notEscalatedProtocol',
            label: 'Not escalated (Clinical Instructions)',
          },
        ],
      },
      {
        name: 'interventionDetails',
        type: 'text',
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#7 Patient Understanding',
    description:
      'Patient verbalized understanding and is agreeable to nursing recommendation',
    type: 'radio',
    required: false,
    name: 'verbalized',
    options: booleanOptions,
    readingTypeFor: ['min', 'max'],
  },
];

export const template = [
  {
    key: 'reading',
    value: "Nurse assessed patient's {{{ reading }}} blood glucose reading.",
  },
  {
    key: 'prescribed',
    value: 'Patient confirms taking routine {{{ prescribed }}} as prescribed.',
  },
  {
    key: 'bloodSugar', // fasting/non-fasting
    value: 'Blood glucose was taken {{{ bloodSugar }}}.',
  },
  {
    key: 'confirmedFactors',
    value:
      'Nurse assessed factors that may impact blood glucose readings.Patient confirms {{{ confirmedFactors }}}.',
  },
  {
    key: 'deniedFactors',
    value: 'Patient denies {{{ deniedFactors }}}.',
  },
  {
    key: 'oneMoreReading', // is/is not
    value:
      'Patient {{{ oneMoreReadingText }}} able to take another reading at the time of call.',
  },
  {
    key: 'bounds', // is/is not
    value: 'The repeat reading {{{ boundsText }}} out of bounds.',
  },
  {
    key: 'symptomsDenied',
    value: 'Patient denies any symptoms at this time.',
  },
  {
    key: 'symptomsEmergent',
    value: 'Patient confirms emergent symptoms {{{ confirmedSymptoms }}}.',
  },
  {
    key: 'symptomsRegularConfirmed',
    value: 'Patient confirms {{{ confirmedSymptoms }}}.',
  },
  {
    key: 'symptomsRegularDenied',
    value: 'Patient denies {{{ deniedSymptoms }}}.',
  },
  {
    key: 'symptomsOnset',
    value: 'Symptoms started on {{{ symptomsOnset }}}.',
  },
  {
    key: 'emergencyRoom',
    value:
      'Patient requires immediate care.Nurse provided emergency assistance to patient.',
  },
  {
    key: 'escalation',
    value:
      'Patient requires provider intervention.Nurse escalated the reading to the clinic according to run book protocol.',
  },
  {
    key: 'nursingEducation',
    value: 'Nurse provided education to patient.',
  },
  {
    key: 'notEscalated',
    value: 'Reading not escalated due to {{{ notEscalated }}}.',
  },
  {
    key: 'interventionDetails',
    value: '{{{ interventionDetails }}}.',
  },
  {
    key: 'verbalized', // is/is not
    value:
      'Patient verbalized understanding and {{{ verbalizedText }}} agreeable to nursing recommendation.',
  },
];
