import i18next from 'i18next';

export interface ISalesForceIntegration {
  id: string;
  name: string;
}

export const salesForceIntegrationDataSource = (): ISalesForceIntegration[] => {
  return [
    {
      id: 'disabled',
      name: i18next.t('salesForceIntegrations.disabled'),
    },
    {
      id: 'inherited',
      name: i18next.t('salesForceIntegrations.inherited'),
    },
  ];
};
