import i18next from 'i18next';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';
import { LoginStatus } from 'infrastructure/enums';

export interface IStatusItem {
  id: LoginStatus;
  name: string;
  color?: string;
}

type StatusArray = ModifiedArray<IStatusItem>;

export const loginStatusesDataSource = (): StatusArray => {
  const dataSource = new ModifiedArray<IStatusItem>(
    {
      id: LoginStatus.Active,
      name: i18next.t('loginStatuses.active'),
      color: '#2F8521',
    },
    {
      id: LoginStatus.Inactive,
      name: i18next.t('loginStatuses.inactive'),
      color: '#BD363B',
    },
    {
      id: LoginStatus.Blocked,
      name: i18next.t('loginStatuses.blocked'),
      color: '#667180',
    },
  );

  return dataSource;
};
