import React, { useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { format } from 'date-fns';
import common from 'constants/common';
import withLoader from 'components/organisms/withLoader';
import DownloadRoundedIcon from 'components/atoms/icons/downloadRoundedIcon';
import Pagination from 'components/molecules/paginationTransition';
import PropTypes from 'prop-types';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { API_BILLING_REPORTS } from 'services/dataService/ccmResources';
import ExpandableBillingReportFilters from 'components/molecules/billing-report/expandable-filters/index.tsx';

import s from './styles.module.scss';
import ActivityRow from './activityRow';

const BillingTable = ({
  timeRanges,
  filters,
  setFilters,
  isLoading,
  items,
  count,
  page,
  setPage,
  itemsPerPage,
}) => {
  const [csvLoading, setCsvLoading] = useState(false);
  const [dateValue, setDateValue] = useState(timeRanges[0]);
  const [dateLabel, setDateLabel] = useState(format(new Date(), 'MMM-yyyy'));

  const sendReport = async () => {
    setCsvLoading(true);
    const { error } = await dataService.createOne(API_BILLING_REPORTS, filters);
    setCsvLoading(false);
    return showResult(error, common.submitSuccessTypes.sendReport);
  };

  const onDateChange = (data) => {
    const value = data.split('-');
    const selectedTimeRange = timeRanges.find((item) =>
      item.value.every((v, i) => v === Number(value[i])),
    );

    setDateValue(selectedTimeRange);
    setFilters({
      ...filters,
      billingYear: value[0],
      billingMonth: value[1],
    });
    setDateLabel(selectedTimeRange.label);
  };

  const onFilterReset = () => {
    setDateValue(timeRanges[0]);
    setFilters({
      billingYear: timeRanges.at(0)?.value[0],
      billingMonth: timeRanges.at(0)?.value[1],
    });
    setDateLabel(format(new Date(), 'MMM-yyyy'));
  };

  return (
    <div className="billing-table">
      <Card className="card-border">
        <CardBody>
          <div className="performance-review__header">
            <div className="billing-table-header">
              <div>CCM Billing Report</div>
            </div>
            <div className="performance-review__header-icon">
              {withLoader(
                csvLoading,
                <button data-cy="download-button" onClick={sendReport}>
                  <DownloadRoundedIcon className="performance-review__header-icon-svg" />
                  <br />
                  <span>Download</span>
                </button>,
              )}
            </div>
          </div>
          <div className={s['filter-wrapper']}>
            <ExpandableBillingReportFilters
              filters={filters}
              setFilters={setFilters}
              timeRanges={timeRanges}
              dateValue={dateValue}
              onDateChange={onDateChange}
              onFilterReset={onFilterReset}
            />
          </div>
          <div>
            <div className="table-sticky-header">
              {withLoader(
                isLoading,
                <Table borderless cellSpacing="0" cellPadding="0">
                  <thead data-cy="ccm-billing-report-table-header">
                    <tr>
                      <th>Patient Guid</th>
                      <th>Insurance Number</th>
                      <th>Patient Name</th>
                      <th>DOB</th>
                      <th>MRN</th>
                      <th>ICD-10 Codes</th>
                      <th>CPT 99490 20 min</th>
                      <th>CPT 99439 40 min</th>
                      <th>CPT 99439 60 min</th>
                      <th>Time Spent</th>
                    </tr>
                  </thead>
                  <tbody data-cy="ccm-billing-report-table-body">
                    {items.map((item, id) => (
                      <ActivityRow key={id} rowItem={item} date={dateLabel} />
                    ))}
                  </tbody>
                </Table>,
              )}
            </div>
          </div>
          <div className="billing-table-button-pagination flex-row-reverse">
            <Pagination
              title=""
              page={page}
              pageTotal={items.length}
              itemsPerPage={itemsPerPage}
              total={count}
              handleFirstClick={() => setPage(1)}
              handleLastClick={() => setPage(Math.ceil(count / itemsPerPage))}
              handlePrevClick={() => setPage(page - 1)}
              handleNextClick={() => setPage(page + 1)}
              handleGoToPage={(numberPage) => setPage(numberPage)}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

BillingTable.propTypes = {
  timeRanges: PropTypes.array.isRequired,
  filters: PropTypes.shape().isRequired,
  setFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};

export default BillingTable;
