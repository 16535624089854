import React, { useEffect, useState } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate, useParams } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import EditButton from 'components/atoms/controls/edit-button';
import { useDoctorInfo } from 'infrastructure/hooks/doctor';
import DoctorViewForm from 'components/molecules/doctor/view-form';
import { Doctor } from 'infrastructure/classes/doctor/info';
import DoctorHistoryLogsTable from 'components/molecules/doctor/history-log-table';
import BaseGrid from 'components/atoms/base-grid';
import { isValidGuid } from 'infrastructure/functions/is-valid-guid';
import { showResult } from 'infrastructure/hooks/utils';

const AdminUserViewPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userGuid: _doctorGuid } = useParams<{
    userGuid: string;
  }>();
  const doctorGuid = _doctorGuid;

  useEffect(() => {
    if (!doctorGuid || !isValidGuid(doctorGuid)) {
      showResult(t('errors.invalidGuid'));
      navigate('/404', { replace: true });
    }
  }, [doctorGuid, navigate]);
  if (!doctorGuid || !isValidGuid(doctorGuid)) {
    return null;
  }

  const { doctorInfoLoading: loading, doctorInfo } = useDoctorInfo({
    doctorGuid,
  });

  const [doctor, setDoctor] = useState<Doctor>({} as Doctor);

  const onEdit = async () => {
    navigate(Routes.adminUsersEdit(doctorGuid));
  };

  const controls = <EditButton width="160px" onClick={onEdit} />;

  useEffect(() => {
    if (doctorInfo) {
      setDoctor(new Doctor(doctorInfo));
    }
  }, [doctorInfo]);

  return (
    <BaseSpinWrapper spinning={loading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminUsers}
          title={t('labels.clinicUserInformation')}
          controls={controls}
        />
        <BaseGrid rowGap={40}>
          <DoctorViewForm info={doctor} />
          <DoctorHistoryLogsTable guid={doctorGuid} />
        </BaseGrid>
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminUserViewPage;
