import i18next from 'i18next';
import {
  ClinicSalesForceIntegrations,
  type ClinicSalesForceIntegration,
} from 'infrastructure/enums';

export interface IClinicSalesForceItem {
  id: ClinicSalesForceIntegration;
  name: string;
}

export const clinicSalesForceDataSource = (): IClinicSalesForceItem[] => {
  return [
    {
      id: ClinicSalesForceIntegrations.Enabled,
      name: i18next.t('clinicSalesForce.enabled'),
    },
    {
      id: ClinicSalesForceIntegrations.Disabled,
      name: i18next.t('clinicSalesForce.disabled'),
    },
  ];
};
