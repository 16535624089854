import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableFilterPanel, {
  ExpandableFilterPanelItem,
} from 'components/molecules/expandable-filter-panel';
import useKey from 'infrastructure/hooks/utils/use-key';
import DoctorSelectBox from 'components/molecules/doctor-select-box';
import { omit } from 'lodash';
import BaseSelectBox from 'components/atoms/base-select-box';
import { RoleGroups } from 'infrastructure/consts/role-groups';

import s from './styles.module.scss';

import type { IFilters } from 'infrastructure/interfaces';

interface ITimeRanges {
  label: string;
  value: number[];
}

interface IProps {
  filters: IFilters;
  setFilters: (val: any) => void;
  onDateChange: (val: any) => void;
  onFilterReset: () => void;
  timeRanges: ITimeRanges[];
  dateValue: ITimeRanges;
}

const ExpandableBillingReportFilters: React.FC<IProps> = (props) => {
  const {
    filters,
    timeRanges,
    dateValue,
    setFilters,
    onDateChange,
    onFilterReset,
  } = props;

  const { t } = useTranslation();
  const { key } = useKey();

  const handleReset = () => {
    onFilterReset();
  };

  const count = Object.keys(filters).length - 1;

  const timeRangesOptions = useMemo(() => {
    return timeRanges.map((item) => ({
      label: item.label,
      value: item.value.join('-'),
      title: item.label,
    }));
  }, []);

  return (
    <ExpandableFilterPanel
      filtersCount={count}
      onReset={handleReset}
      openWithValue
      isExpanded
    >
      <div className={s.form} key={key}>
        <ExpandableFilterPanelItem label={t('labels.practice')}>
          <DoctorSelectBox
            allowClear
            placeholder={t('labels.search')}
            roles={RoleGroups.RpmDashboardFilterDoctorSearchRoles}
            value={filters.doctorGuid}
            onChange={(value) => {
              if (value) setFilters({ ...filters, doctorGuid: value });
              else setFilters(omit(filters, ['doctorGuid']));
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.timeRange')}>
          <BaseSelectBox
            label=""
            options={timeRangesOptions}
            value={timeRangesOptions.find((el) => el.label === dateValue.label)}
            onChange={onDateChange}
          />
        </ExpandableFilterPanelItem>
      </div>
    </ExpandableFilterPanel>
  );
};

export default ExpandableBillingReportFilters;
