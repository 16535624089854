import i18next from 'i18next';
import BaseTableActions from 'components/atoms/base-table/actions';
import {
  ModuleType,
  DeviceGeneration,
  DeviceStatus,
  RpmStatuses,
} from 'infrastructure/enums';
import BaseButton from 'components/atoms/baseButton';
import CartIcon from 'components/atoms/icons/cart';
import forms from 'constants/forms';
import { useMemo } from 'react';

import Empty from './empty';
import DeviceInformation from './device-information';
import CellularSignal from './cellular-signal';
import BatteryLevel from './battery-level';
import AlertsState from './alerts-state';
import AlertsTime from './alerts-time';
import s from './styles.module.scss';

import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface IDevicesColumnsProps {
  module: ModuleType;
  rpmStatus?: RpmStatuses;
  onDelete: (record: PatientDevice) => void;
  onEdit: (record: PatientDevice) => void;
  onToggleStatus: (record: PatientDevice) => void;
  onActivity: (record: PatientDevice) => void;
  onOrder: (record: PatientDevice) => void;
  iamPatientAcceptance: boolean;
}

export const devicesColumns = ({
  module,
  rpmStatus,
  onDelete,
  onEdit,
  onToggleStatus,
  onActivity,
  onOrder,
  iamPatientAcceptance,
}: IDevicesColumnsProps): IBaseTableColumns<PatientDevice> => {
  const columns = useMemo(() => {
    let col: IBaseTableColumns<PatientDevice> = [
      {
        label: i18next.t('labels.deviceInformation'),
        key: 'deviceInformation',
        width: '215px',
        render: (_, record) => <DeviceInformation data={record} />,
      },
      {
        label: i18next.t('labels.readingType'),
        key: 'readingType',
        width: '300px',
        responsive: ['md'],
        render: (_, record) =>
          record.readingTypes
            .map((el) => i18next.t(`readingTypes.${el}`))
            .join(', '),
      },
      {
        label: i18next.t('labels.deviceCheckInTime'),
        key: 'log.localTime',
        width: '200px',
        render: (_, record) => (record.log ? record.log.localTime : <Empty />),
      },
      {
        label: i18next.t('labels.cellularSignal'),
        key: 'cellularSignal',
        width: '120px',
        align: 'center',
        render: (_, record) =>
          record.log ? <CellularSignal data={record} /> : <Empty />,
      },
      {
        label: i18next.t('labels.batteryLevel'),
        key: 'batteryLevel',
        width: '115px',
        align: 'center',
        render: (_, record) =>
          record.log ? <BatteryLevel data={record} /> : <Empty />,
      },
      {
        label: i18next.t('labels.alertsState'),
        key: 'alertsState',
        width: '230px',
        align: 'center',
        render: (_, record) =>
          record.alarm || record.alarmChange ? (
            <AlertsState data={record} />
          ) : (
            <Empty />
          ),
      },
      {
        label: i18next.t('labels.alertsTime'),
        key: 'alertsTime',
        width: '180px',
        align: 'center',
        render: (_, record) =>
          record.alarm || record.alarmChange ? (
            <AlertsTime data={record} />
          ) : (
            <Empty />
          ),
      },
      {
        label: i18next.t('labels.actions'),
        key: 'actions',
        align: 'center',
        width: '40px',
        render: (_, record) => (
          <BaseTableActions
            dataCy="device-table-device-actions"
            disabled={iamPatientAcceptance}
            items={[
              {
                key: 'status',
                className: s['dropdown-action'],
                label:
                  record.status === DeviceStatus.Active
                    ? i18next.t('controls.deactivateDevice')
                    : i18next.t('controls.activateDevice'),
                onClick: () => onToggleStatus(record),
              },
              {
                key: 'editDevice',
                className: s['dropdown-action'],
                label: i18next.t('controls.editDevice'),
                onClick: () => onEdit(record),
              },
              {
                key: 'activityLog',
                className: s['dropdown-action'],
                label: i18next.t('controls.activityLog'),
                visible: record.generation === DeviceGeneration.Gen1,
                onClick: () => onActivity(record),
              },
              {
                key: 'deleteDevice',
                className: s['dropdown-action-delete'],
                label: i18next.t('controls.deleteDevice'),
                onClick: () => onDelete(record),
              },
            ]}
          />
        ),
      },
    ];

    if (module !== ModuleType.ADMIN) {
      const excludedColumns = ['alertsState', 'alertsTime', 'actions'];
      col = col.filter((el) => !excludedColumns.includes(el.key));
    }

    if (module === ModuleType.RPM && rpmStatus) {
      const disableConsumable =
        forms.patientDischargeStatuses.some((el) => el.id === rpmStatus) ||
        rpmStatus === RpmStatuses.ReferralWithdrawn;

      col.push({
        label: i18next.t('labels.consumable'),
        key: 'consumable',
        width: '100px',
        align: 'center',
        render: (_, record) => (
          <BaseButton
            type="secondary"
            disabled={
              record.status === DeviceStatus.Inactive || disableConsumable
            }
            label={i18next.t('controls.order')}
            icon={<CartIcon />}
            onClick={() => onOrder(record)}
          />
        ),
      });
    }

    return col;
  }, [
    module,
    rpmStatus,
    onDelete,
    onEdit,
    onToggleStatus,
    onActivity,
    onOrder,
  ]);

  return columns;
};
