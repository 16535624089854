import {
  parseSearchArrayValue,
  parseSearchBooleanValue,
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';
import { useQueryFilterNew } from 'infrastructure/hooks/common/use-query-param-new';

import type { TParseFunctions } from 'infrastructure/hooks/common/use-query-param-new';
import type { CcmStatuses, RpmStatuses } from 'infrastructure/enums';

export type TPatientFilter = {
  page: number;
  patientKey: string;
  ccmStatus?: CcmStatuses[];
  status?: RpmStatuses[];
  patientGuid?: string;
  patientLabel?: string;
  excludeClinicGuid?: string[];
  clinicGuid?: string[];
  allExcluded?: boolean;
};

const defaultFilter: TPatientFilter = {
  page: 1,
  patientKey: 'fullName',
};

const parseFunctions: TParseFunctions<TPatientFilter> = {
  page: parseSearchNumberValue,
  ccmStatus: parseSearchArrayValue,
  status: parseSearchArrayValue,
  patientGuid: parseSearchStringValue,
  patientLabel: parseSearchStringValue,
  patientKey: parseSearchStringValue,
  clinicGuid: parseSearchArrayValue,
  excludeClinicGuid: parseSearchArrayValue,
  allExcluded: parseSearchBooleanValue,
};

export const usePatientsFilter = () => {
  return useQueryFilterNew<TPatientFilter>(defaultFilter, parseFunctions, [
    'page',
  ]);
};
