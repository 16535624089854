export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const hypotensionSymptoms = [
  {
    label: 'Shortness of breath (without exertion)',
    value: 'shortnessBreathWithoutExertion',
    required: true,
  },
  {
    label: 'Chest pain',
    value: 'chestPain',
    required: true,
  },
  {
    label: 'Chest tightness',
    value: 'chestTightness',
    required: true,
  },
  {
    label: 'Dizziness/Lightheadedness or confusion',
    value: 'dizziness',
    required: true,
  },
  {
    label: 'Shallow/rapid breathing',
    value: 'rapidBreathing',
    required: true,
  },
  {
    label: 'Blue tinge to lips or fingers',
    value: 'difficultySpeaking',
    required: true,
  },
  {
    label: 'Wet productive cough',
    value: 'wetCough',
    required: true,
  },
  {
    label: 'Yellow, green, tan or bloody sputum',
    value: 'sputum',
    required: true,
  },
  {
    label: 'Fever/chills',
    value: 'feverChills',
    required: true,
  },
  {
    label: 'Feeling cold, tired, lethargic',
    value: 'lethargic',
    required: true,
  },
  {
    label: 'Dry hacking cough',
    value: 'cough',
  },
  {
    label: 'Shortness of breath (with exertion)',
    value: 'shortnessBreathWithExertion',
  },
  {
    label: 'Pursed lip breathing/tripod breathing',
    value: 'tripodBreathing',
  },
];

const factorsAssessment = [
  {
    label: 'Taking a few deep breaths before reading',
    value: 'deepBreaths',
  },
  {
    label: 'Hands are warm and dry',
    value: 'warmAndDry',
  },
  {
    label: 'Wearing fingernail polish',
    value: 'fingernailPolish',
  },
];

export const nursingInterventionOptions = [
  {
    value: 'emergencyRoom',
    label: 'Emergency room/urgent care/911 assist',
  },
  {
    value: 'escalation',
    label: 'Escalation to clinic',
  },
  {
    value: 'nursingEducation',
    label: 'Nursing education',
  },
];

export const notEscalatedOptions = [
  {
    value: 'notEscalatedRepeat',
    label: 'Not escalated (Repeat reading recovered)',
  },
  {
    value: 'notEscalatedError',
    label: 'Not escalated (Device/User error)',
  },
  {
    value: 'notEscalatedProtocol',
    label: 'Not escalated (Clinical Instructions)',
  },
];

export const assessmentSpo2Form = [
  {
    title: '#1 Did you speak to patient?',
    description:
      'Choose other if unlisted family member & type in their relationship. (Ex: Daughter in law) Add Name of Individual you spoke to in other.',
    type: 'radio',
    required: true,
    name: 'patient',
    options: [
      { value: 'Patient', label: 'Patient' },
      { value: 'Spouse', label: 'Spouse' },
      { value: 'Caregiver', label: 'Caregiver' },
      { value: 'Emergency Contact', label: 'Emergency Contact' },
      { value: null, label: 'Other' },
    ],
    extra: [
      {
        type: 'doubleRowInput',
        name: 'other',
        options: [
          { label: 'Other Member', value: 'other' },
          { label: 'Relationship', value: 'relationship' },
        ],
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#2 Mental Status Assessment',
    description:
      'Oriented and Coherent 1: Person 2: Person, place 3: Person, place, time 4: Person, place, time, situation?',
    type: 'radio',
    required: false,
    name: 'AOC',
    options: [
      { value: 'x1', label: 'x1' },
      { value: 'x2', label: 'x2' },
      { value: 'x3', label: 'x3' },
      { value: 'x4', label: 'x4' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#3 Appointments',
    type: 'datepicker',
    name: 'datepicker',
    required: false,
    options: [
      {
        value: 'last',
        label: "Last Doctor's Appointment",
        type: 'max',
      },
      {
        value: 'next',
        label: "Next Doctor's Appointment",
        type: 'min',
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#4 Patient is able to take another reading at time of call?',
    subtitle: 'Is the reading still out of bounds?',
    type: 'radio',
    required: true,
    name: 'isOneMoreReading',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    extra: [
      {
        type: 'radio',
        name: 'bounds',
        label: 'Is the reading still out of bounds?',
        options: booleanOptions,
        divider: true,
      },
      {
        type: 'input',
        name: 'repeatReadingWas',
        options: [
          { label: 'The repeat reading was:', value: 'repeatReadingWas' },
        ],
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#5	Signs and Symptoms of Hypoxia',
    type: 'radio-list',
    name: 'symptoms',
    required: true,
    options: hypotensionSymptoms,
    extra: [
      {
        name: 'symptomsOnset',
        type: 'text',
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#6 Did patient take routine medications as prescribed?',
    type: 'radio',
    required: false,
    name: 'isTakeMedications',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    extra: [
      {
        type: 'checkbox',
        name: 'prescribed',
        options: [
          { value: 'oxygen', label: 'Oxygen' },
          { value: 'nebulizer', label: 'Nebulizer' },
          { value: 'rescueInhaler', label: 'Rescue inhaler' },
          { value: 'oralSteroid', label: 'Oral steroid' },
        ],
      },
      {
        type: 'input',
        name: 'otherPrescribed',
        options: [{ label: 'Other', value: 'otherPrescribed' }],
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#7 Did patient take any prescribed PRN medications?',
    type: 'radio',
    required: false,
    name: 'isTakePrnMedications',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    extra: [
      {
        type: 'checkbox',
        name: 'prnMedications',
        options: [
          { value: 'oxygen', label: 'Oxygen' },
          { value: 'nebulizer', label: 'Nebulizer' },
          { value: 'rescueInhaler', label: 'Rescue inhaler' },
          { value: 'oralSteroid', label: 'Oral steroid' },
        ],
      },
      {
        type: 'input',
        name: 'otherPrnMedications',
        options: [{ label: 'Other', value: 'otherPrnMedications' }],
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#8 Does patient wear home O2?',
    description: 'What is the current setting?',
    name: 'wearO2Home',
    required: false,
    type: 'radio',
    options: booleanOptions,
    readingTypeFor: ['min'],
    flexBlock: true,
    extra: [
      {
        type: 'inputNumber',
        name: 'homeO2Setting',
        options: [{ label: 'Setting [L/min]:', value: 'homeO2Setting' }],
      },
    ],
  },
  {
    title: '#9 Factors Assessment',
    type: 'radio-list',
    required: false,
    options: factorsAssessment,
    name: 'factorsAssessment',
    readingTypeFor: ['min'],
  },
  {
    title: '#10 Nursing Intervention',
    type: 'checkbox',
    name: 'nursingIntervention',
    required: true,
    options: nursingInterventionOptions,
    extra: [
      {
        type: 'checkbox',
        name: 'notEscalated',
        options: notEscalatedOptions,
      },
      {
        name: 'interventionDetails',
        type: 'text',
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#11 Patient Understanding',
    description:
      'Patient verbalized understanding and is agreeable to nursing recommendation?',
    type: 'radio',
    required: false,
    name: 'verbalized',
    options: booleanOptions,
    readingTypeFor: ['min'],
  },
];
