import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import common from 'constants/common';
import { format } from 'date-fns';
import { isOnboardingPatient } from 'utils/userTypeHelper';
import { onboardingStatuses } from 'constants/patient_statuses';

const PatientRow = ({ patient }) => {
  const {
    connect: { handleConnectPopoutOpen, connectDetails },
  } = useDispatch();
  const onClickConnect = (isRpmCall) => {
    const details = {
      phone: patient.phone,
      guid: patient.guid,
      isRpmCall,
    };
    connectDetails(details);
    handleConnectPopoutOpen(true);
  };
  const statuses = [...onboardingStatuses, 'referral_withdrawn'];
  return (
    <tr className="activity-row">
      <td data-label="Patient Name">
        {!isOnboardingPatient(statuses, patient.fulfillment) ? (
          <Link to={`/patient/${patient.guid}`}>
            <strong>{patient.name}</strong>
          </Link>
        ) : (
          <strong>{patient.name}</strong>
        )}
      </td>
      <td data-label="MRN">{patient.mrn}</td>
      <td data-label="MRN">{patient.dob}</td>
      <td data-label="Phone Number">
        <div
          onClick={() => onClickConnect(true)}
          className="activity-row-number"
        >
          <strong onClick={() => onClickConnect(true)}>{patient.phone}</strong>
        </div>
      </td>
      <td data-label="Patient State">
        {patient.state && patient.state !== 'none' && patient.state}
      </td>
      <td data-label="Clinic Name">{patient.clinic}</td>
      <td data-label="Provider">{patient.primaryDoctor}</td>
      <td data-label="Provider Email">{patient.practiceEmail}</td>
      <td data-label="Insurance Type">{patient.insType}</td>
      <td data-label="Insurance Provider">{patient.insProvider}</td>
      <td data-label="Insurance Number">{patient.insNumber}</td>
      <td
        data-label="Fulfillment Stage"
        style={{
          backgroundColor: common.statusNames[patient.fulfillment]?.color,
          borderBottom: '1px solid #E8E8E8',
        }}
        className={patient.fulfillment}
      >
        {common.statusNames[patient.fulfillment]?.label}
      </td>
      <td data-label="Patient Referral Date">
        {format(new Date(patient?.createdAt), 'MM-dd-yyyy')}
      </td>
    </tr>
  );
};

PatientRow.propTypes = {
  patient: PropTypes.shape().isRequired,
};

export default PatientRow;
