import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandableFilterPanel, {
  ExpandableFilterPanelItem,
} from 'components/molecules/expandable-filter-panel';
import useKey from 'infrastructure/hooks/utils/use-key';
import MonthPicker from 'components/molecules/datepicker/monthPicker';
import PatientSelectBox from 'components/molecules/patient-select-box';
import DoctorSelectBox from 'components/molecules/doctor-select-box';
import AgencySelectBox from 'components/molecules/agency-select-box';
import { Tooltip } from 'antd';
import BooleanSelectBox from 'components/molecules/boolean-select-box';
import ClinicSelectBox from 'components/molecules/clinic-select-box';

import s from './styles.module.scss';

import type { Option } from 'components/molecules/select/base';
import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { IBaseSelectBoxOption } from 'components/atoms/base-select-box/types';

interface IProps {
  data: Filters;
  updateFilter: AppendFilters;
  resetFilter: () => void;
  count: number;
}

const ExpandableInteractiveTimeReportFilters: React.FC<IProps> = (props) => {
  const { data, count, updateFilter, resetFilter } = props;

  const { t } = useTranslation();
  const { key, changeKey } = useKey();

  const handleReset = () => {
    if (resetFilter) resetFilter();
    setTimeout(changeKey, 200);
  };

  const prepareOptions = (options: IBaseSelectBoxOption[]): Option[] => {
    return options.map((el) => {
      return {
        label: el.label as string,
        value: el.value,
      };
    });
  };

  const agencyGuides = useMemo(
    () => data.agencyGuid?.map((el) => el.value),
    [data.agencyGuid],
  );
  const clinicGuides = useMemo(
    () => data.clinicGuid?.map((el) => el.value),
    [data.clinicGuid],
  );
  const doctorGuides = useMemo(
    () => data.doctorGuid?.map((el) => el.value),
    [data.doctorGuid],
  );
  const patientGuides = useMemo(
    () => data.patientGuid?.map((el) => el.value),
    [data.patientGuid],
  );

  return (
    <ExpandableFilterPanel
      filtersCount={count}
      onReset={handleReset}
      openWithValue
      isExpanded
    >
      <div className={s.form} key={key}>
        <ExpandableFilterPanelItem borderBottom label={t('labels.timeRange')}>
          <MonthPicker
            value={{ year: data.year, month: data.month }}
            onChange={(value) => {
              if (!value) {
                updateFilter({
                  year: new Date().getFullYear(),
                  month: new Date().getMonth() + 1,
                });
              } else {
                updateFilter({ year: value.year, month: value.month });
              }
            }}
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem borderBottom label={t('labels.provider')}>
          <DoctorSelectBox
            allowClear
            mode="multiple"
            value={doctorGuides}
            clinicGuid={clinicGuides}
            agencyGuid={agencyGuides}
            onChange={(_, option: any) =>
              updateFilter({
                doctorGuid: prepareOptions(option),
                patientGuid: [],
              })
            }
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem borderBottom label={t('labels.patient')}>
          <PatientSelectBox
            allowClear
            mode="multiple"
            value={patientGuides}
            agencyGuid={agencyGuides}
            doctorGuid={doctorGuides}
            clinicGuid={clinicGuides}
            onChange={(_, option: any) =>
              updateFilter({ patientGuid: prepareOptions(option) })
            }
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.agency')}>
          <AgencySelectBox
            allowClear
            mode="multiple"
            value={agencyGuides}
            onChange={(_, option: any) =>
              updateFilter({
                agencyGuid: prepareOptions(option),
                clinicGuid: [],
                patientGuid: [],
                doctorGuid: [],
              })
            }
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.clinic')}>
          <ClinicSelectBox
            allowClear
            mode="multiple"
            value={clinicGuides}
            agencyGuid={agencyGuides}
            onChange={(_, option: any) =>
              updateFilter({
                clinicGuid: prepareOptions(option),
                patientGuid: [],
                doctorGuid: [],
              })
            }
          />
        </ExpandableFilterPanelItem>
        <ExpandableFilterPanelItem label={t('labels.childClinics')}>
          <Tooltip
            placement="top"
            trigger="hover"
            title={!clinicGuides && t('messages.disableChildClinicFilter')}
          >
            <div>
              <BooleanSelectBox
                allowClear
                trueLabel={t('labels.included')}
                falseLabel={t('labels.excluded')}
                disabled={!clinicGuides}
                value={data.childClinic}
                onChange={(childClinic) => {
                  updateFilter({ childClinic, page: undefined });
                }}
              />
            </div>
          </Tooltip>
        </ExpandableFilterPanelItem>
      </div>
    </ExpandableFilterPanel>
  );
};

export default ExpandableInteractiveTimeReportFilters;
