import { Roles } from 'infrastructure/enums';

const CCMRoles = [
  Roles.CcmBiller,
  Roles.CcmDoctor,
  Roles.CcmNursePractitioner,
  Roles.CcmPhysicianAssistant,
  Roles.CcmQHCP,
  Roles.CcmSupport,
  Roles.CcmRegisteredNurse,
];

const CCMOnboardingAgentRoles = [...CCMRoles, Roles.PatientAcceptance];

const AppointmentCompletedByAllowedRoles = [
  Roles.CcmQHCP,
  Roles.CcmRegisteredNurse,
];

const RpmDashboardFilterDoctorSearchRoles = [
  Roles.Doctor,
  Roles.NursePractitioner,
  Roles.RegisteredNurse,
  Roles.PhysicianAssistant,
  Roles.PharmD,
];

const AccessToMainPageRoles = [
  Roles.Support,
  Roles.Onboarding,
  Roles.Biller,
  Roles.Default,
  Roles.Doctor,
  Roles.NursePractitioner,
  Roles.PhysicianAssistant,
  Roles.RegisteredNurse,
  Roles.QHCP,
  Roles.PharmD,
  Roles.Clinical,
  Roles.ClientSuccessOnboarding,
  Roles.SalesRepresentative,
  Roles.ClientSuccessScaling,
];

const ClinicalRoles = [
  Roles.Doctor,
  Roles.NursePractitioner,
  Roles.PhysicianAssistant,
  Roles.RegisteredNurse,
  Roles.QHCP,
  Roles.PharmD,
  Roles.CcmPhysicianAssistant,
  Roles.CcmDoctor,
  Roles.CcmQHCP,
  Roles.CcmNursePractitioner,
  Roles.CcmRegisteredNurse,
  Roles.Clinical,
];

const ReadOnlyRoles = [
  Roles.CcmBiller,
  Roles.Biller,
  Roles.ClientSuccessOnboarding,
  Roles.SalesRepresentative,
  Roles.ClientSuccessScaling,
];

const SmsDashboardRoles = [Roles.SmsDashboard, Roles.SmsDashboardUnregistered];

export const RoleGroups = {
  CCMRoles,
  CCMOnboardingAgentRoles,
  AppointmentCompletedByAllowedRoles,
  RpmDashboardFilterDoctorSearchRoles,
  AccessToMainPageRoles,
  ClinicalRoles,
  ReadOnlyRoles,
  SmsDashboardRoles,
} as const;
