import { useQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useEffect } from 'react';
import { prepareLogsDateRange } from 'infrastructure/functions';

import { showResult } from '../utils';
import { apiUrlGenerator } from '../use-api';

import type {
  IDoctorHistoryLogsResponse,
  IPartialPaginationMeta,
} from 'infrastructure/interfaces';
import type { DateRange } from 'infrastructure/types';

interface Props {
  guid: string;
  dateRange?: DateRange;
  meta: IPartialPaginationMeta;
}

type TFilter = {
  guid: string;
  startTime?: number;
  endTime?: number;
} & IPartialPaginationMeta;

export const HISTORY_PER_PAGE = 15;

const getHistoryLogsFn = async ({ guid, ...filter }: TFilter) => {
  const url = `admins/admin-panel/doctors/${guid}/audit-logs`;

  const { data, error } = await dataService.getList<IDoctorHistoryLogsResponse>(
    apiUrlGenerator(url, filter),
  );

  if (error) {
    return Promise.reject(error);
  }

  return data;
};

export const getHistoryLogsKey = (filter: TFilter) => [
  'getDoctorHistoryLogs',
  filter,
];

export const useGetHistoryLogs = ({ guid, dateRange, meta }: Props) => {
  const { startTime, endTime } = prepareLogsDateRange(dateRange);
  const filter: TFilter = {
    ...meta,
    guid,
    startTime,
    endTime,
    items: meta.items ?? HISTORY_PER_PAGE,
  };

  const {
    data: historyLogs,
    isLoading,
    error,
  } = useQuery<IDoctorHistoryLogsResponse | undefined, string | undefined>({
    queryKey: getHistoryLogsKey(filter),
    queryFn: async () => getHistoryLogsFn(filter),
  });

  useEffect(() => {
    if (error) {
      showResult(error);
    }
  }, [error]);

  return { historyLogs, isLoading };
};
