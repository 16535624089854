import { getUserRoutes } from './helpers';

export const userHasAccess = (user: any, path: string) => {
  return getUserRoutes(user).includes(path);
};

export const filterRoutes = (user: any, routes: { link: string }[]) => {
  const userRoutes = getUserRoutes(user);
  return routes.filter(({ link }) => userRoutes.includes(link));
};
