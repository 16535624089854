import { clamp } from 'lodash';

export const safeClamp = (
  number: number | undefined | null,
  lower: number,
  upper: number,
): number => {
  if (typeof number !== 'number' || Number.isNaN(number)) {
    return 0;
  }
  return clamp(number, lower, upper);
};
