import React from 'react';
import classNames from 'classnames';
import { format, isToday, isYesterday } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/atoms/smsDashboard/avatar';
import common from 'constants/common';
import { useSelector } from 'react-redux';
import { PlatformEnums } from 'infrastructure/enums';
import BaseCheckbox from 'components/atoms/baseCheckbox';

import s from './styles.module.scss';

import type { RootState } from 'store';

interface IConversationProps {
  id: number;
  patientName: string;
  unreadMessageCount: number;
  hasPatientMessage: boolean;
  isRead: boolean;
  lastMessage: string;
  lastMessageDate: string;
  closed: boolean;
  spam: boolean;
  onClick: () => void;
  selected: boolean;
  selectMode: boolean;
  checkedConversations: any[];
  setCheckedConversations: (state: any) => void;
}

const Conversation: React.FC<IConversationProps> = (props) => {
  const {
    id,
    patientName,
    unreadMessageCount,
    hasPatientMessage,
    isRead,
    lastMessage,
    lastMessageDate,
    closed,
    spam,
    onClick,
    selected,
    selectMode,
    checkedConversations,
    setCheckedConversations,
  } = props;

  const { t } = useTranslation();

  const { conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const formatLastMessageDate = (messageDate: string) => {
    const date = new Date(messageDate);

    if (isToday(date)) {
      return format(date, common.dateFormats.timeAMPM);
    }
    if (isYesterday(date)) {
      return t('smsDashboard.yesterday');
    }
    return format(date, common.dateFormats.birthDate);
  };

  const conversationClassNames = classNames(
    s['conversation-item'],
    selected && s.selected,
    closed && s.closed,
    !isRead && s.unread,
  );

  const closeOpenClassNames = classNames(
    s['closed-status'],
    closed && s.closed,
    !closed && s.open,
  );

  const spamClassNames = classNames(
    s['spam-status'],
    spam ? s.spam : s['not-spam'],
  );

  const conversationStatusClassNames = classNames(
    s['message-status-badge'],
    isRead ? s.read : s.unread,
  );

  const closeOpenText = closed
    ? t('smsDashboard.closed')
    : t('smsDashboard.open');

  const spamNotSpamText = spam
    ? t('smsDashboard.spam')
    : t('smsDashboard.not_spam');

  const selectUnselectConversation = (conversationId: number) => {
    if (checkedConversations.includes(conversationId)) {
      setCheckedConversations(
        checkedConversations.filter((item) => item !== conversationId),
      );
    } else {
      setCheckedConversations([...checkedConversations, conversationId]);
    }
  };

  const conversationAvatar = () => {
    if (selectMode) {
      return (
        <div className={s['conversation-select-box']}>
          <BaseCheckbox
            checked={checkedConversations.includes(id)}
            onChange={() => {
              selectUnselectConversation(id);
            }}
          />
        </div>
      );
    }
    if (conversationType === PlatformEnums.UNREGISTERED) {
      return <Avatar />;
    }
    if (closed) {
      return <Avatar closed />;
    }
    if (!closed) {
      return <Avatar value={patientName} />;
    }
  };

  const displayName =
    patientName.length > 35 ? `${patientName.slice(0, 35)}...` : patientName;

  const onClickConversation = () => {
    if (selectMode) {
      selectUnselectConversation(id);
    }
    if (!selectMode) {
      onClick();
    }
  };
  return (
    <button onClick={onClickConversation} className={conversationClassNames}>
      <div className={s['message-wrapper']}>
        <div className={s['avatar-wrapper']}>{conversationAvatar()}</div>
        <div className={s['patient-name']} title={patientName}>
          {displayName}
        </div>
      </div>
      <div className={s['last-message-wrapper']}>
        <div className={s['last-message']} title={lastMessage}>
          {lastMessage}
        </div>
        <div className={s['conversation-indicators']}>
          {unreadMessageCount > 0 && isRead === false ? (
            <div className={s['notification-badge']}>{unreadMessageCount}</div>
          ) : null}
          {unreadMessageCount === 0 && isRead === false ? (
            <div className={s['notification-badge']}>&nbsp;</div>
          ) : null}
          {hasPatientMessage === true && isRead === true && !closed ? (
            <div className={conversationStatusClassNames} />
          ) : null}
          {closed && !unreadMessageCount ? (
            <div className={closeOpenClassNames} title={closeOpenText}>
              {closeOpenText}
            </div>
          ) : null}
          {!closed && spam ? (
            <div className={spamClassNames} title={spamNotSpamText}>
              {spamNotSpamText}
            </div>
          ) : null}
        </div>
      </div>
      <div className={s['time-wrapper']}>
        <p className={s['last-message-time']}>
          {formatLastMessageDate(lastMessageDate)}
        </p>
      </div>
    </button>
  );
};

export default Conversation;
