import { endOfDay, startOfMonth } from 'date-fns';
import {
  parseSearchArrayValue,
  parseSearchBooleanValue,
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';
import { useQueryFilterNew } from 'infrastructure/hooks/common/use-query-param-new';

import type { TParseFunctions } from 'infrastructure/hooks/common/use-query-param-new';
import type { IActivityReportListFilters } from 'infrastructure/interfaces';

const [startTime, endTime] = [startOfMonth(new Date()), endOfDay(new Date())];

const defaultFilter: IActivityReportListFilters = {
  status: null,
  reverse: false,
  reviewed: false,
  applySpecialFilter: true,
  childClinic: false,
  insuranceType: null,
  doctorGuid: [],
  startTime: startTime.getTime(),
  endTime: endTime.getTime(),
  page: 1,
};

const parseFunctions: TParseFunctions<IActivityReportListFilters> = {
  page: parseSearchNumberValue,
  startTime: parseSearchNumberValue,
  endTime: parseSearchNumberValue,
  status: parseSearchStringValue,
  readingType: parseSearchStringValue,
  clinicState: parseSearchStringValue,
  doctorGuid: parseSearchArrayValue,
  patientGuid: parseSearchStringValue,
  clinicGuid: parseSearchStringValue,
  assignee: parseSearchStringValue,
  slaReadings: parseSearchStringValue,
  insuranceType: parseSearchStringValue,
  actionTimesQuota: parseSearchStringValue,
  name: parseSearchStringValue,
  applySpecialFilter: parseSearchBooleanValue,
  reviewed: parseSearchBooleanValue,
  reverse: parseSearchBooleanValue,
  childClinic: parseSearchBooleanValue,
};

export const useActivityQueryFilter = () => {
  return useQueryFilterNew<IActivityReportListFilters>(
    defaultFilter,
    parseFunctions,
    ['page'],
  );
};
