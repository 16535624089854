import { stringToBoolean } from 'utils/helpers/string-to-boolean';

import type { FormikProps } from 'formik';
import type { IBaseAlertAssessmentProps } from 'components/molecules/rpm-alert-assessment/types';

export const isEmergentSymptoms = (
  formData: IBaseAlertAssessmentProps,
  emergentSymptoms: string[],
) => {
  return (
    formData.confirmedSymptoms.some((item: string) =>
      emergentSymptoms.includes(item),
    ) && formData.emergencyCare
  );
};

export const isOnlyConfirmedSymptoms = (
  formData: IBaseAlertAssessmentProps,
  symptomsListCount: number,
  emergentSymptoms: string[],
) => {
  return (
    formData.deniedSymptoms.length !== symptomsListCount &&
    formData.confirmedSymptoms.length &&
    (!formData.confirmedSymptoms.some((item: string) =>
      emergentSymptoms.includes(item),
    ) ||
      !formData.emergencyCare)
  );
};

export const isOnlyDeniedSymptoms = (
  formData: IBaseAlertAssessmentProps,
  symptomsListCount: number,
  emergentSymptoms: string[],
) => {
  return (
    formData.deniedSymptoms.length !== symptomsListCount &&
    formData.deniedSymptoms.length &&
    (!formData.confirmedSymptoms.some((item: string) =>
      emergentSymptoms.includes(item),
    ) ||
      !formData.emergencyCare)
  );
};

export const checkSymptomsUpdate = (
  requiredSymptomFields: string[],
  formik: FormikProps<any>,
  toggleModalCallback: () => void,
) => {
  const selectedRequiredFields = Object.entries(formik.values)
    .filter(([key, _]) => requiredSymptomFields.includes(key))
    .reduce((acc, [key, value]) => {
      acc[key] = stringToBoolean(value as string);
      return acc;
    }, {} as Record<string, any>);

  if (
    requiredSymptomFields.length === Object.keys(selectedRequiredFields).length
  ) {
    const hasTrueValue = Object.values(selectedRequiredFields).some(
      (value) => value === true,
    );

    if (hasTrueValue) {
      toggleModalCallback();
      formik.setFieldValue('checkSymptoms', false);
    }
  }
};

export const formatText = <T extends IBaseAlertAssessmentProps>(
  resultText: string,
  formData: T,
) => {
  let text = resultText.replace(/\n\n/g, '\n');
  if (formData.patient !== 'Patient' || formData.relationship) {
    const replacement = formData.patient || formData.relationship;

    if (
      (text.includes('denies') ||
        text.includes('confirms') ||
        text.includes('verbalized')) &&
      !text.includes('denies any symptoms at this time')
    ) {
      text = text.replace(
        /Patient (denies|confirms|verbalized)/g,
        `${replacement} $1`,
      );

      // Edgecase for Q7 & Q8
      text = text.replace(/(denies|confirms) taking/g, `$1 patient took`);

      // Edgecase for Q5(C2)
      text = text.replace(
        /confirms emergent symptoms/g,
        `confirms patient has emergent symptoms `,
      );
    }

    if (text.includes('Patient ')) {
      text = text.replace(
        /Patient(?! requires)/g,
        `${replacement} states that patient`,
      );

      // Edgecase for Q5(C1)
      text = text.replace(
        /denies any symptoms at this time/g,
        `is not having symptoms at this time`,
      );
    }
  }

  return text;
};

export const extractTextInParentheses = <T extends String>(array: T[]) => {
  return array.map((item) => {
    const match = item.match(/\(([^)]+)\)/);
    return match ? match[1] : null;
  });
};
