import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import { assessmentBloodGlucoseForm } from 'constants/criticalBloodGlucoseData';
import classNames from 'classnames';
import EmergencyModal from 'components/molecules/rpm-alert-assessment/emergencyModal';
import FormItemWrapper from 'components/molecules/rpm-alert-assessment/formItemWrapper';

import s from '../styles.module.scss';
import { criticalBloodGlucoseValidationSchema } from '../validation-schema';
import { useBloodGlucoseAssessment } from '../hooks/use-blood-glucose-assessment';
import { checkSymptomsUpdate } from '../helpers';

import type { IFormProps } from 'components/molecules/rpm-alert-assessment/types';
import type { ICriticalBloodGlucoseProps } from '../types';
import type { FC } from 'react';

const CriticalBloodGlucoseModal: FC<ICriticalBloodGlucoseProps> = ({
  isOpen,
  activityId,
  type,
  handleSubmit,
  closeModal,
}) => {
  const {
    doctor: { addComment },
  } = useDispatch();

  const { t } = useTranslation();

  const {
    initialValues,
    openEmergencyModal,
    symptomsList,
    toggleEmergencyModal,
    onSaveForm,
  } = useBloodGlucoseAssessment({
    type,
    activityId,
    addComment,
    closeModal,
    handleSubmit,
  });

  const blockRef = useRef<HTMLDivElement | null>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: (data) => onSaveForm(data),
    validateOnChange: false,
    validationSchema: criticalBloodGlucoseValidationSchema(symptomsList),
  });

  const requiredSymptomFields = symptomsList
    .filter((symptom) => symptom.required)
    .map((symptom) => symptom.value);

  const subTitleModal =
    type === 'max'
      ? t('labels.hyperglycemiaAssessment')
      : t('labels.hypoglycemiaAssessment');

  const onSave = () => {
    formik.submitForm();
  };

  const onEmergencyModalCancel = () => {
    formik.setFieldValue('emergencyCare', false);
    toggleEmergencyModal();
  };

  const onEmergencyModalSave = () => {
    formik.setFieldValue('emergencyCare', true);
    toggleEmergencyModal();
    if (blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (formik.values.checkSymptoms) {
      checkSymptomsUpdate(requiredSymptomFields, formik, toggleEmergencyModal);
    }
  }, [formik.values]);

  return (
    <>
      <DraggableModal
        isOpen={isOpen}
        toggle={closeModal}
        title={t('labels.rpmAlertBasedAssessment')}
        subTitle={subTitleModal}
        styleName={classNames('popup', 'narrative-popup', 'assessment-modal')}
        headerClassName={s['modal-header']}
        bodyClass={s['modal-body']}
      >
        <BaseForm
          formik={formik}
          withControls
          onSave={onSave}
          controlsClassName={s.controls}
        >
          <div className={s.wrapper}>
            {assessmentBloodGlucoseForm.map((formItem: IFormProps, index) => (
              <FormItemWrapper
                formik={formik}
                index={index}
                formItem={formItem}
                type={type}
                requiredSymptomFields={requiredSymptomFields}
                ref={blockRef}
              />
            ))}
          </div>
        </BaseForm>
      </DraggableModal>

      {openEmergencyModal && (
        <EmergencyModal
          openEmergencyModal={openEmergencyModal}
          onSave={onEmergencyModalSave}
          onCancel={onEmergencyModalCancel}
        />
      )}
    </>
  );
};

export default CriticalBloodGlucoseModal;
