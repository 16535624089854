import i18next from 'i18next';
import { Assignee } from 'infrastructure/enums';

export interface IAssigneeItem {
  id: Assignee | null;
  name: string;
}

export const assigneesDataSource = (withNone = true): IAssigneeItem[] => {
  const dataSource: IAssigneeItem[] = [
    {
      id: Assignee.Me,
      name: i18next.t('assignees.me'),
    },
    {
      id: Assignee.Free,
      name: i18next.t('assignees.free'),
    },
  ];

  if (withNone) {
    dataSource.unshift({
      id: null,
      name: i18next.t('assignees.all'),
    });
  }

  return dataSource;
};
