import React, { useState } from 'react';
import TextAreaAutoHeight from 'components/atoms/textarea/autoHeight';
import ChatSendIcon from 'components/atoms/icons/chatSendIcon';
import BaseButton from 'components/atoms/baseButton';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import EmptyNoteIcon from 'components/atoms/icons/emptyNoteIcon';
import { usePatientNotes } from 'infrastructure/hooks/patient/use-patient-notes';
import { usePatientNotesCreate } from 'infrastructure/hooks/patient/use-patient-notes-create';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { ChangeEvent } from 'react';
import type { RootState } from 'store';

type Note = {
  guid: string;
  note: string;
  patientGuid: string;
  createdAt: string;
  createdBy: string;
  author: string;
};

const InternalNotes = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');

  const { currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const { patientNotes, patientNotesLoading } = usePatientNotes({
    patientGuid: currentConversation?.patient.patientGuid,
  });

  const { createNote, createLoading } = usePatientNotesCreate({
    onSuccess() {
      setValue('');
    },
  });

  const onSubmit = async () => {
    createNote({
      patientGuid: currentConversation?.patient.patientGuid,
      note: value,
    });
  };

  const formatDate = (date: string) =>
    DateFormatter({
      date,
      format: DateFormats['MM-dd-yyyy'],
    });

  return (
    <>
      <div className={s['chat-profile']}>
        <div className={s['notes-wrapper']}>
          <div className={s.notes}>
            {!patientNotesLoading ? (
              <>
                {patientNotes.length > 0 &&
                  patientNotes.map((note: Note) => (
                    <div className={s.note} key={note.guid}>
                      <div className={s['note-body']}>{note.note}</div>
                      <div className={s['note-date']}>
                        <span>{formatDate(note.createdAt)}</span>
                        <span>{note.author}</span>
                      </div>
                    </div>
                  ))}
                {patientNotes.length === 0 && (
                  <div className={s.empty}>
                    <EmptyNoteIcon />
                    <p>{t('smsDashboard.empty_notes')}</p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={s.note}>
                  <div className={s['note-body']}>
                    <Skeleton width={270} height={20} />
                    <Skeleton width={270} height={20} />
                    <Skeleton width={270} height={20} />
                  </div>
                  <div className={s['note-date']}>
                    <Skeleton width={140} height={18} />
                  </div>
                </div>
                <div className={s.note}>
                  <div className={s['note-body']}>
                    <Skeleton width={270} height={20} />
                    <Skeleton width={270} height={20} />
                    <Skeleton width={270} height={20} />
                  </div>
                  <div className={s['note-date']}>
                    <Skeleton width={140} height={18} />
                  </div>
                </div>
                <div className={s.note}>
                  <div className={s['note-body']}>
                    <Skeleton width={270} height={20} />
                    <Skeleton width={270} height={20} />
                    <Skeleton width={270} height={20} />
                  </div>
                  <div className={s['note-date']}>
                    <Skeleton width={140} height={18} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`${s['messages-input-wrapper']} ${s['note-input']}`}>
        <div className={s['chat-form-wrapper']}>
          <div className={s['input-wrapper']}>
            <TextAreaAutoHeight
              className={s.input}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setValue(e.target.value)
              }
              onSubmit={onSubmit}
              value={value}
              loading={patientNotesLoading || createLoading}
              placeholder={t('smsDashboard.type_note')}
              hideHint
              height="37px"
            />
            <div className={s['send-button']}>
              <BaseButton
                type="secondary"
                disabled={value === ''}
                loading={patientNotesLoading || createLoading}
                onClick={onSubmit}
                icon={<ChatSendIcon />}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternalNotes;
