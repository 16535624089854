import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import BaseLoader from '../base-loader';

const InfiniteScroll = ({
  loadMore,
  uniqueId,
  disabled,
  allLoaded,
  manualMode,
}) => {
  const elementId = `loadMoreTrigger-${uniqueId}`;
  const observer = useRef(null);

  useEffect(() => {
    if (manualMode) return;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };
    const handleObserver = async (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !disabled && !allLoaded) {
        await loadMore();
      }
    };

    observer.current = new IntersectionObserver(handleObserver, options);
    if (observer.current) {
      observer.current.observe(document.querySelector(`#${elementId}`));
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [loadMore, disabled, manualMode]);

  if (manualMode) {
    return (
      <button
        className="load-more-button"
        onClick={loadMore}
        disabled={disabled}
      >
        Load more
      </button>
    );
  }

  return (
    <div id={elementId}>
      {!allLoaded && (
        <BaseLoader
          style={{ textAlign: 'center', padding: '1rem' }}
          height={30}
          width={30}
          loading
        />
      )}
    </div>
  );
};

InfiniteScroll.propTypes = {
  loadMore: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  allLoaded: PropTypes.bool,
  uniqueId: PropTypes.string.isRequired,
  manualMode: PropTypes.bool,
};

export default InfiniteScroll;
