import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RadioButton from 'components/atoms/radioButton';
import { Field, Formik, Form } from 'formik';
import { showResult } from 'infrastructure/hooks/utils';
import Input from 'components/atoms/input';
import useUser from 'utils/useUser';
import * as Yup from 'yup';
import { format, parse } from 'date-fns';
import common from 'constants/common';
import { parseDateSafe } from 'utils/timeHelper';
import { loadDoctorsAndAgencyDoctorByRoles } from 'utils/loadDoctorsAndAgencyDoctors';
import {
  completionAppointmentMethod,
  nextAppointmentMethod,
} from 'constants/forms';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { useQueryClient } from '@tanstack/react-query';
import { getNextAppointmentKey } from 'infrastructure/hooks/patient/use-patient-next-appointment';
import { RoleGroups } from 'infrastructure/consts/role-groups';

const schema = Yup.object().shape({
  completedByAgencyDoctorGuid: Yup.string().required('required'),
  completedDate: Yup.string()
    .required('required')
    .test(
      'date-format-is-valid',
      `Date format must be ${common.dateFormats.priorAuthorizationAdmin}`,
      (value) => {
        return parseDateSafe(
          parse(value, common.dateFormats.priorAuthorizationAdmin, new Date()),
        );
      },
    ),
  appointmentMethod: Yup.string().required('required'),
  nextAppointmentScheduled: Yup.bool().required('required'),
  scheduledWithAgencyDoctorGuid: Yup.string().when('nextAppointmentScheduled', {
    is: true,
    then: Yup.string().required('required'),
  }),
  scheduledDate: Yup.string().when('nextAppointmentScheduled', {
    is: true,
    then: Yup.string()
      .required('required')
      .test(
        'date-format-is-valid',
        `Date format must be ${common.dateFormats.full}`,
        (value) => {
          return parseDateSafe(
            parse(value, common.dateFormats.full, new Date()),
          );
        },
      ),
  }),
  nextAppointmentMethod: Yup.string().when('nextAppointmentScheduled', {
    is: true,
    then: Yup.string().required('required'),
  }),
});

const CcmInteractionStatusModal = ({ toggle, isOpen, patientGuid }) => {
  const queryClient = useQueryClient();
  const patientInfoKey = getPatientInfoKey(patientGuid);
  const nextAppointmentKey = getNextAppointmentKey(patientGuid);

  const user = useUser();
  const { togglePatientCcmInteractionStatus: updateLoading } = useSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );

  const {
    adminPanelPatients: { togglePatientCcmInteractionStatus },
  } = useDispatch();

  const [completedByOptions, setCompletedByOptions] = useState([]);
  const [completedByOptionsLoading, setCompletedByOptionsLoading] =
    useState(false);

  const [nextAppointmentOptions, setNextAppointmentOptions] = useState([]);
  const [nextAppointmentOptionsLoading, setNextAppointmentOptionsLoading] =
    useState(false);

  const [initialValues, setInitialValues] = useState({
    completedByAgencyDoctorGuid: undefined,
    scheduledWithAgencyDoctorGuid: undefined,
    completedDate: format(
      new Date(),
      common.dateFormats.priorAuthorizationAdmin,
    ),
  });

  const submit = async (values) => {
    const completedByType = completedByOptions.find(
      (e) => e.guid === values.completedByAgencyDoctorGuid,
    )?.type;

    let completedByData = {
      completedByAgencyDoctorGuid: values.completedByAgencyDoctorGuid,
    };

    if (completedByType === 'doctor')
      completedByData = {
        completedByDoctorGuid: values.completedByAgencyDoctorGuid,
      };

    const postData = {
      isInteracted: true,
      appointmentCompletion: {
        completedDate: values.completedDate,
        appointmentMethod: values.appointmentMethod,
        ...completedByData,
      },
    };
    if (values.nextAppointmentScheduled) {
      const scheduledWithType = nextAppointmentOptions.find(
        (e) => e.guid === values.scheduledWithAgencyDoctorGuid,
      )?.type;

      let scheduledWithData = {
        scheduledWithAgencyDoctorGuid: values.scheduledWithAgencyDoctorGuid,
      };

      if (scheduledWithType === 'doctor')
        scheduledWithData = {
          scheduledWithDoctorGuid: values.scheduledWithAgencyDoctorGuid,
        };

      postData.nextAppointment = {
        scheduledDate: values.scheduledDate,
        appointmentMethod: values.nextAppointmentMethod,
        ...scheduledWithData,
      };
    }
    const { error, data } = await togglePatientCcmInteractionStatus({
      patientGuid,
      postData,
    });

    if (error) {
      showResult(error);
      return;
    }

    if (data) {
      showResult();
      if (typeof window.loadNextAppointment === 'function') {
        window.loadNextAppointment();
      }
      if (typeof window.loadAppointmentCompletions === 'function') {
        window.loadAppointmentCompletions();
      }
      queryClient.setQueryData(patientInfoKey, (oldData) => {
        if (oldData && data) {
          return {
            ...oldData,
            ccmInteractionStatus: data.ccmInteractionStatus,
          };
        }
      });
      queryClient.invalidateQueries(nextAppointmentKey);
    }
    toggle(false);
  };

  useEffect(() => {
    if (!isOpen) return;

    if (
      completedByOptions.length === 0 &&
      completedByOptionsLoading === false
    ) {
      loadDoctorsAndAgencyDoctorByRoles({
        patientGuid,
        roles: RoleGroups.AppointmentCompletedByAllowedRoles,
        setData: setCompletedByOptions,
        setLoading: setCompletedByOptionsLoading,
      });
    }

    if (
      nextAppointmentOptions.length === 0 &&
      nextAppointmentOptionsLoading === false
    ) {
      loadDoctorsAndAgencyDoctorByRoles({
        patientGuid,
        roles: RoleGroups.CCMRoles.join(','),
        setData: setNextAppointmentOptions,
        setLoading: setNextAppointmentOptionsLoading,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const completedByAgencyDoctorGuid = completedByOptions.find(
      ({ id }) => id === user.guid,
    )?.id;
    const scheduledWithAgencyDoctorGuid = nextAppointmentOptions.find(
      ({ id }) => id === user.guid,
    )?.id;
    setInitialValues({
      ...initialValues,
      completedByAgencyDoctorGuid,
      scheduledWithAgencyDoctorGuid,
    });
  }, [completedByOptions, nextAppointmentOptions]);

  const formRef = useRef();

  useEffect(() => {
    formRef.current?.resetForm();
    formRef.current?.setFieldValue('completedByAgencyDoctorGuid', '');
    formRef.current?.setFieldValue('scheduledWithAgencyDoctorGuid', '');
  }, [patientGuid]);

  if (!isOpen) return null;
  return (
    <DraggableModal
      styleName="popup ccm-appointment-popup"
      isOpen={isOpen}
      toggle={() => toggle(false)}
      title="Appointment Completion"
    >
      <>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={submit}
          validationSchema={schema}
        >
          {({ values: { nextAppointmentScheduled }, errors }) => (
            <Form>
              <>
                <Input
                  label="Appointment Completed By"
                  id="completedByAgencyDoctorGuid"
                  dataCy="completedByAgencyDoctorGuid"
                  type="select"
                  options={completedByOptions}
                  isLoading={completedByOptionsLoading}
                />
                {errors.completedByAgencyDoctorGuid && (
                  <div className="input-error">
                    {errors.completedByAgencyDoctorGuid}
                  </div>
                )}
              </>
              <>
                <Input
                  label="Date and Time of appointment"
                  id="completedDate"
                  type="date"
                  format={common.dateFormats.priorAuthorizationAdmin}
                />
                {errors.completedDate && (
                  <div className="input-error">{errors.completedDate}</div>
                )}
              </>
              <>
                <Input
                  label="Appointment Completion Method"
                  id="appointmentMethod"
                  dataCy="appointmentMethod"
                  type="select"
                  options={completionAppointmentMethod}
                />
                {errors.appointmentMethod && (
                  <div className="input-error">{errors.appointmentMethod}</div>
                )}
              </>
              <div className="ccm-interaction-status-modal-popup__content">
                <div className="ccm-interaction-status-modal-popup__title">
                  Next Appointment
                </div>
                <div className="ccm-interaction-status-modal-popup__field">
                  <h6 className="ccm-interaction-status-modal-popup__field-title">
                    Is Next Appointment Scheduled
                  </h6>
                  <Field name="nextAppointmentScheduled">
                    {({ field, form }) => (
                      <>
                        <RadioButton
                          key="nextAppointmentScheduledYes"
                          name="nextAppointmentScheduledYes"
                          id="nextAppointmentScheduledYes"
                          label="Yes"
                          onChange={() => {
                            form.setFieldValue(
                              'nextAppointmentScheduled',
                              true,
                            );
                          }}
                          isChecked={field.value === true}
                        />
                      </>
                    )}
                  </Field>
                  <Field name="nextAppointmentScheduled">
                    {({ field, form }) => (
                      <RadioButton
                        key="nextAppointmentScheduledNo"
                        name="nextAppointmentScheduledNo"
                        id="nextAppointmentScheduledNo"
                        label="No"
                        onChange={() => {
                          form.setFieldValue('nextAppointmentScheduled', false);
                        }}
                        isChecked={field.value === false}
                      />
                    )}
                  </Field>
                  {errors.nextAppointmentScheduled && (
                    <div className="input-error" style={{ paddingTop: 10 }}>
                      {errors.nextAppointmentScheduled}
                    </div>
                  )}
                  {nextAppointmentScheduled && (
                    <>
                      <>
                        <Input
                          label="Who is the next appointment scheduled with"
                          id="scheduledWithAgencyDoctorGuid"
                          dataCy="scheduledWithAgencyDoctorGuid"
                          type="select"
                          options={nextAppointmentOptions}
                          isLoading={nextAppointmentOptionsLoading}
                        />
                        {errors.scheduledWithAgencyDoctorGuid && (
                          <div className="input-error">
                            {errors.scheduledWithAgencyDoctorGuid}
                          </div>
                        )}
                      </>
                      <>
                        <Input
                          label="Date and Time of the next appointment (CST Time Zone)"
                          id="scheduledDate"
                          type="dateWithTime"
                          timeCaption="CST Time Zone"
                        />
                        {errors.scheduledDate && (
                          <div className="input-error">
                            {errors.scheduledDate}
                          </div>
                        )}
                      </>
                      <>
                        <Input
                          label="Next Month Appointment Method"
                          id="nextAppointmentMethod"
                          dataCy="nextAppointmentMethod"
                          type="select"
                          options={nextAppointmentMethod}
                        />
                        {errors.nextAppointmentMethod && (
                          <div className="input-error">
                            {errors.nextAppointmentMethod}
                          </div>
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
              <div className="ccm-interaction-status-modal-popup__control">
                <button
                  className="ccm-interaction-status-modal-popup__button"
                  type="submit"
                  data-cy="submit-next-appointment-button"
                  disabled={updateLoading}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </DraggableModal>
  );
};

CcmInteractionStatusModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  patientGuid: PropTypes.string,
};

export default CcmInteractionStatusModal;
