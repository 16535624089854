import { CcmStatuses } from 'infrastructure/enums';

const PatientProfile = [
  CcmStatuses.Enrolled,
  CcmStatuses.CCMOnboarding,
  CcmStatuses.CCMOnboardingUnsuccessfulNoContact,
  CcmStatuses.CCMOnboardingUnsuccessfulPatientRefused,
  CcmStatuses.CCMOnboardingUnsuccessfulPatientNotQualifiedForCCM,
  CcmStatuses.CCMOnboardingUnsuccessfulChangedDoctor,
  CcmStatuses.CCMOnboardingUnsuccessfulOther,
  CcmStatuses.CCMUnenrolledChangedDoctor,
  CcmStatuses.CCMUnenrolledClientRequest,
  CcmStatuses.CCMUnenrolledCopayLossOfInsurance,
  CcmStatuses.CCMUnenrolledNonAdherent,
  CcmStatuses.CCMUnenrolledExpired,
  CcmStatuses.CCMUnenrolledHospice,
  CcmStatuses.CCMUnenrolledLongTermSkilledNursingFacilityRehab,
  CcmStatuses.CCMUnenrolledPhysicianClinicRequest,
  CcmStatuses.CCMUnenrolledClientRequestMoving,
  CcmStatuses.CCMUnenrolledHealthPlanRequestGoalsMet,
  CcmStatuses.CCMUnenrolledOther,
];

const PageReadOnly = [
  CcmStatuses.CCMOnboardingUnsuccessfulNoContact,
  CcmStatuses.CCMOnboardingUnsuccessfulPatientRefused,
  CcmStatuses.CCMOnboardingUnsuccessfulPatientNotQualifiedForCCM,
  CcmStatuses.CCMOnboardingUnsuccessfulChangedDoctor,
  CcmStatuses.CCMOnboardingUnsuccessfulOther,
  CcmStatuses.CCMUnenrolledChangedDoctor,
  CcmStatuses.CCMUnenrolledClientRequest,
  CcmStatuses.CCMUnenrolledCopayLossOfInsurance,
  CcmStatuses.CCMUnenrolledNonAdherent,
  CcmStatuses.CCMUnenrolledExpired,
  CcmStatuses.CCMUnenrolledHospice,
  CcmStatuses.CCMUnenrolledLongTermSkilledNursingFacilityRehab,
  CcmStatuses.CCMUnenrolledPhysicianClinicRequest,
  CcmStatuses.CCMUnenrolledOther,
];

const PageActionAllowed = [CcmStatuses.Enrolled, CcmStatuses.CCMOnboarding];

const Onboarding = [
  CcmStatuses.CCMReferralReceived,
  CcmStatuses.CCMReferralOnHold,
  CcmStatuses.CCMReferralWithdrawn,
  CcmStatuses.CCMCallingQueue,
  CcmStatuses.CCMPatientNoContact,
  CcmStatuses.CCMPatientRefusedCoPayInsurance,
  CcmStatuses.CCMPatientRefusedExpired,
  CcmStatuses.CCMPatientRefusedChangedDoctor,
  CcmStatuses.CCMPatientRefusedNotInterestedNotNecessarySuspectScam,
  CcmStatuses.CCMPatientRefusedWrongContactInformation,
  CcmStatuses.CCMPatientRefusedNoContact,
  CcmStatuses.CCMPatientRefusedOther,
  CcmStatuses.CCMOnboarding,
  CcmStatuses.CCMOnboardingUnsuccessfulNoContact,
  CcmStatuses.CCMOnboardingUnsuccessfulPatientRefused,
  CcmStatuses.CCMOnboardingUnsuccessfulPatientNotQualifiedForCCM,
  CcmStatuses.CCMOnboardingUnsuccessfulChangedDoctor,
  CcmStatuses.CCMOnboardingUnsuccessfulOther,
];

export const CCMStatusGroups = {
  PatientProfile,
  PageReadOnly,
  PageActionAllowed,
  Onboarding,
} as const;
