import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse, isValid } from 'date-fns';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils/deviceHelper';
import useOutsideClick from 'utils/useOutsideClick';
import common from 'constants/common';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useCloseOpenConversation } from 'infrastructure/hooks/smsDashboard/useCloseOpenConversation';
import { getSmsDashboardMessagesKey } from 'infrastructure/hooks/smsDashboard/useMessages';
import { useMarkConversation } from 'infrastructure/hooks/smsDashboard/useMarkConversation';
import { useQueryClient } from '@tanstack/react-query';
import { INITIAL_STATE } from 'store/smsDashboard';
import { useSmsDashboardUnreadCount } from 'infrastructure/hooks/smsDashboard/useUnreadConversationsCount';
import { DatePicker } from 'antd';
import { PlatformEnums } from 'infrastructure/enums';
import { useMarkConversationAsSpam } from 'infrastructure/hooks/smsDashboard/useMarkConversationAsSpam';
import classNames from 'classnames';
import ConversationNameEditModal from 'components/molecules/smsDashboard/conversationNameEditModal';
import ChatSearchIcon from 'components/atoms/icons/chatSearchIcon';
import ThreeDotsIcon from 'components/atoms/icons/threeDotsIcon';
import XIcon from 'components/atoms/icons/xIcon';

import s from './styles.module.scss';

import type {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import type { RootDispatch, RootState } from 'store';
import type {
  IConversation,
  IMessagesListPages,
  IMessagesListRes,
} from 'infrastructure/interfaces';

const prepareDate = (value: string) => {
  const isDateValid = Date.parse(value);
  if (!isDateValid) return '';

  const formattedDate = format(new Date(value), common.dateFormats.birthDate);
  const preparedDate = parse(
    formattedDate,
    common.dateFormats.birthDate,
    new Date(),
  ).setDate(
    parse(formattedDate, common.dateFormats.birthDate, new Date()).getDate() +
      1,
  );
  const date = format(preparedDate, common.dateFormats.dateUTC);

  return date;
};

interface IChatHeaderProps {
  conversation: IConversation;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  fetchMessages: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<InfiniteData<IMessagesListRes | undefined>, unknown>
  >;
  setSearchDateValue: React.Dispatch<React.SetStateAction<string>>;
  isSearchShown: boolean;
  setIsSearchShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatHeader = (props: IChatHeaderProps) => {
  const {
    conversation,
    setSearchValue,
    searchValue,
    fetchMessages,
    setSearchDateValue,
    setIsSearchShown,
    isSearchShown,
  } = props;

  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const patientName = conversation?.patient?.fullNameWithOnlyTitle;
  const patientPhone = conversation?.patient?.patientPhone;

  const [datePickerDate, setDatePickerDate] = useState(null);
  const [_, setIsDatepickerOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);

  const device = useSelector((state: RootState) => state.user.deviceType);

  const { filters, conversationType, currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const {
    smsDashboard: { changeCurrentConversation },
  } = useDispatch<RootDispatch>();

  const { fetchUnreadConversationsCount } = useSmsDashboardUnreadCount();

  const { markConversation } = useMarkConversation({
    onSuccess() {
      const action = t('smsDashboard.marked_as_unread');
      notificationController.info({
        message: t('smsDashboard.conversation_is_actioned_successfully', {
          action,
        }),
      });
      changeCurrentConversation(INITIAL_STATE.currentConversation);
      fetchUnreadConversationsCount();
    },
  });

  useEffect(() => {
    setSearchValue('');
    setSearchDateValue('');
    setIsSearchShown(false);
    setIsDatepickerOpen(false);
    setIsMenuOpen(false);
    setDatePickerDate(null);
  }, [conversation]);

  const handleDatePicker = (value: string) => {
    const preparedDate = prepareDate(value);
    setSearchDateValue(preparedDate);
  };

  const handleInputChange = (value: string) => {
    const isDate = !!isValid(
      parse(value, common.dateFormats.birthDate, new Date()),
    );

    if (isDate) {
      const preparedDate = prepareDate(value);
      setSearchDateValue(preparedDate);
    } else {
      setSearchDateValue('');
      setDatePickerDate(null);
      setSearchValue(value);
    }
  };

  const clearInput = () => {
    handleInputChange('');
    setIsSearchShown(false);
    setDatePickerDate(null);
    setSearchDateValue('');
    setSearchValue('');
  };

  useOutsideClick(ref, () => {
    setIsMenuOpen(false);
  });

  const { closeOpenConversation } = useCloseOpenConversation({
    type: conversationType,
    read: filters.read,
    closed: filters.closed,
    lastMessageDate: filters.lastMessageDate,
    searchQuery: filters.searchQuery,
    onSuccess() {
      fetchMessages();
      const action = conversation.closed === true ? 'opened' : 'closed';
      notificationController.info({
        message: t('smsDashboard.conversation_is_actioned_successfully', {
          action,
        }),
      });
    },
  });

  const { markConversationAsSpam } = useMarkConversationAsSpam({
    type: conversationType,
    read: filters.read,
    closed: filters.closed,
    lastMessageDate: filters.lastMessageDate,
    searchQuery: filters.searchQuery,
    onSuccess() {
      fetchMessages();
      const action =
        conversation.spam === true
          ? t('smsDashboard.not_spam')
          : t('smsDashboard.spam');
      if (conversation.spam) {
        notificationController.info({
          message: t('smsDashboard.conversation_marked_successfully', {
            action,
          }),
        });
      }
      if (!conversation.spam) {
        notificationController.success({
          message: t('smsDashboard.conversation_marked_successfully', {
            action,
          }),
        });
      }
    },
  });
  const messagesKey = getSmsDashboardMessagesKey({
    conversationId: currentConversation.id,
    nextCursor: undefined,
    searchQuery: undefined,
  });

  const messages = queryClient.getQueryData<IMessagesListPages>(messagesKey);

  const totalMessages = messages?.pages.reduce(
    (acc, page) => acc + page.items.length,
    0,
  );

  const conversationsSortButtonClassNames = classNames(
    s['conversations-sort-button'],
    s['card-border'],
    {
      active: isMenuOpen,
    },
  );

  const deviceIsMobile = isMobile(device);

  const toggleConversationNameModal = () => {
    setIsRenameModalOpen(!isRenameModalOpen);
  };
  return (
    <div className={s['chat-header']}>
      {deviceIsMobile && (
        <button
          type="button"
          aria-label="Previous"
          className={s.prev}
          data-cy="pagination__prev"
          onClick={() =>
            changeCurrentConversation(INITIAL_STATE.currentConversation)
          }
        />
      )}
      <div className={s['chat-header-info']} hidden={isSearchShown}>
        <div className={s['chat-header-patient']}>
          <div className={s['patient-name']}>
            {patientName && <span>{patientName} </span>}
            {conversation.name && <span>{conversation.name} </span>}
            <span>{patientPhone}</span>
          </div>
        </div>
        <div className={s['chat-header-buttons']}>
          <button
            className={s['chat-message-search']}
            onClick={() => setIsSearchShown(true)}
          >
            <ChatSearchIcon />
          </button>
          <div className={s['conversations-filters']}>
            <div className={s['conversations-sort']} ref={ref}>
              <Card className={conversationsSortButtonClassNames}>
                <CardBody
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className={s['card-body-sort-arrow']}
                >
                  <ThreeDotsIcon />
                </CardBody>
              </Card>
              {isMenuOpen && (
                <div className={s['conversations-sort-body']}>
                  {!conversation.closed &&
                    conversation.read === true &&
                    totalMessages !== 0 && (
                      <div
                        className={s['conversations-sort-item']}
                        onClick={() => {
                          markConversation({
                            conversationId: conversation.id,
                            read: false,
                          });
                          setIsMenuOpen(!isMenuOpen);
                        }}
                      >
                        <span>{t(`smsDashboard.mark_as_unread`)}</span>
                      </div>
                    )}
                  <div
                    className={s['conversations-sort-item']}
                    onClick={() => {
                      closeOpenConversation({
                        conversationIds: [conversation.id],
                        close: !conversation.closed,
                      });
                      setIsMenuOpen(!isMenuOpen);
                    }}
                  >
                    <span>
                      {conversation.closed
                        ? t(`smsDashboard.open_conversation`)
                        : t(`smsDashboard.close_conversation`)}
                    </span>
                  </div>
                  {conversation.type === PlatformEnums.UNREGISTERED && (
                    <div
                      className={s['conversations-sort-item']}
                      onClick={() => {
                        toggleConversationNameModal();
                        setIsMenuOpen(!isMenuOpen);
                      }}
                    >
                      {conversation.name === null ||
                      conversation.name === '' ? (
                        <span>{t(`smsDashboard.add_name`)}</span>
                      ) : (
                        <span>{t(`smsDashboard.edit_name`)}</span>
                      )}
                    </div>
                  )}
                  {conversation.type === PlatformEnums.UNREGISTERED && (
                    <div
                      className={s['conversations-sort-item']}
                      onClick={() => {
                        markConversationAsSpam({
                          conversationId: conversation.id,
                          spam: !conversation.spam,
                        });
                        setIsMenuOpen(!isMenuOpen);
                      }}
                    >
                      {conversation.spam ? (
                        <span>{t(`smsDashboard.mark_as_not_spam`)}</span>
                      ) : (
                        <span>{t(`smsDashboard.mark_as_spam`)}</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={s['chat-header-search']} hidden={!isSearchShown}>
        <div className={s['chat-search-input']}>
          <input
            type="text"
            placeholder={t('smsDashboard.search_messages')}
            value={searchValue}
            onChange={(e) => handleInputChange(e.target.value)}
          />
          <span className={s['chat-search-icon']} />
        </div>
        <div className={s['chat-search-action']}>
          <DatePicker
            placeholder="Search date"
            value={datePickerDate}
            onChange={(value: any, dateString) => {
              if (!Array.isArray(dateString))
                handleDatePicker(dateString ?? '');
              setDatePickerDate(value);
            }}
            onOpenChange={(isOpen: boolean) => setIsDatepickerOpen(isOpen)}
            placement="bottomRight"
            showNow={false}
          />
          <button
            className={s['chat-message-search-cancel']}
            onClick={clearInput}
          >
            <XIcon />
          </button>
        </div>
      </div>
      <ConversationNameEditModal
        isOpen={isRenameModalOpen}
        toggle={toggleConversationNameModal}
        name={conversation.name}
        conversationId={conversation.id}
      />
    </div>
  );
};

export default ChatHeader;
