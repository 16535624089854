const telemedUrl = process.env.REACT_APP_TELEMED_URL;
const filesDotComUrl = process.env.REACT_APP_FILES_URL;
const awsConnectUrl = process.env.REACT_APP_AWS_CONNECT_URL;

export const PublicRoutes = {
  emailSubscription: '/email-subscription',
  emailUnsubscribed: '/email-unsubscribed',
  ssoLogin: '/sso-login',
  resetPassword: '/reset-password',
} as const;

export const Routes = {
  // General
  dashboard: '/',
  login: '/login',
  notFound: '/404',
  profile: '/profile',
  sso: '/sso',

  // Activity & Reports
  activityReport: '/activity-report',
  adherenceReport: '/adherence-report',
  adherenceReportLast30Days: '/adherence-report-30-days',
  bucketReport: filesDotComUrl,
  clinicalMetrics: '/clinical-metrics',
  complianceReport: '/compliance-report',
  interactionStatus: '/interaction-status',
  interactiveReport: '/interactive-time',
  interactiveTime: '/interactive-time',
  interactiveTimeDashboard: '/interactive-time-dashboard',
  patientsStatusReport: '/patients-status',
  performanceReview: '/performance-review',
  statisticsReport: '/statistics-report',
  smsDashboard: '/sms-dashboard',
  turkDashboard: '/turk-dashboard',

  // CCM
  ccm: '/ccm',
  ccmAgentDashboard: '/ccm/agent-dashboard',
  ccmBillingReport: '/ccm/billing-report',
  ccmBillingReportV2: '/v2/ccm/billing-report',
  ccmConsent: (patientGuid: string) => `/ccm/consent/${patientGuid}`,
  ccmInteractiveTimeReport: '/ccm/interactive-time',
  ccmOnboardingStatus: '/ccm/onboarding-status',
  ccmPatient: (patientGuid: string) => `/ccm/patient/${patientGuid}`,
  ccmStatusReport: '/ccm/status-report',

  // Eligibility & Billing
  billingClaimsAthena: '/billing-tools-claims-athena',
  billingClaimsEpic: '/billing-tools-claims-epic',
  eligibilityTool: '/eligibility-tool',
  eligibilityToolItems: (processGuid: string) =>
    `/eligibility-tool/${processGuid}`,
  medicareBilling: '/medicare-billing',

  // Onboarding & Practice
  onboardingStatus: '/onboarding-status',
  practiceOverview: '/practice-overview',

  // Patient Management
  patientDetails: '/patient/details',
  patientGuid: (patientGuid: string) => `/patient/${patientGuid}`,
  patientReferral: 'https://www.accuhealth.tech/patientsubmission',
  servicePlans: '/service-plans',

  // Admin - Agencies
  adminAgencyList: '/admin/agencies',
  adminAgency: (agencyGuid: string) => `/admin/agency/${agencyGuid}`,
  adminAgencyAdd: '/admin/agencies/add',
  adminAgencyEdit: (agencyGuid: string) => `/admin/agency/${agencyGuid}/edit`,

  // Admin - Agency Doctor
  adminAgencyDoctorList: '/admin/agents',
  adminAgencyDoctor: (agentGuid: string) => `/admin/agent/${agentGuid}`,
  adminAgencyDoctorAdd: '/admin/agents/add',
  adminAgencyDoctorEdit: (agentGuid: string) =>
    `/admin/agent/${agentGuid}/edit`,

  // Admin - Clinics
  adminClinics: '/admin/clinics',
  adminClinic: (clinicGuid: string) => `/admin/clinic/${clinicGuid}`,
  adminClinicAdd: '/admin/clinics/add',
  adminClinicEdit: (clinicGuid: string) => `/admin/clinic/${clinicGuid}/edit`,

  // Admin - Patients
  adminPatients: '/admin/patients',
  adminPatient: (patientGuid: string) => `/admin/patient/${patientGuid}`,
  adminPatientAdd: '/admin/patients/add',
  adminPatientEdit: (patientGuid: string) =>
    `/admin/patient/${patientGuid}/edit`,

  // Admin - Users
  adminUsers: '/admin/users',
  adminUser: (userGuid: string) => `/admin/user/${userGuid}`,
  adminUserAdd: '/admin/users/add',
  adminUserEdit: (userGuid: string) => `/admin/user/${userGuid}/edit`,

  // Admin - Turk Users
  adminTurkUserList: '/admin/turk-users',
  adminTurkUser: (agentGuid: string) => `/admin/turk-users/${agentGuid}`,
  adminTurkUsersAdd: '/admin/turk-users/add',
  adminTurkUsersEdit: (agentGuid: string) =>
    `/admin/turk-users/${agentGuid}/edit`,

  // Admin - Other
  adminBulkUpload: '/admin/bulk-upload',
  adminTurkConfigurations: '/admin/turk-configurations',

  // External URLs
  awsConnectURL: awsConnectUrl,
  BAA: 'https://myaccu.net/BAAform',
  telmed: telemedUrl,
  W9: 'https://myaccu.net/w9form',
} as const;

export const GroupedRoutes: Record<string, string[]> = {
  adminPanelUsers: [
    Routes.smsDashboard,
    Routes.adminUsers,
    Routes.adminUser(':userGuid'),
    Routes.adminUserEdit(':userGuid'),
    Routes.adminUserAdd,
  ],
  adminPanelAgencyDoctor: [
    Routes.adminAgencyDoctorList,
    Routes.adminAgencyDoctorAdd,
    Routes.adminAgencyDoctor(':agentGuid'),
    Routes.adminAgencyDoctorEdit(':agentGuid'),
  ],
  adminPanelClinics: [
    Routes.adminClinics,
    Routes.adminClinicAdd,
    Routes.adminClinic(':clinicGuid'),
    Routes.adminClinicEdit(':clinicGuid'),
  ],
  adminPanelAgencies: [
    Routes.adminAgencyList,
    Routes.adminAgencyAdd,
    Routes.adminAgency(':agencyGuid'),
    Routes.adminAgencyEdit(':agencyGuid'),
  ],
  adminPanelPatient: [
    Routes.adminPatients,
    Routes.adminPatientAdd,
    Routes.adminBulkUpload,
    Routes.adminPatient(':patientGuid'),
    Routes.adminPatientEdit(':patientGuid'),
    Routes.ccmConsent(':patientGuid'),
  ],
  patientAcceptance: [
    Routes.adminPatients,
    Routes.profile,
    Routes.adminPatient(':patientGuid'),
    Routes.adminPatientEdit(':patientGuid'),
    Routes.ccmConsent(':patientGuid'),
  ],
  rpm: [
    Routes.profile,
    Routes.patientGuid(':patientGuid'),
    Routes.patientDetails,
    Routes.medicareBilling,
    Routes.interactiveReport,
    Routes.activityReport,
    Routes.adherenceReport,
    Routes.adherenceReportLast30Days,
    Routes.complianceReport,
    Routes.onboardingStatus,
    Routes.dashboard,
    Routes.ccm,
    Routes.clinicalMetrics,
    Routes.statisticsReport,
    Routes.performanceReview,
    Routes.patientsStatusReport,
    Routes.interactionStatus,
    Routes.interactiveTimeDashboard,
  ],
  rpmPracticeReport: [
    Routes.profile,
    Routes.patientGuid(':patientGuid'),
    Routes.medicareBilling,
    Routes.interactiveReport,
    Routes.activityReport,
    Routes.adherenceReport,
    Routes.adherenceReportLast30Days,
    Routes.complianceReport,
    Routes.onboardingStatus,
    Routes.clinicalMetrics,
    Routes.statisticsReport,
    Routes.performanceReview,
    Routes.patientsStatusReport,
    Routes.interactionStatus,
  ],
  rpmAdd: [Routes.servicePlans, Routes.practiceOverview],
  ccm: [
    Routes.profile,
    Routes.ccm,
    Routes.ccmPatient(':patientGuid'),
    Routes.ccmBillingReport,
    Routes.ccmBillingReportV2,
    Routes.ccmInteractiveTimeReport,
    Routes.dashboard,
    Routes.ccmOnboardingStatus,
    Routes.ccmStatusReport,
  ],
  ccmAdminHHA: [Routes.ccmAgentDashboard],
  turkAdmin: [
    Routes.adminTurkConfigurations,
    Routes.adminTurkUsersAdd,
    Routes.adminTurkUsersEdit(':agentGuid'),
    Routes.adminTurkUserList,
    Routes.adminTurkUser(':agentGuid'),
  ],
  turkUser: [Routes.turkDashboard],
  smsDashboard: [Routes.smsDashboard],
  adminTools: [
    Routes.eligibilityTool,
    Routes.eligibilityToolItems(':processGuid'),
  ],
  billingTools: [Routes.billingClaimsAthena, Routes.billingClaimsEpic],
};
