import i18next from 'i18next';
import { ModifiedArray } from 'infrastructure/classes/common/modified-array';

type Label = string | undefined | null;

export interface IBooleanItem {
  id: boolean | null;
  name: string;
  color?: string;
}

type BooleanTypesArray = ModifiedArray<IBooleanItem>;

export const booleansDataSource = (
  trueLabel?: Label,
  falseLabel?: Label,
  withAll?: boolean,
): BooleanTypesArray => {
  const dataSource = new ModifiedArray<IBooleanItem>(
    {
      id: true,
      name: trueLabel ?? i18next.t('controls.true'),
      color: '#2F8521',
    },
    {
      id: false,
      name: falseLabel ?? i18next.t('controls.false'),
      color: '#BD363B',
    },
  );
  if (withAll) {
    dataSource.unshift({
      id: null,
      name: i18next.t('labels.all'),
      color: '',
    });
  }
  return dataSource;
};

export const optedDataSource = (): IBooleanItem[] => {
  return [
    {
      id: true,
      name: i18next.t('controls.optedIn'),
    },
    {
      id: false,
      name: i18next.t('controls.optedOut'),
    },
  ];
};
