import React from 'react';
import classNames from 'classnames';
import { getObjValueByStringKey } from 'infrastructure/functions';

import s from './styles.module.scss';

import type {
  IBaseComponentProps,
  ICommonInputProps,
} from 'infrastructure/interfaces';
import type { CSSProperties } from 'react';
import type { ReactElementWithName, IPrivateProps, direction } from '../types';

const renderBaseFormItem = (
  child: ReactElementWithName,
  inputProps: ICommonInputProps,
) => {
  if (!child) return;

  const hasManyChildren =
    Array.isArray(child.props.children) &&
    React.Children.count(child.props.children) > 0;

  const hasValidChildren = hasManyChildren
    ? child.props.children.some((children: ReactElementWithName) =>
        React.isValidElement(children),
      )
    : React.isValidElement(child.props.children);

  const isDefaultTags =
    typeof child.type !== 'function' || typeof child.type === 'string';

  if (isDefaultTags) return child;

  if (hasValidChildren) {
    const modifiedChildren: React.ReactElement[] = React.Children.map(
      child.props.children,
      (childElement: any) => {
        return renderBaseFormItem(childElement, inputProps);
      },
    );
    return React.cloneElement(child, {}, modifiedChildren);
  }

  const style: CSSProperties = { ...inputProps?.style };

  if (inputProps.plaintext) {
    style.padding = '0';
  }

  const childProps = child.props ?? {};
  return React.cloneElement(child, { ...inputProps, ...childProps, style });
};

interface IBaseFormItemProps extends IBaseComponentProps {
  name: string;
  label?: string;
  wrapperClassName?: string;
  labelDirection?: direction;
  required?: boolean;
  children: React.ReactElement;
  showError?: boolean;
  showErrorBlock?: boolean;
  labelInfo?: string;
  isBlackLabel?: boolean;
}

const BaseFormItem: React.FC<IBaseFormItemProps> = (props) => {
  const {
    name,
    label,
    children,
    privateProps,
    labelDirection = 'top',
    required = false,
    wrapperClassName,
    className,
    labelInfo,
    isBlackLabel = false,
    showError: showErrorProps = true,
    showErrorBlock: showErrorBlockProps,
  } = props as IBaseFormItemProps & { privateProps: IPrivateProps };

  const {
    errors,
    values,
    setFieldValue,
    disabled,
    readonly,
    plaintext,
    showError = showErrorProps,
    showErrorBlock: showErrorBlockPrivateProps,
  } = privateProps;

  const error = getObjValueByStringKey(errors, name);
  const value = getObjValueByStringKey(values, name);

  const labelDirectionClassNames = classNames(
    s['label-wrapper'],
    s[labelDirection],
    { [s.plain]: !label },
    className,
  );
  const labelClassNames = classNames(s.label, {
    [s.required]: required && !readonly,
    [s['label-info']]: !!labelInfo,
    [s['is-black-label']]: isBlackLabel,
  });
  const errorLabelClassNames = classNames(s.label, 'error-message', {
    [s.error]: !!error,
    [s.required]: required && !readonly,
  });

  const inputProps: ICommonInputProps = {
    value,
    disabled,
    readonly,
    plaintext,
    invalid: !!error,
    onChange: (val: any): void => {
      setFieldValue(name, val);
    },
  };

  return (
    <div className={wrapperClassName}>
      <div className={labelDirectionClassNames}>
        {label && (
          <label className={labelClassNames} data-cy={`label-${name}`}>
            <span>{label}</span>
            {labelInfo && <span className={s['info-label']}>{labelInfo}</span>}
          </label>
        )}
        <div className={s.input} data-cy="edit-tab-item">
          {children &&
            React.Children.map(children, (child) =>
              renderBaseFormItem(child as ReactElementWithName, inputProps),
            )}
        </div>
      </div>
      {(showErrorBlockProps ?? showErrorBlockPrivateProps) && (
        <div className={s['error-wrapper']}>
          {showError && error && (
            <span className={errorLabelClassNames}>{error}</span>
          )}
        </div>
      )}
    </div>
  );
};

export const BaseFormItemDisplayName = 'BaseFormItem';
BaseFormItem.displayName = BaseFormItemDisplayName;

export default BaseFormItem;
