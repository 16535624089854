import { useSelector } from 'react-redux';
import { isSlaReadingsAllowed } from 'constants/permissions';

import {
  hasClinicalRole,
  isAdmin,
  isAgencyDoctor,
  isBiller,
  isPatient,
  isPractice,
  isRoleDefault,
  isTurkUserRole,
} from './userTypeHelper';
import {
  hasBillingToolsAccess,
  isAtRiskRole,
  isCriticalOrAtRiskAlertingRole,
  isCriticalRole,
  hasPatientAcceptanceRole,
} from './accessManager/helpers';
import { isDesktop, isMobile, isTablet } from './deviceHelper';

import type { Roles } from 'infrastructure/enums';
import type { RootState } from 'store';

const useUser = () => {
  const user = useSelector((state: RootState) => state.user);
  const { getProfile: profileLoading } = useSelector(
    (state: RootState) => state.loading.effects.user,
  );

  const hasAnyRole = (rolesToCheck: Roles[]): boolean => {
    return rolesToCheck.some((role) => user.roleNames.includes(role));
  };

  return {
    isLoading: profileLoading,
    isAgencyDoctor: isAgencyDoctor(user.userType),
    isPractice: isPractice(user.userType),
    isPatient: isPatient(user.userType),
    isAdmin: isAdmin(user.userType),
    isRootAdmin: isAdmin(user.userType) && isRoleDefault(user.roleNames),
    isBiller: isBiller(user.roleNames),
    isCriticalOrAtRiskAlertingRole: isCriticalOrAtRiskAlertingRole(
      user.roleNames,
    ) as boolean,
    isCriticalRole: isCriticalRole(user.roleNames) as boolean,
    isTurkUserRole: isTurkUserRole(user.roleNames),
    isAtRiskRole: isAtRiskRole(user.roleNames) as boolean,
    iamPatientAcceptance: hasPatientAcceptanceRole(user.roleNames),
    isSlaReadingsAllowed: isSlaReadingsAllowed(user.userType),
    isMobileDevice: isMobile(user.deviceType),
    isTabletDevice: isTablet(user.deviceType),
    isDesktopDevice: isDesktop(user.deviceType),
    billingToolsAccess: hasBillingToolsAccess(user.roleNames),
    hasClinicalRole: hasClinicalRole(user.roleNames),
    hasAnyRole,
    ...user,
  };
};

export default useUser;
