import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { RESET_PASSWORD } from 'services/dataService/resources';
import dataService from 'services/dataService';
import withLoader from 'components/organisms/withLoader';
import { scrollToErrorIfExist } from 'utils';
import { showResult } from 'infrastructure/hooks/utils';
import Button from 'components/atoms/button';
import { Formik, Form } from 'formik';
import { Card, CardBody, Table } from 'reactstrap';
import ControlPanelButton from 'components/atoms/controlPanelButton';
import ResetPasswordIcon from 'components/atoms/icons/resetPassword.jsx';
import validationSchema from 'components/organisms/adminPanels/users/validationSchema';
import requestBodyGenerator from 'components/organisms/adminPanels/users/requestBodyGenerator';
import PersonalInfo from 'components/organisms/adminPanels/users/components/personalInfo';
import Address from 'components/organisms/adminPanels/users/components/address';
import Emr from 'components/organisms/adminPanels/users/components/emr';
import IRS from 'components/organisms/adminPanels/users/components/irs';
import Registration from 'components/organisms/adminPanels/users/components/registration';
import ProfessionInfo from 'components/organisms/adminPanels/users/components/professionInfo';
import { formatRoleName } from 'utils/formatters';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { Routes } from 'infrastructure/consts/routes';
import { useQueryClient } from '@tanstack/react-query';
import { getDoctorInfoKey } from 'infrastructure/hooks/doctor';

import ActiveStatusButton from './components/activeStatusButton';

const resetPassword = async (email) => {
  const { data, error } = await dataService.createList(RESET_PASSWORD, {
    email,
  });
  if (error) return showResult(error);
  if (data) {
    notificationController.success({
      message: "'Reset password' link sent to email",
    });
  }
};

const EditPatient = () => {
  const navigate = useNavigate();
  const {
    adminPanelUsers: { getUser, editUser, getRoles },
  } = useDispatch();
  const { userGuid } = useParams();
  const queryClient = useQueryClient();
  const doctor = useSelector((state) => state.adminPanelUsers.user);
  const [emailAlerts, setEmailAlerts] = useState(false);
  const [smsAlerts, setSmsAlerts] = useState(false);
  useEffect(() => {
    getUser(userGuid);
  }, [getUser]);

  useEffect(() => {
    if (doctor) {
      setEmailAlerts(doctor.alertEmail);
      setSmsAlerts(doctor.alertSms);
    }
  }, [doctor]);

  const {
    clinics: { getClinics },
  } = useDispatch();

  const { getUser: doctorDetailsLoading, editUser: submitLoading } =
    useSelector((state) => state.loading.effects.adminPanelUsers);

  const submitHandler = async (doctorRequest) => {
    const body = requestBodyGenerator(doctorRequest, emailAlerts, smsAlerts);
    const { data, error } = await editUser(body);
    if (error) return showResult(error);
    if (data) {
      showResult();
      queryClient.removeQueries({
        queryKey: getDoctorInfoKey(data.guid),
      });
      navigate(Routes.adminUsersDetail(data.guid));
    }
  };

  useEffect(() => {
    const run = async () => {
      await getClinics();
      await getRoles();
    };
    run();
  }, []);
  const initialRoles = doctor?.roles.map(({ guid, name }) => ({
    value: guid,
    label: formatRoleName(name),
  }));

  return (
    <div className="admin-patient">
      {withLoader(
        doctorDetailsLoading,
        <>
          {doctor && (
            <Card className="card-border">
              <CardBody>
                <Formik
                  onSubmit={submitHandler}
                  initialValues={{ ...doctor, roles: initialRoles }}
                  validationSchema={validationSchema}
                >
                  {({ errors, values, setFieldValue }) => (
                    <Form>
                      <div className="admin-patient-header admin-user-edit__header__wrapper">
                        <span>Edit Clinic's User Page</span>
                        <div className="admin-patient-header-actions">
                          <div className="admin-patient-header-actions-call">
                            <ControlPanelButton
                              label="Send Password Reset Email"
                              icon={ResetPasswordIcon}
                              handleClick={(e) => {
                                e.preventDefault();
                                resetPassword(doctor.email);
                              }}
                            />
                          </div>
                          <div className="admin-patient-header-actions-status">
                            <ActiveStatusButton status={values.status} />
                          </div>
                        </div>
                      </div>
                      <div className="admin-patient-edit-form">
                        <Table>
                          <tbody>
                            <tr>
                              <td rowSpan={2}>
                                <PersonalInfo errors={errors} />
                              </td>
                              <td>
                                <ProfessionInfo errors={errors} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Registration errors={errors} />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Address errors={errors} />
                              </td>
                              <td>
                                <IRS
                                  emailAlert={emailAlerts}
                                  setEmailAlert={setEmailAlerts}
                                  smsAlert={smsAlerts}
                                  setSmsAlert={setSmsAlerts}
                                  errors={errors}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Emr
                                  errors={errors}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="text-center">
                        <Button
                          disabled={submitLoading && errors}
                          type="submit"
                          text="Save"
                          data-cy="save-button"
                          handleClick={scrollToErrorIfExist}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </>,
      )}
    </div>
  );
};

export default EditPatient;
