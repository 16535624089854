import paths from 'constants/paths';
import CcmIcon from 'components/atoms/icons/ccmIcon';
import RpmIcon from 'components/atoms/icons/rpmIcon';
import { ModuleType } from 'infrastructure/enums';

const CCM_PREFIX = 'ccm-';
const PLATFORM_MODE_PATIENT_ACCEPTANCE = 'PATIENT_ACCEPTANCE';
const PLATFORM_MODE_ADMIN_PANEL_ACCESS = 'ADMIN-PANEL-ACCESS';
const PLATFORM_MODE_LOCALSTORAGE_KEY = 'EVELYN_PLATFORM_MODE';

const PLATFORM_CONFIG = {
  [ModuleType.CCM]: {
    dashboard: paths.ccm,
    switch: ModuleType.RPM,
    icon: CcmIcon,
  },
  [ModuleType.RPM]: {
    dashboard: paths.dashboard,
    switch: ModuleType.CCM,
    icon: RpmIcon,
  },
  [PLATFORM_MODE_PATIENT_ACCEPTANCE]: {
    dashboard: paths.adminPatients,
  },
  [PLATFORM_MODE_ADMIN_PANEL_ACCESS]: {
    dashboard: paths.adminPatients,
  },
};

export {
  CCM_PREFIX,
  PLATFORM_MODE_PATIENT_ACCEPTANCE,
  PLATFORM_MODE_ADMIN_PANEL_ACCESS,
  PLATFORM_MODE_LOCALSTORAGE_KEY,
  PLATFORM_CONFIG,
};
