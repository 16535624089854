import React, { useEffect } from 'react';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import RunbookLogSelectBox from 'components/molecules/runbook-log-select-box';
import { useRunbookLog } from 'infrastructure/hooks/clinic/use-runbook-logs';
import useUser from 'utils/useUser';
import { useEdit } from 'infrastructure/providers/edit-provider';
import { applyFields } from 'infrastructure/functions';
import classNames from 'classnames';

import s from './styles.module.scss';

import type { RunbookLog } from 'infrastructure/classes/runbook-log';

const formKey = 'AdminPanelClinicInstructionsForm';

const HtmlViewer: React.FC<{ val?: string | null }> = ({ val }) => {
  return val && val !== '-' ? (
    <p
      className={classNames(s.label, 'ql-editor', 'ql-index-1')}
      dangerouslySetInnerHTML={{ __html: val }}
    />
  ) : (
    '-'
  );
};

interface IAdminPanelClinicInstructionsFormProps {
  clinicGuid?: string;
  loading?: boolean;
}

const AdminPanelClinicInstructionsForm: React.FC<
  IAdminPanelClinicInstructionsFormProps
> = (props) => {
  const { clinicGuid, loading: loadingProp = false } = props;
  const { t } = useTranslation();
  const { canEdit } = useEdit();

  const { runbookLogs, loading, loadRunbookLogs, meta } = useRunbookLog({
    meta: {
      items: 1,
    },
  });

  const user = useUser();

  const emptyValue = {
    afterHours: '-',
    businessHours: '-',
    contactMethod: '-',
    contactMethodValue: '-',
    createdBy: '-',
    criticalAfterHours: '-',
    criticalBusinessHours: '-',
    dateNoteLogged: '-',
    notes: '-',
    createdAt: '-',
  } as RunbookLog;

  const formik = useFormik({
    initialValues: emptyValue,
    onSubmit: async () => {},
  });

  const { values } = formik;

  const loadData = async (pageParam: number) => {
    await loadRunbookLogs(
      {
        ...meta,
        page: pageParam,
        clinicGuid,
      },
      true,
    );
  };

  useEffect(() => {
    if (runbookLogs[0]) {
      formik.setValues(applyFields(emptyValue, runbookLogs[0]));
    } else {
      formik.setValues(emptyValue);
    }
  }, [runbookLogs]);

  useEffect(() => {
    if (clinicGuid) loadData(1);
  }, [clinicGuid]);

  return (
    <PatientBlock
      title={t('labels.clinicInstructions')}
      canEdit={false}
      loading={loading || loadingProp}
      disabled={!canEdit(formKey)}
    >
      <BaseForm formik={formik} plaintext readonly className={s.form}>
        <BaseFormItem name="createdAt" label={t('labels.date')}>
          <RunbookLogSelectBox
            clinicGuid={`${clinicGuid}`}
            plaintext={!clinicGuid || !user.isAdmin}
            readonly={!clinicGuid || !user.isAdmin}
            onChange={(value, option) => {
              formik.setValues(applyFields(emptyValue, option.record));
            }}
          />
        </BaseFormItem>
        <BaseFormItem
          name="criticalBusinessHours"
          label={t('labels.criticalEscalationsInstructionsAtBusinessHours')}
        >
          <HtmlViewer val={values?.criticalBusinessHours} />
        </BaseFormItem>
        <BaseFormItem
          name="criticalAfterHours"
          label={t('labels.criticalEscalationsInstructionsAfterHours')}
        >
          <HtmlViewer val={values?.criticalAfterHours} />
        </BaseFormItem>
        <BaseFormItem name="businessHours" label={t('labels.businessHours')}>
          <HtmlViewer val={values?.businessHours} />
        </BaseFormItem>
        <BaseFormItem name="afterHours" label={t('labels.afterHours')}>
          <HtmlViewer val={values?.afterHours} />
        </BaseFormItem>
        <BaseFormItem name="contactMethod" label={t('labels.contactMethod')}>
          <HtmlViewer val={values?.contactMethod} />
        </BaseFormItem>
        <BaseFormItem name="notes" label={t('labels.note')}>
          <HtmlViewer val={values?.notes} />
        </BaseFormItem>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelClinicInstructionsForm;
