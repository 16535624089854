import React, { forwardRef } from 'react';

import FormItem from './formItem';
import FormItemExtra from './formItemExtra';

import type { IFormItemWrapperProps } from './types';

const FormItemWrapper = forwardRef<HTMLDivElement, IFormItemWrapperProps>(
  (props, ref) => {
    const {
      formik,
      index,
      formItem,
      type,
      requiredSymptomFields,
      isShowExtraFields = true,
    } = props;

    return (
      <FormItem
        key={`rpmAlertBasedAssessmentForm-${index}`}
        formik={formik}
        formItem={formItem}
        modalType={type}
        requiredSymptomFields={requiredSymptomFields}
        ref={ref}
      >
        {formItem.extra &&
          isShowExtraFields &&
          formItem.extra.map((extra) => (
            <FormItemExtra
              formik={formik}
              type={extra.type}
              name={extra.name}
              options={extra.options}
              label={extra.label}
              divider={extra?.divider}
              formItemName={formItem.name}
            />
          ))}
      </FormItem>
    );
  },
);

export default FormItemWrapper;
