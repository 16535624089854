import React from 'react';
import BaseTag from 'components/atoms/base-tag';
import { useTranslation } from 'react-i18next';
import COLORS from 'assets/styles/color.module.scss';
import { DateFormats, DeviceStatus } from 'infrastructure/enums';
import { DateFormatter } from 'infrastructure/functions';

import s from './styles.module.scss';

import type { PatientDevice } from 'infrastructure/classes/patient/patient-device';

interface IDeviceInformationProps {
  data: PatientDevice;
}

const DeviceInformation: React.FC<IDeviceInformationProps> = (props) => {
  const { data } = props;

  const { t } = useTranslation();

  return (
    <div className={s['device-info']} data-cy="device-table-device-info">
      <b>{data.manufacturer}</b> <br />
      <label className={s.label}>
        {t('labels.id')}: {data.deviceId}
      </label>
      {data.createdAt && (
        <>
          <br />
          <label className={s.label}>
            {t('labels.deviceAdded')}:{' '}
            {DateFormatter({
              date: data.createdAt,
              format: DateFormats['MM-dd-yyyy'],
            })}
          </label>
        </>
      )}
      <div className={s.tags}>
        {data.status === DeviceStatus.Active ? (
          <BaseTag
            width="72px"
            color={COLORS.RANGE_LAND}
            label={t('labels.active')}
          />
        ) : (
          <BaseTag
            width="72px"
            color={COLORS.USUBENI_RED}
            label={t('labels.inactive')}
          />
        )}
        <BaseTag label={`${t('labels.gen')}-${data.generation}`} />
      </div>
    </div>
  );
};

export default DeviceInformation;
