import { useState } from 'react';
import {
  hypotensionSymptoms,
  nursingInterventionOptions,
} from 'constants/criticalSpo2Data';
import { convertDateToTimeZone, DateFormatter } from 'infrastructure/functions';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { THRESHOLD_UNITS, Timezones } from 'infrastructure/enums';

import { extractTextInParentheses } from '../helpers';
import { generateSpO2Message } from '../blurbs';

import type { IUseSpo2Props } from '../types';
import type { ISpo2Values } from 'components/molecules/rpm-alert-assessment/types';

export const useSpoAssessment = ({
  activityId,
  sharedValues,
  activityDate,
  values,
  patientGuid,
  addComment,
  closeModal,
  handleSubmit,
}: IUseSpo2Props) => {
  const [openEmergencyModal, setOpenEmergencyModal] = useState<boolean>(false);
  const { patientInfo } = usePatientInfo({
    patientGuid,
  });

  const initialValues: ISpo2Values = {
    // Q1
    patient: sharedValues?.patient,
    other: sharedValues?.other || null,
    relationship: sharedValues?.relationship || null,
    // Q2
    AOC: sharedValues?.AOC || null,
    // Q3
    last: sharedValues?.last || null,
    next: sharedValues?.next || null,
    // Q4
    isOneMoreReading: sharedValues?.isOneMoreReading ?? null,
    bounds: null,
    repeatReadingWas: null,
    // Q5
    symptoms: null,
    confirmedSymptoms: [],
    deniedSymptoms: [],
    symptomsOnset: null,
    emergencyCare: null,
    checkSymptoms: true,
    // Q6
    isTakeMedications: null,
    prescribed: [],
    otherPrescribed: null,
    // Q7
    isTakePrnMedications: null,
    prnMedications: [],
    otherPrnMedications: null,
    // Q8
    wearO2Home: null,
    homeO2Setting: null,
    // Q9
    confirmedFactors: [],
    deniedFactors: [],
    // Q10
    nursingIntervention: [],
    notEscalated: [],
    interventionDetails: null,
    // Q11
    verbalized: sharedValues?.verbalized ?? null,
  };

  const reading = `${values.spo2} ${THRESHOLD_UNITS.SpO2}`;
  const timeZone =
    patientInfo?.loginInfo?.timezone || Timezones['America/Chicago'];

  const dataInPatientTimeZone = convertDateToTimeZone(
    new Date(Number(activityDate)),
    Timezones[timeZone],
  );
  const dateOfReading = DateFormatter({
    date: dataInPatientTimeZone,
  });

  const symptomsList = hypotensionSymptoms;

  const emergentSymptoms = symptomsList
    .filter((item) => item.required)
    .map((item) => item.label);

  const preparedData = (data: ISpo2Values) => {
    const formData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
    const medications = [
      ...formData.prescribed,
      formData.otherPrescribed,
    ].filter(Boolean);
    const prnMedications = [
      ...formData?.prnMedications,
      formData.otherPrnMedications,
    ].filter(Boolean);

    const isSpeakWithPatient = formData.patient === 'Patient';
    const lastDate = formData.last
      ? DateFormatter({ date: new Date(formData.last) })
      : null;
    const nextDate = formData.next
      ? DateFormatter({ date: new Date(formData.next) })
      : null;
    const emergencyRoom = formData.nursingIntervention?.includes(
      nursingInterventionOptions[0].label,
    );
    const escalation = formData.nursingIntervention?.includes(
      nursingInterventionOptions[1].label,
    );
    const nursingEducation = formData.nursingIntervention?.includes(
      nursingInterventionOptions[2].label,
    );
    const confirmedEmergentSymptoms = formData.confirmedSymptoms.filter(
      (item: string) => emergentSymptoms.includes(item),
    );
    const confirmedSymptomsWithoutEmergent = formData.confirmedSymptoms.filter(
      (item: string) => !emergentSymptoms.includes(item),
    );

    return {
      // Did you speak to patient?
      Q1: {
        reading,
        dateOfReading,
        patient: formData.patient,
        relationship: formData.relationship,
        speakWithPatient: isSpeakWithPatient,
        speakWithOther: formData.other,
      },
      // Mental Status Assessment
      Q2: {
        AOC: formData.AOC,
      },
      // Appointments
      Q3: {
        last: lastDate,
        next: nextDate,
      },
      //
      Q4: {
        isOneMoreReading: formData.isOneMoreReading ?? null,
        bounds: formData.bounds ?? null,
        repeatReadingWas: formData.repeatReadingWas ?? null,
      },
      Q5: {
        confirmedSymptoms: confirmedSymptomsWithoutEmergent,
        deniedSymptoms: formData.deniedSymptoms,
        isAllSymptomsDenied:
          formData.deniedSymptoms.length === symptomsList.length,
        confirmedEmergentSymptoms,
        symptomsOnset: formData.symptomsOnset,
        emergencyModalAnswer: formData.emergencyCare,
      },
      // Medications
      Q6: {
        isTakeMedications: formData.isTakeMedications,
        medications,
        hasMedications: !!medications?.length,
      },
      // PRM medications
      Q7: {
        isTakePrnMedications: formData.isTakePrnMedications,
        prnMedications,
        hasPrnMedications: !!prnMedications.length,
      },
      // Does patient wear home O2?
      Q8: {
        wearO2Home: formData.wearO2Home ?? null,
        homeO2Setting: formData.homeO2Setting,
      },
      // Factors Assessment
      Q9: {
        confirmedFactors: formData.confirmedFactors,
        deniedFactors: formData.deniedFactors,
      },
      // Nursing Intervention
      Q10: {
        emergencyRoom,
        escalation,
        nursingEducation,
        notEscalated: extractTextInParentheses(formData.notEscalated),
        interventionDetails: formData.interventionDetails,
      },
      Q11: {
        verbalized: formData.verbalized ?? null,
      },
    };
  };

  const toggleEmergencyModal = () => setOpenEmergencyModal((prev) => !prev);

  const onSaveForm = (formData: ISpo2Values) => {
    const preparedValues = preparedData(formData);

    const filteredValues = Object.fromEntries(
      Object.entries(preparedValues).filter(([_, value]) => value !== null),
    );

    const message = generateSpO2Message(filteredValues);

    handleSubmit(message);
    addComment(activityId);
    closeModal();
  };

  return {
    initialValues,
    openEmergencyModal,
    symptomsList,
    onSaveForm,
    toggleEmergencyModal,
  };
};
