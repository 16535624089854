import { prepareDoctorName } from 'infrastructure/functions';

import type { LoginStatus } from 'infrastructure/enums';
import type { Nullable } from 'infrastructure/types';
import type { IDoctorInfo, IShortInfo } from 'infrastructure/interfaces';

export class Doctor {
  guid: string;
  loginGuid?: string;
  title?: Nullable<string>;
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  middleName?: Nullable<string>;
  phone?: Nullable<string>;
  email?: Nullable<string>;
  birthDate?: Nullable<string>;
  language?: Nullable<string>;
  timezone?: Nullable<string>;
  roleNames?: string[];
  mobileNumber?: Nullable<string>;
  workNumber?: Nullable<string>;
  npiNumber?: Nullable<string>;
  emrName?: Nullable<string>;
  emrId1?: Nullable<string>;
  emrId2?: Nullable<string>;
  renderingProviderId?: Nullable<string>;
  supervisingProviderId?: Nullable<string>;
  emrReadingsFlag?: boolean;
  emrReports?: Nullable<string>;
  emrBilling?: boolean;
  emrBillingCycle?: Nullable<string>;
  professionType?: Nullable<string>;
  roles: IShortInfo[];
  primaryLanguage?: Nullable<string>;
  secondaryLanguage?: Nullable<string>;
  addressLine1?: Nullable<string>;
  addressLine2?: Nullable<string>;
  state?: Nullable<string>;
  city?: Nullable<string>;
  zip?: Nullable<string>;
  alertEmail?: boolean;
  alertSms?: boolean;
  logoUrl?: Nullable<string>;
  designation?: Nullable<string>;
  clinics: IShortInfo[];
  createdAt?: Nullable<string>;
  status: LoginStatus;
  numberType?: Nullable<string>;

  constructor(data: IDoctorInfo) {
    this.guid = data.guid;
    this.loginGuid = data.loginGuid;
    this.title = data.title;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.middleName = data.middleName;
    this.phone = data.phone;
    this.email = data.email;
    this.birthDate = data.birthDate;
    this.language = data.language;
    this.timezone = data.timezone;
    this.roleNames = data.roleNames;
    this.mobileNumber = data.mobileNumber;
    this.workNumber = data.workNumber;
    this.npiNumber = data.npiNumber;
    this.emrName = data.emrName;
    this.emrId1 = data.emrId1;
    this.emrId2 = data.emrId2;
    this.renderingProviderId = data.renderingProviderId;
    this.supervisingProviderId = data.supervisingProviderId;
    this.emrReadingsFlag = data.emrReadingsFlag;
    this.emrReports = data.emrReports;
    this.emrBilling = data.emrBilling;
    this.emrBillingCycle = data.emrBillingCycle;
    this.professionType = data.professionType;
    this.roles = data.roles ?? [];
    this.primaryLanguage = data.primaryLanguage;
    this.secondaryLanguage = data.secondaryLanguage;
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.state = data.state;
    this.city = data.city;
    this.zip = data.zip;
    this.alertEmail = data.alertEmail;
    this.alertSms = data.alertSms;
    this.logoUrl = data.logoUrl;
    this.designation = data.designation;
    this.clinics = data.clinics ?? [];
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.numberType = data.numberType;
  }

  get fullName() {
    return prepareDoctorName({
      title: this.title,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      professionType: this.professionType,
    });
  }
}
