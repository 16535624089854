import React, { useCallback, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  conversationReadFilter,
  conversationClosedFilter,
  conversationLastMessageDateFilter,
} from 'constants/forms';
import common from 'constants/common';
import useOutsideClick from 'utils/useOutsideClick';
import { INITIAL_STATE } from 'store/smsDashboard';
import { useCloseOpenConversation } from 'infrastructure/hooks/smsDashboard/useCloseOpenConversation';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import BaseSelect from 'components/atoms/baseSelect';
import ChatFilterIcon from 'components/atoms/icons/chatFilterIcon';
import BaseButton from 'components/atoms/baseButton';
import ChatFilterActiveIcon from 'components/atoms/icons/chatFilterActiveIcon';
import EditIcon from 'components/atoms/icons/pencilIcon';
import DownloadIcon from 'components/atoms/icons/downloadOutlinedIcon';
import BaseModal from 'components/atoms/base-modal';
import NewConversationButton from 'components/molecules/smsDashboard/newConversationButton';

import s from './styles.module.scss';

import type { IFilterConversation } from 'infrastructure/interfaces';
import type { RootDispatch, RootState } from 'store';

interface Props {
  selectMode: boolean;
  setSelectMode: (state: boolean) => void;
  checkedConversations: any[];
  setCheckedConversations: (state: any) => void;
}

const ConversationHeader: React.FC<Props> = ({
  selectMode,
  setSelectMode,
  checkedConversations,
  setCheckedConversations,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>();
  const [isCloseModalOpen, setIsCloseModalOpen] = useState<boolean>(false);

  const { filters, conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const {
    smsDashboard: { updateConversationsFilters },
  } = useDispatch<RootDispatch>();

  const selectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  useOutsideClick(ref, () => {
    setIsMenuOpen(false);
  });

  const { closeOpenConversation, closeOpenConversationLoading } =
    useCloseOpenConversation({
      type: conversationType,
      read: filters.read,
      closed: filters.closed,
      lastMessageDate: filters.lastMessageDate,
      searchQuery: filters.searchQuery,
      onSuccess() {
        notificationController.info({
          message: t('smsDashboard.conversation_is_actioned_successfully', {
            action: 'closed',
          }),
        });
        closeSelectMode();
        setIsCloseModalOpen(false);
      },
    });

  const closeSelectMode = () => {
    setSelectMode(false);
    setCheckedConversations([]);
  };

  const filterChanged = async (e: {
    type: string;
    value?: string | boolean;
  }) => {
    if (!e) return;
    updateConversationsFilters({ ...filters, [e.type]: e.value });
  };

  const debouncedSearch = useCallback(
    debounce((data) => {
      filterChanged(data);
    }, 800),
    [],
  );

  const filterReset = async () => {
    updateConversationsFilters({ ...INITIAL_STATE.filters });
  };

  const searchApply = async (e: { type: string; value?: string | boolean }) => {
    if (!e) return;
    if (e.type === 'searchQuery') {
      setSearchText(String(e.value));
      debouncedSearch({ type: e.type, value: e.value });
    }
  };

  const getFilterValue = (
    options: IFilterConversation[],
    value: string | boolean,
  ) => {
    const res = options.find((element) => element.value === value);
    return { label: res?.label, value: res?.value };
  };

  const isDefaultFilter =
    filters.read === INITIAL_STATE.filters.read &&
    filters.closed === INITIAL_STATE.filters.closed &&
    filters.lastMessageDate === INITIAL_STATE.filters.lastMessageDate;

  const filterIcon = isDefaultFilter ? (
    <ChatFilterIcon />
  ) : (
    <ChatFilterActiveIcon />
  );

  const selectButtonTitle =
    filters.closed !== INITIAL_STATE.filters.closed
      ? t('smsDashboard.select_conversations_disabled')
      : t('smsDashboard.select_conversations');

  return (
    <>
      {!selectMode && (
        <div className={s['conversations-header']}>
          <div className={s['conversations-search']}>
            <input
              type="text"
              className={s['conversations-search-input']}
              placeholder={t('smsDashboard.search_number_name')}
              value={searchText}
              onChange={(e) =>
                searchApply({
                  value: e.target.value,
                  type: 'searchQuery',
                })
              }
            />
            <span className={s['chat-search-icon']} />
          </div>
          <div className={s['conversations-filter']}>
            <button
              className={s['conversations-filter-button']}
              onClick={() => setSelectMode(!selectMode)}
              disabled={filters.closed !== INITIAL_STATE.filters.closed}
              title={selectButtonTitle}
            >
              <EditIcon />
            </button>
          </div>
          <div className={s['conversations-filter']}>
            <button
              className={s['conversations-filter-button']}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {filterIcon}
            </button>
            {isMenuOpen && (
              <div className={s['conversations-filter-modal']} ref={ref}>
                <div className={s['conversations-filter-header']}>
                  <p>{t('filters.filters')}</p>
                </div>

                <div className={s['conversations-filter-body']}>
                  <div className={s['conversations-filter']}>
                    <span>{t('smsDashboard.statuses')}</span>
                    <BaseSelect
                      options={conversationClosedFilter}
                      defaultValue={conversationClosedFilter[0]}
                      value={getFilterValue(
                        conversationClosedFilter,
                        filters.closed,
                      )}
                      theme={(theme) => selectTheme(theme)}
                      classNamePrefix="select-type"
                      onChange={(e) =>
                        filterChanged({
                          type: 'closed',
                          value: (e as unknown as { [key: string]: string })
                            .value,
                        })
                      }
                    />
                  </div>
                  <div className={s['conversations-filter']}>
                    <span>{t('smsDashboard.message_type')}</span>
                    <BaseSelect
                      options={conversationReadFilter}
                      defaultValue={conversationReadFilter[0]}
                      value={getFilterValue(
                        conversationReadFilter,
                        filters.read,
                      )}
                      theme={(theme) => selectTheme(theme)}
                      classNamePrefix="select-type"
                      onChange={(e) =>
                        filterChanged({
                          type: 'read',
                          value: (e as unknown as { [key: string]: string })
                            .value,
                        })
                      }
                    />
                  </div>
                  <div className={s['conversations-filter']}>
                    <span>{t('smsDashboard.sorting')}</span>
                    <BaseSelect
                      options={conversationLastMessageDateFilter}
                      defaultValue={conversationLastMessageDateFilter[0]}
                      value={getFilterValue(
                        conversationLastMessageDateFilter,
                        filters.lastMessageDate,
                      )}
                      theme={(theme) => selectTheme(theme)}
                      classNamePrefix="select-type"
                      onChange={(e) =>
                        filterChanged({
                          type: 'lastMessageDate',
                          value: (e as unknown as { [key: string]: string })
                            .value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className={s['conversations-filter-footer']}>
                  <BaseButton
                    type="secondary"
                    label={t('controls.close')}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  />
                  <BaseButton
                    label={t('controls.reset')}
                    onClick={() => {
                      filterReset();
                      setIsMenuOpen(!isMenuOpen);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <NewConversationButton />
        </div>
      )}
      {selectMode && (
        <div
          className={`${s['conversations-header']} ${s['conversations-header-select']}`}
        >
          <div className={s['conversations-search']}>
            {checkedConversations.length} {t('labels.selected')}
          </div>
          <BaseButton
            label={t('controls.cancel')}
            type="secondary"
            onClick={closeSelectMode}
          />
          <BaseButton
            label={t('controls.close')}
            type="secondary"
            onClick={() => {
              setIsCloseModalOpen(true);
            }}
            icon={<DownloadIcon />}
          />
          <BaseModal
            title={t('smsDashboard.close_selected_conversations')}
            open={isCloseModalOpen}
            onCancel={() => {
              closeSelectMode();
              setIsCloseModalOpen(false);
            }}
            withFooter={[
              <BaseButton
                dataCy="cancel-button"
                key="cancel"
                type="secondary"
                label={t('controls.cancel')}
                disabled={closeOpenConversationLoading}
                onClick={() => {
                  closeSelectMode();
                  setIsCloseModalOpen(false);
                }}
              />,
              <BaseButton
                dataCy="save-button"
                key="save"
                type="primary"
                label={t('controls.confirm')}
                loading={closeOpenConversationLoading}
                onClick={() => {
                  closeOpenConversation({
                    conversationIds: checkedConversations,
                    close: true,
                  });
                }}
              />,
            ]}
          >
            <p>{t('smsDashboard.close_selected_conversations_message')}</p>
          </BaseModal>
        </div>
      )}
    </>
  );
};

export default ConversationHeader;
