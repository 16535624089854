import React, { useState } from 'react';
import classNames from 'classnames';
import EllipseIcon from 'components/atoms/icons/ellipse';
import HospitalIcon from 'components/atoms/icons/hospital';
import BaseCheckbox from 'components/atoms/base-checkbox';
import BaseTag from 'components/atoms/base-tag';
import { useTranslation } from 'react-i18next';
import CaretDownIcon from 'components/atoms/icons/caret-down';
import { Dropdown } from 'antd';

import s from './styles.module.scss';

import type { MenuProps } from 'antd';

interface IProps {
  name: string;
  isParent: boolean;
  isLast: boolean;
  checked: boolean;
  isControl?: boolean;
  withoutParent?: boolean;
  showIcon?: boolean;
  showCheckBox?: boolean;
  onChange: (checked: boolean) => void;
  parentOnChange: (key: string) => void;
}

const ClinicFilterFormListItem: React.FC<IProps> = ({
  name,
  isParent,
  isLast,
  checked,
  isControl = false,
  showIcon = true,
  showCheckBox = true,
  withoutParent = false,
  onChange,
  parentOnChange,
}) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const { t } = useTranslation();

  const icon = isParent ? <HospitalIcon size="small" /> : <EllipseIcon />;

  const itemClassNames = classNames(s.item, {
    [s.child]: !isParent && !withoutParent,
    [s.parent]: isParent && !isLast,
    [s.open]: openDropdown,
  });

  const onCheck = () => {
    if (showCheckBox && !openDropdown) onChange(!checked);
  };

  const parentOnCheck = (value: any) => {
    parentOnChange(value.key);
  };

  const parentClinicOptions = [
    {
      label: t('labels.all'),
      id: 'all',
    },
    {
      label: t('labels.none'),
      id: 'none',
    },
    {
      label: t('labels.onlyParent'),
      id: 'parent',
    },
    {
      label: t('labels.onlyChildren'),
      id: 'children',
    },
  ];

  const items: MenuProps['items'] = parentClinicOptions.map((option) => ({
    label: option.label,
    key: option.id,
    onClick: (value) => parentOnCheck(value),
  }));

  return (
    <li className={itemClassNames} onClick={onCheck} data-cy="clinicList-item">
      <div className={s.title} data-cy="clinicList-title">
        {showIcon && <i>{icon}</i>}
        {name}
        {isParent && !isLast && <BaseTag label={t('labels.parent')} />}
      </div>

      {isLast && showCheckBox && !isControl && (
        <div className={s.checkbox} data-cy="clinicList-checkbox">
          <BaseCheckbox value={checked} />
        </div>
      )}

      {isParent && !isLast && showCheckBox && !isControl && (
        <div className={s.checkbox} data-cy="clinicList-checkbox">
          <BaseCheckbox value={checked} />
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            rootClassName={s.menu}
            getPopupContainer={(trigger) => trigger}
            onOpenChange={() => setOpenDropdown((prevState) => !prevState)}
          >
            <div
              className={s['caret-wrapper']}
              onClick={(e) => e.stopPropagation()}
            >
              <CaretDownIcon />
            </div>
          </Dropdown>
        </div>
      )}
    </li>
  );
};

export default ClinicFilterFormListItem;
