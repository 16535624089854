export const PatientSalesForceIntegrations = {
  Disabled: 'disabled',
  Inherited: 'inherited',
} as const;
export const PatientSalesForceIntegrationEnum = Object.values(
  PatientSalesForceIntegrations,
);

export type PatientSalesForceIntegration =
  (typeof PatientSalesForceIntegrationEnum)[number];

export const ClinicSalesForceIntegrations = {
  Disabled: 'disabled',
  Enabled: 'enabled',
} as const;
export const ClinicSalesForceIntegrationEnum = Object.values(
  ClinicSalesForceIntegrations,
);

export type ClinicSalesForceIntegration =
  (typeof ClinicSalesForceIntegrationEnum)[number];
