import { buildTableCardItems } from 'components/atoms/base-table-card';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { timezonesDataSource } from 'infrastructure/data-sources/timezones';
import { languagesDataSource } from 'infrastructure/data-sources/languages';
import { formatRoleName } from 'utils/formatters';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import type { Doctor } from 'infrastructure/classes/doctor/info';

interface IProps {
  info: Doctor;
}

export const useDoctorViewForm = ({ info }: IProps) => {
  const { t } = useTranslation();

  const timezone = useMemo(() => {
    return timezonesDataSource().find((el) => el.id === info.timezone);
  }, [info]);

  const primaryLanguage = useMemo(() => {
    return languagesDataSource().find((el) => el.id === info.primaryLanguage);
  }, [info]);

  const secondaryLanguage = useMemo(() => {
    return languagesDataSource().find((el) => el.id === info.secondaryLanguage);
  }, [info]);

  const personalInfo = buildTableCardItems([
    {
      label: t('labels.name'),
      value: info.fullName,
    },
    {
      label: t('labels.phoneNumber'),
      value: info.mobileNumber,
    },
    {
      label: t('labels.workNumber'),
      value: info.workNumber,
    },
    {
      label: t('labels.email'),
      value: info.email,
      originView: true,
    },
    {
      label: t('labels.primaryLanguage'),
      value: primaryLanguage?.name,
    },
    {
      label: t('labels.secondaryLanguage'),
      value: secondaryLanguage?.name,
    },
    {
      label: t('labels.dob'),
      value: info?.birthDate,
    },
  ]);

  const professionInfo = buildTableCardItems([
    {
      label: t('labels.clinics'),
      value: info?.clinics?.map((el) => el.name).join(', '),
    },
    {
      label: t('labels.profession'),
      value: info.professionType,
    },
    {
      label: t('labels.roles'),
      value: info?.roles?.map((el) => formatRoleName(el.name)).join(', '),
    },
  ]);

  const addressInfo = buildTableCardItems([
    {
      label: t('labels.addressLine1'),
      value: info.addressLine1,
    },
    {
      label: t('labels.addressLine2'),
      value: info.addressLine2,
    },
    {
      label: t('labels.city'),
      value: info.city,
    },
    {
      label: t('labels.state'),
      value: info.state,
    },
    {
      label: t('labels.zip'),
      value: info.zip,
    },
    {
      label: t('labels.timezone'),
      value: timezone?.name,
    },
  ]);

  const registrationInfo = buildTableCardItems([
    {
      label: t('labels.registeredAt'),
      value: info.createdAt
        ? DateFormatter({
            date: new Date(info.createdAt),
            format: DateFormats['MMM dd yyyy HH:mm:ss'],
          })
        : '',
    },
    {
      label: t('labels.username'),
      value: info.email,
      originView: true,
    },
  ]);

  const emrInfo = buildTableCardItems([
    {
      label: t('labels.emrName'),
      value: info.emrName,
    },

    {
      label: `${t('labels.emrId')} 1`,
      value: info.emrId1,
    },
    {
      label: `${t('labels.emrId')} 2`,
      value: info.emrId2,
    },
    {
      label: t('labels.supervisingProvider'),
      value: info.supervisingProviderId,
    },
    {
      label: t('labels.renderingProvider'),
      value: info.renderingProviderId,
    },
    {
      label: t('labels.billing'),
      value: info.emrBilling,
    },
    {
      label: t('labels.billingCycle'),
      value: info.emrBillingCycle,
    },

    {
      label: t('labels.readings'),
      value: info.emrReadingsFlag,
    },
    {
      label: t('labels.report'),
      value: info.emrReports,
    },
  ]);

  const irsInfo = buildTableCardItems([
    {
      label: t('labels.npi'),
      value: info.npiNumber,
    },
    {
      label: t('labels.alertEmail'),
      value: info.alertEmail,
    },
    {
      label: t('labels.alertSms'),
      value: info.alertSms,
    },
  ]);

  return {
    personalInfo,
    professionInfo,
    addressInfo,
    registrationInfo,
    emrInfo,
    irsInfo,
  };
};
