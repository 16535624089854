import React, { useCallback } from 'react';
import BaseAsyncSelectBox from 'components/atoms/base-select-box/async';
import { usePatientSelect } from 'infrastructure/hooks/select/use-patient-select';

import type { PatientSelect } from 'infrastructure/classes/select/patient-select';
import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { IPaginationMeta } from 'infrastructure/interfaces';
import type { LoginStatus } from 'infrastructure/enums/login-status';
import type { RpmStatuses, Roles } from 'infrastructure/enums';

type PatientSelectOption = IBaseSelectBoxOption<string, PatientSelect>;

interface IPatientSelectBoxProps extends Omit<IBaseSelectBoxProps, 'options'> {
  agencyGuid?: string | string[];
  clinicGuid?: string | string[];
  doctorGuid?: string | string[];
  roles?: Array<Roles>;
  loginStatus?: Array<LoginStatus>;
  patientStatus?: Array<RpmStatuses>;
  onLoaded?: (
    items: Array<PatientSelectOption>,
    selected?: PatientSelectOption,
  ) => void;
  labelRender?: (el: PatientSelectOption) => React.ReactNode;
}

const PatientSelectBox: React.FC<IPatientSelectBoxProps> = (props) => {
  const {
    agencyGuid,
    clinicGuid,
    doctorGuid,
    roles,
    loginStatus,
    patientStatus,
    labelRender,
    onLoaded,
  } = props;

  const { meta, loadPatients } = usePatientSelect();

  const fetchOptions = useCallback(
    async (search: string, metaProps: IPaginationMeta) => {
      const clinicPatients = await loadPatients({
        ...metaProps,
        search,
        agencyGuid,
        clinicGuid,
        doctorGuid,
        loginStatus,
        patientStatus,
      });

      if (clinicPatients)
        return clinicPatients.map((el) => {
          return {
            label: el.label,
            value: el.guid,
            record: el,
          };
        });

      return [];
    },
    [agencyGuid, clinicGuid, doctorGuid, roles, loginStatus, patientStatus],
  );

  return (
    <BaseAsyncSelectBox
      key={`${agencyGuid}-${clinicGuid} ${doctorGuid}`}
      fetchOptions={fetchOptions}
      onValueLoaded={onLoaded}
      meta={meta}
      canSearch
      labelRender={labelRender}
      {...props}
    />
  );
};

export default PatientSelectBox;
