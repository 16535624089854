import BaseModal from 'components/atoms/base-modal';
import BaseTextArea from 'components/atoms/base-textarea';
import BaseButton from 'components/atoms/baseButton';
import forms from 'constants/forms';
import { useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BaseSelect from 'components/molecules/select/base';

import s from './style.module.scss';

import type { RootDispatch, RootState } from 'store';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSubmit: (data: any) => Promise<void>;
  modalLabel: string;
};

type TemplateType = {
  key: string | null;
  value: string;
  label: string | null;
};
const SmsDashboardTemplate: FunctionComponent<Props> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  modalLabel,
}) => {
  const { t } = useTranslation();
  const { currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [template, setTemplate] = useState<TemplateType>({
    label: null,
    value: '',
    key: null,
  });

  const {
    patient: { getBingoNumber },
  } = useDispatch<RootDispatch>();

  const smsTemplates = forms.smsDashboardTemplates.map((i) => ({
    key: i.label,
    label: i.label,
    value: i.value,
  }));

  const fillFieldTemplate = async (value: any) => {
    let text = value?.value;
    if (text.includes('{bingo_number}')) {
      setLoading(true);
      const { data } = await getBingoNumber();
      setLoading(false);
      if (data) {
        text = text.replace('{bingo_number}', data.todays_bingo_number);
      }
    }
    if (text.includes('{first_name}')) {
      text = text.replace(/{first_name}/g, currentConversation.title);
    }
    setTemplate({
      label: value.label,
      value: text,
      key: value.label,
    });
  };

  const handleCancel = () => {
    setTemplate({ label: null, value: '', key: '' });
    setIsOpen(false);
  };
  const handleSubmit = async () => {
    await onSubmit(template.value);
    handleCancel();
  };

  return (
    <BaseModal
      title={modalLabel}
      open={isOpen}
      onCancel={handleCancel}
      withFooter={[
        <BaseButton
          label={t('controls.cancel')}
          type="secondary"
          onClick={handleCancel}
          className={s.button}
          key={t('controls.cancel')}
          loading={loading}
        />,
        <BaseButton
          label={t('controls.send')}
          onClick={handleSubmit}
          className={s.button}
          key={t('controls.send')}
          loading={loading}
        />,
      ]}
    >
      <div className={s.wrapper}>
        <BaseSelect
          label={t('smsDashboard.template')}
          options={smsTemplates}
          placeholder={t('smsDashboard.select_option')}
          onChange={(value: any) => {
            fillFieldTemplate(value);
          }}
        />
        <div style={{ width: '100%' }}>
          <label htmlFor="templateTextAreaId" className={s.label}>
            {t('smsDashboard.template_text')}
          </label>
          <BaseTextArea
            id="templateTextAreaId"
            disabled={loading}
            value={template?.value}
            style={{ resize: 'vertical' }}
            onChange={(value) =>
              setTemplate({
                ...template,
                value,
              })
            }
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default SmsDashboardTemplate;
