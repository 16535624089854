import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSmsDashobardPatientInfo } from 'infrastructure/hooks/smsDashboard/usePatientInfo';
import ProfileInfo from 'components/molecules/smsDashboard/profileInfo';
import InternalNotes from 'components/molecules/smsDashboard/internalNotes';

import s from './styles.module.scss';

import type { RootState } from 'store';

const Sidebar = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState<'patient' | 'notes'>('patient');

  const { currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const onPatientInfoTabClick = () => {
    setTab('patient');
  };

  const onNotesTabClick = () => {
    setTab('notes');
  };

  useEffect(() => {
    return () => {
      setTab('patient');
    };
  }, [currentConversation]);

  const { patientInfo, patientInfoLoading, fetchPatientInfo } =
    useSmsDashobardPatientInfo({
      patientGuid: currentConversation.patient.patientGuid,
    });

  return (
    <div className={s.sidebar}>
      <div className={s.tabs}>
        <button
          className={tab === 'patient' ? s.active : ''}
          onClick={onPatientInfoTabClick}
        >
          {t('smsDashboard.patient_info')}
        </button>
        <button
          className={tab === 'notes' ? s.active : ''}
          onClick={onNotesTabClick}
        >
          {t('smsDashboard.notes')}
        </button>
      </div>
      {tab === 'patient' && (
        <ProfileInfo
          patientInfo={patientInfo}
          patientInfoLoading={patientInfoLoading}
          fetchPatientInfo={fetchPatientInfo}
        />
      )}
      {tab === 'notes' && <InternalNotes />}
    </div>
  );
};

export default Sidebar;
