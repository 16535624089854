import BaseTableNew from 'components/atoms/base-table/new';
import BasePagination from 'components/atoms/base-pagination';
import ResetPasswordModal from 'components/molecules/reset-password-modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';
import SearchIcon from 'components/atoms/icons/search.svg?react';
import {
  useGetAdminPanelAgencyDoctors,
  ADMIN_PANEL_AGENCY_DOCTORS_PER_PAGE,
} from 'infrastructure/hooks/doctor';
import { scrollToTop } from 'infrastructure/functions';

import { adminPanelAgencyUsersColumns } from './columns';
import s from './styles.module.scss';
import { useAgencyUsersFilter } from '../agency-users-filter/use-users-filter';

const AdminAgencyUsersTable = () => {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');
  const { filter, updateFilter, count } = useAgencyUsersFilter();
  const { status, page, agencyGuid, guid, roleName } = filter;
  const { doctors, isFetching } = useGetAdminPanelAgencyDoctors({
    page,
    status,
    roleName,
    agencyGuid,
    guid,
  });
  const { t } = useTranslation();

  const toggleOpen = () => setOpenModal((prev) => !prev);

  const onClickResetPass = (userEmail: string) => {
    toggleOpen();
    setEmail(userEmail);
  };

  const emptyTableLabel =
    count === 0
      ? t('labels.noUsersFound')
      : t('labels.noUsersFoundByFiltering');

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.header}>
          <p className={s.users}>
            {t('labels.agencyUsers')}: <span>{doctors?.count ?? ''}</span>
          </p>
          <BasePagination
            page={page}
            total={doctors?.count}
            align="end"
            onChange={async (newPage) => {
              updateFilter({ page: newPage });
            }}
            pageSize={ADMIN_PANEL_AGENCY_DOCTORS_PER_PAGE}
            pageSizeOptions={[]}
            hide={false}
            showSizeChanger={false}
          />
        </div>
        <div className={s.divider} />
        <BaseTableNew
          columns={adminPanelAgencyUsersColumns({
            onClickResetPass,
            disabledReset: openModal,
          })}
          bordered
          loading={isFetching}
          items={doctors?.items ?? []}
          pagination={{
            current: page,
            pageSize: ADMIN_PANEL_AGENCY_DOCTORS_PER_PAGE,
            total: doctors?.count ?? 0,
            position: ['bottomCenter'],
          }}
          className={s.table}
          onChangePage={(newPage) => {
            scrollToTop();
            updateFilter({ page: newPage });
          }}
          emptyText={
            <EmptyTablePlaceholder
              text={emptyTableLabel}
              icon={<SearchIcon width={100} height={100} />}
              column
              textColor="purple"
            />
          }
        />
      </div>
      <ResetPasswordModal
        open={openModal}
        toggleOpen={toggleOpen}
        email={email}
        to={t('labels.agencyUser')}
      />
    </>
  );
};
export default AdminAgencyUsersTable;
