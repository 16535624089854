import i18next from 'i18next';
import ChevronRightIcon from 'components/atoms/icons/chevron-right.svg?react';
import { parse } from 'date-fns';
import { convertUTCToLocal } from 'utils/date';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type { Nullable } from 'infrastructure/types';
import type { ThresholdLog } from 'infrastructure/classes/logs/threshold-log';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

const checkValue = (value: Nullable<string>) =>
  !value || value === 'null' ? i18next.t('labels.bannerNoData') : value;

export const thresholdLogsColumns = (): IBaseTableColumns<ThresholdLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '170px',
      render(value) {
        const date = parse(value, 'MM-dd-yyyy | hh:mm a', new Date());
        const localDate = convertUTCToLocal(date.toString());
        let formattedDate;

        if (localDate) {
          formattedDate = DateFormatter({
            date: localDate,
            format: DateFormats['MM-dd-yyyy | h:mm a'],
          });
        }

        return (
          <span className={s.date} data-cy="logs-dateChanged">
            {formattedDate}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.statusChanging'),
      key: 'from',
      width: '600px',
      responsive: ['md'],
      render(_, record) {
        return record.changes.map(({ field, from, to }, index) => (
          <div key={index} className={s.change}>
            <span className={s.date}>{field}</span>
            <div className={s.info}>
              <span className={s.from}>
                {`${i18next.t('labels.from')}: ${checkValue(from)}`}
              </span>
              <ChevronRightIcon />
              <span className={s.to}>{`${i18next.t('labels.to')}: ${checkValue(
                to,
              )}`}</span>
            </div>
          </div>
        ));
      },
    },
    {
      label: i18next.t('labels.electronicallySigned'),
      align: 'right',
      key: 'author',
      width: '130px',
      render(_, record) {
        return (
          <span className={s.who} data-cy="logs-electronicallySigned">
            {`${record.author} ${
              record.professionType ? `- ${record.professionType}` : ''
            }`}
          </span>
        );
      },
    },
  ];
};
