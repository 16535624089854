import forms from 'constants/forms';
import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useAminPanelSearchDoctor } from 'infrastructure/hooks/doctor';
import { useAminPanelSearchClinics } from 'infrastructure/hooks/clinic';
import useRoles from 'infrastructure/hooks/use-get-roles';
import { formatRoleName } from 'utils/formatters';
import { useTranslation } from 'react-i18next';
import { UserType } from 'infrastructure/enums';

import { useClinicUsersFilter } from './use-users-filter';

export const options = forms.adminPanelPracticeUserFilter.map((opt) => ({
  value: opt.id,
  label: opt.label,
  placeholder: opt.placeholder,
}));

const DEFAULT_DOCTOR_OPTION = options[0];

export const useFilter = () => {
  const { filter, updateFilter, resetFilter, count } = useClinicUsersFilter();
  const { searchDoctor, searchDoctorLoading, doctors } =
    useAminPanelSearchDoctor();
  const { searchClinic, clinics, searchClinicLoading } =
    useAminPanelSearchClinics();
  const { roles } = useRoles(UserType.Practice);
  const { t } = useTranslation();

  const statusOptions = forms.loginStatusFilterSelect;

  const placeholder = useMemo(
    () =>
      options.find(
        (o) =>
          o.value === filter.doctorKey ||
          o.value === DEFAULT_DOCTOR_OPTION.value,
      )?.placeholder,
    [filter.doctorKey],
  );

  const debounceSearchDoctor = useCallback(debounce(searchDoctor, 800), []);
  const debounceSearchClinic = useCallback(debounce(searchClinic, 800), []);

  const roleOptions = [
    {
      value: null,
      label: t('labels.all'),
    },
    ...(roles ?? []).map((role) => ({
      value: role.name,
      label: formatRoleName(role.name),
    })),
  ];

  const doctorOptions =
    doctors ??
    (filter.doctorLabel && filter.guid
      ? [
          {
            label: filter.doctorLabel,
            value: filter.guid,
          },
        ]
      : []);

  const clinicOptions =
    clinics?.items ??
    (filter.clinicLabel && filter.clinicGuid
      ? [
          {
            label: filter.clinicLabel,
            value: filter.clinicGuid,
          },
        ]
      : []);

  return {
    filter,
    updateFilter,
    searchDoctorLoading,
    searchClinicLoading,
    statusOptions,
    placeholder,
    debounceSearchDoctor,
    debounceSearchClinic,
    doctorOptions,
    clinicOptions,
    count,
    DEFAULT_DOCTOR_OPTION,
    options,
    roleOptions,
    resetFilter,
  };
};
