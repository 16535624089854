import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { differenceInCalendarDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import common from 'constants/common';
import { convertSecondsToTime, parseDateSafe } from 'utils/timeHelper';
import Status from 'components/atoms/status';
import GeneralCommentModal from 'components/templates/rpm/patients/patient/generalComments/modal';
import { PlatformEnums } from 'infrastructure/enums/platformEnums';
import { Link } from 'react-router-dom';
import {
  hasPatientProfileCcm,
  hasPatientProfileRpm,
} from 'utils/userTypeHelper';
import { moduleRoutes, Routes } from 'infrastructure/consts/routes';
import GiftIcon from 'components/atoms/icons/giftIcon';
import BuildingIcon from 'components/atoms/icons/buildingIcon';
import PersonIcon from 'components/atoms/icons/personIcon';
import EmailDefaultIcon from 'components/atoms/icons/emailDefaultIcon';
import TelephoneIcon from 'components/atoms/icons/telephoneIcon';
import PersonCheckIcon from 'components/atoms/icons/personCheckIcon';
import CopyIcon from 'components/atoms/icons/copyIcon';
import CopyableText from 'components/atoms/buttons/copyable-text';
import DisabledIcon from 'components/atoms/icons/disabledIcon';
import CheckCircleOutlined from 'components/atoms/icons/checkCircleOutlined';
import {
  useGetCcmInteractionTime,
  useGetRpmInteractionTime,
} from 'components/organisms/interactiveTime/hooks';
import {
  isCcmModule,
  isRpmModule,
  prepareDoctorName,
} from 'infrastructure/functions';
import { ClinicStatus, LoginStatus, ModuleType } from 'infrastructure/enums';
import { useInteractionActionButtons } from 'components/organisms/admin-control-panel/hooks/useInteractionActionButtons';
import { numberTypesDataSource } from 'infrastructure/data-sources/number-types';
import { languagesMap } from 'constants/languages';

import s from './styles.module.scss';

import type { IConversation } from 'infrastructure/interfaces';
import type { RootState } from 'store';
import type { INumberTypeItem } from 'infrastructure/data-sources/number-types';
import type { IPatientDetails } from 'infrastructure/hooks/smsDashboard/usePatientInfo';

interface Props {
  patientInfo: IPatientDetails | undefined;
  patientInfoLoading: boolean;
  fetchPatientInfo: () => void;
}

const ProfileInfo = ({
  patientInfo,
  patientInfoLoading,
  fetchPatientInfo,
}: Props) => {
  const { t } = useTranslation();
  const numberTypes = numberTypesDataSource();
  const { currentConversation, conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const [isVisible, setVisible] = useState<{
    general: boolean;
    contact: boolean;
    account: boolean;
  }>({
    general: false,
    contact: false,
    account: false,
  });

  const { patientGuid } = currentConversation.patient;

  const { rpmInteractionTime, isFetching: isFetchRpm } =
    useGetRpmInteractionTime({
      patientGuid,
      enabled:
        hasPatientProfileRpm(patientInfo?.profile.status) &&
        isRpmModule(
          ModuleType[conversationType.toUpperCase() as keyof typeof ModuleType],
        ),
    });
  const { ccmInteractionTime, isFetching: isFetchCcm } =
    useGetCcmInteractionTime({
      patientGuid,
      enabled:
        hasPatientProfileCcm(patientInfo?.profile.ccmStatus) &&
        isCcmModule(
          ModuleType[conversationType.toUpperCase() as keyof typeof ModuleType],
        ),
    });

  const {
    commentsPopupOpen,
    commentLoading,
    setCommentsPopupOpen,
    sendComment,
  } = useInteractionActionButtons({ patientGuid });

  useEffect(() => {
    const anchor = document.querySelector('#chat-profile__avatar');
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [patientInfoLoading]);

  const toggleVisibility = async (name: string) => {
    setVisible({
      ...isVisible,
      [name]: !(isVisible as { [key: string]: boolean })[name],
    });
  };

  const calculateHoldingStatus = (holdingStatus: {
    status: string;
    endTime: string;
  }) => {
    const label = (common.patientHoldingStatus as { [key: string]: string })[
      holdingStatus.status
    ];

    if (
      holdingStatus.status === 'hospital' ||
      holdingStatus.status === 'vacation'
    ) {
      const today = new Date();
      const endDate = parseDateSafe(holdingStatus?.endTime);
      const remainingDays =
        endDate && differenceInCalendarDays(new Date(endDate), today);

      return `${label} ( ${(remainingDays as number) + 1} days )`;
    }
    return label;
  };

  const interactiveTime = ccmInteractionTime || rpmInteractionTime;

  const isCurrentConversationExist = currentConversation.id !== 0;

  const patientFullNameRpm = () => (
    <>
      <p>
        {hasPatientProfileRpm(patientInfo?.profile.status) &&
        patientInfo?.profile.guid ? (
          <Link to={Routes.patientDetail(patientInfo?.profile.guid)}>
            {isCurrentConversationExist &&
              (currentConversation as IConversation).patient
                .fullNameWithOnlyTitle}
          </Link>
        ) : (
          isCurrentConversationExist &&
          (currentConversation as IConversation).patient.fullNameWithOnlyTitle
        )}
      </p>
    </>
  );
  const patientFullNameCcm = () => (
    <>
      <p>
        {hasPatientProfileCcm(patientInfo?.profile.ccmStatus) &&
        patientInfo?.profile.guid ? (
          <Link
            to={`${moduleRoutes.ccm}${Routes.patientDetail(
              patientInfo?.profile.guid,
            )}`}
          >
            {isCurrentConversationExist &&
              (currentConversation as IConversation).patient
                .fullNameWithOnlyTitle}
          </Link>
        ) : (
          isCurrentConversationExist &&
          (currentConversation as IConversation).patient.fullNameWithOnlyTitle
        )}
      </p>
    </>
  );

  const cutText = (text: string) =>
    text?.length > 27 ? `${text.slice(0, 27)}...` : text;

  const clinicName = (
    <>
      {patientInfo?.clinic.legalname}
      {patientInfo?.clinic.status === ClinicStatus.Inactive && (
        <>
          {' '}
          <span className={s.status}>({t('clinicStatuses.inactive')})</span>
        </>
      )}
      {patientInfo?.clinic.status === ClinicStatus.Delinquent && (
        <>
          {' '}
          <span className={s.status}>({t('clinicStatuses.delinquent')})</span>
        </>
      )}
    </>
  );
  const doctorName = (
    <>
      {prepareDoctorName({
        title: patientInfo?.doctor.title,
        firstName: patientInfo?.doctor.firstName,
        lastName: patientInfo?.doctor.lastName,
      })}
      {patientInfo?.doctor.status === LoginStatus.Inactive && (
        <>
          {' '}
          <span className={s.status}>({t('loginStatuses.inactive')})</span>
        </>
      )}
      {patientInfo?.doctor.status === LoginStatus.Blocked && (
        <>
          {' '}
          <span className={s.status}>({t('loginStatuses.blocked')})</span>
        </>
      )}
    </>
  );

  return (
    <>
      {!patientInfoLoading && (!isFetchCcm || !isFetchRpm) ? (
        <div className={s['chat-profile']}>
          <div className={s['chat-profile__info']}>
            <div className={s['chat-profile__fullname']}>
              {conversationType === PlatformEnums.RPM
                ? patientFullNameRpm()
                : patientFullNameCcm()}
            </div>
            {isCurrentConversationExist && (
              <div className={s['chat-profile__phone']}>
                <p>{t('labels.primaryNumber')}:</p>
                <p>{patientInfo?.profile.phone}</p>
              </div>
            )}
            <div className={s['chat-profile__lang']}>
              <p>{t('labels.preferredLanguage')}:</p>
              <p>{languagesMap[patientInfo?.profile.language ?? '']}</p>
            </div>
            <div className={s['chat-profile__service']}>
              <p>{t('labels.serviceModel')}:</p>
              <p>{patientInfo?.clinic.serviceDeliveryModel}</p>
            </div>
          </div>
          <div className={s['chat-profile-general-comment']}>
            <div
              className={`${s['chat-profile__item']} 
                ${s['chat-profile__item--orange']} 
                ${s['chat-profile__item--curve']} 
                ${s['chat-profile__item--pointer']}`}
              onClick={() => {
                fetchPatientInfo();
                setCommentsPopupOpen(true);
              }}
            >
              <div
                className={`${s['chat-profile__inline']} 
                ${s['chat-profile--center']}`}
              >
                <div className={s['chat-profile__text']}>
                  {t('smsDashboard.general_comment')}
                </div>
              </div>
            </div>
          </div>

          <div className={s['chat-profile-general']}>
            <div
              className={`${s['chat-profile__item']} ${s['chat-profile-group-name']}`}
            >
              <div className={s['chat-profile__inline']}>
                <div className={s['chat-profile__text']}>
                  {t('smsDashboard.personal_info')}
                </div>
                <button onClick={() => toggleVisibility('general')}>
                  {isVisible.general
                    ? t('smsDashboard.less')
                    : t('smsDashboard.more')}
                </button>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.general}
            >
              <div className={s['chat-profile__inline']}>
                <div
                  className={`${s['chat-profile__icon']} ${s['chat-profile__icon--wrapper']}`}
                >
                  <GiftIcon />
                </div>
                <div className={s['chat-profile__text']}>
                  {patientInfo?.profile.birthDate}
                </div>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.general}
            >
              <div className={s['chat-profile__inline']}>
                <div
                  className={`${s['chat-profile__icon']} ${s['chat-profile__icon--wrapper']}`}
                >
                  <BuildingIcon />
                </div>
                <div className={s['chat-profile__text']}>{clinicName}</div>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.general}
            >
              <div className={s['chat-profile__inline']}>
                <div
                  className={`${s['chat-profile__icon']} ${s['chat-profile__icon--wrapper']}`}
                >
                  <PersonIcon />
                </div>
                <div className={s['chat-profile__text']}>{doctorName}</div>
              </div>
            </div>
          </div>
          <div className={s['chat-profile-contact']}>
            <div
              className={`${s['chat-profile__item']} ${s['chat-profile-group-name']}`}
            >
              <div className={s['chat-profile__inline']}>
                <div className={s['chat-profile__text']}>
                  {t('smsDashboard.contact_info')}
                </div>
                <button onClick={() => toggleVisibility('contact')}>
                  {isVisible.contact
                    ? t('smsDashboard.less')
                    : t('smsDashboard.more')}
                </button>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.contact}
            >
              <div className={s['chat-profile__inline']}>
                <div
                  className={`${s['chat-profile__icon']} ${s['chat-profile__icon--wrapper']}`}
                >
                  <EmailDefaultIcon />
                </div>
                <div
                  className={s['chat-profile__text']}
                  title={patientInfo?.profile.email}
                >
                  {patientInfo?.profile.email &&
                    cutText(patientInfo?.profile.email)}
                </div>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.contact}
            >
              <div
                className={`${s['chat-profile__inline']} ${s['align-reset']}`}
              >
                <div
                  className={`${s['chat-profile__icon']} ${s['chat-profile__icon--wrapper']}`}
                >
                  <TelephoneIcon />
                </div>
                <div className={s['chat-profile__text']}>
                  <div className={s['chat-profile__definition']}>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-text']}>
                        <p>{t('smsDashboard.primary')}:</p>
                      </div>
                      <div className={s['chat-profile__definition-label']}>
                        <p className={s.capitalize}>
                          {patientInfo?.profile.phone}
                        </p>
                      </div>
                    </div>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-text']}>
                        <p>{t('smsDashboard.type')}</p>
                      </div>
                      <div className={s['chat-profile__definition-label']}>
                        <p>
                          {
                            numberTypes.find(
                              (type: INumberTypeItem) =>
                                type.id ===
                                patientInfo?.profile.primaryNumberType,
                            )?.name
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={s['chat-profile__definition']}>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-text']}>
                        <p>{t('smsDashboard.secondary')}:</p>
                      </div>
                      <div className={s['chat-profile__definition-label']}>
                        <p className={s.capitalize}>
                          {patientInfo?.profile.homeNumber
                            ? patientInfo?.profile.homeNumber
                            : '-'}
                        </p>
                      </div>
                    </div>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-text']}>
                        <p>{t('smsDashboard.type')}</p>
                      </div>
                      <div className={s['chat-profile__definition-label']}>
                        <p>
                          {
                            numberTypes.find(
                              (type: INumberTypeItem) =>
                                type.id ===
                                patientInfo?.profile.secondaryNumberType,
                            )?.name
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.contact}
            >
              <div className={s['chat-profile__inline']}>
                <div
                  className={`${s['chat-profile__icon']} ${s['chat-profile__icon--wrapper']}`}
                >
                  <PersonCheckIcon />
                </div>
                <div className={s['chat-profile__text']}>
                  <div className={s['chat-profile__definition']}>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-text']}>
                        <p>{t('smsDashboard.preferred_contact_method')}</p>
                      </div>
                      <div
                        className={s['chat-profile__definition-label']}
                        title={
                          patientInfo?.profile.preferredContactMethod &&
                          (patientInfo?.profile[
                            patientInfo?.profile
                              .preferredContactMethod as keyof typeof patientInfo.profile
                          ] as string)
                        }
                      >
                        <p>
                          {patientInfo?.profile.preferredContactMethod
                            ? cutText(
                                patientInfo?.profile[
                                  `${patientInfo?.profile.preferredContactMethod}` as keyof typeof patientInfo.profile
                                ] as string,
                              )
                            : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s['chat-profile-account']}>
            <div
              className={`${s['chat-profile__item']} ${s['chat-profile-group-name']}`}
            >
              <div className={s['chat-profile__inline']}>
                <div className={s['chat-profile__text']}>
                  {t('smsDashboard.account_info')}
                </div>
                <button onClick={() => toggleVisibility('account')}>
                  {isVisible.account
                    ? t('smsDashboard.less')
                    : t('smsDashboard.more')}
                </button>
              </div>
            </div>
            <div
              className={s['chat-profile__item']}
              hidden={!isVisible.account}
            >
              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.id')}:</p>
                  </div>
                  <div className={s['chat-profile__definition-text']}>
                    <CopyableText
                      value={
                        patientInfo?.profile.guid
                          ? patientInfo?.profile.guid
                          : ''
                      }
                      icon={<CopyIcon />}
                      className={s['chat-profile__copy-id']}
                    />
                  </div>
                </div>
              </div>
              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.rpm_status')}:</p>
                  </div>
                </div>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <Status
                      status={patientInfo?.profile.status}
                      category="statusPatients"
                    />
                  </div>
                </div>
              </div>
              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.ссm_status')}:</p>
                  </div>
                </div>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <Status
                      status={patientInfo?.profile.ccmStatus}
                      category="ccmStatus"
                    />
                  </div>
                </div>
              </div>
              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.pcm_status')}:</p>
                  </div>
                </div>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <Status
                      status={`${patientInfo?.profile.pcmStatus}`}
                      category="pcmStatusNames"
                    />
                  </div>
                </div>
              </div>

              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.holding_status')}:</p>
                  </div>
                </div>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-text']}>
                    <p>
                      {patientInfo?.profile.holdingStatus &&
                        calculateHoldingStatus(
                          patientInfo?.profile.holdingStatus,
                        )}
                    </p>
                  </div>
                </div>
              </div>
              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.rpm_monthly_interaction')}:</p>
                  </div>
                </div>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <Status
                      status={`${patientInfo?.profile.interactionStatus}`}
                      category="pcmStatusNames"
                    />
                  </div>
                </div>
              </div>
              <div className={s['chat-profile__definition']}>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    <p>{t('smsDashboard.call_availability')}:</p>
                  </div>
                </div>
                <div className={s['chat-profile__definition-block']}>
                  <div className={s['chat-profile__definition-label']}>
                    {patientInfo?.profile.doNotCall ? (
                      <DisabledIcon />
                    ) : (
                      <CheckCircleOutlined width="16" height="16" />
                    )}
                    <Status
                      status={`${patientInfo?.profile.doNotCall}`}
                      category="doNotCallStatusNames"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s['chat-profile__interactive']}>
            <div
              className={`${s['chat-profile__item']} ${s['chat-profile-group-name']}`}
            >
              <div className={s['chat-profile__inline']}>
                <div className={s['chat-profile__text']}>
                  {t('smsDashboard.interactive_time')}
                </div>
              </div>
            </div>
            <div className={s['chat-profile__interactive-data']}>
              <div className={s['chat-profile__interactive-block']}>
                <div className={s['chat-profile__interactive-label']}>
                  <p>{t('smsDashboard.total')}</p>
                </div>
                <div className={s['chat-profile__interactive-text']}>
                  <p>{convertSecondsToTime(interactiveTime?.totalTime)}</p>
                </div>
              </div>
              <div className={s['chat-profile__interactive-block']}>
                <div className={s['chat-profile__interactive-label']}>
                  <p>{t('smsDashboard.clinic')}</p>
                </div>
                <div className={s['chat-profile__interactive-text']}>
                  <p>{convertSecondsToTime(interactiveTime?.clinicTime)}</p>
                </div>
              </div>
              <div className={s['chat-profile__interactive-date']}>
                <p className={s['chat-profile--center']}>
                  {format(new Date(), 'MMMM')}
                </p>
              </div>
            </div>
          </div>
          <GeneralCommentModal
            isOpen={commentsPopupOpen}
            toggle={setCommentsPopupOpen}
            patientGuid={patientGuid}
            loading={commentLoading}
            submit={sendComment}
            isRpmPanel={conversationType === PlatformEnums.RPM}
            isCcmPanel={conversationType === PlatformEnums.CCM}
            interactionStatus={
              conversationType === PlatformEnums.RPM
                ? patientInfo?.profile.interactionStatus
                : patientInfo?.profile.ccmInteractionStatus
            }
            getCommentsList={false}
          />
        </div>
      ) : (
        <div className={s['chat-profile']}>
          <div className={s['chat-profile-general']}>
            <div className={s['chat-profile__info']}>
              <div className={s['chat-profile__fullname']}>
                <Skeleton width={150} height={16} />
              </div>
              <div className={s['chat-profile__fullname']}>
                <Skeleton width={150} height={16} />
              </div>
            </div>
            <Skeleton width={270} height={31} />
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <div key={index} className={s['chat-profile__item']}>
                  <div className={s['chat-profile__inline']}>
                    <Skeleton circle height={32} width={32} />
                    <Skeleton width={150} height={20} />
                  </div>
                </div>
              ))}
          </div>
          <div className={s['chat-profile-contact']}>
            <div className={s['chat-profile__item']}>
              <div className={s['chat-profile__inline']}>
                <Skeleton circle height={30} width={32} />
                <Skeleton width={150} height={20} />
              </div>
            </div>
            <div className={s['chat-profile__item']}>
              <div className={s['chat-profile__inline']}>
                <Skeleton circle height={30} width={32} />
                <Skeleton width={150} height={20} />
              </div>
            </div>
            <div className={s['chat-profile__item']}>
              <div className={s['chat-profile__inline']}>
                <Skeleton circle height={30} width={32} />
                <Skeleton width={150} height={20} />
              </div>
            </div>
          </div>
          <div className={s['chat-profile-account']}>
            <div className={s['chat-profile__item']}>
              <div className={s['chat-profile__inline']}>
                <Skeleton circle height={32} width={32} />
                <Skeleton width={150} height={20} />
              </div>
              {Array(7)
                .fill(null)
                .map((_, index) => (
                  <div key={index} className={s['chat-profile__definition']}>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-label']}>
                        <Skeleton width={100} height={20} />
                      </div>
                    </div>
                    <div className={s['chat-profile__definition-block']}>
                      <div className={s['chat-profile__definition-label']}>
                        <Skeleton width={130} height={20} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileInfo;
