import { buildTableCardItems } from 'components/atoms/base-table-card';
import { useTranslation } from 'react-i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats, LoginStatus } from 'infrastructure/enums';
import { timezonesDataSource } from 'infrastructure/data-sources/timezones';
import { useMemo } from 'react';
import { serviceDeliveryThresholdsDataSource } from 'infrastructure/data-sources/service-delivery-thresholds';

import type { Clinic } from 'infrastructure/classes/clinic';

interface IProps {
  info: Clinic;
}

export const useClinicViewForm = ({ info }: IProps) => {
  const { t } = useTranslation();

  const timezone = useMemo(() => {
    return timezonesDataSource().find((el) => el.id === info.timezone);
  }, [info]);

  const contactInformation = buildTableCardItems([
    {
      label: t('labels.legalName'),
      value: info.legalname,
    },
    {
      label: t('labels.businessEmail'),
      value: info.email,
      originView: true,
    },
    {
      label: t('labels.businessPhone'),
      value: info.phone,
    },
    {
      label: t('labels.businessFax'),
      value: info.fax,
    },
    {
      label: t('labels.clinicCallerID'),
      value: info.callerId,
      originView: true,
    },
    {
      label: t('labels.crmId'),
      value: info.crmId,
      originView: true,
    },
  ]);

  const clinicAddress = buildTableCardItems([
    {
      label: `${t('labels.addressLine')} 1`,
      value: info.addressLine1,
    },
    {
      label: `${t('labels.addressLine')} 2`,
      value: info.addressLine2,
    },
    {
      label: t('labels.city'),
      value: info.city,
    },
    {
      label: t('labels.state'),
      value: info.state,
    },
    {
      label: t('labels.zipCode'),
      value: info.zip,
    },
    {
      label: t('labels.timezone'),
      value: timezone?.name,
    },
  ]);

  const serviceDeliveryThreshold = serviceDeliveryThresholdsDataSource({
    withNone: true,
  }).findByField('id', info.serviceDeliveryThresholds);

  const clinicDetails = buildTableCardItems([
    {
      label: t('labels.npi'),
      value: info.npiNumber,
    },
    {
      label: t('labels.taxIdNumber'),
      value: info.taxIdNumber,
      originView: true,
    },
    {
      label: t('labels.medicalIdTpiNumber'),
      value: info.medicalIdTpiNumber,
      originView: true,
    },
    {
      label: t('labels.ots'),
      value: info.ots,
    },
    {
      label: t('labels.otsStartDate'),
      value: info.otsStartDate,
    },
    {
      label: t('labels.referralTypes'),
      value: info.referralTypes?.join(', '),
    },
    {
      label: t('labels.serviceDeliveryThresholds'),
      value: serviceDeliveryThreshold?.name,
    },
    {
      label: t('labels.providerServiceType'),
      value: info.providerServiceType?.join(', '),
    },
    {
      label: t('labels.revenueChannels'),
      value: info.revenueChannels?.join(', '),
    },
    {
      label: t('labels.serviceDeliveryModel'),
      value: info.serviceDeliveryModel,
    },
    {
      label: t('labels.autoGeneratedClaimDrop'),
      value: info.autoGeneratedClaimDrop,
    },
    {
      label: t('labels.finId'),
      value: info.finId,
      originView: true,
    },
    {
      label: t('labels.stateIdNumber'),
      value: info.stateIdNumber,
      originView: true,
    },
    {
      label: t('labels.taxonomyNumber'),
      value: info.taxonomyNumber,
    },
    {
      label: t('labels.medicareNumber'),
      value: info.medicareNumber,
    },
    {
      label: t('labels.officeHours'),
      value: info.officeHours,
    },
    {
      label: t('labels.salesForceIntegration'),
      value: info.salesForceIntegration,
    },
  ]);

  const clinicMappings = buildTableCardItems([
    {
      label: t('labels.registrationId'),
      value: info.clinicId,
      originView: true,
    },
    {
      label: t('labels.registeredAt'),
      value: DateFormatter({
        date: info.createdAt,
        format: DateFormats['MMM d yyyy, hh:mm a'],
      }),
    },
    {
      label: t('labels.parentClinic'),
      value: info.parentClinicName,
    },
    {
      label: t('labels.agency'),
      value: info.agencyName,
    },
  ]);

  const getDoctorErrorMessage = (status?: LoginStatus) => {
    if (status && status === LoginStatus.Inactive) {
      return t('errors.currentlyInactive');
    }
    return undefined;
  };

  const representatives = buildTableCardItems([
    {
      label: t('labels.clinicSuccessOnboarding'),
      value: info?.clinicSuccessOnboardingInfo?.name,
      errorMessage: getDoctorErrorMessage(
        info?.clinicSuccessOnboardingInfo?.status,
      ),
    },
    {
      label: t('labels.clinicSuccessScaling'),
      value: info?.clinicSuccessScalingInfo?.name,
      errorMessage: getDoctorErrorMessage(
        info?.clinicSuccessScalingInfo?.status,
      ),
    },
    {
      label: t('labels.sales'),
      value: info?.salesRepresentativeInfo?.name,
      errorMessage: getDoctorErrorMessage(
        info?.salesRepresentativeInfo?.status,
      ),
    },
  ]);

  return {
    contactInformation,
    clinicAddress,
    clinicDetails,
    clinicMappings,
    representatives,
  };
};
