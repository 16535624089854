import dataService from 'services/dataService';
import { API_SEARCH_PATIENTS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import { useTranslation } from 'react-i18next';
import QueryParams from 'utils/queryParams';

import BaseAutocomplete from './base';

import type { Option } from './base';
import type { FC } from 'react';

const generateLabel = (item: any) =>
  `${item.firstName} ${item.lastName} - ${item.birthDate}`;

type SearchFields = 'fullName' | 'guid';

const fetchUserList = async ({
  search,
  items,
  page,
  searchField,
}: {
  search: string;
  page: number;
  items: number;
  searchField: SearchFields;
}): Promise<{ count: number; data: Option[] } | undefined> => {
  const emptyResult = {
    data: [],
    count: 0,
  };
  if (!search) return emptyResult;
  const url = QueryParams.stringifyUrl(API_SEARCH_PATIENTS, {
    items,
    page,
    [searchField]: search,
  });
  const { data, error } = await dataService.getList(url);
  if (error) {
    showResult(error);
  }
  if (!data || error) return emptyResult;
  const values: Option[] =
    data.data?.map((item: any) => ({
      label: generateLabel(item),
      value: item.guid,
    })) || [];

  return {
    data: values,
    count: data.count,
  };
};

interface Props {
  value?: Option[];
  onChange: (value: Option[]) => void;
  searchField?: SearchFields;
  showSelectAll?: boolean;
  width?: string;
}

const PatientsAutocomplete: FC<Props> = ({
  onChange,
  value,
  searchField = 'fullName',
  showSelectAll,
  width,
}) => {
  const { t } = useTranslation();

  const labelMapping = {
    fullName: t('labels.patientName'),
    guid: t('labels.patientGuid'),
  };

  return (
    <BaseAutocomplete
      width={width}
      label={labelMapping[searchField]}
      placeholder={t('labels.all')}
      mode="multiple"
      fetchOptions={(args) => fetchUserList({ ...args, searchField })}
      onChange={(newValues) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        onChange(items);
      }}
      value={value}
      showSelectAll={showSelectAll}
    />
  );
};

export default PatientsAutocomplete;
