export enum DateFormats {
  'hh:mm:ss aa' = 'hh:mm:ss aa',
  'eee MMM d, yyyy' = 'eee MMM d, yyyy',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'mm-dd-yyyy' = 'mm-dd-yyyy',
  'MM-dd-yyyy' = 'MM-dd-yyyy',
  'MM.dd.yyyy' = 'MM.dd.yyyy',
  'MMM dd, yyyy' = 'MMM dd, yyyy',
  'HH:mm' = 'HH:mm',
  'h:mm a' = 'h:mm a',
  'MM-dd-yyyy | h:mm a' = 'MM-dd-yyyy | h:mm a',
  'MMM dd yyyy HH:mm:ss' = 'MMM dd yyyy HH:mm:ss',
  'MM-dd-yy | h:mm a' = 'MM-dd-yy | h:mm a',
  'MM-dd-yyyy h:mm a' = 'MM-dd-yyyy h:mm a',
  'MM-dd-yyyy HH:mm:ss' = 'MM.dd.yyyy hh:mm:ss a',
  'yyyy-MM-dd HH:mm:s' = 'yyyy-MM-dd HH:mm:s',
  'yyyy-MM-dd' = 'yyyy-MM-dd',
  'MMM dd yyyy' = 'MMM dd yyyy',
  'MMM d yyyy, hh:mm a' = 'MMM d yyyy, hh:mm a',
  'MMM dd yyyy, hh:mm a' = 'MMM dd yyyy, hh:mm a',
  'MMM dd yyyy | hh:mm a' = 'MMM dd yyyy | hh:mm a',
  'MM-dd-yyyy hh:mm:ss a' = 'MM-dd-yyyy hh:mm:ss a',
  'MMM d, yyyy' = 'MMM d, yyyy',
  'EEE, MMM d, yyyy' = 'EEE, MMM d, yyyy',
  'hh:mm:ss a' = 'hh:mm:ss a',
  'yyyy-MM' = 'yyyy-MM',
  'dd MMMM, yy' = 'dd MMMM, yy',
  'MMMM dd, yyyy' = 'MMMM dd, yyyy',
}
