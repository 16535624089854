import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import {
  assigneesDataSource,
  type IAssigneeItem,
} from 'infrastructure/data-sources/assignees';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { Assignee } from 'infrastructure/enums';

type Options = IBaseSelectBoxOption<IAssigneeItem['id'], IAssigneeItem>[];

interface IAssigneeSelectBoxProps
  extends Omit<IBaseSelectBoxProps<Assignee>, 'options'> {
  withNone?: boolean;
}

const AssigneeSelectBox: React.FC<IAssigneeSelectBoxProps> = (props) => {
  const { withNone } = props;
  const dataSource = useMemo(() => assigneesDataSource(withNone), [withNone]);

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default AssigneeSelectBox;
