import { useQueryClient } from '@tanstack/react-query';
import { STATUS_LEVEL } from 'store/smsDashboard';

import { getSmsDashboardMessagesKey } from './useMessages';

import type { IMessage, IMessagesListPages } from 'infrastructure/interfaces';

interface ISocketData {
  conversationId: number;
  messageId: string | number;
  status: number;
}

export const useTryUpdateMessageStatus = () => {
  const queryClient = useQueryClient();

  const tryUpdateMessageStatus = (socketData: ISocketData) => {
    const messagesKey = getSmsDashboardMessagesKey({
      conversationId: socketData.conversationId,
      nextCursor: undefined,
      searchQuery: undefined,
    });

    const messages = queryClient.getQueryData<IMessagesListPages>(messagesKey);

    const pages = messages?.pages ?? [];
    const [firstPage, ...restPages] = pages;

    pages.forEach((page) => {
      const index = page.items.findIndex(
        (item: IMessage) => item.id === socketData.messageId,
      );
      if (index !== -1) {
        const message = page.items[index];

        const currentMessageStatusLevel =
          (STATUS_LEVEL as { [key: string]: number })[message.status] || -1;
        const newStatusLevel =
          (STATUS_LEVEL as { [key: string]: number })[socketData.status] || 0;
        if (currentMessageStatusLevel > newStatusLevel) {
          return;
        }

        page.items[index] = {
          ...page.items[index],
          status: String(socketData.status),
        };
      }
    });

    const updatedPages = [firstPage, ...restPages];

    queryClient.setQueryData<IMessagesListPages>(
      messagesKey,
      (oldConversations) => {
        if (!oldConversations) return;
        return { ...oldConversations, pages: updatedPages };
      },
    );
  };

  return { tryUpdateMessageStatus };
};
