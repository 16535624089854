import React, { useMemo } from 'react';
import BaseSelectBox from 'components/atoms/base-select-box';
import {
  actionTimesQuotasDataSource,
  type IActionTimesQuotaItem,
} from 'infrastructure/data-sources/action-times-quotas';

import type {
  IBaseSelectBoxOption,
  IBaseSelectBoxProps,
} from 'components/atoms/base-select-box/types';
import type { ActionTimesQuota } from 'infrastructure/enums';

type Options = IBaseSelectBoxOption<
  IActionTimesQuotaItem['id'],
  IActionTimesQuotaItem
>[];

interface IActionTimesQuotaSelectBoxProps
  extends Omit<IBaseSelectBoxProps<ActionTimesQuota>, 'options'> {
  withNone?: boolean;
}

const ActionTimesQuotaSelectBox: React.FC<IActionTimesQuotaSelectBoxProps> = (
  props,
) => {
  const { withNone } = props;
  const dataSource = useMemo(
    () => actionTimesQuotasDataSource(withNone),
    [withNone],
  );

  const options: Options = useMemo(
    () =>
      dataSource.map((el) => ({
        value: el.id,
        label: el.name,
        record: el,
      })),
    [dataSource],
  );

  return <BaseSelectBox options={options} {...props} />;
};

export default ActionTimesQuotaSelectBox;
