export enum Roles {
  CcmAdministrator = 'ccm-administrator',
  CcmBiller = 'ccm-biller',
  CcmDoctor = 'ccm-doctor',
  CcmNursePractitioner = 'ccm-nursePractitioner',
  CcmPhysicianAssistant = 'ccm-physicianAssistant',
  CcmQHCP = 'ccm-QHCP',
  CcmRegisteredNurse = 'ccm-registeredNurse',
  CcmSupport = 'ccm-support',

  Doctor = 'doctor',
  NursePractitioner = 'nursePractitioner',
  PharmD = 'pharmD',
  PhysicianAssistant = 'physicianAssistant',
  QHCP = 'QHCP',
  RegisteredNurse = 'registeredNurse',

  ClientSuccessOnboarding = 'clientSuccessOnboarding',
  ClientSuccessScaling = 'clientSuccessScaling',
  ClientSuccessRepresentative = 'clientSuccessRepresentative',
  SalesRepresentative = 'salesRepresentative',

  AdminPanelAccess = 'admin-panel-access',
  AdminTools = 'adminTools',
  Biller = 'biller',
  BillingTools = 'billingTools',

  AtRiskAlerting = 'atRiskAlerting',
  CriticalAlerting = 'criticalAlerting',
  Default = 'default',
  Nutritionist = 'nutritionist',
  Onboarding = 'onboarding',
  PatientAcceptance = 'patientAcceptance',
  SmsDashboard = 'smsDashboard',
  SmsDashboardUnregistered = 'smsDashboardUnregistered',
  Support = 'support',
  Clinical = 'clinical',
  Turk = 'turk',
}
