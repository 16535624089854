import React from 'react';
import BaseTableCard from 'components/atoms/base-table-card';
import BaseGrid from 'components/atoms/base-grid';
import { useTranslation } from 'react-i18next';

import { useDoctorViewForm } from './hook';
import DoctorHeaderInfo from '../header-info';

import type { Doctor } from 'infrastructure/classes/doctor/info';

interface IProps {
  info: Doctor;
}

const DoctorViewForm: React.FC<IProps> = (props) => {
  const { info } = props;

  const { t } = useTranslation();

  const {
    personalInfo,
    professionInfo,
    addressInfo,
    registrationInfo,
    emrInfo,
    irsInfo,
  } = useDoctorViewForm({
    info,
  });

  return (
    <BaseGrid rowGap={40}>
      <DoctorHeaderInfo info={info} />
      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard title={t('labels.personalInfo')} items={personalInfo} />
        <BaseTableCard title={t('labels.address')} items={addressInfo} />
      </BaseGrid>
      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard
          title={t('labels.professionInfo')}
          items={professionInfo}
        />
        <BaseTableCard
          title={t('labels.registration')}
          items={registrationInfo}
        />
      </BaseGrid>
      <BaseGrid columns={2} columnGap={20}>
        <BaseTableCard
          title={t('labels.emrDetails')}
          items={emrInfo}
          columns={2}
        />
        <BaseTableCard title={t('labels.irs')} items={irsInfo} />
      </BaseGrid>
    </BaseGrid>
  );
};

export default DoctorViewForm;
