import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { addQueryParams } from 'utils/queryHelpers';
import { isEqual } from 'lodash';

export type TParseFunctions<T = Record<string, any>> = {
  [K in keyof T]?: (value: any) => T[K] | undefined;
};

type TExcludeCount<T> = (keyof T)[];

export const useQueryFilterNew = <TFilter extends Record<string, any>>(
  defaultFilter: TFilter,
  parseFunctions: TParseFunctions<TFilter>,
  excludeFromCount?: TExcludeCount<TFilter>,
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const parsedQuery = queryString.parse(location.search) as TFilter;

  const filter = useMemo(() => {
    const newFilter: TFilter = structuredClone(defaultFilter);
    const queries = structuredClone(parsedQuery);

    for (const key in queries) {
      const parseFn = parseFunctions[key];
      if (parseFn) {
        const hasQuery = parsedQuery[key] !== undefined;
        const newValue = hasQuery
          ? parseFn(parsedQuery[key])
          : defaultFilter[key];
        newFilter[key] = newValue ?? defaultFilter[key];
      }
    }

    return newFilter;
  }, [location.search]);

  const resetFilter = () => {
    navigate({ search: queryString.stringify({}) }, { replace: true });
  };

  const updateFilter = (newFilter: Partial<TFilter>) => {
    addQueryParams({ navigate, location }, newFilter);
  };

  const countOfChangedFilterField = useMemo(
    () =>
      Object.entries(filter).filter(
        ([k, v]) =>
          Boolean(v) &&
          !isEqual(defaultFilter[k], v) &&
          !excludeFromCount?.includes(k),
      ).length,
    [filter],
  );

  return {
    count: countOfChangedFilterField,
    filter,
    updateFilter,
    resetFilter,
  };
};
