import { Input } from 'antd';
import { debounce } from 'lodash';
import { availableBillableMinutesToUnregisterReadings } from 'infrastructure/services/interactiveTime';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';
import type { FC, ChangeEvent } from 'react';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const AvailableBillableMinutes: FC<Props> = ({ appendFilters, filters }) => {
  const { t } = useTranslation();

  const handleChange = debounce(
    (
      e: ChangeEvent<HTMLInputElement>,
      key:
        | 'agencyAvailableBillableMinutesMin'
        | 'agencyAvailableBillableMinutesMax',
    ) => {
      const value = availableBillableMinutesToUnregisterReadings(
        e.target.value,
      );
      appendFilters({
        [key]: {
          inputValue: e.target.value,
          value,
        },
      });
    },
    500,
  );

  return (
    <div className={s['available-billable-minutes-wrapper']}>
      <label htmlFor="availableBillableMinutesMin">
        {t('labels.availableBillableMinutes')}
      </label>
      <div className={s['input-wrapper']}>
        <Input
          onWheel={(e) => e.currentTarget.blur()}
          id="availableBillableMinutesMin"
          placeholder="Min"
          type="number"
          defaultValue={filters.agencyAvailableBillableMinutesMin?.inputValue}
          onChange={(e) => handleChange(e, 'agencyAvailableBillableMinutesMin')}
        />
        <Input
          onWheel={(e) => e.currentTarget.blur()}
          id="agencyAvailableBillableMinutesMax"
          placeholder="Max"
          type="number"
          defaultValue={filters.agencyAvailableBillableMinutesMax?.inputValue}
          onChange={(e) => handleChange(e, 'agencyAvailableBillableMinutesMax')}
        />
      </div>
    </div>
  );
};

export default AvailableBillableMinutes;
