import React, { forwardRef } from 'react';
import BaseGrid from 'components/atoms/base-grid';
import { Radio } from 'antd';
import BaseTextArea from 'components/atoms/base-textarea';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';
import CheckboxItem from './checkboxItem';
import DateItem from './dateItem';
import RadioLineItem from './radioLineItem';

import type { IFormItemProps } from './types';

const FormItem = forwardRef<HTMLDivElement, IFormItemProps>((props, ref) => {
  const { formik, formItem, modalType, children, requiredSymptomFields } =
    props;

  const {
    title,
    description,
    type,
    required,
    options,
    name: generalName,
    readingTypeFor,
    index,
    extra,
  } = formItem;

  const isExtraFieldError =
    Object.keys(formik.errors).some((k) => extra?.some((e) => e?.name === k)) ||
    Object.keys(formik.errors).some((k) =>
      extra?.some((e) => e.options?.some((eo) => eo?.value === k)),
    );
  const isOptionsError = Object.keys(formik.errors).some((k) =>
    options
      ?.filter((o) => o.required)
      .map((i) => i.value)
      .includes(k),
  );
  const isShowErrorMessage =
    (Object.keys(formik.errors).includes(generalName) && required) ||
    isOptionsError ||
    isExtraFieldError;

  const itemClassNames = classNames(s['form-item'], {
    [s.error]: !!Object.keys(formik.errors).length && isShowErrorMessage,
  });

  const { t } = useTranslation();

  const bodyClassNames = classNames(s.body, {
    [s['flex-body']]: formItem.flexBlock,
  });

  return (
    <>
      {modalType && readingTypeFor.includes(modalType) && (
        <div
          className={itemClassNames}
          ref={generalName === 'nursingIntervention' ? ref : null}
        >
          <div className={type === 'radio-list' ? '' : s.header}>
            <div className={s.title}>
              {index ? `#${index} ${title}` : title}
              {required && <span>*</span>}
            </div>
            {description && <div className={s.description}>{description}</div>}
          </div>
          <div className={bodyClassNames}>
            {type === 'checkbox' &&
              options &&
              options.map((option) => (
                <CheckboxItem
                  option={option}
                  formik={formik}
                  generalName={generalName}
                />
              ))}

            {type === 'datepicker' && options && (
              <BaseGrid columns={2} className={s['item-row']}>
                {options.map((option) => {
                  return <DateItem option={option} formik={formik} />;
                })}
              </BaseGrid>
            )}

            {type === 'radio' && options && (
              <Radio.Group
                options={options}
                name={generalName}
                value={formik.values[generalName]}
                className={s.radio}
                onChange={(e) =>
                  formik.setFieldValue(generalName, e.target.value)
                }
              />
            )}

            {type === 'text' && (
              <BaseTextArea
                rows={6}
                placeholder="Type here details"
                onChange={(value) => formik.setFieldValue(generalName, value)}
                value={formik.values[generalName]}
              />
            )}

            {type === 'radio-list' && options && (
              <div className={s['radio-wrapper']}>
                {options.map((option) => (
                  <RadioLineItem
                    option={option}
                    formik={formik}
                    generalName={generalName}
                    requiredSymptomFields={requiredSymptomFields}
                  />
                ))}
              </div>
            )}

            {children}
          </div>
          {isShowErrorMessage && (
            <span className={s['error-block']}>
              {isExtraFieldError ? '' : t('labels.requiredQuestion')}
            </span>
          )}
        </div>
      )}
    </>
  );
});

export default FormItem;
