import { ClinicStatus, ServiceDeliveryThreshold } from 'infrastructure/enums';
import {
  parseSearchBooleanValue,
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';

import { useQueryFilterNew } from '../common/use-query-param-new';

import type { TParseFunctions } from '../common/use-query-param-new';
import type { IClinicListFilters } from 'infrastructure/interfaces';

const defaultFilter: IClinicListFilters = {
  status: ClinicStatus.Active,
  serviceDeliveryThresholds: ServiceDeliveryThreshold.All,
  page: 1,
};

const parseFunctions: TParseFunctions<IClinicListFilters> = {
  page: parseSearchNumberValue,
  status: parseSearchStringValue,
  agencyGuid: parseSearchStringValue,
  guid: parseSearchStringValue,
  legalname: parseSearchStringValue,
  parentClinicGuid: parseSearchStringValue,
  serviceDeliveryThresholds: parseSearchNumberValue,
  noLimit: parseSearchBooleanValue,
};

export const useClinicQueryFilterNew = () => {
  return useQueryFilterNew<IClinicListFilters>(defaultFilter, parseFunctions, [
    'page',
  ]);
};
