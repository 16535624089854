import {
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';
import { useQueryFilterNew } from 'infrastructure/hooks/common/use-query-param-new';

import type { TParseFunctions } from 'infrastructure/hooks/common/use-query-param-new';

export type TAgencyUsersFilter = {
  page: number;
  doctorKey: string;
  roleName?: string;
  status?: string;
  guid?: string;
  doctorLabel?: string;
  agencyGuid?: string;
  agencyLabel?: string;
};

const defaultFilter: TAgencyUsersFilter = {
  page: 1,
  doctorKey: 'fullName',
};

const parseFunctions: TParseFunctions<TAgencyUsersFilter> = {
  page: parseSearchNumberValue,
  roleName: parseSearchStringValue,
  status: parseSearchStringValue,
  guid: parseSearchStringValue,
  agencyGuid: parseSearchStringValue,
  doctorLabel: parseSearchStringValue,
  agencyLabel: parseSearchStringValue,
  doctorKey: parseSearchStringValue,
};

export const useAgencyUsersFilter = () => {
  return useQueryFilterNew<TAgencyUsersFilter>(defaultFilter, parseFunctions, [
    'page',
    'doctorLabel',
    'doctorKey',
    'agencyLabel',
  ]);
};
