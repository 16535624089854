import type { IIconProps } from 'infrastructure/interfaces';

const CaretDownIcon = ({ ...props }: IIconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.34141 9.74742L2.14471 4.95119C1.64967 4.38543 2.05145 3.5 2.80322 3.5H11.1966C11.9484 3.5 12.3502 4.38543 11.8551 4.95119L7.65842 9.74742C7.30981 10.1458 6.69002 10.1458 6.34141 9.74742Z"
      fill="#667180"
    />
  </svg>
);

export default CaretDownIcon;
