import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getPatientInfoKey } from 'infrastructure/hooks/patient/use-patient-info';
import { showResult } from 'infrastructure/hooks/utils';

import { updateBingoCardStatusFn } from './api/updateBingoCardStatusFn';

import type { IPatient } from 'infrastructure/interfaces';

export const useUpdateBingoCardStatus = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: updateBingoCardStatusFn,
    onSuccess(data, variables) {
      const patientInfoKey = getPatientInfoKey(variables.patientGuid);
      queryClient.setQueryData<IPatient>(patientInfoKey, (oldData) => {
        if (oldData && data) {
          return {
            ...oldData,
            acceptBingoStatus: data.acceptBingoStatus,
          };
        }
      });
      showResult();
    },
  });

  return {
    updateBingoCardStatus: mutate,
    updateBingoCardStatusLoading: isLoading,
  };
};
