import type { ArgsProps } from 'antd/es/notification';

export const toastSettingsAntd: ArgsProps = {
  duration: 5,
  message: '',
  placement: 'topRight',
  showProgress: true,
  pauseOnHover: true,
};

export default {
  toastSettingsAntd,
  loaderColor: '#E99041',
  activeIconColor: '#FB9318',
  disabledIconColor: '#CCCAD7',
  activeColor: '#FC7013',
  modalCloseIconColor: '#b2beb5',
  toastSettings: {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  },
  primaryColors: {
    primary: '#c9c9c9',
    primary25: '#b6b6b6',
    primary50: '#c4c4c4',
    neutral50: '#cccad7',
  },
  commonColors: {
    green: '#84E075',
    red: '#EF686D',
    gray: '#CCCAD7',
    dargGray: '#2C2543',
    yellow: '#FFCF56',
    white: '#FFF',
    darkGray: '#667180',
    orange60: '#E28416',
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  activity: {
    statuses: {
      normal: 'Normal',
      risk: 'At Risk',
      critical: 'Critical',
      slaReading: 'Priority',
    },
  },
  chartWidth: 1290,
  chartHeight: 200,
  sleepMatChartHeight: 240,
  temperatureChartHeight: 300,
  chartColors: {
    systolic: {
      low: '#FFCF56',
      high: '#EF686D',
      main: '#FB9318',
    },
    diastolic: {
      low: '#84E075',
      high: '#84E075',
      main: '#84E075',
    },
    glucose: {
      low: '#EF686D',
      high: '#EF686D',
      main: '#488DFF',
    },
    pulse: {
      low: '#EF686D',
      high: '#EF686D',
      main: '#84E075',
    },
    bloodPressure: {
      lineColor: '#EF686D',
    },
    weight: {
      low: '#EF686D',
      high: '#EF686D',
      main: '#84E075',
    },
    spO2: {
      high: '#EF686D',
      main: '#FC7013',
    },
    temperature: {
      low: '#FFCF56',
      high: '#EF686D',
      main: '#84E075',
    },
    sleepMat: {
      pulse: '#EF686D',
      turnOver: '#998F89',
      rem: '#619AF8',
      lightSleep: '#9F93FF',
      deepSleep: '#77CB11',
      respiration: '#FFB600',
    },
    pef: {
      risk: '#FFCF56',
      critical: '#EF686D',
      main: '#84E075',
    },
    fev1: {
      critical: '#EF686D',
      main: '#84E075',
    },
  },
  temperatureCriticals: {
    min: 0,
    max: 99,
  },
  chartYaxisScales: {
    spO2: [0, 100],
    weight: [0, 600],
    glucose: [0, 400],
    pulse: [0, 200],
    bloodPressure: [0, 200],
    temperature: [94, 102],
    fev1: [0, 5.5],
    pef: [0, 800],
  },
  statusColors: {
    critical: '#EF686D',
    risk: '#FFB600',
    normal: '#84E075',
    slaReadings: '#488DFF',
  },
  dateFormats: {
    full: 'MMM dd yyyy hh:mm aa',
    time: 'hh:mm:ss',
    timeAPM: 'hh:mm:ss aa',
    hoursMinutesPM: 'hh:mm aa',
    commonTime: 'HH:mm:ss',
    dayOfWeek: 'eee MMM d, yyyy',
    dateAndTime: 'MMM d, yyyy h:mm aa',
    short: 'MMM d, yyyy',
    birthDate: 'MM-dd-yyyy',
    chartXTime: 'hh:mm aa',
    chartXTimeOnly: 'hh:mm',
    chartXTimeAMPM: 'aa',
    chartXDate: 'MMM d',
    interactiveTime: 'MMMM',
    priorAuthorization: 'yyyy/MM/dd 00:00',
    priorAuthorizationAdmin: 'MMM dd yyyy',
    dateDotSeparator: 'dd.MM.yyyy',
    dateUTC: "yyyy-MM-dd'T'00:00.000'Z'",
    dateDefaultHourMinute: 'HH:mm',
    timeAMPM: 'hh:mm a',
    dateSlashSeparator: 'dd/MM/yyyy',
    textYesterday: 'Yesterday',
    fullDateTimeAMPM: 'dd MMMM yyyy hh:mm a',
    fullDateUSTimeAMPM: 'MMMM dd, yyyy hh:mm a',
    dateReverseHyphenSeparator: 'yyyy-MM-dd',
    dateMonthName: 'dd MMMM yyyy',
    fullDateUS: 'MMMM dd, yyyy',
    DateUS: 'dd MMMM, yy',
  },
  statusNames: {
    patient_referral: {
      label: 'Patient Referral',
      color: '#9B51E0',
      description:
        'We have received the order and our human team has proofed and created the patient ID in our system Evelyn',
    },
    referral_on_hold: {
      label: 'Referral On-Hold',
      color: '#FDDA0D',
      description:
        'The patient has been referred, however the patient onboarding process has been temporarily put on-hold.',
    },
    referral_withdrawn: {
      label: 'Referral Withdrawn',
      color: '#947DBE',
    },
    patient_notified: {
      label: 'Patient Notified',
      color: '#E0C275',
      description:
        'We send out a HIPAA complaint SMS to expedite patient acceptance',
    },
    calling_queue: {
      label: 'Calling Queue',
      color: '#C77C6E',
      description:
        'Patient has been engaged via phone by our patient acceptance team',
    },
    patient_no_contact: {
      label: 'Patient No Contact',
      color: '#FB9318',
      description:
        'Unable to reach patient after 5 attempts over several days, we do revisit these patients.',
    },
    noContact_20days: {
      label: 'Patient No Contact - 20 days',
      color: '#FCB35D',
    },
    noContact_30days: {
      label: 'Patient No Contact - 30 days',
      color: '#FDCA8E',
    },
    noContact_closed: {
      label: 'Patient No Contact - Closed',
      color: '#FEDAB0',
    },
    refused: {
      label: 'Patient Refused',
      color: '#BD8EE9',
    },
    patient_refused_connectivity_or_tech_barier: {
      label: 'Patient Refused - Connectivity/Tech Barier',
      color: '#BD8EE9',
    },
    patient_refused_copay_insurance: {
      label: 'Patient Refused - Co-pay/Insurance',
      color: '#BD8EE9',
    },
    patient_refused_not_interested_not_necessary_suspect_scam: {
      label: 'Patient Refused - Not interested/Not necessary/Suspect Scam',
      color: '#BD8EE9',
    },
    patient_refused_expired: {
      label: 'Patient Refused - Expired',
      color: '#BD8EE9',
    },
    patient_refused_there_is_no_one_to_help: {
      label: 'Patient Refused - There is No one to Help',
      color: '#BD8EE9',
    },
    patient_refused_has_a_new_physician: {
      label: 'Patient Refused - Has a New Physician',
      color: '#BD8EE9',
    },
    patient_refused_wants_to_discuss_with_physician: {
      label: 'Patient Refused - Wants to Discuss with Physician',
      color: '#BD8EE9',
    },
    patient_refused_wrong_contact_information: {
      label: 'Patient Refused - Wrong Contact Information',
      color: '#BD8EE9',
    },
    patient_refused_no_contact: {
      label: 'Patient Refused - No Contact',
      color: '#BD8EE9',
    },
    insurance_refused: {
      label: 'Insurance Refused',
      color: '#c22121',
    },
    active: {
      label: 'Active',
      color: '#84E075',
      description:
        'Patient has taken a reading and we have received it. The patient is active going forward.',
    },
    onboarding: {
      label: 'Onboarding',
      color: '#CCCAD7',
      description:
        'Patient Accepted and the device is on route, waiting for the patient to take a reading with the device. Our clinical team will phone the patient to ensure they are trained on the device.',
    },
    onboarding_unsuccessful: {
      label: 'Onboarding Unsuccessful',
      color: '#EF686D',
      description:
        'Patient Accepted and Received Device(s) but not able to reach for First Reading',
    },
    total: {
      label: 'Total Patients',
      color: '',
    },
  },
  pcmStatusNames: {
    true: {
      label: 'True',
      color: '#2F8521',
    },
    false: {
      label: 'False',
      color: '#BD363B',
    },
  },
  doNotCallStatusNames: {
    true: {
      label: 'Do Not Call',
      color: '#BD363B',
    },
    false: {
      label: 'Available',
      color: '#2F8521',
    },
  },
  ccmStatusNames: {
    'CCM Referral Received': {
      label: 'CCM Referral Received',
      color: '#E5D0FF',
      description: 'The patient referral for CCM has been received.',
    },
    'CCM Referral On-Hold': {
      label: 'CCM Referral On-Hold',
      color: '#FDDA0D',
      description:
        'The patient has been referred for CCM, however the patient onboarding process has been temporarily put on-hold.',
    },
    'CCM Referral Withdrawn': {
      label: 'CCM Referral Withdrawn',
      color: '#947DBE',
      description:
        'The patient had been referred for CCM, however the clinic or referring provider has withdrawn the referral.',
    },
    'CCM Patient No Contact': {
      label: 'CCM Patient No Contact',
      color: '#800000',
      description:
        'The patient has been referred for CCM, however Accuhealth has not been able to contact the patient. Accuhealth is in the final stage of attempting to contact the patient before halting all future attempts.',
    },
    'CCM Calling Queue': {
      label: 'CCM Calling Queue',
      color: '#C77C6E',
      description:
        'The patient has been referred for CCM and is in the queue to be called for acceptance into the CCM program.',
    },
    'CCM Patient Refused': {
      label: 'CCM Patient Refused',
      color: '#BD8EE9',
      description:
        'The patient has been called for acceptance into the CCM program but refused.',
    },
    'CCM Onboarding': {
      label: 'CCM Onboarding',
      color: '#CCCAD7',
      description:
        'The patient has accepted CCM services and an appointment has been made for a CCM Admission Agent to meet with the patient to onboard them.',
    },
    'CCM Onboarding Unsuccessful': {
      label: 'CCM Onboarding Unsuccessful',
      color: '#EF686D',
      description:
        'The patient had accepted CCM services and an appointment was made for a CCM Admission Agent to meet and onboard them however when contacted was not enrolled in the program.',
    },
    'Total CCM Patients': {
      label: 'Total CCM Patients',
      color: '',
    },
    Active: {
      label: 'Active',
      color: '#84E075',
      description:
        'The patient is enrolled in the CCM program and is contacted monthly for follow-up appointments.',
    },
  },
  filterNames: {
    status: 'status',
    clinic: 'clinic',
    insuranceType: 'insurance',
  },
  submitSuccessTypes: {
    changed: 'changed',
    reviewed: 'reviewed',
    sendReport: 'sendReport',
    deleted: 'deleted',
  },
  patientHoldingStatus: {
    vacation: 'Vacation',
    hospital: 'In Hospital',
    active: 'Active',
  },
  ccmStatus: {
    enrolled: {
      label: 'Enrolled',
      color: '#2F8521',
    },
    not_enrolled: {
      label: 'Not Enrolled',
      color: '#2C2543',
    },
    ccm_calling_queue: {
      label: 'CCM Calling Queue',
      color: '#974DDC',
    },
    ccm_patient_refused_copay_insurance: {
      label: 'CCM Patient Refused - Co-pay/Insurance',
      color: '#330066',
    },
    ccm_patient_refused_expired: {
      label: 'CCM Patient Refused - Expired',
      color: '#330066',
    },
    ccm_patient_refused_changed_doctor: {
      label: 'CCM Patient Refused - Changed Doctor',
      color: '#330066',
    },
    ccm_patient_refused_not_interested_not_necessary_suspect_scam: {
      label: 'CCM Patient Refused - Not interested/Not necessary/Suspect Scam',
      color: '#330066',
    },
    ccm_patient_refused_wrong_contact_information: {
      label: 'CCM Patient Refused - Wrong Contact Information',
      color: '#330066',
    },
    ccm_patient_refused_no_contact: {
      label: 'CCM Patient Refused - No Contact',
      color: '#330066',
    },
    ccm_patient_refused_other: {
      label: 'CCM Patient Refused - Other',
      color: '#330066',
    },
    ccm_onboarding: {
      label: 'CCM Onboarding',
      color: '#E28416',
    },
    ccm_onboarding_unsuccessful_no_contact: {
      label: 'CCM Onboarding Unsuccessful - No Contact',
      color: '#8D6C00',
    },
    ccm_onboarding_unsuccessful_patient_refused: {
      label: 'CCM Onboarding Unsuccessful - Patient Refused',
      color: '#8D6C00',
    },
    ccm_onboarding_unsuccessful_patient_not_qualified_for_ccm: {
      label: 'CCM Onboarding Unsuccessful - Patient Not Qualified for CCM',
      color: '#8D6C00',
    },
    ccm_onboarding_unsuccessful_changed_doctor: {
      label: 'CCM Onboarding Unsuccessful - Changed Doctor',
      color: '#8D6C00',
    },
    ccm_onboarding_unsuccessful_other: {
      label: 'CCM Onboarding Unsuccessful - Other',
      color: '#8D6C00',
    },
    ccm_unenrolled_changed_doctor: {
      label: 'CCM Unenrolled - Changed Doctor',
      color: '#C44A4F',
    },
    ccm_unenrolled_client_request: {
      label: 'CCM Unenrolled - Client Request',
      color: '#C44A4F',
    },
    ccm_unenrolled_copay_loss_of_insurance: {
      label: 'CCM Unenrolled - Copay/Loss of Insurance',
      color: '#C44A4F',
    },
    ccm_unenrolled_non_adherent: {
      label: 'CCM Unenrolled - Non-Adherent',
      color: '#C44A4F',
    },
    ccm_unenrolled_expired: {
      label: 'CCM Unenrolled - Expired',
      color: '#C44A4F',
    },
    ccm_unenrolled_hospice: {
      label: 'CCM Unenrolled - Hospice',
      color: '#C44A4F',
    },
    ccm_unenrolled_long_term_skilled_nursing_facility_rehab: {
      label: 'CCM Unenrolled - Long Term Skilled Nursing Facility/Rehab',
      color: '#C44A4F',
    },
    ccm_unenrolled_physician_clinic_request: {
      label: 'CCM Unenrolled - Physician/Clinic Request',
      color: '#C44A4F',
    },
    ccm_unenrolled_other: {
      label: 'CCM Unenrolled - Other',
      color: '#C44A4F',
    },
    ccm_referral_on_hold: {
      label: 'CCM Referral On-Hold',
      color: '#0041C2',
    },
    ccm_referral_received: {
      label: 'CCM Referral Received',
      color: '#0041C2',
    },
    ccm_referral_withdrawn: {
      label: 'CCM Referral Withdrawn',
      color: '#0041C2',
    },
    ccm_patient_no_contact: {
      label: 'CCM Patient No Contact',
      color: '#667180',
    },
    ccm_unenrolled_client_request_moving: {
      label: 'CCM Unenrolled - Client Request Moving',
      color: '#BD363B',
    },
    ccm_unenrolled_health_plan_request_goals_met: {
      label: 'CCM Unenrolled - Health Plan Request Goals Met',
      color: '#BD363B',
    },
    ccm_patient_refused_wants_to_discuss_with_physician: {
      label: 'CCM Patient Refused - Wants to Discuss with Physician',
      color: '#330066',
    },
  },
  consentStatus: {
    confirmed: {
      label: 'Confirmed',
      color: '#2F8521',
    },
    not_confirmed: {
      label: 'Not Confirmed',
      color: '#667180',
    },
  },
  statusPatients: {
    patient_referral: {
      label: 'Patient Referral',
      color: '#0041C2',
    },
    referral_on_hold: {
      label: 'Referral On-Hold',
      color: '#0041C2',
    },
    referral_withdrawn: {
      label: 'Referral Withdrawn',
      color: '#0041C2',
    },
    calling_queue: {
      label: 'Calling Queue',
      color: '#974DDC',
    },
    inactive_patient: {
      label: 'Inactive',
      color: '#667180',
    },
    patient_no_contact: {
      label: 'Patient No Contact',
      color: '#667180',
    },
    patient_no_contact_day_20: {
      label: 'Patient No Contact - Day 20',
      color: '#667180',
    },
    patient_no_contact_day_30: {
      label: 'Patient No Contact - Day 30',
      color: '#667180',
    },
    patient_no_contact_closed: {
      label: 'Patient No Contact - Closed',
      color: '#667180',
    },
    patient_refused_connectivity_or_tech_barier: {
      label: 'Patient Refused - Connectivity/Tech Barier',
      color: '#330066',
    },
    patient_refused_copay_insurance: {
      label: 'Patient Refused - Co-pay/Insurance',
      color: '#330066',
    },
    patient_refused_not_interested_not_necessary_suspect_scam: {
      label: 'Patient Refused - Not interested/Not necessary/Suspect Scam',
      color: '#330066',
    },
    patient_refused_expired: {
      label: 'Patient Refused - Expired',
      color: '#330066',
    },
    patient_refused_there_is_no_one_to_help: {
      label: 'Patient Refused - There is No one to Help',
      color: '#330066',
    },
    patient_refused_has_a_new_physician: {
      label: 'Patient Refused - Has a New Physician',
      color: '#330066',
    },
    patient_refused_wants_to_discuss_with_physician: {
      label: 'Patient Refused - Wants to Discuss with Physician',
      color: '#330066',
    },
    patient_refused_wrong_contact_information: {
      label: 'Patient Refused - Wrong Contact Information',
      color: '#330066',
    },
    patient_refused_no_contact: {
      label: 'Patient Refused - No Contact',
      color: '#330066',
    },
    insurance_refused: {
      label: 'Insurance Refused',
      color: '#330066',
    },
    patient_notified: {
      label: 'Patient Notified',
      color: '#E28416',
    },
    onboarding: {
      label: 'Onboarding',
      color: '#E28416',
    },
    active: {
      label: 'Active',
      color: '#2F8521',
    },
    inactive_bad_no_signal_connectivity: {
      label: 'Inactive Bad/No Signal Connectivity',
      color: '#BD363B',
    },
    inactive_changed_doctor: {
      label: 'Inactive Changed Doctor',
      color: '#BD363B',
    },
    inactive_client_request_inconvenient: {
      label: 'Inactive Client Request Inconvenient',
      color: '#BD363B',
    },
    inactive_client_request_moving: {
      label: 'Inactive Client Request Moving',
      color: '#BD363B',
    },
    inactive_client_request_other: {
      label: 'Inactive Client Request Other',
      color: '#BD363B',
    },
    inactive_expired: {
      label: 'Inactive Expired',
      color: '#BD363B',
    },
    inactive_health_plan_request_goals_met: {
      label: 'Inactive Health Plan Request Goals Met',
      color: '#BD363B',
    },
    inactive_health_plan_request_non_compliant: {
      label: 'Inactive Health Plan Request Non Compliant',
      color: '#BD363B',
    },
    inactive_health_plan_request_other: {
      label: 'Inactive Health Plan Request Other',
      color: '#BD363B',
    },
    inactive_hospice: {
      label: 'Inactive Hospice',
      color: '#BD363B',
    },
    inactive_copay: {
      label: 'Inactive Copay',
      color: '#BD363B',
    },
    inactive_long_term_care_rehab: {
      label: 'Inactive Long Term Care/Rehab',
      color: '#BD363B',
    },
    inactive_loss_of_eligibility: {
      label: 'Inactive Loss of Eligibility (Insurance)',
      color: '#BD363B',
    },
    inactive_physician_request: {
      label: 'Inactive Physician Request',
      color: '#BD363B',
    },
    onboarding_unsuccessful: {
      label: 'Onboarding Unsuccessful',
      color: '#8D6C00',
    },
    pending_review: {
      label: 'Pending Review',
      color: '#04819A',
    },
  },
  covidStatuses: {
    positive: {
      status: 'covid_at_risk',
      label: 'Covid/At-riskCovid',
    },
    negative: {
      status: 'none',
      label: 'None',
    },
  },
  disabledEmailDomain: '@accuhealthid.com',
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
} as const;

export const appointmentMethodMap = {
  phone_or_video_conference: 'Phone/Video Conference',
  sms: 'SMS',
  email: 'Email',
  sms_or_email: 'SMS/Email',
} as const;

export const colors = {
  dullOrange: '#FDCA99',
  dullGreen: '#D0E9CB',
  dullGray: '#F2F2FB',
} as const;
