/* eslint-disable no-shadow */
export enum MessageStatusEnums {
  accepted = 'accepted',
  scheduled = 'scheduled',
  queued = 'queued',
  sending = 'sending',
  sent = 'sent',
  delivery_unknown = 'delivery unknown',
  delivered = 'delivered',
  undelivered = 'undelivered',
  failed = 'failed',
  receiving = 'receiving',
  received = 'received',
}

export enum MessageStatusCodeEnums {
  accepted = 0,
  scheduled = 1,
  queued = 2,
  sending = 3,
  sent = 4,
  delivery_unknown = 5,
  delivered = 6,
  undelivered = 7,
  failed = 8,
  receiving = 9,
  received = 10,
}

export enum MessageStatusReadEnums {
  read = 'read',
  unread = 'unread',
}

export enum ConversationActionEnums {
  sms = 'sms',
  closed = 'closed',
  opened = 'opened',
  spam = 'spam',
  not_spam = 'not_spam',
}
