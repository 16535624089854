import { useTranslation } from 'react-i18next';
import { clinicServiceDeliveryModel } from 'constants/clinicProviderServiceTypeOptions';

import BaseSelect from './base';

import type { Option } from './base';
import type { FC } from 'react';

interface Props {
  value?: Option[];
  showSelectAll?: boolean;
  width?: string;
  onChange: (e: Option[]) => void;
}

const ServiceDeliveryModelSelect: FC<Props> = ({
  onChange,
  value,
  width = '100%',
  showSelectAll = false,
}) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      width={width}
      label={t('labels.serviceDeliveryModel')}
      placeholder={t('labels.all')}
      mode="multiple"
      showSelectAll={showSelectAll}
      options={clinicServiceDeliveryModel}
      value={value}
      onChange={(newValues) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        onChange(items);
      }}
    />
  );
};

export default ServiceDeliveryModelSelect;
