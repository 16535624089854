import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChatHeader from 'components/molecules/smsDashboard/chatHeader';
import MessagesInput from 'components/molecules/smsDashboard/messagesInput';
import MessagesList from 'components/molecules/smsDashboard/messagesList';
import ChatEmptyIcon from 'components/atoms/icons/chatEmptyIcon';
import { useSmsDashboardMessages } from 'infrastructure/hooks/smsDashboard/useMessages';
import { debounce } from 'lodash';

import s from './styles.module.scss';

import type { RootState } from 'store';

const Chat = () => {
  const { t } = useTranslation();

  const { currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const isCurrentConversationExist = currentConversation.id !== 0;
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  const [debouncedSearchDateValue, setDebouncedSearchDateValue] =
    useState<string>();
  const [enabled, setEnabled] = useState(true);
  const [searchDateValue, setSearchDateValue] = useState<string>('');
  const [isSearchShown, setIsSearchShown] = useState<boolean>(false);

  const updateDebouncedValue = useCallback(
    debounce((value) => setDebouncedSearchValue(value), 300),
    [],
  );
  const updateDebouncedDateValue = useCallback(
    debounce((value) => setDebouncedSearchDateValue(value), 300),
    [],
  );
  useEffect(() => {
    updateDebouncedValue(searchValue);
    return () => updateDebouncedValue.cancel();
  }, [searchValue, updateDebouncedValue]);
  useEffect(() => {
    updateDebouncedDateValue(searchDateValue);
    return () => updateDebouncedDateValue.cancel();
  }, [searchDateValue, updateDebouncedDateValue]);
  const {
    fetchMessages,
    messages,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    messagesLoading,
  } = useSmsDashboardMessages({
    conversationId: currentConversation.id,
    searchQuery: debouncedSearchValue,
    enabled: isCurrentConversationExist && enabled,
    searchQueryDate: debouncedSearchDateValue,
  });

  return (
    <>
      {!isCurrentConversationExist ? (
        <div className={s['empty-conversation']}>
          <div className={s['message-wrapper']}>
            <div className={s['empty-message']}>
              <ChatEmptyIcon />
              <div className={s.message}>
                {t('smsDashboard.select_a_chat_to_start_a_conversation')}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={s['chat-wrapper']}>
          <ChatHeader
            conversation={currentConversation}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            fetchMessages={fetchMessages}
            setSearchDateValue={setSearchDateValue}
            isSearchShown={isSearchShown}
            setIsSearchShown={setIsSearchShown}
          />
          <MessagesList
            currentConversation={currentConversation}
            setEnabled={setEnabled}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            fetchNextPage={fetchNextPage}
            fetchPreviousPage={fetchPreviousPage}
            messages={messages}
            searchValue={searchValue}
            searchDateValue={searchDateValue}
            setSearchValue={setSearchValue}
            setSearchDateValue={setSearchDateValue}
            setIsSearchShown={setIsSearchShown}
            messagesLoading={messagesLoading}
          />
          <MessagesInput conversation={currentConversation} />
        </div>
      )}
    </>
  );
};

export default Chat;
