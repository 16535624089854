import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import { LoginStatus } from 'infrastructure/enums/login-status';
import { DoctorSelect } from 'infrastructure/classes/select/doctor-select';

import useApi from '../use-api';

import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IDoctorSelectPaginatedData,
} from 'infrastructure/interfaces';
import type { Roles } from 'infrastructure/enums';

const getDoctorsApi = 'core/doctors/select-options';

export interface IUseDoctorMeta {
  search?: string;
  guid?: string | string[];
  clinicGuid?: string | string[];
  agencyGuid?: string | string[];
  roles?: Array<Roles>;
  status?: Array<LoginStatus>;
}

type MergedMeta = IUseDoctorMeta & IPaginationMeta;

type UseDoctor = {
  loading: boolean;
  meta: MergedMeta;
  loadDoctors: (meta: MergedMeta) => Promise<DoctorSelect[]>;
};

interface IUseDoctorSelectProps {
  meta?: IUseDoctorMeta & IPartialPaginationMeta;
}

export const useDoctorSelect = (props?: IUseDoctorSelectProps): UseDoctor => {
  const { meta: metaProps = {} } = props || {};
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadDoctors = async (filters: MergedMeta) => {
    const queryKey = [
      getDoctorsApi,
      {
        page: filters.page,
        roles: filters.roles,
        search: filters.search,
        status: filters.status,
        guid: filters.guid,
        clinicGuid: filters.clinicGuid,
        agencyGuid: filters.agencyGuid,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () => {
        const hasGuid = Array.isArray(filters.guid)
          ? !!filters.guid.length
          : !!filters.guid;
        return loadData<
          IDoctorSelectPaginatedData,
          Omit<IUseDoctorMeta, 'sortField'>
        >(getDoctorsApi, {
          sortField: JSON.stringify(filters.sortField),
          page: filters.page,
          items: filters.items,
          guid: filters.guid,
          search: filters.search,
          clinicGuid: hasGuid ? undefined : filters.clinicGuid,
          agencyGuid: filters.agencyGuid,
          roles: filters.roles,
          status: hasGuid
            ? [LoginStatus.Active, LoginStatus.Blocked, LoginStatus.Inactive]
            : filters.status,
        });
      },
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
        totalCount: data.count,
      };

      setMeta(newMeta);

      return arrUniqEl(data.items, 'guid').map((el) => new DoctorSelect(el));
    }

    return [];
  };

  return {
    loading,
    meta,
    loadDoctors,
  };
};
