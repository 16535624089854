import { convertDateToTimeZone, DateFormatter } from 'infrastructure/functions';
import {
  hypertensionSymptoms,
  hypotensionSymptoms,
  template,
} from 'constants/criticalBloodPressureData';
import { DateFormats, Timezones } from 'infrastructure/enums';
import { useState } from 'react';
import mustache from 'mustache';
import {
  addSpaceAfterMarks,
  formatTemplateValues,
} from 'utils/templateHelpers';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { stringToBoolean } from 'utils/helpers/string-to-boolean';

import {
  extractTextInParentheses,
  formatText,
  isEmergentSymptoms,
  isOnlyConfirmedSymptoms,
  isOnlyDeniedSymptoms,
} from '../helpers';

import type { IUseBloodPressureProps } from '../types';
import type { IBloodPressureValues } from 'components/molecules/rpm-alert-assessment/types';

export const useBloodPressureAssessment = ({
  type,
  activityId,
  activityDate,
  values,
  patientGuid,
  sharedValues,
  addComment,
  closeModal,
  handleSubmit,
}: IUseBloodPressureProps) => {
  const [openEmergencyModal, setOpenEmergencyModal] = useState<boolean>(false);

  const initialValues: IBloodPressureValues = {
    // Q1
    patient: sharedValues?.patient,
    other: sharedValues?.other || null,
    relationship: sharedValues?.relationship || null,
    // Q2
    AOC: sharedValues?.AOC || null,
    // Q3
    last: sharedValues?.last || null,
    next: sharedValues?.next || null,
    // Q4
    isOneMoreReading: sharedValues?.isOneMoreReading ?? null,
    repeatReadingWas: null,
    bounds: null,

    bpMedications: sharedValues?.bpMedications || null,
    isTakePrnMedications: sharedValues?.isTakePrnMedications || null,
    isTakeMedications: sharedValues?.isTakeMedications || null,
    details: sharedValues?.details || null,
    symptoms: null,
    symptomsOnset: null,
    emergencyCare: null,
    verbalized: sharedValues?.verbalized || null,
    nursingIntervention: [],
    notEscalated: [],
    interventionDetails: null,
    confirmedFactors: [],
    deniedFactors: [],
    confirmedSymptoms: [],
    deniedSymptoms: [],
    checkSymptoms: true,
  };

  const { patientInfo } = usePatientInfo({
    patientGuid,
  });

  const symptomsList =
    type === 'min' ? hypotensionSymptoms : hypertensionSymptoms;

  const emergentSymptoms = symptomsList
    .filter((item) => item.required)
    .map((item) => item.label);

  const preparedData = (data: IBloodPressureValues) => {
    const formData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
    const reading = `${values.systolic}/${values.diastolic} mmHg`;
    const timeZone = patientInfo?.loginInfo?.timezone || 'America/Chicago';

    const dataInPatientTimeZone = convertDateToTimeZone(
      new Date(Number(activityDate)),
      Timezones[timeZone],
    );
    const dateOfReading = DateFormatter({
      date: dataInPatientTimeZone,
      format: DateFormats['MM-dd-yyyy'],
    });

    const lastDate = formData.last
      ? DateFormatter({
          date: new Date(formData.last),
          format: DateFormats['MM-dd-yyyy'],
        })
      : null;

    const nextDate = formData.next
      ? DateFormatter({
          date: new Date(formData.next),
          format: DateFormats['MM-dd-yyyy'],
        })
      : null;

    const isSpokeWithPatient = !formData.other && !formData.relationship;

    return {
      hypoPreviewText: true,
      reading,
      dateOfReading,
      patient: formData.patient,
      speakWithPatient: isSpokeWithPatient,
      speakWithOther: formData.other,
      relationship: formData.relationship,
      AOC: formData.AOC?.length ? formData.AOC : null,
      lastAndNext: lastDate && nextDate,
      lastOnly: lastDate && !nextDate,
      nextOnly: !lastDate && nextDate,
      last: lastDate,
      next: nextDate,
      bpMedications: formData.bpMedications,
      prnMedications:
        formData.isTakePrnMedications !== undefined
          ? formData.isTakePrnMedications
          : null,
      prnMedicationsText: stringToBoolean(formData.isTakePrnMedications)
        ? 'confirms'
        : 'denies',
      routineMedications:
        formData.isTakeMedications !== undefined
          ? formData.isTakeMedications
          : null,
      routineMedicationsText: stringToBoolean(formData.isTakeMedications)
        ? 'confirms'
        : 'denies',
      details: formData.details,
      confirmedFactors: formData.confirmedFactors.length
        ? formData.confirmedFactors
        : null,
      deniedFactors: formData.deniedFactors.length
        ? formData.deniedFactors
        : null,
      oneMoreReading:
        formData.isOneMoreReading !== null ? formData.isOneMoreReading : null,
      oneMoreReadingText: stringToBoolean(formData.isOneMoreReading)
        ? 'is'
        : 'is not',
      bounds: formData.bounds !== undefined ? String(formData.bounds) : null,
      boundsText: formData.bounds ? 'is' : 'is not',
      repeatReadingWas: formData.repeatReadingWas,
      space: true,
      symptomsDenied: formData.deniedSymptoms.length === symptomsList.length,
      symptomsEmergent: isEmergentSymptoms(data, emergentSymptoms),
      symptomsRegularConfirmed: isOnlyConfirmedSymptoms(
        data,
        symptomsList.length,
        emergentSymptoms,
      ),
      symptomsRegularDenied: isOnlyDeniedSymptoms(
        data,
        symptomsList.length,
        emergentSymptoms,
      ),
      confirmedSymptoms: formData.confirmedSymptoms.length
        ? formData.confirmedSymptoms
        : null,
      deniedSymptoms: formData.deniedSymptoms.length
        ? formData.deniedSymptoms
        : null,
      symptomsOnset: formData.symptomsOnset,
      verbalized:
        formData.verbalized !== undefined ? String(formData.verbalized) : null,
      verbalizedText: formData.verbalized ? 'is' : 'is not',
      emergencyRoom: formData.nursingIntervention?.includes(
        'Emergency room/urgent care/911 assist',
      ),
      escalation: formData.nursingIntervention?.includes(
        'Escalation to clinic',
      ),
      nursingEducation:
        formData.nursingIntervention?.includes('Nursing education'),
      notEscalated: formData.notEscalated.length
        ? extractTextInParentheses(formData.notEscalated)
        : null,
      interventionDetails: formData.interventionDetails,
    };
  };

  const toggleEmergencyModal = () => setOpenEmergencyModal((prev) => !prev);

  const onSaveForm = (formData: IBloodPressureValues) => {
    const preparedValues = preparedData(formData);

    const filteredValues = Object.fromEntries(
      Object.entries(preparedValues).filter(([_, value]) => value !== null),
    );

    const commentTemplate = template
      .map((item) => {
        return filteredValues[item.key] ? item.value : '';
      })
      .join('');

    const resultText = mustache.render(
      commentTemplate,
      formatTemplateValues(filteredValues),
    );

    const formattedResultText = formatText(resultText, formData);

    handleSubmit(addSpaceAfterMarks(formattedResultText));
    addComment(activityId);
    closeModal();
  };

  return {
    initialValues,
    openEmergencyModal,
    symptomsList,
    onSaveForm,
    toggleEmergencyModal,
  };
};
