/* eslint-disable */
import 'amazon-connect-streams';
import {
  API_PATIENTS_COMMENTS,
  API_READINGS_ACTIONS,
  API_CCM_PATIENT_CALL,
} from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from '../dataService';
import { SocketRoutes } from 'services/wsService';

const AWS_CONNECT_URL_CCP = process.env.REACT_APP_CONNECT_URL_CCP;
const AWS_CONNECT_URL = process.env.REACT_APP_CONNECT_URL;

const contactToPatientMap = {};

const init = (
  phoneNumber,
  patientGuid,
  setCallId,
  isRpmCall,
  deviceId,
  readingId,
  wsClient,
  awsRumAgent,
  isReadOnlyRole,
) => {
  const containerDiv = document.getElementById('connect-el');

  connect.core.initCCP(containerDiv, {
    ccpUrl: AWS_CONNECT_URL_CCP,
    loginUrl: AWS_CONNECT_URL,
    loginPopup: true,
    loginPopupAutoClose: true,
    region: 'us-east-1',
    pageOptions: {
      enableAudioDeviceSettings: false,
      enablePhoneTypeSettings: false,
    },
    softphone: {
      allowFramedSoftphone: true,
    },
  });

  if (phoneNumber) {
    connect.agent((agent) => {
      const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
      agent.connect(endpoint);
    });

    connect.contact((contact) => {
      contact.onConnecting(async (event) => {
        if (!patientGuid) return;

        const { contactId } = event;
        setCallId(contactId);
        awsRumAgent.recordEvent('callConnecting', {
          patientGuid,
          phoneNumber,
          contactId,
        });
        let callData = {};
        callData = { patientGuid };

        contactToPatientMap[contactId] = patientGuid;
        wsClient.send(SocketRoutes.OnCallStarted, { patientGuid, isRpmCall });

        if (isRpmCall) {
          callData = {
            ...callData,
            deviceId,
            readingId,
            type: 'call',
            message: contactId,
            commentType: 'rpm',
          };
        } else {
          callData = {
            ...callData,
            callId: contactId,
          };
        }

        if (!isReadOnlyRole) {
          const { error } = await dataService.createOne(
            !isRpmCall
              ? API_CCM_PATIENT_CALL
              : deviceId && readingId
              ? API_READINGS_ACTIONS
              : API_PATIENTS_COMMENTS,
            callData,
          );
  
          if (error) showResult(error);
        }

        patientGuid = null;
      });

      contact.onDestroy(async (event) => {
        const { contactId } = event;
        const currentPatientGuid = contactToPatientMap[contactId];
        if (currentPatientGuid) {
          awsRumAgent.recordEvent('callDestroyed', {
            patientGuid: currentPatientGuid,
            contactId,
          });
          wsClient.send(SocketRoutes.OnCallEnded, {
            patientGuid: currentPatientGuid,
            isRpmCall,
          });
        }
      });

      contact.onEnded(async (event) => {
        const { contactId } = event;
        const currentPatientGuid = contactToPatientMap[contactId];
        if (currentPatientGuid) {
          awsRumAgent.recordEvent('callDestroyed', {
            patientGuid: currentPatientGuid,
            contactId,
          });
          wsClient.send(SocketRoutes.OnCallEnded, {
            patientGuid: currentPatientGuid,
            isRpmCall,
          });
        }
      });
    });
  }
};

const terminate = () => {
  connect.core.terminate();
  const containerDiv = document.getElementById('connect-el');
  if (containerDiv) {
    const iframe = containerDiv.firstElementChild;
    containerDiv.removeChild(iframe);
  }
};

export { init, terminate };
