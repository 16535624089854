export const Routes = {
  adminAgencyUsers: '/admin/agents',
  adminAgencies: '/admin/agencies',
  adminClinics: '/admin/clinics',
  adminUsers: '/admin/users',
  patientDetail: (patientGuid: string) => `/patient/${patientGuid}`,
  ccmPatientDetail: (patientGuid: string) => `/ccm/patient/${patientGuid}`,
  adminClinicsDetail: (clinicGuid: string) => `/admin/clinic/${clinicGuid}`,
  adminClinicsEdit: (clinicGuid: string) => `/admin/clinic/${clinicGuid}/edit`,
  adminUsersDetail: (guid: string) => `/admin/user/${guid}`,
  adminUsersEdit: (guid: string) => `/admin/user/${guid}/edit`,
  adminAgencyUserEdit: (agentGuid: string) => `/admin/agent/${agentGuid}/edit`,
  adminClinicDetail: (clinicGuid: string) => `/admin/clinic/${clinicGuid}`,
  adminAgencyDetail: (agencyGuid: string) => `/admin/agency/${agencyGuid}`,
  adminAgencyEdit: (agencyGuid: string) => `/admin/agency/${agencyGuid}/edit`,
  adminPatientDetail: (patientGuid: string) => `/admin/patient/${patientGuid}`,
  consentForm: (patientGuid: string) => `/ccm/consent/${patientGuid}`,
  eligibilityToolsItem: (processGuid: string) =>
    `/eligibility-tool/${processGuid}`,
};

export const moduleRoutes = {
  ccm: '/ccm',
} as const;
