import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import MonthPicker from 'components/molecules/datepicker/monthPicker';

import s from './styles.module.scss';

import type { Filters } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface IProps {
  filters: Filters;
  appendFilters: (filter: Partial<Filters>) => void;
}

const TimeRangeFilter: FC<IProps> = ({ appendFilters, filters }) => {
  const { t } = useTranslation();

  return (
    <div className={s['available-billable-minutes-wrapper']}>
      <label>{t('labels.timeRange')}</label>
      <MonthPicker
        value={{ year: filters.year, month: filters.month }}
        onChange={(value) => {
          if (!value) {
            appendFilters({
              year: new Date().getFullYear(),
              month: new Date().getMonth() + 1,
            });
          } else {
            appendFilters({ year: value.year, month: value.month });
          }
        }}
      />
    </div>
  );
};

export default TimeRangeFilter;
