import React from 'react';
import { useTranslation } from 'react-i18next';
import TextAreaAutoHeight from 'components/atoms/textarea/autoHeight';
import ChatSendIcon from 'components/atoms/icons/chatSendIcon';
import BaseButton from 'components/atoms/baseButton';

import s from './styles.module.scss';

import type { ChangeEvent } from 'react';

interface IChatInputProps {
  value: string;
  setValue: (data: string) => void;
  onSubmit: () => void;
  loading: boolean;
  id: string;
}

const ChatInput: React.FC<IChatInputProps> = (props) => {
  const { value, setValue, onSubmit, loading, id } = props;

  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <TextAreaAutoHeight
        className={s.input}
        id={id}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        onSubmit={onSubmit}
        value={value}
        loading={loading}
        placeholder={`${t('smsDashboard.type_message')}...`}
        hideHint
        height="37px"
      />
      <div className={s['send-button']}>
        <BaseButton
          type="secondary"
          disabled={value === ''}
          loading={loading}
          onClick={onSubmit}
          icon={<ChatSendIcon />}
        />
      </div>
    </div>
  );
};

export default ChatInput;
