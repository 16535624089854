import { useTranslation } from 'react-i18next';
import useUser from 'utils/useUser';
import { isAgencyDoctor } from 'utils/userTypeHelper';
import { type FC } from 'react';

import BaseSelect from '../select/base';

import type { Option } from './base';

interface Props {
  value?: Option[];
  onChange: (value: Option[]) => void;
  label?: string;
  width?: string;
}

const CcmAgentDashboardAgencyDoctors: FC<Props> = ({
  onChange,
  value,
  label,
  width,
}) => {
  const { t } = useTranslation();

  const { guid: userGuid, userType } = useUser();

  const defaultOptions = [
    {
      id: 'all',
      value: 'all',
      label: t('labels.all'),
    },
    {
      id: 'empty',
      value: 'empty',
      label: t('labels.empty'),
    },
  ];

  if (isAgencyDoctor(userType)) {
    defaultOptions.splice(1, 0, {
      id: userGuid,
      value: userGuid,
      label: t('labels.me'),
    });
  }

  return (
    <BaseSelect
      width={width}
      label={label || t('labels.agencyDoctor')}
      placeholder={t('labels.all')}
      mode="multiple"
      value={value}
      onChange={(newValues) => {
        let items = Array.isArray(newValues) ? newValues : [newValues];
        const isAllSelected = items.some((item) => item.value === 'all');
        if (isAllSelected) {
          onChange([]);
          return;
        }
        if (items.at(0)?.value === 'empty' && items.length > 1) {
          items = items.filter((item) => item.value !== 'empty');
        }
        const emptyValue = items.find((item) => item.value === 'empty');
        if (emptyValue) {
          onChange([emptyValue]);
          return;
        }
        onChange(items);
      }}
      options={defaultOptions}
    />
  );
};

export default CcmAgentDashboardAgencyDoctors;
