import { useWebsocketRoom } from 'infrastructure/hooks/websockets/use-room';
import RpmActivity from 'components/organisms/rpm-dashboard-activity';
import Filter from 'components/organisms/rpm-dashboard-filter';
import RiskConditionFilter from 'components/organisms/rpm-dashboard-risk-condition-filter';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import CustomLocationPrompt from 'components/molecules/custom-location-prompt';
import { ASSESSMENT_PREFIX } from 'infrastructure/consts/assessment-modal';
import useUser from 'utils/useUser';
import { Navigate } from 'react-router-dom';
import { Routes } from 'router/routes';
import { ModuleType } from 'infrastructure/enums';
import {
  PLATFORM_MODE_ADMIN_PANEL_ACCESS,
  PLATFORM_MODE_PATIENT_ACCEPTANCE,
} from 'utils/accessManager/constants';
import BaseGrid from 'components/atoms/base-grid';

import s from './styles.module.scss';

import type { FC } from 'react';

const Page: FC = () => {
  const actions = useAppSelector((state) => state.activityActions.actions);
  const {
    activityActions: { resetActions },
  } = useAppDispatch();
  const { t } = useTranslation();

  const hasUnsavedMessage = Object.values(actions).some((item) =>
    Boolean(item?.message?.length),
  );

  useWebsocketRoom('rpmDashboard');

  const promptCallback = () => {
    resetActions();
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(`${ASSESSMENT_PREFIX}-`)) {
        localStorage.removeItem(key);
      }
    });
  };

  return (
    <>
      <CustomLocationPrompt
        when={hasUnsavedMessage}
        callback={promptCallback}
      />
      <BaseGrid className={s.wrapper} rowGap={40}>
        <h1 className={s.title}>{t('labels.realTimePatientReadings')}</h1>
        <RiskConditionFilter />
        <Filter />
        <RpmActivity />
      </BaseGrid>
    </>
  );
};

const OverviewDashboardPage = () => {
  const { isTurkUserRole, guid, platformMode, isPatient } = useUser();

  if (isPatient) return <Navigate to={Routes.patientGuid(guid)} />;
  if (isTurkUserRole) return <Navigate to={Routes.turkDashboard} />;
  if (platformMode === ModuleType.CCM) return <Navigate to={Routes.ccm} />;
  if (
    platformMode === PLATFORM_MODE_PATIENT_ACCEPTANCE ||
    platformMode === PLATFORM_MODE_ADMIN_PANEL_ACCESS
  )
    return <Navigate to={Routes.adminPatients} />;

  return <Page />;
};

export default OverviewDashboardPage;
