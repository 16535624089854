import { createModel } from '@rematch/core';
import { AwsRum } from 'aws-rum-web';

import type { AwsRumConfig } from 'aws-rum-web';
import type { RootModel } from 'store/models';

type AwsRumState = {
  agent: AwsRum | null;
};

const cloudWatchRumUrl = process.env.REACT_APP_CLOUD_WATCH_RUM_URL;
const cloudWatchApplicationId = process.env.REACT_APP_CLOUD_WATCH_APP_ID;
const cloudWatchPoolId = process.env.REACT_APP_CLOUD_WATCH_POOL_ID;
const rogerEnv = process.env.REACT_APP_ENV || 'local';
const rogerVersion = process.env.REACT_APP_VERSION || '1.0.0';

const awsRum = createModel<RootModel>()({
  state: {
    agent: null,
  } as AwsRumState,
  reducers: {
    setAgent: (state, agent: AwsRum) => ({
      ...state,
      agent,
    }),
  },
  effects: (dispatch) => ({
    init() {
      if (
        cloudWatchRumUrl &&
        cloudWatchApplicationId &&
        cloudWatchPoolId &&
        rogerEnv !== 'local'
      ) {
        try {
          const config: AwsRumConfig = {
            sessionSampleRate: 1,
            identityPoolId: cloudWatchPoolId,
            endpoint: cloudWatchRumUrl,
            telemetries: [
              ['errors', { stackTraceLength: 500 }],
              'performance',
              [
                'http',
                {
                  stackTraceLength: 500,
                  recordAllRequests: true,
                  addXRayTraceIdHeader: true,
                },
              ],
            ],
            allowCookies: true,
            enableXRay: true,
            enableRumClient: true,
          };

          const APPLICATION_REGION = 'us-east-1';
          const awsRumAgent = new AwsRum(
            cloudWatchApplicationId,
            rogerVersion,
            APPLICATION_REGION,
            config,
          );
          dispatch.awsRum.setAgent(awsRumAgent);
        } catch (error) {
          // Ignore errors thrown during CloudWatch RUM web client initialization
        }
      }
    },
  }),
});

export default awsRum;
