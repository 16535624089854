import {
  hypertensionSymptoms,
  hypotensionSymptoms,
  template,
} from 'constants/criticalBloodGlucoseData';
import { useState } from 'react';
import mustache from 'mustache';
import {
  addSpaceAfterMarks,
  formatTemplateValues,
} from 'utils/templateHelpers';
import { stringToBoolean } from 'utils/helpers/string-to-boolean';

import {
  extractTextInParentheses,
  isEmergentSymptoms,
  isOnlyConfirmedSymptoms,
  isOnlyDeniedSymptoms,
} from '../helpers';

import type { IUseBloodGlucoseProps } from '../types';
import type { IBloodGlucoseValues } from 'components/molecules/rpm-alert-assessment/types';

export const useBloodGlucoseAssessment = ({
  type,
  activityId,
  addComment,
  closeModal,
  handleSubmit,
}: IUseBloodGlucoseProps) => {
  const [openEmergencyModal, setOpenEmergencyModal] = useState<boolean>(false);
  const initialValues: IBloodGlucoseValues = {
    prescribed: [],
    bloodSugar: null,
    confirmedFactors: [],
    symptoms: null,
    symptomsOnset: null,
    emergencyCare: null,
    verbalized: null,
    nursingIntervention: [],
    notEscalated: [],
    interventionDetails: null,
    deniedFactors: [],
    confirmedSymptoms: [],
    deniedSymptoms: [],
    checkSymptoms: true,
    // Q1
    patient: undefined,
    other: null,
    relationship: null,
    // Q2
    AOC: null,
    // Q3
    last: null,
    next: null,
    // Q4
    isOneMoreReading: null,
    repeatReadingWas: null,
    bounds: null,
  };

  const symptomsList =
    type === 'min' ? hypotensionSymptoms : hypertensionSymptoms;

  const emergentSymptoms = symptomsList
    .filter((item) => item.required)
    .map((item) => item.label);

  const preparedData = (data: IBloodGlucoseValues) => {
    const formData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );

    return {
      reading: type === 'min' ? 'low' : 'high',
      prescribed: formData.prescribed.length ? formData.prescribed : null,
      bloodSugar: formData.bloodSugar,
      confirmedFactors: formData.confirmedFactors.length
        ? formData.confirmedFactors
        : null,
      deniedFactors: formData.deniedFactors.length
        ? formData.deniedFactors
        : null,
      oneMoreReading:
        formData.oneMoreReading !== undefined
          ? stringToBoolean(formData.isOneMoreReading)
          : null,
      oneMoreReadingText: stringToBoolean(formData.isOneMoreReading)
        ? 'is'
        : 'is not',
      bounds: formData.bounds !== undefined ? String(formData.bounds) : null,
      boundsText: formData.bounds ? 'is' : 'is not',
      symptomsDenied: formData.deniedSymptoms.length === symptomsList.length,
      symptomsEmergent: isEmergentSymptoms(data, emergentSymptoms),
      symptomsRegularConfirmed: isOnlyConfirmedSymptoms(
        data,
        symptomsList.length,
        emergentSymptoms,
      ),
      symptomsRegularDenied: isOnlyDeniedSymptoms(
        data,
        symptomsList.length,
        emergentSymptoms,
      ),
      confirmedSymptoms: formData.confirmedSymptoms.length
        ? formData.confirmedSymptoms
        : null,
      deniedSymptoms: formData.deniedSymptoms.length
        ? formData.deniedSymptoms
        : null,
      symptomsOnset: formData.symptomsOnset,
      verbalized:
        formData.verbalized !== undefined ? String(formData.verbalized) : null,
      verbalizedText: formData.verbalized ? 'is' : 'is not',
      emergencyRoom: formData.nursingIntervention?.includes(
        'Emergency room/urgent care/911 assist',
      ),
      escalation: formData.nursingIntervention?.includes(
        'Escalation to clinic',
      ),
      nursingEducation:
        formData.nursingIntervention?.includes('Nursing education'),
      notEscalated: formData.notEscalated.length
        ? extractTextInParentheses(formData.notEscalated)
        : null,
      interventionDetails: formData.interventionDetails,
    };
  };

  const toggleEmergencyModal = () => setOpenEmergencyModal((prev) => !prev);

  const onSaveForm = (formData: IBloodGlucoseValues) => {
    const preparedValues = preparedData(formData);

    const filteredValues = Object.fromEntries(
      Object.entries(preparedValues).filter(([_, value]) => value !== null),
    );

    const commentTemplate = template
      .map((item) => {
        return filteredValues[item.key] ? item.value : '';
      })
      .join('');

    const resultText = mustache.render(
      commentTemplate,
      formatTemplateValues(filteredValues),
    );

    handleSubmit(addSpaceAfterMarks(resultText));
    addComment(activityId);
    closeModal();
  };

  return {
    initialValues,
    openEmergencyModal,
    symptomsList,
    onSaveForm,
    toggleEmergencyModal,
  };
};
