import React, { useState, useEffect } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';
import CloseIcon from 'components/atoms/icons/closeIcon';
import classNames from 'classnames';
import useUser from 'utils/useUser';
import { isMobile } from 'utils/deviceHelper';
import DraggableIcon from 'components/atoms/icons/draggable-icon.svg?react';

const DraggableModal = ({
  title,
  subTitle,
  children,
  toggle,
  isOpen,
  styleName,
  bodyClass = '',
  headerClassName = '',
}) => {
  const { deviceType } = useUser();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: isMobile(deviceType) ? 10 : 170,
    y: 14,
  });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const style = { top: position.y, left: position.x };

  const modalToggle = () => toggle(!isOpen);

  const handleMouseDown = (e) => {
    if (e.target?.tagName?.toLowerCase() !== 'div') return;
    setIsDragging(true);
    setInitialPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - initialPosition.x,
        y: e.clientY - initialPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        toggle();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggle]);

  const closeButton = (
    <button className="close" onClick={modalToggle}>
      <CloseIcon />
    </button>
  );

  return (
    <div
      id="draggable-modal"
      style={style}
      className={classNames(styleName, {
        'draggable-modal': true,
        'draggable-modal--dragging': isDragging,
      })}
    >
      <ModalHeader
        onMouseDown={handleMouseDown}
        toggle={modalToggle}
        close={closeButton}
        className={classNames('narrative-popup-header', headerClassName)}
      >
        {title}
        {subTitle && <small>{subTitle}</small>}
        <div className="narrative-popup-header__icon">
          <DraggableIcon />
        </div>
      </ModalHeader>
      <ModalBody onMouseDown={handleMouseDown} className={bodyClass}>
        {children}
      </ModalBody>
    </div>
  );
};
const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  styleName: PropTypes.string,
  bodyClass: PropTypes.string,
  headerClassName: PropTypes.string,
};

DraggableModal.propTypes = propTypes;

export default DraggableModal;
