import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  timeframeOptions,
  forms,
  template,
  allRequiredForm,
} from 'constants/rpmActivity';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import Button from 'components/atoms/button';
import mustache from 'mustache';
import format from 'date-fns/format';
import common from 'constants/common';
import {
  addSpaceAfterMarks,
  formatTemplateValues,
} from 'utils/templateHelpers';
import Section from 'components/molecules/section';

const RpmCommentModal = ({ isOpen, closeModal, activityProps }) => {
  const {
    doctor: { addComment },
  } = useDispatch();
  const { values, setValues, activity, handleSubmit } = activityProps;
  const [modalValue, setModalValue] = useState(values);
  const setValue = (value) => {
    setModalValue((prev) => ({ ...prev, ...value }));
  };

  useEffect(() => {
    setValues(modalValue);
  }, [modalValue, values]);

  const submit = () => {
    const commentTemplate = template
      .map((item) => {
        if (item.key === 'timeframe') {
          return timeframeOptions[modalValue.timeframe];
        }
        return modalValue[item.key] ? item.value : '';
      })
      .join('');
    const resultText = mustache.render(
      commentTemplate,
      formatTemplateValues(modalValue),
    );
    handleSubmit(addSpaceAfterMarks(resultText));
    addComment(activity.id);
    closeModal();
  };

  const modalTitle = useMemo(() => {
    const formattedDate = format(
      new Date(parseInt(activity.createdAt)),
      common.dateFormats.dayOfWeek,
    );
    const formattedDateTime = format(
      new Date(parseInt(activity.createdAt)),
      common.dateFormats.timeAPM,
    );
    return `RPM Comment ${formattedDate} ${formattedDateTime}`;
  }, [activity]);

  const disableSaveButton = useMemo(
    () =>
      allRequiredForm.some((formId) => {
        const value = modalValue[formId];
        if (Array.isArray(value) && !value.length) {
          return true;
        }
        if (!value) {
          return true;
        }
        return false;
      }),
    [modalValue],
  );

  return (
    <DraggableModal
      isOpen={isOpen}
      toggle={closeModal}
      title={modalTitle}
      styleName="popup narrative-popup"
    >
      <Section forms={forms} setValue={setValue} values={modalValue} />
      <Button
        handleClick={submit}
        disabled={disableSaveButton}
        text="Save"
        data-cy="save-button"
      />
    </DraggableModal>
  );
};

RpmCommentModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  activityProps: PropTypes.object,
};

export default RpmCommentModal;
