import i18next from 'i18next';
import ChevronRightIcon from 'components/atoms/icons/chevron-right.svg?react';

import s from './styles.module.scss';

import type { Nullable } from 'infrastructure/types';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';
import type { BaseLog } from 'infrastructure/classes/logs/base-log';

const checkValue = (value?: Nullable<string>) =>
  !value || value === 'null' ? i18next.t('labels.bannerNoData') : value;

export const logsColumns = (): IBaseTableColumns<BaseLog> => {
  return [
    {
      label: i18next.t('labels.dateTime'),
      key: 'date',
      width: '170px',
      render(value) {
        return (
          <span className={s.date} data-cy="logs-dateChanged">
            {value}
          </span>
        );
      },
    },
    {
      label: i18next.t('labels.statusChanging'),
      key: 'from',
      width: '400px',
      responsive: ['md'],
      render(_, record) {
        return (
          <div data-cy="logs-status-change">
            {record.from && (
              <>
                <span className={s.from} data-cy="logs-status-change-from">
                  {`${i18next.t('labels.from')}: ${checkValue(record.from)} `}
                </span>
                <ChevronRightIcon />
              </>
            )}
            <span className={s.to} data-cy="logs-status-change-to">
              {` ${i18next.t('labels.to')}: ${checkValue(record.to)}`}
            </span>
          </div>
        );
      },
    },
    {
      label: i18next.t('labels.electronicallySigned'),
      align: 'right',
      key: 'who',
      width: '330px',
      render(value) {
        return (
          <span className={s.who} data-cy="logs-electronicallySigned">
            {value}
          </span>
        );
      },
    },
  ];
};
