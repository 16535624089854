const RoleTypes = [
  { value: 'all', label: 'All' },
  { value: 'admin-panel-access', label: 'Admin Panel Access' },
  { value: 'adminTools', label: 'Admin Tools' },
  { value: 'biller', label: 'Biller' },
  { value: 'doctor', label: 'Physician' },
  { value: 'nursePractitioner', label: 'Nurse Practitioner' },
  { value: 'physicianAssistant', label: 'Physician Assistant' },
  { value: 'QHCP', label: 'QHCP' },
  { value: 'support', label: 'Support' },
  { value: 'onboarding', label: 'Onboarding' },
  { value: 'registeredNurse', label: 'Registered Nurse' },
  { value: 'patientAcceptance', label: 'Patient Acceptance' },
  { value: 'criticalAlerting', label: 'Critical Alerting' },
  { value: 'atRiskAlerting', label: 'At Risk Alerting' },
  {
    value: 'clientSuccessOnboarding',
    label: 'Client Success Onboarding',
  },
  {
    value: 'clientSuccessScaling',
    label: 'Client Success Scaling',
  },
  { value: 'salesRepresentative', label: 'Sales Representative' },
  { value: 'ccm-biller', label: 'CCM Biller' },
  { value: 'ccm-doctor', label: 'CCM Physician' },
  { value: 'ccm-nursePractitioner', label: 'CCM Nurse Practitioner' },
  { value: 'ccm-physicianAssistant', label: 'CCM Physician Assistant' },
  { value: 'ccm-QHCP', label: 'CCM QHCP' },
  { value: 'ccm-support', label: 'CCM Support' },
  { value: 'ccm-registeredNurse', label: 'CCM Registered Nurse' },
  { value: 'nutritionist', label: 'Nutritionist' },
  { value: 'smsDashboard', label: 'SMS Dashboard' },
  { value: 'smsDashboardUnregistered', label: 'SMS Dashboard Unregistered' },
  { value: 'billingTools', label: 'Billing Tools' },
];

export default RoleTypes;
