import dataService from 'services/dataService';
import { API_PATIENTS_SELECT_OPTIONS } from 'services/dataService/resources';
import { useTranslation } from 'react-i18next';
import QueryParams from 'utils/queryParams';
import BaseAutocomplete from 'components/molecules/autocompleteFilter/base';
import { showResult } from 'infrastructure/hooks/utils';

import type { Option } from 'components/molecules/autocompleteFilter/base';
import type { FC } from 'react';

type SearchFields = 'fullName' | 'guid';

const fetchUserList = async ({
  agencyGuid,
  clinicGuid,
  excludeClinicGuid,
  doctorGuid,
  search,
  items,
  page,
  showOnInteractiveTimeDashboard,
}: {
  excludeClinicGuid?: string | string[];
  agencyGuid?: string | string[];
  clinicGuid?: string | string[];
  doctorGuid?: string | string[];
  search: string;
  page: number;
  items: number;
  showOnInteractiveTimeDashboard?: boolean;
}): Promise<{ count: number; data: Option[] } | undefined> => {
  const emptyResult = {
    data: [],
    count: 0,
  };
  if (!search) return emptyResult;
  const url = QueryParams.stringifyUrl(API_PATIENTS_SELECT_OPTIONS, {
    items,
    page,
    search,
    showOnInteractiveTimeDashboard,
    agencyGuid,
    clinicGuid,
    excludeClinicGuid,
    doctorGuid,
  });
  const { data, error } = await dataService.getList(url);
  if (error) {
    showResult(error);
  }
  if (!data || error) return emptyResult;
  const values: Option[] =
    data.items?.map((item: any) => ({
      label: item.label,
      value: item.guid,
    })) || [];

  return {
    data: values,
    count: data.count,
  };
};

interface Props {
  excludeClinicGuid?: string | string[];
  agencyGuid?: string | string[];
  clinicGuid?: string | string[];
  doctorGuid?: string | string[];
  value?: Option[];
  onChange: (value: Option[]) => void;
  searchField?: SearchFields;
  showSelectAll?: boolean;
  width?: string;
  withSelectedData?: boolean;
  showOnInteractiveTimeDashboard?: boolean;
}

const PatientsAutocomplete: FC<Props> = ({
  onChange,
  value,
  agencyGuid,
  clinicGuid,
  excludeClinicGuid,
  doctorGuid,
  searchField = 'fullName',
  showSelectAll,
  width = '100%',
  withSelectedData,
  showOnInteractiveTimeDashboard,
}) => {
  const { t } = useTranslation();

  const labelMapping = {
    fullName: t('labels.patientSearch'),
    guid: t('labels.patientGuid'),
  };

  return (
    <BaseAutocomplete
      width={width}
      label={labelMapping[searchField]}
      placeholder={t('labels.searchByNameOrGuid')}
      mode="multiple"
      showSelectAll={showSelectAll}
      withSelectedData={withSelectedData}
      fetchOptions={(args) =>
        fetchUserList({
          ...args,
          agencyGuid,
          clinicGuid,
          excludeClinicGuid,
          doctorGuid,
          showOnInteractiveTimeDashboard,
        })
      }
      onChange={(newValues) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        onChange(items);
      }}
      value={value}
    />
  );
};

export default PatientsAutocomplete;
