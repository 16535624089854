import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import BaseContainer from 'components/atoms/base-container';
import { useAgencyQueryFilter } from 'infrastructure/hooks/agency';
import ExpandableAgencyFilters from 'components/molecules/agency/expandable-filters';
import AgenciesTable from 'components/molecules/agency/table';

import s from './styles.module.scss';

const AdminAgencies: React.FC = () => {
  const { t } = useTranslation();

  const { count, filter, resetFilter, updateFilter } = useAgencyQueryFilter();

  return (
    <BaseContainer>
      <AdminNavigationHeader title={t('labels.agenciesList')} />
      <div className={s.page}>
        <ExpandableAgencyFilters
          data={filter}
          updateFilter={updateFilter}
          resetFilter={resetFilter}
          count={count}
        />
        <AgenciesTable filters={filter} updateFilter={updateFilter} />
      </div>
    </BaseContainer>
  );
};

export default AdminAgencies;
