import NotFound from 'components/atoms/notFound';
import AppEmptyLayout from 'pages/app/empty-layout';
import MainAppLayout from 'pages/app/layout';
import AdminAgencies from 'pages/admin/agencies';
import AdminAgencyAdd from 'pages/admin/agencyAdd';
import AdminAgencyDoctorAdd from 'pages/admin/hhaDoctorAdd';
import AdminAgencyDoctorEdit from 'pages/admin/hhaDoctorEdit';
import AdminAgencyDoctorInfo from 'pages/admin/hhaDoctor';
import AdminAgencyDoctorList from 'pages/admin/hhaDoctors';
import AdminAgencyEdit from 'pages/admin/agencyEdit';
import AdminAgencyViewPage from 'pages/admin/agency';
import AdminClinicAddPage from 'pages/admin/clinicAdd';
import AdminClinicEditPage from 'pages/admin/clinicEdit';
import AdminClinicViewPage from 'pages/admin/clinic';
import AdminClinics from 'pages/admin/clinics';
import AdminPatientAdd from 'pages/admin/patientAdd';
import AdminPatientDetailsPage from 'pages/admin/patient/index';
import AdminPatients from 'pages/admin/patients';
import AdminTurkUserInfoPage from 'pages/admin/turkUser';
import AdminTurkUsersPage from 'pages/admin/turkUsers';
import AdminUsers from 'pages/admin/users';
import AdminUser from 'pages/admin/user';
import AdminUserAdd from 'pages/admin/userAdd';
import AdminUserEdit from 'pages/admin/userEdit';
import BillingClaimsAthenaPage from 'pages/billingTools/claims/athena';
import EpicClaimsPage from 'pages/billingTools/claims/epic';
import CCMConsentForm from 'pages/ccm/consentForm';
import CcmAgentDashboardPage from 'pages/ccm/dashboards/ccmAgentDashboard';
import CCMPatientDetails from 'pages/ccm/patientDetails';
import CCMBillingReport from 'pages/ccm/practice/billingReport';
import CCMBillingReportV2 from 'pages/ccm/practice/billingReportV2';
import CCMInteractiveTimeReport from 'pages/ccm/practice/ccmInteractiveReport';
import CcmOnboardingStatus from 'pages/ccm/practice/ccmOnboardingStatus';
import CCMStatusReport from 'pages/ccm/practice/statusReport';
import CCMPatientsActivities from 'pages/ccm/patientsActivities';
import ClinicalMetrics from 'pages/rpm/practice/clinicalMetrics';
import ComplianceReport from 'pages/rpm/practice/complianceReport';
import DashboardPage from 'pages/rpm/dashboard';
import EligibilityItemsPage from 'pages/tools/eligibility/items';
import EligibilityPage from 'pages/tools/eligibility';
import EmailUnsubscribePage from 'pages/public/emailSubscription';
import EmailUnsubscribedPage from 'pages/public/emailUnsubscribed';
import InteractiveTimeDashboardPage from 'pages/rpm/interactiveTimeDashboard';
import InteractiveTimeReportV2 from 'pages/rpm/practice/interactiveReportV2';
import InteractionStatus from 'pages/rpm/practice/interactionStatus';
import MedicareBilling from 'pages/rpm/practice/medicareBilling';
import OnboardingStatus from 'pages/rpm/practice/onboardingStatus';
import PatientDetails from 'pages/rpm/patients/patientDetails';
import PatientPage from 'pages/rpm/patients/patient';
import PatientsStatusReport from 'pages/rpm/practice/patientsStatusReport';
import PerformanceReview from 'pages/rpm/practice/performanceReview';
import Profile from 'pages/profile';
import ResetPasswordLinkPage from 'pages/public/resetPasswordLink';
import SmsDashboard from 'pages/smsDashboard';
import SSO from 'pages/sso';
import SSOLogin from 'pages/sso/login';
import StatisticsReport from 'pages/rpm/practice/statisticsReport';
import ActivityReport from 'pages/rpm/practice/activity-report';
import AdherenceLast30DaysReport from 'pages/rpm/practice/adherenceReport/last30DaysAdherence';
import AdherenceReport from 'pages/rpm/practice/adherenceReport';
import TurkConfigurationPage from 'pages/admin/turkConfiguration';
import TurkDashboardPage from 'pages/turkDashboard';
import TurkUserAddPage from 'pages/admin/turkUserAdd';
import TurkUserEditPage from 'pages/admin/turkUserEdit';
import {
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { PublicRoutes, Routes } from './routes';
import { renderRoutes } from './generate-routes';

import type { ILayoutRoute } from './types';

const routes: ILayoutRoute[] = [
  {
    layout: AppEmptyLayout,
    routes: [
      {
        path: PublicRoutes.emailSubscription,
        component: EmailUnsubscribePage,
      },
      {
        path: PublicRoutes.emailUnsubscribed,
        component: EmailUnsubscribedPage,
      },
      {
        path: PublicRoutes.ssoLogin,
        component: SSOLogin,
      },
      {
        path: PublicRoutes.resetPassword,
        component: ResetPasswordLinkPage,
      },
    ],
  },
  {
    layout: MainAppLayout,
    isProtected: true,
    routes: [
      { path: '*', component: NotFound },
      { path: Routes.notFound, component: NotFound },
      { path: Routes.activityReport, component: ActivityReport },
      { path: Routes.adherenceReport, component: AdherenceReport },
      {
        path: Routes.adherenceReportLast30Days,
        component: AdherenceLast30DaysReport,
      },
      { path: Routes.adminAgencyList, component: AdminAgencies },
      {
        path: Routes.adminAgency(':agencyGuid'),
        component: AdminAgencyViewPage,
      },
      { path: Routes.adminAgencyAdd, component: AdminAgencyAdd },
      {
        path: Routes.adminAgencyDoctor(':agentGuid'),
        component: AdminAgencyDoctorInfo,
      },
      { path: Routes.adminAgencyDoctorAdd, component: AdminAgencyDoctorAdd },
      {
        path: Routes.adminAgencyDoctorEdit(':agentGuid'),
        component: AdminAgencyDoctorEdit,
      },
      { path: Routes.adminAgencyDoctorList, component: AdminAgencyDoctorList },
      {
        path: Routes.adminAgencyEdit(':agencyGuid'),
        component: AdminAgencyEdit,
      },
      {
        path: Routes.adminClinic(':clinicGuid'),
        component: AdminClinicViewPage,
      },
      { path: Routes.adminClinicAdd, component: AdminClinicAddPage },
      {
        path: Routes.adminClinicEdit(':clinicGuid'),
        component: AdminClinicEditPage,
      },
      { path: Routes.adminClinics, component: AdminClinics },
      {
        path: Routes.adminPatient(':patientGuid'),
        component: AdminPatientDetailsPage,
      },
      { path: Routes.adminPatientAdd, component: AdminPatientAdd },
      { path: Routes.adminPatients, component: AdminPatients },
      {
        path: Routes.adminTurkConfigurations,
        component: TurkConfigurationPage,
      },
      {
        path: Routes.adminTurkUser(':agentGuid'),
        component: AdminTurkUserInfoPage,
      },
      { path: Routes.adminTurkUserList, component: AdminTurkUsersPage },
      { path: Routes.adminTurkUsersAdd, component: TurkUserAddPage },
      {
        path: Routes.adminTurkUsersEdit(':agentGuid'),
        component: TurkUserEditPage,
      },
      { path: Routes.adminUser(':userGuid'), component: AdminUser },
      { path: Routes.adminUserAdd, component: AdminUserAdd },
      { path: Routes.adminUserEdit(':userGuid'), component: AdminUserEdit },
      { path: Routes.adminUsers, component: AdminUsers },
      { path: Routes.billingClaimsAthena, component: BillingClaimsAthenaPage },
      { path: Routes.billingClaimsEpic, component: EpicClaimsPage },
      { path: Routes.ccm, component: CCMPatientsActivities },
      { path: Routes.ccmAgentDashboard, component: CcmAgentDashboardPage },
      { path: Routes.ccmBillingReport, component: CCMBillingReport },
      { path: Routes.ccmBillingReportV2, component: CCMBillingReportV2 },
      { path: Routes.ccmConsent(':patientGuid'), component: CCMConsentForm },
      {
        path: Routes.ccmInteractiveTimeReport,
        component: CCMInteractiveTimeReport,
      },
      { path: Routes.ccmOnboardingStatus, component: CcmOnboardingStatus },
      { path: Routes.ccmPatient(':patientGuid'), component: CCMPatientDetails },
      { path: Routes.ccmStatusReport, component: CCMStatusReport },
      { path: Routes.clinicalMetrics, component: ClinicalMetrics },
      { path: Routes.complianceReport, component: ComplianceReport },
      { path: Routes.dashboard, component: DashboardPage, isIndex: true },
      { path: Routes.eligibilityTool, component: EligibilityPage },
      {
        path: Routes.eligibilityToolItems(':processGuid'),
        component: EligibilityItemsPage,
      },
      { path: Routes.interactionStatus, component: InteractionStatus },
      { path: Routes.interactiveReport, component: InteractiveTimeReportV2 },
      {
        path: Routes.interactiveTimeDashboard,
        component: InteractiveTimeDashboardPage,
      },
      { path: Routes.medicareBilling, component: MedicareBilling },
      { path: Routes.onboardingStatus, component: OnboardingStatus },
      { path: Routes.patientDetails, component: PatientDetails },
      { path: Routes.patientGuid(':patientGuid'), component: PatientPage },
      { path: Routes.patientsStatusReport, component: PatientsStatusReport },
      { path: Routes.performanceReview, component: PerformanceReview },
      { path: Routes.profile, component: Profile },
      { path: Routes.smsDashboard, component: SmsDashboard },
      { path: Routes.sso, component: SSO },
      { path: Routes.statisticsReport, component: StatisticsReport },
      { path: Routes.turkDashboard, component: TurkDashboardPage },
    ],
  },
];

export const router = createBrowserRouter(
  createRoutesFromElements(renderRoutes(routes)),
  {
    future: {
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_skipActionErrorRevalidation: true,
    },
  },
);
