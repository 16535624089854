import i18next from 'i18next';
import { States } from 'infrastructure/enums';

export interface IStateItem {
  id: States | null;
  name: string;
}

export const statesDataSource = (
  withAll = false,
  withNone = false,
): IStateItem[] => {
  const dataSource: IStateItem[] = [
    {
      id: States.Alabama,
      name: i18next.t('states.Alabama'),
    },
    {
      id: States.Alaska,
      name: i18next.t('states.Alaska'),
    },
    {
      id: States.AmericanSamoa,
      name: i18next.t('states.AmericanSamoa'),
    },
    {
      id: States.Arizona,
      name: i18next.t('states.Arizona'),
    },
    {
      id: States.Arkansas,
      name: i18next.t('states.Arkansas'),
    },
    {
      id: States.California,
      name: i18next.t('states.California'),
    },
    {
      id: States.Colorado,
      name: i18next.t('states.Colorado'),
    },
    {
      id: States.Connecticut,
      name: i18next.t('states.Connecticut'),
    },
    {
      id: States.Delaware,
      name: i18next.t('states.Delaware'),
    },
    {
      id: States.DistrictOfColumbia,
      name: i18next.t('states.DistrictOfColumbia'),
    },
    {
      id: States.FederatedStatesOfMicronesia,
      name: i18next.t('states.FederatedStatesOfMicronesia'),
    },
    {
      id: States.Florida,
      name: i18next.t('states.Florida'),
    },
    {
      id: States.Georgia,
      name: i18next.t('states.Georgia'),
    },
    {
      id: States.Guam,
      name: i18next.t('states.Guam'),
    },
    {
      id: States.Hawaii,
      name: i18next.t('states.Hawaii'),
    },
    {
      id: States.Idaho,
      name: i18next.t('states.Idaho'),
    },
    {
      id: States.Illinois,
      name: i18next.t('states.Illinois'),
    },
    {
      id: States.Indiana,
      name: i18next.t('states.Indiana'),
    },
    {
      id: States.Iowa,
      name: i18next.t('states.Iowa'),
    },
    {
      id: States.Kansas,
      name: i18next.t('states.Kansas'),
    },
    {
      id: States.Kentucky,
      name: i18next.t('states.Kentucky'),
    },
    {
      id: States.Louisiana,
      name: i18next.t('states.Louisiana'),
    },
    {
      id: States.Maine,
      name: i18next.t('states.Maine'),
    },
    {
      id: States.MarshallIslands,
      name: i18next.t('states.MarshallIslands'),
    },
    {
      id: States.Maryland,
      name: i18next.t('states.Maryland'),
    },
    {
      id: States.Massachusetts,
      name: i18next.t('states.Massachusetts'),
    },
    {
      id: States.Michigan,
      name: i18next.t('states.Michigan'),
    },
    {
      id: States.Minnesota,
      name: i18next.t('states.Minnesota'),
    },
    {
      id: States.Mississippi,
      name: i18next.t('states.Mississippi'),
    },
    {
      id: States.Missouri,
      name: i18next.t('states.Missouri'),
    },
    {
      id: States.Montana,
      name: i18next.t('states.Montana'),
    },
    {
      id: States.Nebraska,
      name: i18next.t('states.Nebraska'),
    },
    {
      id: States.Nevada,
      name: i18next.t('states.Nevada'),
    },
    {
      id: States.NewHampshire,
      name: i18next.t('states.NewHampshire'),
    },
    {
      id: States.NewJersey,
      name: i18next.t('states.NewJersey'),
    },
    {
      id: States.NewMexico,
      name: i18next.t('states.NewMexico'),
    },
    {
      id: States.NewYork,
      name: i18next.t('states.NewYork'),
    },
    {
      id: States.NorthCarolina,
      name: i18next.t('states.NorthCarolina'),
    },
    {
      id: States.NorthDakota,
      name: i18next.t('states.NorthDakota'),
    },
    {
      id: States.NorthernMarianaIslands,
      name: i18next.t('states.NorthernMarianaIslands'),
    },
    {
      id: States.Ohio,
      name: i18next.t('states.Ohio'),
    },
    {
      id: States.Oklahoma,
      name: i18next.t('states.Oklahoma'),
    },
    {
      id: States.Oregon,
      name: i18next.t('states.Oregon'),
    },
    {
      id: States.Palau,
      name: i18next.t('states.Palau'),
    },
    {
      id: States.Pennsylvania,
      name: i18next.t('states.Pennsylvania'),
    },
    {
      id: States.PuertoRico,
      name: i18next.t('states.PuertoRico'),
    },
    {
      id: States.RhodeIsland,
      name: i18next.t('states.RhodeIsland'),
    },
    {
      id: States.SouthCarolina,
      name: i18next.t('states.SouthCarolina'),
    },
    {
      id: States.SouthDakota,
      name: i18next.t('states.SouthDakota'),
    },
    {
      id: States.Tennessee,
      name: i18next.t('states.Tennessee'),
    },
    {
      id: States.Texas,
      name: i18next.t('states.Texas'),
    },
    {
      id: States.Utah,
      name: i18next.t('states.Utah'),
    },
    {
      id: States.Vermont,
      name: i18next.t('states.Vermont'),
    },
    {
      id: States.VirginIslands,
      name: i18next.t('states.VirginIslands'),
    },
    {
      id: States.Virginia,
      name: i18next.t('states.Virginia'),
    },
    {
      id: States.Washington,
      name: i18next.t('states.Washington'),
    },
    {
      id: States.WestVirginia,
      name: i18next.t('states.WestVirginia'),
    },
    {
      id: States.Wisconsin,
      name: i18next.t('states.Wisconsin'),
    },
    {
      id: States.Wyoming,
      name: i18next.t('states.Wyoming'),
    },
  ];

  if (withAll) {
    dataSource.unshift({
      id: null,
      name: i18next.t('states.All'),
    });
  }
  if (withNone) {
    dataSource.unshift({
      id: null,
      name: i18next.t('states.None'),
    });
  }
  return dataSource;
};
