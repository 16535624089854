import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import ChevronRightIcon from 'components/atoms/icons/chevron-right.svg?react';

import s from './styles.module.scss';
import { checkValue, fieldMap, findKeyByValue } from './halpers';

import type {
  IDoctorHistoryLogs,
  THistoryLogOperation,
} from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

export const columns: IBaseTableColumns<IDoctorHistoryLogs> = [
  {
    label: i18next.t('labels.dateTime'),
    key: 'createdAt',
    width: '100px',
    render(date: string) {
      return (
        <p className={s.date}>
          {DateFormatter({
            date,
            format: DateFormats['MM-dd-yyyy h:mm a'],
          })}
        </p>
      );
    },
    dataCy: 'agency-history-date',
  },
  {
    label: i18next.t('labels.operationType'),
    key: 'operation',
    width: '130px',
    render(operation: THistoryLogOperation) {
      return <p className={s.operation}>{operation.toLowerCase()}</p>;
    },
    dataCy: 'agency-history-operation',
  },
  {
    label: i18next.t('labels.path'),
    key: 'changes',
    width: '310px',
    dataCy: 'agency-history-path',
    render(_, record) {
      return record.changes?.map(({ field }) => (
        <div className={s.path}>
          {field !== 'status' && (
            <>
              <p>{findKeyByValue(field)}</p>
              <ChevronRightIcon className={s.arrow} />
            </>
          )}
          <p>{fieldMap[field]}</p>
        </div>
      ));
    },
  },
  {
    label: i18next.t('labels.changes'),
    key: 'changes',
    width: '570px',
    dataCy: 'agency-history-changes',
    render(_, record) {
      return record.changes?.map(({ from, to, field }) => (
        <div className={s.changes}>
          <p>
            {i18next.t('labels.from')}: {checkValue(field, from)}
          </p>
          <ChevronRightIcon className={s.arrow} />
          <p className={s.to}>{checkValue(field, to)}</p>
        </div>
      ));
    },
  },
  {
    label: i18next.t('labels.user'),
    width: '230px',
    key: 'author',
    align: 'right',
    dataCy: 'agency-history-user',
    render(_, record) {
      return (
        <div className={s.author}>
          <p className={s.name}>{record.author.fullName}</p>
          <p className={s.type}>
            <span>{i18next.t('labels.userType')}:</span>{' '}
            {record.author.userType}
          </p>
        </div>
      );
    },
  },
];
