import { Radio } from 'antd';
import classNames from 'classnames';
import { stringToBoolean } from 'utils/helpers/string-to-boolean';

import s from './styles.module.scss';

import type { IFormSubItemProps, IOptionProps } from './types';
import type { FC } from 'react';

const RadioLineItem: FC<
  IFormSubItemProps & {
    generalName: string;
  }
> = ({ formik, option, generalName, requiredSymptomFields }) => {
  const onHandleFactors = (opt: IOptionProps, value: boolean) => {
    const { confirmedFactors, deniedFactors } = formik.values;
    const fieldName = String(opt.value);
    formik.setFieldValue(fieldName, String(value));

    if (value) {
      const newDeniedValues = deniedFactors?.filter(
        (factor: string) => factor !== opt.label,
      );

      formik.setFieldValue('confirmedFactors', [
        ...confirmedFactors,
        opt.label,
      ]);
      formik.setFieldValue('deniedFactors', newDeniedValues);
    } else {
      const newConfirmedValues = confirmedFactors?.filter(
        (factor: string) => factor !== opt.label,
      );

      formik.setFieldValue('confirmedFactors', newConfirmedValues);
      formik.setFieldValue('deniedFactors', [...deniedFactors, opt.label]);
    }
  };

  const onHandleSymptoms = (opt: IOptionProps, value: boolean) => {
    const fieldName = String(opt.value);
    formik.setFieldValue(fieldName, String(value));

    if (
      requiredSymptomFields &&
      requiredSymptomFields.includes(fieldName) &&
      !formik.values.checkSymptoms
    ) {
      formik.setFieldValue('checkSymptoms', true);
    }

    const { confirmedSymptoms, deniedSymptoms } = formik.values;
    formik.setFieldValue('symptoms', [...confirmedSymptoms, ...deniedSymptoms]);

    if (value) {
      const newDeniedValues = deniedSymptoms?.filter(
        (symptom: string) => symptom !== opt.label,
      );

      formik.setFieldValue('confirmedSymptoms', [
        ...confirmedSymptoms,
        opt.label,
      ]);
      formik.setFieldValue('deniedSymptoms', newDeniedValues);
    } else {
      const newConfirmedValues = confirmedSymptoms?.filter(
        (symptom: string) => symptom !== opt.label,
      );

      formik.setFieldValue('confirmedSymptoms', newConfirmedValues);
      formik.setFieldValue('deniedSymptoms', [...deniedSymptoms, opt.label]);
    }
  };

  const isShowErrorMessage = Object.keys(formik.errors).includes(
    option.value as string,
  );

  const itemClassNames = classNames(s['radio-line'], {
    [s.error]:
      !!Object.keys(formik.errors).length &&
      option.required &&
      isShowErrorMessage,
  });

  return (
    <div className={itemClassNames}>
      <Radio.Group
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ]}
        name={option.value as string}
        className={s['radio-items']}
        onChange={(e) =>
          generalName === 'symptoms'
            ? onHandleSymptoms(option, e.target.value)
            : onHandleFactors(option, e.target.value)
        }
        defaultValue={stringToBoolean(formik.values[option.value as string])}
      />
      <div className={s['radio-label']}>
        {option.label}
        {option.required && <> ⚠️</>}
      </div>
    </div>
  );
};

export default RadioLineItem;
