import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import DraggableModal from 'components/organisms/draggableModal/draggableModal';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import classNames from 'classnames';
import { assessmentSpo2Form } from 'constants/criticalSpo2Data';
import EmergencyModal from 'components/molecules/rpm-alert-assessment/emergencyModal';
import FormItemWrapper from 'components/molecules/rpm-alert-assessment/formItemWrapper';

import s from '../styles.module.scss';
import { criticalSpo2ValidationSchema } from '../validation-schema';
import { useSpoAssessment } from '../hooks/use-spo-assessment';
import { checkSymptomsUpdate } from '../helpers';
import { useStoreModalValue } from '../hooks/use-store-modal-value';

import type {
  IFormProps,
  ISpo2Values,
} from 'components/molecules/rpm-alert-assessment/types';
import type { ICriticalSpo2Props, ISharedValues } from '../types';
import type { FC } from 'react';

const CriticalSpo2Modal: FC<ICriticalSpo2Props> = ({
  isOpen,
  activityId,
  type,
  sharedValues,
  activityDate,
  values,
  idx,
  patientGuid,
  handleSubmit,
  closeModal,
  updateSharedValue,
}) => {
  const {
    doctor: { addComment },
  } = useDispatch();

  const { t } = useTranslation();

  const {
    initialValues,
    openEmergencyModal,
    symptomsList,
    toggleEmergencyModal,
    onSaveForm,
  } = useSpoAssessment({
    type,
    activityId,
    addComment,
    sharedValues,
    activityDate,
    values,
    patientGuid,
    closeModal,
    handleSubmit,
  });

  type TInitialValues = typeof initialValues;
  const { parsedData, setModalValuesToStorage } =
    useStoreModalValue<TInitialValues>({
      idx,
      type,
      modalName: 'puls',
    });
  const blockRef = useRef<HTMLDivElement | null>(null);

  const formik = useFormik({
    initialValues: parsedData ?? initialValues,
    onSubmit: (data) => {
      if (data.isOneMoreReading === 'false') {
        data.bounds = null;
        data.repeatReadingWas = null;
      }
      if (data.isTakeMedications === 'false') {
        data.prescribed = [];
      }
      if (data.isTakePrnMedications === 'false') {
        data.prnMedications = [];
      }
      if (data.patient !== null) {
        data.other = null;
        data.relationship = null;
      }

      Object.keys(sharedValues).forEach((key) => {
        if (key in data) {
          const typedKey = key as keyof ISpo2Values & keyof ISharedValues;
          updateSharedValue(typedKey, data[typedKey]);
        }
      });

      setModalValuesToStorage(data);

      onSaveForm(data);
    },
    validateOnChange: false,
    validationSchema: criticalSpo2ValidationSchema(symptomsList),
  });

  const requiredSymptomFields = symptomsList
    .filter((symptom) => symptom.required)
    .map((symptom) => symptom.value);

  const onSave = () => {
    formik.submitForm();
  };

  const onEmergencyModalCancel = () => {
    formik.setFieldValue('emergencyCare', false);
    toggleEmergencyModal();
  };

  const onEmergencyModalSave = () => {
    formik.setFieldValue('emergencyCare', true);
    toggleEmergencyModal();
    if (blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (formik.values.checkSymptoms) {
      checkSymptomsUpdate(requiredSymptomFields, formik, toggleEmergencyModal);
    }
  }, [formik.values]);

  const expandableExtraFields = [
    'isTakeMedications',
    'isTakePrnMedications',
    'isOneMoreReading',
  ];

  return (
    <>
      <DraggableModal
        isOpen={isOpen}
        toggle={closeModal}
        title={t('labels.rpmAlertBasedAssessment')}
        subTitle={t('labels.hypoxiaAssessment')}
        styleName={classNames('popup', 'narrative-popup', 'assessment-modal')}
        headerClassName={s['modal-header']}
        bodyClass={s['modal-body']}
      >
        <BaseForm
          formik={formik}
          withControls
          onSave={onSave}
          controlsClassName={s.controls}
          className={s.form}
        >
          <div className={s.wrapper}>
            {assessmentSpo2Form.map((formItem: IFormProps, index) => {
              const isShowExtraFields = expandableExtraFields.includes(
                formItem.name,
              )
                ? formik.getFieldMeta(formItem.name)?.value === 'true'
                : undefined;

              return (
                <FormItemWrapper
                  formik={formik}
                  index={index}
                  formItem={formItem}
                  type={type}
                  requiredSymptomFields={requiredSymptomFields}
                  ref={blockRef}
                  isShowExtraFields={isShowExtraFields}
                />
              );
            })}
          </div>
        </BaseForm>
      </DraggableModal>

      {openEmergencyModal && (
        <EmergencyModal
          openEmergencyModal={openEmergencyModal}
          onSave={onEmergencyModalSave}
          onCancel={onEmergencyModalCancel}
        />
      )}
    </>
  );
};

export default CriticalSpo2Modal;
