import {
  isTurkUserRole,
  isSmsDashboardOrUnregisteredRole,
  isRoleNameAdminTools,
  isRoleNameBillingTools,
  isPractice,
  isAdmin,
  isAgencyDoctor,
  isPatient,
} from 'utils/userTypeHelper';
import { GroupedRoutes, Routes } from 'router/routes';
import { ModuleType, Roles, UserType } from 'infrastructure/enums';
import { RoleGroups } from 'infrastructure/consts/role-groups';

import {
  CCM_PREFIX,
  PLATFORM_MODE_LOCALSTORAGE_KEY,
  PLATFORM_MODE_PATIENT_ACCEPTANCE,
  PLATFORM_MODE_ADMIN_PANEL_ACCESS,
} from './constants';

export const hasClinicalRole = (roleNames: string[]) =>
  roleNames && roleNames.some((roleName) => roleName.includes(Roles.Clinical));

const hasCCMRole = (roleNames: string[]) =>
  roleNames && roleNames.some((roleName) => roleName.includes(CCM_PREFIX));

const hasRPMRole = (roleNames: string[]) =>
  roleNames &&
  roleNames.some(
    (roleName) =>
      !roleName.includes(CCM_PREFIX) &&
      !roleName.includes(Roles.AdminPanelAccess) &&
      !roleName.includes(Roles.CriticalAlerting) &&
      !roleName.includes(Roles.AtRiskAlerting),
  );

export const hasAccessToMainPage = (roleNames: Roles[]) =>
  roleNames.some((role) => RoleGroups.AccessToMainPageRoles.includes(role));

export const hasPatientAcceptanceRole = (roleNames: string[]) =>
  roleNames &&
  roleNames.some((roleName) => roleName.includes(Roles.PatientAcceptance));

export const isCriticalOrAtRiskAlertingRole = (roleNames: string[]) =>
  roleNames &&
  roleNames.every(
    (roleName) =>
      roleName === Roles.CriticalAlerting || roleName === Roles.AtRiskAlerting,
  );

export const isCriticalRole = (roleNames: string[]) =>
  roleNames &&
  roleNames.some((roleName) => roleName === Roles.CriticalAlerting);

export const isAtRiskRole = (roleNames: string[]) =>
  roleNames && roleNames.some((roleName) => roleName === Roles.AtRiskAlerting);

export const hasAdminPanelAccess = (roleNames: string[]) =>
  roleNames.includes(Roles.AdminPanelAccess);

export const hasBillingToolsAccess = (roleNames: string[]) =>
  roleNames.includes(Roles.BillingTools);

export const getUserRoutes = (user: any) => {
  const { userType, roleNames, platformMode } = user;
  const isCCM = platformMode === ModuleType.CCM;
  const isRPM = platformMode === ModuleType.RPM;

  const routes = new Set<string>([Routes.sso, Routes.dashboard]);

  const addRoutes = (...newRoutes: string[]) => {
    newRoutes.forEach((route) => routes.add(route));
  };

  if (isAdmin(userType)) {
    addRoutes(
      ...GroupedRoutes.adminPanelPatient,
      ...GroupedRoutes.adminPanelClinics,
      ...GroupedRoutes.adminPanelUsers,
      ...GroupedRoutes.adminPanelAgencies,
      ...GroupedRoutes.adminPanelAgencyDoctor,
      ...GroupedRoutes.rpmPracticeReport,
      ...GroupedRoutes.rpm,
      ...GroupedRoutes.rpmAdd,
      ...GroupedRoutes.ccm,
      ...GroupedRoutes.turkAdmin,
      ...GroupedRoutes.smsDashboard,
      ...GroupedRoutes.adminTools,
      ...GroupedRoutes.billingTools,
    );

    if (isCCM) {
      addRoutes(...GroupedRoutes.ccmAdminHHA);
    }

    return Array.from(routes);
  }

  if (hasAdminPanelAccess(roleNames)) {
    addRoutes(
      ...GroupedRoutes.adminPanelPatient,
      ...GroupedRoutes.adminPanelClinics,
      ...GroupedRoutes.adminPanelUsers,
    );
  }

  if (isRoleNameAdminTools(roleNames)) {
    addRoutes(...GroupedRoutes.adminTools);
  }

  if (isRoleNameBillingTools(roleNames)) {
    addRoutes(...GroupedRoutes.billingTools);
  }

  if (isSmsDashboardOrUnregisteredRole(roleNames)) {
    addRoutes(...GroupedRoutes.smsDashboard);
  }

  if (hasPatientAcceptanceRole(roleNames)) {
    addRoutes(...GroupedRoutes.patientAcceptance);
  }

  if (isPractice(userType)) {
    if (isRPM) addRoutes(...GroupedRoutes.rpm);
    else if (isCCM) addRoutes(...GroupedRoutes.ccm);
  }

  if (isAgencyDoctor(userType)) {
    if (hasCCMRole(roleNames) && isCCM) {
      addRoutes(...GroupedRoutes.ccm);
    }

    if (hasRPMRole(roleNames) && isRPM) {
      addRoutes(...GroupedRoutes.rpm, ...GroupedRoutes.rpmAdd);
    }

    if (hasAdminPanelAccess(roleNames)) {
      addRoutes(
        ...GroupedRoutes.adminPanelAgencies,
        ...GroupedRoutes.adminPanelAgencyDoctor,
        ...GroupedRoutes.rpmPracticeReport,
      );
    }

    if (isTurkUserRole(roleNames)) {
      addRoutes(...GroupedRoutes.turkUser);
    }

    if (isCCM) {
      addRoutes(...GroupedRoutes.ccmAdminHHA);
    }
  }

  if (isPatient(roleNames)) {
    addRoutes(Routes.profile, Routes.patientGuid(user?.guid));
  }

  return Array.from(routes);
};

export const getUserPlatformMode = (user: any) => {
  const { userType, roleNames } = user;

  if (hasPatientAcceptanceRole(roleNames) && !hasAccessToMainPage(roleNames)) {
    return PLATFORM_MODE_PATIENT_ACCEPTANCE;
  }

  if (userType === UserType.HomeHealth) {
    if (hasCCMRole(roleNames) && !hasRPMRole(roleNames)) {
      return ModuleType.CCM;
    }
    if (hasAdminPanelAccess(roleNames) && !hasAccessToMainPage(roleNames)) {
      return PLATFORM_MODE_ADMIN_PANEL_ACCESS;
    }
  }

  if (
    userType === UserType.Practice &&
    hasAdminPanelAccess(roleNames) &&
    !hasAccessToMainPage(roleNames)
  ) {
    return PLATFORM_MODE_ADMIN_PANEL_ACCESS;
  }

  if (
    userType === UserType.Practice &&
    roleNames.length === 1 &&
    hasAdminPanelAccess(roleNames)
  )
    return PLATFORM_MODE_ADMIN_PANEL_ACCESS;

  const userPlatformMode = localStorage.getItem(PLATFORM_MODE_LOCALSTORAGE_KEY);
  if (
    userPlatformMode &&
    (userPlatformMode === ModuleType.RPM || userPlatformMode === ModuleType.CCM)
  )
    return userPlatformMode;
  localStorage.setItem(PLATFORM_MODE_LOCALSTORAGE_KEY, ModuleType.RPM);
  return ModuleType.RPM;
};

export const toggleUserPlatformMode = (user: any) => {
  const { platformMode } = user;

  let newMode = ModuleType.RPM;
  if (platformMode === ModuleType.RPM) {
    newMode = ModuleType.CCM;
  }
  localStorage.setItem(PLATFORM_MODE_LOCALSTORAGE_KEY, newMode);

  return newMode;
};
