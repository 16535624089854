import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import BaseLoader from 'components/atoms/base-loader';

import type { IRoute } from 'router/types';

export const ProtectedRoute: React.FC<Pick<IRoute, 'component'>> = ({
  component: Page,
}) => {
  const Component = withAuthenticationRequired(Page, {
    onRedirecting: () => <BaseLoader className="loader fixed" loading />,
  });
  return <Component />;
};
