import BaseTableReport from 'components/atoms/base-table/report/report';
import { useTranslation } from 'react-i18next';
import { ELASTIC_SEARCH_MAX_ITEMS } from 'constants/count';
import { safeClamp } from 'utils/helpers/safeClamp';

import s from './styles.module.scss';
import { useColumns } from './columns/useColumns';

import type { FC } from 'react';
import type {
  InteractiveTimeItem,
  Filters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  isLoading: boolean;
  interactiveTime?: {
    data?: InteractiveTimeItem[] | undefined;
    count?: number | undefined;
  };
  filters: Filters;
  changePage: (page: number) => void;
}

const InteractiveTimeDashboardTable: FC<Props> = ({
  interactiveTime,
  isLoading,
  filters,
  changePage,
}) => {
  const { t } = useTranslation();
  const columns = useColumns();

  return (
    <div className={s.wrapper}>
      <BaseTableReport
        isLoading={isLoading}
        columns={columns}
        headerTitle={t('labels.patients')}
        dataSource={interactiveTime?.data}
        pagination={{
          current: filters.page,
          pageSize: filters.items,
          total: safeClamp(interactiveTime?.count, 0, ELASTIC_SEARCH_MAX_ITEMS),
          onChange: changePage,
        }}
        showCount
        anchor
      />
    </div>
  );
};

export default InteractiveTimeDashboardTable;
