import React, { useEffect, useState } from 'react';
import BaseContainer from 'components/atoms/base-container';
import AdminNavigationHeader from 'components/organisms/admin-panel/navigation-header';
import { useTranslation } from 'react-i18next';
import { Routes } from 'infrastructure/consts/routes';
import { useNavigate, useParams } from 'react-router-dom';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import EditButton from 'components/atoms/controls/edit-button';
import { useAgencyInfo } from 'infrastructure/hooks/agency';
import AgencyViewForm from 'components/molecules/agency/view-form';
import AgencyHistoryLogsTable from 'components/molecules/agency/agency-history-logs-table';
import { isValidGuid } from 'infrastructure/functions/is-valid-guid';
import { showResult } from 'infrastructure/hooks/utils';
import BaseGrid from 'components/atoms/base-grid';

import type { AgencyInfo } from 'infrastructure/classes/agency/agency-info';

const AdminAgencyViewPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agencyGuid } = useParams<{
    agencyGuid: string;
  }>();

  useEffect(() => {
    if (!agencyGuid || !isValidGuid(agencyGuid)) {
      showResult(t('errors.invalidGuid'));
      navigate('/404', { replace: true });
    }
  }, [agencyGuid, navigate]);
  if (!agencyGuid || !isValidGuid(agencyGuid)) {
    return null;
  }

  const { agencyInfoLoading, agencyInfo } = useAgencyInfo({ agencyGuid });

  const [agency, setAgency] = useState<AgencyInfo>({} as AgencyInfo);

  const onEdit = async () => {
    navigate(Routes.adminAgencyEdit(agencyGuid!));
  };

  const controls = <EditButton width="160px" onClick={onEdit} />;

  useEffect(() => {
    if (agencyInfo) {
      setAgency(agencyInfo);
    }
  }, [agencyInfo]);

  return (
    <BaseSpinWrapper spinning={agencyInfoLoading}>
      <BaseContainer>
        <AdminNavigationHeader
          goBackPath={Routes.adminAgencies}
          title={t('labels.agencyInformation')}
          controls={controls}
        />
        <BaseGrid rowGap={40}>
          <AgencyViewForm info={agency} />
          <AgencyHistoryLogsTable guid={agencyGuid} />
        </BaseGrid>
      </BaseContainer>
    </BaseSpinWrapper>
  );
};

export default AdminAgencyViewPage;
