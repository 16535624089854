import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Col, Row } from 'reactstrap';
import forms from 'constants/forms';
import common from 'constants/common';
import AutoCompleteCareAgent from 'components/atoms/autocomplete';
import AutoCompleteClinic from 'components/molecules/autocompleteFilter/clinic';
import { API_ADMIN_PANEL_AGENCY_DOCTORS } from 'services/dataService/resources';
import useUser from 'utils/useUser';
import { isPractice } from 'utils/userTypeHelper';
import BaseSelect from 'components/atoms/baseSelect';
import { useTranslation } from 'react-i18next';
import { RoleGroups } from 'infrastructure/consts/role-groups';

const DEFAULT_STATUS = forms.ccmInteractionStatuses[0];
const DEFAULT_PATIENT_FILTER = forms.patientFilterOptions[0];
const DEFAULT_CLINIC_FILTER = forms.clinicFilterOptions[0];
const DEFAULT_CCM_STATUS = forms.ccmPatientStatuses[0];
const DEFAULT_CCM_HOLDING_STATUS = forms.ccmHoldingStatuses[0];
const DEFAULT_CHILD_CLINIC = forms.childClinicSelect[0];

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '52px',
  }),
};

const ccmStatusOptions = [
  DEFAULT_CCM_STATUS,
  ...forms.ccmPatientStatuses.filter((status) =>
    ['enrolled', 'ccm_onboarding'].includes(status.value),
  ),
];

const Sorting = ({ setFilters, filters, timeRanges }) => {
  const { userType } = useUser();
  const [dateValue, setDateValue] = useState(timeRanges[0]);
  const [isShowSwitcher, setIsShowSwitcher] = useState(false);

  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 12,
    colors: {
      ...theme.colors,
      ...common.primaryColors,
    },
  });

  const statusValue = (statusName) => {
    const value = forms.statusSelect.find(
      (element) => element.value === statusName,
    );
    return value;
  };

  const ccmStatusChanged = async ({ value }) =>
    setFilters({ ccmStatus: value });

  const ccmInteractionStatusChanged = async ({ value }) =>
    setFilters({ ccmInteractionStatus: value });

  const pcmStatusChanged = async ({ value }) =>
    setFilters({ pcmStatus: value });

  const ccmHoldingStatusChanged = async ({ value }) =>
    setFilters({ ccmHoldingStatus: value });

  const onDateChange = (value) => {
    setDateValue(value);
    setFilters({
      ...filters,
      billingYear: value.value[0],
      billingMonth: value.value[1],
    });
  };

  const { t } = useTranslation();

  const ccmRoleNames = RoleGroups.CCMRoles.map(
    (role) => `roleName=${encodeURIComponent(role)}`,
  ).join('&');

  return (
    <Row>
      <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
        <p className="mb-1" data-cy="report-time-filter-label">
          Report Time Window
        </p>
        <BaseSelect
          options={timeRanges}
          defaultValue={timeRanges[0]}
          value={dateValue}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          onChange={onDateChange}
        />
      </Col>
      <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
        <p className="mb-1" data-cy="ccm-interaction-status-filter-label">
          CCM Interaction Status
        </p>
        <BaseSelect
          options={forms.ccmInteractionStatuses}
          defaultValue={DEFAULT_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          onChange={ccmInteractionStatusChanged}
        />
      </Col>
      <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
        <p className="mb-1" data-cy="pcm-status-filter-label">
          PCM Status
        </p>
        <BaseSelect
          options={forms.ccmInteractionStatuses}
          defaultValue={DEFAULT_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          onChange={pcmStatusChanged}
        />
      </Col>
      <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
        <p className="mb-1" data-cy="ccm-status-filter-label">
          CCM Status
        </p>
        <BaseSelect
          options={ccmStatusOptions}
          defaultValue={DEFAULT_CCM_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          value={statusValue(filters.ccmStatus)}
          onChange={ccmStatusChanged}
        />
      </Col>
      <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
        <p className="mb-1">Clinic</p>
        <AutoCompleteClinic
          filter={DEFAULT_CLINIC_FILTER}
          handleClick={({ value, parentClinic }) => {
            setFilters({ clinicGuid: value, childClinic: parentClinic });
            setIsShowSwitcher(parentClinic);
          }}
        />
      </Col>
      {!isPractice(userType) && (
        <>
          <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
            <p className="mb-1">CCM Onboarding Agent</p>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ ccmOnboardingAgentGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}&roleName=patientAcceptance&${ccmRoleNames}`
              }
              dataCy="ccm-activity-header__care-agent-filter"
            />
          </Col>
          <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
            <p className="mb-1">CCM Case Manager</p>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ ccmCaseManagerGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}&${ccmRoleNames}`
              }
              dataCy="ccm-activity-header__care-agent-filter"
            />
          </Col>
          <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
            <p className="mb-1">CCM PAT Agent</p>
            <AutoCompleteCareAgent
              filter={DEFAULT_PATIENT_FILTER}
              handleClick={({ value }) => {
                setFilters({ ccmPatAgentGuid: value });
              }}
              getApi={(queryString) =>
                `${API_ADMIN_PANEL_AGENCY_DOCTORS}?fullName=${queryString}`
              }
              dataCy="ccm-activity-header__care-agent-filter"
            />
          </Col>
        </>
      )}
      <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
        <p className="mb-1" data-cy="ccm-holding-status-filter-label">
          CCM Temporary On-Hold Status
        </p>
        <BaseSelect
          options={forms.ccmHoldingStatuses}
          defaultValue={DEFAULT_CCM_HOLDING_STATUS}
          theme={(theme) => selectTheme(theme)}
          classNamePrefix="select-type"
          styles={selectStyles}
          onChange={ccmHoldingStatusChanged}
        />
      </Col>

      {isShowSwitcher && (
        <Col xl="3" lg="3" md="6" sm="6" xs="12" className="mb-3">
          <p className="mb-1" data-cy="ccm-holding-status-filter-label">
            {t('labels.childClinics')}
          </p>
          <BaseSelect
            options={forms.childClinicSelect}
            defaultValue={DEFAULT_CHILD_CLINIC}
            theme={(theme) => selectTheme(theme)}
            classNamePrefix="select-type"
            onChange={({ value }) => setFilters({ childClinic: value })}
          />
        </Col>
      )}
    </Row>
  );
};

Sorting.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
  timeRanges: PropTypes.array.isRequired,
};

export default Sorting;
