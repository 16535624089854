import { toFirstUpperCase } from 'infrastructure/functions';

type TData = {
  [k: string]: Record<string, any>;
};

const joinArray = (arr: string[]) => arr?.join(', ');

export const generateSpO2Message = <T extends TData>(data: T) => {
  const { Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9, Q10, Q11 } = data;
  let message = '';

  const relationship = Q1?.relationship
    ? toFirstUpperCase(Q1.relationship)
    : '';
  const speakWith = Q1.speakWithPatient
    ? 'Patient'
    : `${Q1.patient ?? ''}${relationship}`;
  const thirdPersonText = Q1.speakWithPatient ? '' : ' states that patient';
  const speakerStatement = speakWith + thirdPersonText;

  // Q1
  if (Q1) {
    message += `Nurse called patient for a critical oxygen level of ${Q1.reading} on ${Q1.dateOfReading}. `;
    if (Q1.patient) {
      message += `Spoke with ${Q1.patient} and notified them they are on a recorded line for quality and compliance purposes. `;
    }
    if (Q1.speakWithOther) {
      message += `Spoke with ${relationship} and notified them they are on a recorded line for quality and compliance purposes. Name of alternate contact: ${Q1.speakWithOther}, ${relationship}. `;
    }
    message += '\n';
  }

  // Q2
  if (Q2.AOC) {
    message += speakerStatement;
    message += ` is alert and oriented ${Q2.AOC}. `;
    message += '\n';
  }

  // Q3
  if (Q3.last || Q3.next) {
    if (Q3.last && Q3.next) {
      message += speakerStatement;
      message += ` was last seen on ${Q3.last} and has an upcoming appointment on ${Q3.next}. `;
    } else {
      if (Q3.last) {
        message += speakerStatement;
        message += ` was last seen by their provider on ${Q3.last}. `;
      }
      if (Q3.next) {
        message += speakerStatement;
        message += ` next provider appointment is scheduled for ${Q3.next}. `;
      }
    }
    message += '\n';
  }

  // Q4
  if (Q4.isOneMoreReading) {
    message += speakerStatement;
    message += Q4.isOneMoreReading === 'true' ? ' is' : ' is not';
    message += ' able to take another reading at the time of call. ';
  }
  if (Q4.bounds !== null) {
    message += `The repeat reading ${
      Q4.bounds ? 'is' : 'is not'
    } out of bounds. `;
  }
  if (Q4.repeatReadingWas) {
    message += `The repeat reading was: ${Q4.repeatReadingWas}. `;
  }
  message += '\n';

  // Q5
  const allConfirmedSymptoms = [
    ...Q5.confirmedEmergentSymptoms,
    ...Q5.confirmedSymptoms,
  ];

  const isConfirmedSymptomsEmergency =
    Q5.emergencyModalAnswer === true &&
    Q5.confirmedEmergentSymptoms?.length > 0;

  if (isConfirmedSymptomsEmergency) {
    message += speakerStatement;
    message += ` confirms emergent symptoms ${joinArray(
      allConfirmedSymptoms,
    )}. `;
  }

  if (Q5.isAllSymptomsDenied) {
    message += speakWith;
    message += Q1.speakWithPatient
      ? ' denies any'
      : ' states that patient is not having';
    message += ' symptoms at this time. ';
  }

  if (!isConfirmedSymptomsEmergency && !Q5.isAllSymptomsDenied) {
    if (allConfirmedSymptoms?.length > 0) {
      message += speakerStatement;
      message += ` confirms ${joinArray(allConfirmedSymptoms)}. `;
    }
    if (Q5.deniedSymptoms?.length > 0) {
      message += speakerStatement;
      message += ` denies ${joinArray(Q5.deniedSymptoms)}. `;
    }
  }

  if (Q5.symptomsOnset) {
    message += `${Q5.symptomsOnset}. `;
  }
  message += '\n';

  // Q6
  if (Q6.isTakeMedications !== undefined) {
    if (Q6.isTakeMedications === 'true') {
      if (Q6.medications.length === 0) {
        message += speakerStatement;
        message += Q1?.speakWithPatient ? ' confirms taking' : ' took';
        message += ' the following routine medications as prescribed. ';
      } else {
        message += speakerStatement;
        message += Q1?.speakWithPatient ? ' confirms taking' : ' took';
        message += ' the following routine medications: ';
        message += `${joinArray(Q6.medications)}. `;
      }
    }
    if (Q6.isTakeMedications === 'false') {
      message += speakerStatement;
      message += Q1?.speakWithPatient ? ' denies taking' : ' did not take';
      message += ' the following routine medications. ';
    }
    message += '\n';
  }
  // Q7
  if (Q7.isTakePrnMedications !== undefined) {
    if (Q7.isTakePrnMedications === 'true') {
      if (Q7.prnMedications.length === 0) {
        message += speakerStatement;
        message += ' confirms taking the following any PRN medications. ';
      } else {
        message += speakerStatement;
        message += Q1?.speakWithPatient ? ' confirms taking' : ' took';
        message += ' the following any PRN medications: ';
        message += `${joinArray(Q7.prnMedications)}. `;
      }
    }
    if (Q7.isTakePrnMedications === 'false') {
      message += speakerStatement;
      message += Q1?.speakWithPatient ? ' denies taking' : ' did not take';
      message += ' any PRN medications. ';
    }
    message += '\n';
  }

  // Q8
  if (Q8.wearO2Home !== null || Q8.homeO2Setting !== undefined) {
    if (Q8.wearO2Home) {
      message += `${speakerStatement} ${
        Q8.wearO2Home ? 'does' : 'is not'
      } wear oxygen at home. `;
    }
    if (Q8.homeO2Setting) {
      message += `The current setting is ${Q8.homeO2Setting} L/min. `;
    }
    message += '\n';
  }

  // Q9
  if (Q9.confirmedFactors?.length > 0 || Q9.deniedFactors?.length > 0) {
    if (Q9.confirmedFactors?.length > 0) {
      message += `Nurse assessed factors that may impact SpO2 readings. ${speakerStatement} confirms the following: ${joinArray(
        Q9.confirmedFactors,
      )}. `;
    }
    if (Q9.deniedFactors?.length > 0) {
      message += `${speakerStatement} denies the following: ${joinArray(
        Q9.deniedFactors,
      )}. `;
    }
    message += '\n';
  }

  // Q10
  if (Q10.emergencyRoom) {
    message += `${speakerStatement} requires immediate care. Nurse provided emergency assistance to patient. `;
  }
  if (Q10.escalation) {
    message += `${speakerStatement} requires provider intervention. Nurse escalated the reading to the clinic according to run book protocol. `;
  }
  if (Q10.nursingEducation) {
    message += `Nurse provided education to patient. `;
  }
  if (Q10.notEscalated?.length > 0) {
    message += `Reading not escalated due to ${joinArray(Q10.notEscalated)}. `;
  }
  if (Q10.interventionDetails) {
    message += `${Q10.interventionDetails}. `;
  }
  message += '\n';

  // Q11
  if (Q11.verbalized !== null) {
    message += `${speakerStatement} verbalized understanding and ${
      Q11.verbalized ? 'is' : 'is not'
    } agreeable to nursing recommendation.`;
    message += '\n';
  }

  return message.trim();
};
