import {
  DEFAULT_DATE_RANGE,
  useActivityFilter,
  useGetInsuranceTypes,
} from 'infrastructure/hooks/rpm';
import { useEffect, useMemo } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { timePresetKeys } from 'infrastructure/enums';

import { calculateChangedFields } from '../utils/calculate-changed-fields';

import type { PresetKeyEnum } from 'infrastructure/enums';

export type TDashboardFilter = ReturnType<typeof useDashboardFilter>;

export const useDashboardFilter = () => {
  const {
    filter,
    updateQueryParams,
    updateQueryParamsAndResetPage,
    resetFilter,
  } = useActivityFilter();

  const { insuranceTypes, refetchInsuranceType } = useGetInsuranceTypes(
    Boolean(filter.clinicGuid),
  );

  const countOfChangedFilterField = calculateChangedFields(filter);

  const defaultDateValues: [Date, Date] = useMemo(
    () =>
      filter.startTime && filter.endTime
        ? [new Date(filter.startTime), new Date(filter.endTime)]
        : DEFAULT_DATE_RANGE,
    [],
  );

  const dateValues: [Date, Date] | undefined = useMemo(
    () =>
      filter.startTime && filter.endTime
        ? [new Date(filter.startTime), new Date(filter.endTime)]
        : undefined,
    [filter.startTime, filter.endTime],
  );

  const onDateChange = async (
    dates: [Date | null, Date | null],
    dateString: [string, string],
    info: { range?: 'start' | 'end' },
    presetKey?: string,
  ) => {
    const [startDate, endDate] = dates;

    if (startDate === null && endDate === null) {
      updateQueryParamsAndResetPage({
        startTime: undefined,
        endTime: undefined,
      });
      return;
    }

    const isTimePreset =
      presetKey && timePresetKeys.includes(presetKey as PresetKeyEnum);

    if (startDate && info.range === 'start') {
      updateQueryParamsAndResetPage({
        startTime: (isTimePreset ? startDate : startOfDay(startDate)).getTime(),
      });
    }
    if (endDate && info.range === 'end') {
      updateQueryParamsAndResetPage({
        endTime: (isTimePreset ? endDate : endOfDay(endDate)).getTime(),
      });
    }
  };

  const handleChangeValue = async (key: keyof typeof filter, value: string) => {
    if (key === 'clinicGuid') {
      updateQueryParamsAndResetPage({
        insuranceType: undefined,
        doctorGuid: undefined,
        patientGuid: undefined,
        clinicGuid: value,
      });
      refetchInsuranceType();

      return;
    }

    if (key === 'reviewed') {
      updateQueryParamsAndResetPage({ action: Boolean(value) });

      return;
    }

    if (key === 'doctorGuid') {
      updateQueryParamsAndResetPage({
        patientGuid: undefined,
      });
    }

    updateQueryParamsAndResetPage({ [key]: value });
  };

  const onResetFilter = async () => {
    resetFilter();
  };

  useEffect(() => {
    if (!filter.startTime || !filter.endTime) {
      const [startTime, endTime] = defaultDateValues;
      updateQueryParams({
        startTime: startTime.getTime(),
        endTime: endTime.getTime(),
      });
    }
  }, []);

  return {
    onResetFilter,
    values: filter,
    dateValues,
    countOfChangedFilterField,
    insuranceTypes,
    onDateChange,
    handleChangeValue,
  };
};
