import React from 'react';

const ConversationOpenedIcon = ({
  height = '17',
  width = '16',
  color = '#2C2543',
}: {
  height?: string;
  width?: string;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 9C5.22386 9 5 9.22386 5 9.5C5 9.77614 5.22386 10 5.5 10H10.5C10.7761 10 11 9.77614 11 9.5C11 9.22386 10.7761 9 10.5 9H5.5Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 14.5V7V6H1V7V14.5C1 15.8807 2.11929 17 3.5 17H12.5C13.8807 17 15 15.8807 15 14.5ZM2 7V14.5C2 15.3284 2.67157 16 3.5 16H12.5C13.3284 16 14 15.3284 14 14.5V7H2Z"
      fill="#2C2543"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.375C7.76331 6.375 7.57143 6.20711 7.57143 6L7.57143 2.28033L5.73162 3.89016C5.56425 4.03661 5.29289 4.03661 5.12553 3.89016C4.95816 3.74372 4.95816 3.50628 5.12553 3.35983L7.69695 1.10984C7.86432 0.963389 8.13568 0.963389 8.30305 1.10984L10.8745 3.35983C11.0418 3.50628 11.0418 3.74372 10.8745 3.89017C10.7071 4.03661 10.4357 4.03661 10.2684 3.89017L8.42857 2.28033L8.42857 6C8.42857 6.20711 8.23669 6.375 8 6.375Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ConversationOpenedIcon;
