import { useQuery } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { showResult } from 'infrastructure/hooks/utils';
import dataService from 'services/dataService';
import { AgencyDoctor } from 'infrastructure/classes/agency-doctor';

import type { IAgencyDoctor } from 'infrastructure/interfaces';

const api = (agentGuid: string) =>
  `admins/admin-panel/agency-doctors/${agentGuid}`;

interface Props {
  agentGuid?: string;
}

export const getAgencyDoctorInfoKey = (agentGuid: string) => [
  'agencyDoctorInfo',
  agentGuid,
];

export const useAgencyDoctorInfo = ({ agentGuid }: Props) => {
  const { data: agencyDoctorInfo, isLoading } = useQuery({
    queryKey: getAgencyDoctorInfoKey(agentGuid ?? ''),
    queryFn: async () => {
      const { data, error } = await dataService.getOnly<IAgencyDoctor>(
        api(agentGuid!),
      );

      if (error) {
        showResult(error);
        throw new Error(error);
      }

      if (data) {
        return new AgencyDoctor(data);
      }
    },
    staleTime: STALE_TIME,
    enabled: Boolean(agentGuid),
  });

  return {
    agencyDoctorInfo,
    agencyDoctorInfoLoading: isLoading,
  };
};
