import BaseDatePicker from 'components/atoms/datePicker';

import type { IFormSubItemProps } from './types';
import type { FC } from 'react';

const DateItem: FC<IFormSubItemProps> = ({ formik, option }) => {
  const today = new Date();
  const currentValues = formik.values[option.value as string];

  return (
    <div>
      <label>
        <span>{option.label}</span>
      </label>
      <BaseDatePicker
        style={{ width: '100%' }}
        minDate={option.type === 'min' ? today : undefined}
        maxDate={option.type === 'max' ? today : undefined}
        onChange={(value) =>
          formik.setFieldValue(option.value as string, value)
        }
        value={currentValues ? new Date(currentValues) : null}
      />
    </div>
  );
};

export default DateItem;
