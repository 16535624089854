import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseSelectBox from 'components/atoms/base-select-box';

import s from './styles.module.scss';

import type { IBaseSelectBoxProps } from 'components/atoms/base-select-box/types';
import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const InteractionType: React.FC<Props> = ({ appendFilters, filters }) => {
  const { t } = useTranslation();

  const options: IBaseSelectBoxProps['options'] = [
    {
      label: t('labels.interaction'),
      value: 'interaction',
    },
    {
      label: t('labels.noInteraction'),
      value: 'no_interaction',
    },
  ];

  return (
    <div style={{ width: '70%' }}>
      <div className={s['interaction-date-range']}>
        <label htmlFor="daysRangeType">{t('labels.daysRangeType')}</label>
        <div className={s['input-wrapper']}>
          <BaseSelectBox
            defaultValue={options[0]}
            options={options}
            onChange={(interactionFlagType) =>
              appendFilters({ interactionFlagType })
            }
            value={filters.interactionFlagType}
          />
        </div>
      </div>
    </div>
  );
};

export default InteractionType;
