import React from 'react';
import BaseGrid from 'components/atoms/base-grid';
import BaseTextArea from 'components/atoms/base-textarea';
import BaseInput from 'components/atoms/base-input';
import BaseInputNumber from 'components/atoms/base-input-number';
import classNames from 'classnames';
import { Radio } from 'antd';

import s from './styles.module.scss';
import CheckboxItem from './checkboxItem';

import type { IFormItemExtraProps } from './types';

const FormItemExtra: React.FC<IFormItemExtraProps> = (props) => {
  const {
    formik,
    type,
    name: generalName = '',
    options,
    label = '',
    divider,
    formItemName,
  } = props;

  return (
    <div className={s.body}>
      {type === 'checkbox' && options && (
        <div className={s['not-escalated']}>
          {options.map((option) => (
            <CheckboxItem
              option={option}
              formik={formik}
              generalName={generalName}
            />
          ))}
        </div>
      )}
      {type === 'text' && (
        <>
          {label && <label>{label}</label>}
          <BaseTextArea
            rows={6}
            placeholder="Type here details"
            onChange={(value) => formik.setFieldValue(generalName, value)}
            value={formik.values[generalName]}
          />
        </>
      )}
      {type === 'radio' && (
        <>
          {divider ? (
            <div className={s.header}>
              <div className={s.title}>{label}</div>
            </div>
          ) : (
            <label>{label}</label>
          )}
          <Radio.Group
            options={options}
            name={generalName}
            value={formik.values[generalName]}
            className={s.radio}
            onChange={(e) => formik.setFieldValue(generalName, e.target.value)}
          />
        </>
      )}

      {type === 'doubleRowInput' && options && (
        <BaseGrid columns={2} className={s['item-row']}>
          {options.map((option) => (
            <div>
              <label>
                <span>{option.label}</span>
              </label>
              <BaseInput
                onChange={(value) => {
                  formik.setFieldValue(option.value as string, value);
                }}
                value={formik.values[option.value as string]}
                disabled={formik.values[formItemName] !== null}
              />
            </div>
          ))}
        </BaseGrid>
      )}

      {type === 'input' && options && (
        <div className={s['extra-input']}>
          {options.map((option) => (
            <>
              <label>
                <span>{option.label}</span>
              </label>
              <BaseInput
                onChange={(value) => formik.setFieldValue(generalName, value)}
                value={formik.values[option.value as string]}
              />
            </>
          ))}
        </div>
      )}

      {type === 'inputNumber' && options && (
        <div className={s['extra-num']}>
          {options.map((option) => {
            const isShowErrorMessage = Object.keys(formik.errors).includes(
              String(option.value),
            );

            const itemClassNames = classNames({
              [s.error]: isShowErrorMessage,
            });

            return (
              <div className={itemClassNames}>
                <label>
                  <span>{option.label}</span>
                </label>
                <BaseInputNumber
                  placeholder="0.0"
                  onChange={(value) =>
                    formik.setFieldValue(option.value as string, value)
                  }
                  value={formik.values[option.value as string]}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FormItemExtra;
