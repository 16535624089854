import { sortArrayByFieldName } from 'utils/helpers/sort-array-by-field-name';
import { copyObject } from 'infrastructure/functions/copy-object';
import { SortOrder } from 'infrastructure/enums';
import { arrUniqEl, prepareDoctorName } from 'infrastructure/functions';

import type {
  ClinicRevenueChannel,
  ClinicSalesForceIntegration,
  ClinicStatus,
  LoginStatus,
  ProviderServiceType,
  ServiceTypeModels,
  Timezones,
} from 'infrastructure/enums';
import type { IClinic } from 'infrastructure/interfaces';
import type { DateString, Nullable } from 'infrastructure/types';

interface IClinicDoctorInfo {
  name: string;
  status: LoginStatus;
}

const buildDoctorInfo = (
  info: IClinic['clientSuccessScaling'],
): IClinicDoctorInfo | undefined => {
  if (!info) return undefined;
  const name = prepareDoctorName(info);
  return {
    name,
    status: info?.status,
  };
};

export class Clinic {
  guid: string;
  agencyGuid: string;
  parentClinicGuid: Nullable<string>;
  clinicId: Nullable<string>;
  legalname: string;
  email: Nullable<string>;
  phone: Nullable<string>;
  fax: Nullable<string>;
  callerId: Nullable<string>;
  crmId: Nullable<string>;
  addressLine1: Nullable<string>;
  addressLine2: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  zip: Nullable<string>;
  timezone: Nullable<Timezones>;
  npiNumber: Nullable<string>;
  stateIdNumber: Nullable<string>;
  taxIdNumber: Nullable<string>;
  medicareNumber: Nullable<string>;
  status: ClinicStatus;
  notes: Nullable<string>;
  officeHours: Nullable<string>;
  providerServiceType: Array<ProviderServiceType>;
  serviceDeliveryModel: Nullable<ServiceTypeModels>;
  serviceDeliveryThresholds: Nullable<number>;
  referralTypes?: Nullable<string[]>;
  clientSuccessOnboardingGuid: Nullable<string>;
  salesRepresentativeGuid: Nullable<string>;
  clientSuccessScalingGuid: Nullable<string>;
  medicalIdTpiNumber: Nullable<string>;
  ots: Nullable<boolean>;
  otsStartDate: Nullable<DateString>;
  revenueChannels: Nullable<Array<ClinicRevenueChannel>>;
  autoGeneratedClaimDrop: Nullable<boolean>;
  finId: Nullable<string>;
  taxonomyNumber: Nullable<string>;
  criticalBusinessHours: Nullable<string>;
  criticalAfterHours: Nullable<string>;
  afterHours: Nullable<string>;
  businessHours: Nullable<string>;
  contactMethod: Nullable<string>;
  contactMethodValue: Nullable<string>;
  dateNoteLogged: Nullable<DateString>;
  showOnInteractiveTimeDashboard: boolean;
  acceptBingoStatus: boolean;
  adherenceSmsSubscription: boolean;
  doNotCall: boolean;
  withoutParent?: boolean;
  childClinicsGuid: { guid: string }[];
  childClinics: Clinic[];
  agencyName?: string;
  parentClinicName?: string;
  createdAt: string;
  salesForceIntegration: ClinicSalesForceIntegration;

  clinicSuccessOnboardingInfo?: IClinicDoctorInfo;
  clinicSuccessScalingInfo?: IClinicDoctorInfo;
  salesRepresentativeInfo?: IClinicDoctorInfo;

  constructor(data: IClinic) {
    this.parentClinicGuid = data.parentClinicGuid;
    this.legalname = data.legalname;
    this.guid = data.guid;
    this.agencyGuid = data.agencyGuid ?? data.agency?.guid;
    this.parentClinicGuid = data.parentClinicGuid;
    this.clinicId = data.clinicId;
    this.email = data.email;
    this.phone = data.phone;
    this.fax = data.fax;
    this.callerId = data.callerId;
    this.crmId = data.crmId;
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.timezone = data.timezone;
    this.npiNumber = data.npiNumber;
    this.stateIdNumber = data.stateIdNumber;
    this.taxIdNumber = data.taxIdNumber;
    this.medicareNumber = data.medicareNumber;
    this.status = data.status;
    this.notes = data.notes;
    this.officeHours = data.officeHours;
    this.providerServiceType = data.providerServiceType;
    this.serviceDeliveryModel = data.serviceDeliveryModel;
    this.serviceDeliveryThresholds = data.serviceDeliveryThresholds;
    this.parentClinicGuid = data.parentClinicGuid;
    this.referralTypes = data.referralTypes;
    this.clientSuccessOnboardingGuid = data.clientSuccessOnboardingGuid;
    this.salesRepresentativeGuid = data.salesRepresentativeGuid;
    this.clientSuccessScalingGuid = data.clientSuccessScalingGuid;
    this.criticalBusinessHours = data.criticalBusinessHours;
    this.criticalAfterHours = data.criticalAfterHours;
    this.afterHours = data.afterHours;
    this.businessHours = data.businessHours;
    this.contactMethod = data.contactMethod;
    this.contactMethodValue = data.contactMethodValue;
    this.dateNoteLogged = data.dateNoteLogged;
    this.medicalIdTpiNumber = data.medicalIdTpiNumber;
    this.ots = data.ots;
    this.otsStartDate = data.otsStartDate;
    this.revenueChannels = data.revenueChannels;
    this.autoGeneratedClaimDrop = data.autoGeneratedClaimDrop;
    this.finId = data.finId;
    this.taxonomyNumber = data.taxonomyNumber;
    this.showOnInteractiveTimeDashboard = data.showOnInteractiveTimeDashboard;
    this.acceptBingoStatus = data.acceptBingoStatus;
    this.adherenceSmsSubscription = data.adherenceSmsSubscription;
    this.doNotCall = data.doNotCall;
    this.showOnInteractiveTimeDashboard =
      data.showOnInteractiveTimeDashboard ?? true;
    this.childClinicsGuid = data.childClinics?.length ? data.childClinics : [];
    this.childClinics = [];
    this.withoutParent = !data.parentClinicGuid && !data.parentClinic;
    this.agencyName = data.agency?.legalName ?? data.agency?.name;
    this.parentClinicName = data.parentClinic?.legalname;

    this.clinicSuccessOnboardingInfo = buildDoctorInfo(
      data.clientSuccessOnboarding,
    );
    this.clinicSuccessScalingInfo = buildDoctorInfo(data.clientSuccessScaling);
    this.salesRepresentativeInfo = buildDoctorInfo(data.salesRepresentative);

    this.createdAt = data.createdAt;
    this.salesForceIntegration = data.salesForceIntegration;
  }

  get address() {
    let str = '';
    if (this.addressLine1) {
      str += this.addressLine1;
    }
    if (this.addressLine2) {
      str += `, ${this.addressLine2}`;
    }

    return str;
  }

  pushChildClinic(clinic: Clinic) {
    this.childClinics.push(clinic);
  }

  emptyChildClinic() {
    this.childClinics = [];
  }

  static sortByParent(clinics: Clinic[], sortOrder: SortOrder = SortOrder.ASC) {
    const sortedClinics = copyObject(
      sortArrayByFieldName(clinics, 'legalname', sortOrder),
    );

    const clinicMap = new Map(
      sortedClinics.map((clinic) => [clinic.guid, clinic]),
    );

    const flatItems: Clinic[] = [];

    sortedClinics.forEach((clinic) => {
      const withParent =
        clinic.parentClinicGuid &&
        clinics.some((el) => el.guid === clinic.parentClinicGuid);
      if (clinic.childClinicsGuid.length) {
        flatItems.push(clinic);
        clinic.childClinicsGuid.forEach(({ guid }) => {
          const childClinic = clinicMap.get(guid);
          if (childClinic) {
            flatItems.push(childClinic);
          }
        });
      } else if (!clinic.parentClinicGuid || !withParent) {
        clinic.withoutParent = true;
        flatItems.push(clinic);
      }
    });

    return arrUniqEl(flatItems, 'guid');
  }
}
