import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useFormik } from 'formik';
import { useRenameConversation } from 'infrastructure/hooks/smsDashboard/useRenameConversation';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import BaseInput from 'components/atoms/base-input';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import TrashDeleteIcon from 'components/atoms/icons/trashIcon';

export interface IConversationNameEditModalProps {
  isOpen: boolean;
  toggle: () => void;
  name?: string | null;
  conversationId: number;
}

const ConversationNameEditModal: React.FC<IConversationNameEditModalProps> = (
  props,
) => {
  const { isOpen, toggle, name, conversationId } = props;
  const { t } = useTranslation();
  const { renameConversation, renameConversationLoading } =
    useRenameConversation({
      onSuccess: (data) => {
        resetFormikValues();
        toggle();
        showNotificaiton(data.name);
      },
    });

  const modalName =
    name === null || name === ''
      ? t(`smsDashboard.add_name`)
      : t(`smsDashboard.edit_name`);

  const formik = useFormik({
    initialValues: {
      conversationPhone: name || '',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      renameConversation({
        conversationId,
        name: values.conversationPhone as string,
      });
    },
  });
  const { submitForm, setValues, values } = formik;

  const resetFormikValues = (customName?: any) => {
    setValues({
      conversationPhone: customName || '',
    });
  };
  const onDelete = () => {
    resetFormikValues();
    submitForm();
  };

  const onCancel = () => {
    resetFormikValues(name);
    toggle();
  };

  const onSave = () => {
    submitForm();
  };

  const showNotificaiton = (dataName: string | null) => {
    if (dataName === null || dataName === '') {
      notificationController.info({
        message: t('messages.customNameDeleted'),
      });
    }
    if (dataName !== null && dataName !== '') {
      if (name !== null && name !== '') {
        notificationController.success({
          message: t('messages.customNameUpdated'),
        });
      } else {
        notificationController.success({
          message: t('messages.customNameAdded'),
        });
      }
    }
  };

  return (
    <BaseModal
      title={modalName}
      open={isOpen}
      onCancel={onCancel}
      width={552}
      withFooter={[
        name !== null && name !== '' && (
          <>
            <BaseButton
              dataCy="delete-button"
              key="delete"
              type="secondary"
              icon={<TrashDeleteIcon />}
              label={t('controls.delete')}
              onClick={onDelete}
              width={112}
              disabled={renameConversationLoading}
            />
            <div key="space" style={{ width: '120px' }} />
          </>
        ),

        <BaseButton
          dataCy="cancel-button"
          key="cancel"
          type="secondary"
          label={t('controls.cancel')}
          width={112}
          onClick={onCancel}
          disabled={renameConversationLoading}
        />,
        <BaseButton
          dataCy="save-button"
          key="save"
          type="primary"
          label={t('controls.save')}
          loading={renameConversationLoading}
          width={112}
          onClick={onSave}
          disabled={
            values.conversationPhone === null || values.conversationPhone === ''
          }
        />,
      ]}
    >
      <BaseSpinWrapper spinning={renameConversationLoading}>
        <BaseForm
          loading={renameConversationLoading}
          formik={formik}
          plaintext={false}
          readonly={false}
          onSave={submitForm}
        >
          <BaseFormItem name="conversationPhone" label={t('labels.name')}>
            <BaseInput />
          </BaseFormItem>
        </BaseForm>
      </BaseSpinWrapper>
    </BaseModal>
  );
};

export default ConversationNameEditModal;
