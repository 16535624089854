import { useTranslation } from 'react-i18next';
import { interactiveTimeGroupsOptions } from 'infrastructure/services/interactiveTime';

import BaseSelect from './base';

import type { Option } from './base';
import type { FC } from 'react';

interface Props {
  value?: Option[];
  showSelectAll?: boolean;
  width?: string;
  onChange: (e: Option[]) => void;
}

const InteractiveTimeGroupSelect: FC<Props> = ({
  onChange,
  value,
  width,
  showSelectAll,
}) => {
  const { t } = useTranslation();

  return (
    <BaseSelect
      width={width}
      label={t('labels.interactiveTimeGroup')}
      placeholder={t('labels.all')}
      mode="multiple"
      showSelectAll={showSelectAll}
      options={interactiveTimeGroupsOptions}
      value={value}
      onChange={(newValues) => {
        const items = Array.isArray(newValues) ? newValues : [newValues];
        onChange(items);
      }}
    />
  );
};

export default InteractiveTimeGroupSelect;
