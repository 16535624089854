import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import withLoader from 'components/organisms/withLoader';
import SortableColumn from 'components/atoms/sortableColumn';

import PatientRow from './patientRow';
import Sorting from './sorting';

const Patients = ({ patients, count }) => {
  const {
    onboardingStatus: { setReferralDateSort, getPatientsReport },
  } = useDispatch();

  const { getPatientsReport: patientsLoading } = useSelector(
    (state) => state.loading.effects.onboardingStatus,
  );
  const { referralDateSort } = useSelector((state) => state.onboardingStatus);

  const setFilter = async (sort) => {
    setReferralDateSort(sort);
    getPatientsReport();
  };
  return (
    <div className="onboarding-table">
      <Card className="card-border">
        <CardBody>
          <div className="onboarding-body">
            <div className="sort-row">
              <Sorting count={count} data={patients} />
            </div>
            {withLoader(
              patientsLoading,
              <div className="table-outer">
                <table>
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>MRN</th>
                      <th>DOB</th>
                      <th>Phone Number</th>
                      <th>Patient State</th>
                      <th>Clinic Name</th>
                      <th>Provider</th>
                      <th>Provider Email</th>
                      <th>Insurance Type</th>
                      <th>Insurance Provider</th>
                      <th>Insurance Number</th>
                      <th>Fulfillment Stage</th>
                      <th>
                        <SortableColumn
                          title="Patient Referral Date"
                          handler={(sort) => setFilter(sort)}
                          sort={referralDateSort}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {patients.map((patientRow, index) => (
                      <PatientRow key={index} patient={patientRow} />
                    ))}
                  </tbody>
                </table>
              </div>,
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

Patients.propTypes = {
  patients: PropTypes.array,
  count: PropTypes.number,
};

export default Patients;
