import React from 'react';
import BaseDatePicker from 'components/atoms/datePicker';

interface Value {
  year: number;
  month: number;
}

interface Props {
  value: Value;
  onChange: (value: Value | null) => void;
}

const MonthPicker: React.FC<Props> = ({ value, onChange }) => {
  return (
    <BaseDatePicker
      style={{ width: '100%' }}
      value={new Date(value.year, value.month - 1)}
      onChange={(newValue) => {
        if (!newValue) {
          onChange(null);
          return;
        }
        const date = newValue;
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        onChange({ year, month });
      }}
      picker="month"
    />
  );
};

export default MonthPicker;
