import { useTranslation } from 'react-i18next';
import { type FC } from 'react';
import useUser from 'utils/useUser';
import { isAgencyDoctor } from 'utils/userTypeHelper';

import BaseSelect from '../select/base';

import type { Option } from './base';
import type { AppendFilters } from 'infrastructure/hooks/ccmAgentDashboard/types';

interface Props {
  value?: Option[];
  appendFilters: AppendFilters;
  label?: string;
  width?: string;
}

const CompletedByAutocomplete: FC<Props> = ({
  appendFilters,
  value,
  label,
  width,
}) => {
  const { t } = useTranslation();

  const { guid: userGuid, userType } = useUser();

  const defaultOptions = [
    {
      id: 'all',
      value: 'all',
      label: t('labels.all'),
    },
    {
      id: 'empty',
      value: 'empty',
      label: t('labels.empty'),
    },
  ];

  if (isAgencyDoctor(userType)) {
    defaultOptions.splice(1, 0, {
      id: userGuid,
      value: userGuid,
      label: t('labels.me'),
    });
  }

  const handleOnChange = (options: Option | Option[]) => {
    const currentMonthAppointmentCompletedByDoctors: Option[] = [];
    const currentMonthAppointmentCompletedByAgencyDoctors: Option[] = [];

    let items = Array.isArray(options) ? options : [options];
    const isAllSelected = items.some((item) => item.value === 'all');
    if (isAllSelected) {
      appendFilters({
        currentMonthAppointmentCompletedByDoctors: [],
        currentMonthAppointmentCompletedByAgencyDoctors: [],
      });
      return;
    }

    if (items.at(0)?.value === 'empty' && items.length > 1) {
      items = items.filter((item) => item.value !== 'empty');
    }

    const emptyValue = items.find((item) => item.value === 'empty');
    if (emptyValue) {
      appendFilters({
        currentMonthAppointmentCompletedByDoctors: [],
        currentMonthAppointmentCompletedByAgencyDoctors: [emptyValue],
      });
      return;
    }

    if (items.at(0)?.value === userGuid) {
      currentMonthAppointmentCompletedByAgencyDoctors.push(items[0]);
    }

    appendFilters({
      currentMonthAppointmentCompletedByDoctors,
      currentMonthAppointmentCompletedByAgencyDoctors,
    });
  };

  return (
    <BaseSelect
      width={width}
      label={label || t('labels.currentMonthAppointmentCompletedBy')}
      placeholder={t('labels.all')}
      mode="multiple"
      value={value}
      onChange={handleOnChange}
      options={defaultOptions}
    />
  );
};

export default CompletedByAutocomplete;
