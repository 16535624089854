import React from 'react';
import classNames from 'classnames';
import formatDate from 'utils/hooks/shared/formatDate';
import common from 'constants/common';
import {
  PlatformCodeEnums,
  PlatformEnums,
} from 'infrastructure/enums/platformEnums';
import {
  ConversationActionEnums,
  MessageStatusEnums,
  MessageStatusReadEnums,
} from 'infrastructure/enums/messageStatusEnums';
import { Image } from 'antd';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import JumpToMessageIcon from 'components/atoms/icons/jump-to-message.svg?react';
import BaseIconButton from 'components/atoms/base-icon-button';
import SpamIcon from 'components/atoms/icons/spamIcon';
import NotSpamIcon from 'components/atoms/icons/notSpamIcon';
import ConversationClosedIcon from 'components/atoms/icons/conversationClosed';
import ConversationOpenedIcon from 'components/atoms/icons/conversationOpened';

import s from './styles.module.scss';

import type { ConversationType } from 'infrastructure/interfaces';

interface IMessageProps {
  left: boolean;
  createdAt: Date;
  body: string;
  action: string;
  authorType: string;
  authorName?: string;
  status: string;
  to: string;
  from: string;
  conversationType: ConversationType;
  mediaUrl: string | null;
  readBy: string | null;
  readAt: Date | null;
  handleJumpToComment: () => void;
  messageId: string;
  isShowJumpToMsgBtn: boolean;
  searchValue: string;
}

const formatMessageStatus = (status: string) => {
  if (status === MessageStatusEnums.delivered) {
    return MessageStatusReadEnums.read;
  }
  if (status === MessageStatusEnums.sent) {
    return MessageStatusReadEnums.unread;
  }
  return status;
};

const Message: React.FC<IMessageProps> = (props) => {
  const {
    left,
    createdAt,
    body,
    authorType,
    status,
    to,
    from,
    conversationType,
    authorName,
    action,
    mediaUrl,
    readBy,
    readAt,
    handleJumpToComment,
    messageId,
    isShowJumpToMsgBtn,
    searchValue,
  } = props;

  const formattedDate = formatDate(
    createdAt,
    common.dateFormats.hoursMinutesPM,
  );
  const formattedFullDate = formatDate(
    createdAt,
    common.dateFormats.fullDateUSTimeAMPM,
  );
  const messageStatus = formatMessageStatus(status);

  const messageStatusBadgeClassName = classNames(
    s['message-status-badge'],
    status === MessageStatusEnums.delivered && s.read,
    status === MessageStatusEnums.sent && s.unread,
  );

  const getMessageType = (id: string) => {
    if (id === PlatformCodeEnums.RPM) {
      return PlatformEnums.RPM;
    }
    if (id === PlatformCodeEnums.CCM) {
      return PlatformEnums.CCM;
    }
  };

  const messageType = left ? getMessageType(to) : getMessageType(from);

  const actionIcons = {
    [ConversationActionEnums.closed]: <ConversationClosedIcon />,
    [ConversationActionEnums.opened]: <ConversationOpenedIcon />,
    [ConversationActionEnums.spam]: <SpamIcon />,
    [ConversationActionEnums.not_spam]: <NotSpamIcon />,
  };

  const messageClassNames = classNames(
    s['message-item'],
    left ? s.left : s.right,
  );

  function highlightText(text: string, search: string) {
    if (!search) return text;

    const parts = text.split(new RegExp(`(${search})`, 'gi'));

    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      ),
    );
  }

  const actionTypeNotSms = (
    <div className={messageClassNames} id={messageId}>
      <div className={`${s['message-text']} ${s.action}`}>
        <div className={s['message-inner-body']}>
          <div>{actionIcons[action as keyof typeof actionIcons]}</div>
          <p className={s.body}>{body}</p>
          <p className={s.gray}>{formattedDate}</p>
        </div>
        <p className={s.gray}>{authorName}</p>
      </div>
      <div
        className={s['message-footer']}
        title={`${messageStatus} | ${formattedFullDate}`}
      >
        {authorType !== 'patient' ? (
          <div className={messageStatusBadgeClassName} />
        ) : null}
      </div>
    </div>
  );

  return action === ConversationActionEnums.sms ? (
    <div className={messageClassNames} id={messageId}>
      <div className={s['message-text']}>
        {isShowJumpToMsgBtn && (
          <BaseIconButton
            className={s['jump-to-comment-btn']}
            onClick={handleJumpToComment}
            icon={<JumpToMessageIcon width={12} height={12} />}
          />
        )}
        <div className={s['message-time']}>
          <p className={s.author}>{authorName}</p>
          <div className={s['time-and-type-block']}>
            <p className={s.gray}>{formattedDate}</p>
            {conversationType === PlatformEnums.UNREGISTERED && (
              <span className={s['message-type']}> {messageType}</span>
            )}
          </div>
        </div>
        <div>{mediaUrl && <Image width={40} src={mediaUrl} />}</div>
        <p className={s['message-body']}>{highlightText(body, searchValue)}</p>
        <div
          className={s['message-footer']}
          title={`${messageStatus} | ${formattedFullDate}`}
        >
          {authorType === 'patient' ? (
            <>
              {readBy} -{' '}
              {DateFormatter({
                date: readAt ?? '',
                format: DateFormats['MM-dd-yyyy | h:mm a'],
              })}
              <div className={messageStatusBadgeClassName} />
            </>
          ) : (
            <div className={messageStatusBadgeClassName} />
          )}
        </div>
      </div>
    </div>
  ) : (
    actionTypeNotSms
  );
};

export default Message;
