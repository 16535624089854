import React from 'react';

const ConversationClosedIcon = ({
  height = '16',
  width = '16',
  color = '#2C2543',
}: {
  height?: string;
  width?: string;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 1.44771 0.447716 1 1 1H15C15.5523 1 16 1.44772 16 2V4C16 4.55228 15.5523 5 15 5V12.5C15 13.8807 13.8807 15 12.5 15H3.5C2.11929 15 1 13.8807 1 12.5V5C0.447715 5 0 4.55228 0 4V2ZM2 5V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5H2ZM15 2H1V4H15V2ZM5 7.5C5 7.22386 5.22386 7 5.5 7H10.5C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7761 8 10.5 8H5.5C5.22386 8 5 7.77614 5 7.5Z"
      fill={color}
    />
  </svg>
);

export default ConversationClosedIcon;
