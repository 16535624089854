import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';
import { useFormik } from 'formik';
import PatientBlock from 'components/molecules/patientBlock';
import { useTranslation } from 'react-i18next';
import BaseSwitch from 'components/atoms/base-switch';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { ModuleType } from 'infrastructure/enums';
import { useEdit } from 'infrastructure/providers/edit-provider';
import useUser from 'utils/useUser';
import { isMobile } from 'utils/deviceHelper';
import SalesForceIntegrationSelectBox from 'components/molecules/salesforce-integration-select-box';
import BaseInput from 'components/atoms/base-input';

import s from '../account-info/styles.module.scss';

import type { Patient } from 'infrastructure/classes/patient/patient';

const formKey = 'AdminPanelAccountHubForm';

interface IAdminPanelAccountHubFormProps {
  data: Patient;
  module: ModuleType;
  loading?: boolean;
}

const AdminPanelAccountHubForm: React.FC<IAdminPanelAccountHubFormProps> = (
  props,
) => {
  const { data, module, loading: loadingProp = false } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isAdmin = module === ModuleType.ADMIN;

  const { canEdit, setEdit } = useEdit();
  const { t } = useTranslation();

  const { loading, updatePatientInfo } = usePatientInfo({
    patientGuid: data.guid,
  });

  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      await updatePatientInfo(values).then(() => {
        formik.resetForm({ values });
      });
      setIsEdit(false);
      setEdit(false);
    },
    validateOnChange: false,
  });

  const onEdit = () => {
    setIsEdit(true);
    setEdit(true, formKey);
  };
  const onSave = () => {
    formik.submitForm();
  };
  const onCancel = () => {
    formik.resetForm();
    setIsEdit(false);
    setEdit(false);
  };

  const { deviceType, iamPatientAcceptance } = useUser();

  useEffect(() => {
    formik.resetForm({ values: data });
  }, [data]);

  return (
    <PatientBlock
      title={t('labels.accountsHub')}
      canEdit={isAdmin}
      isEdit={isEdit}
      onEdit={onEdit}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading || loadingProp}
      disabled={!canEdit(formKey) || iamPatientAcceptance}
    >
      <BaseForm formik={formik} plaintext={!isEdit} readonly={!isEdit}>
        <BaseFormItem
          name="loginInfo.auth0Enabled"
          label={t('labels.evelynAccount')}
          labelDirection="left"
        >
          <BaseSwitch />
        </BaseFormItem>
        <BaseFormItem
          name="salesForceIntegration"
          label={t('labels.salesForceIntegration')}
          className={classNames({ [s['input-wrapper']]: !isEdit })}
          showErrorBlock={isMobile(deviceType) ? isEdit : true}
        >
          <SalesForceIntegrationSelectBox />
        </BaseFormItem>
        <BaseFormItem
          name="slhId"
          label={t('labels.slhId')}
          className={classNames({ [s['input-wrapper']]: !isEdit })}
          showErrorBlock={isMobile(deviceType) ? isEdit : true}
        >
          <BaseInput />
        </BaseFormItem>
      </BaseForm>
    </PatientBlock>
  );
};

export default AdminPanelAccountHubForm;
