import { makeid } from 'utils/helpers/makeid';
import CallControlIcon from 'components/atoms/icons/callControlIcon';
import SubscriptionControlIcon from 'components/atoms/icons/subscriptionControlIcon';
import EHRControlIcon from 'components/atoms/icons/ehrControlIcon';
import { useAppSelector } from 'store';
import { isMobile } from 'utils/deviceHelper';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useState } from 'react';
import {
  useEmailSubscriptionStatus,
  useResetPassword,
  useUpdateInteractionStatus,
} from 'infrastructure/hooks/control-panel';
import useUser from 'utils/useUser';
import { isPatientAcceptance } from 'utils/userTypeHelper';
import FalseMonthlyInteractionIcon from 'components/atoms/icons/falseMontlyInteractionIcon';
import { CcmStatuses } from 'infrastructure/enums';
import { Routes } from 'infrastructure/consts/routes';
import { useTranslation } from 'react-i18next';
import { useUpdateBingoCardStatus } from 'infrastructure/hooks/control-panel/use-update-bingo-card-status';

interface Props {
  patientGuid: string;
  onIafFormOpen?: () => void;
  iafFormLoading?: boolean;
}

export const useProfileActionButtons = ({
  patientGuid,
  onIafFormOpen,
  iafFormLoading,
}: Props) => {
  const { t } = useTranslation();

  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openCcmInteractionModal, setOpenCcmInteractionModal] = useState(false);
  const [openIafModal, setOpenIafModal] = useState(false);
  const device = useAppSelector((state) => state.user.deviceType);
  const { updateInteractionStatus, updateInteractionStatusLoading } =
    useUpdateInteractionStatus();
  const { updateBingoCardStatus, updateBingoCardStatusLoading } =
    useUpdateBingoCardStatus();
  const { patientInfo } = usePatientInfo({ patientGuid });
  const { updateSubscriptionStatus, subscriptionStatusLoading } =
    useEmailSubscriptionStatus({
      patientGuid,
      onSuccess() {
        setOpenSubscriptionModal(false);
      },
    });
  const { resetPassword, resetPassLoading } = useResetPassword();
  const { togglePatientCcmInteractionStatus: updateLoading } = useAppSelector(
    (state) => state.loading.effects.adminPanelPatients,
  );
  const { roleNames, iamPatientAcceptance } = useUser();

  const interactionStatus = patientInfo?.interactionStatus;
  const ccmInteractionStatus = patientInfo?.ccmInteractionStatus;
  const acceptBingoStatus = patientInfo?.acceptBingoStatus;
  const ccmConsentFormStatus = patientInfo?.ccmConsentFormStatus;
  const emailSubscriptionStatus = patientInfo?.emailSubscription;
  const disabledCreateInternalAdmissionForms =
    patientInfo?.disabledCreateInternalAdmissionForms;
  const ccmStatus = patientInfo?.ccmStatus;

  const profileActionButtons = [
    {
      key: makeid(5),
      label: t('controls.monthlyInteraction'),
      Icon: interactionStatus ? CallControlIcon : FalseMonthlyInteractionIcon,
      status: interactionStatus ? t('controls.yes') : t('controls.no'),
      loading: updateInteractionStatusLoading,
      handleClick: () => {
        updateInteractionStatus({
          patientGuid,
          isInteracted: !interactionStatus,
        });
      },
      disabled: interactionStatus,
      render: !isMobile(device) && !isPatientAcceptance(roleNames),
      extraAccent: !interactionStatus,
    },
    {
      key: makeid(5),
      label: t('labels.bingoCardOpt'),
      Icon: SubscriptionControlIcon,
      status: acceptBingoStatus
        ? t('controls.optedIn')
        : t('controls.optedOut'),
      loading: updateBingoCardStatusLoading,
      handleClick: () => {
        updateBingoCardStatus({
          patientGuid,
          acceptBingoStatus: !acceptBingoStatus,
        });
      },
      disabled: iamPatientAcceptance,
      render: true,
    },
    {
      key: makeid(5),
      label: t('controls.consentForm'),
      Icon: CallControlIcon,
      status: ccmConsentFormStatus
        ? t('controls.confirmed')
        : t('controls.unconfirmed'),
      loading: false,
      handleClick: () => {
        const canPressNo = ccmStatus !== CcmStatuses.Enrolled;
        const newHref = Routes.consentForm(patientGuid);
        window.history.pushState({ canPressNo }, '', newHref);
        window.location.href = newHref;
      },
      disabled: false,
      render: true,
    },
    {
      key: makeid(5),
      label: t('controls.resetPassword'),
      Icon: CallControlIcon,
      loading: resetPassLoading,
      handleClick: () => {
        if (patientInfo?.loginInfo?.email)
          resetPassword({ email: patientInfo.loginInfo.email });
      },
      disabled: iamPatientAcceptance,
      render: true,
    },
    {
      key: makeid(5),
      label: t('controls.emailSubscription'),
      Icon: SubscriptionControlIcon,
      status: emailSubscriptionStatus
        ? t('controls.subscribed')
        : t('controls.unsubscribed'),
      loading: subscriptionStatusLoading,
      handleClick: () => {
        setOpenSubscriptionModal(true);
      },
      disabled: emailSubscriptionStatus || iamPatientAcceptance,
      render: true,
    },
    {
      key: makeid(5),
      label: t('controls.followUpAppointmentComplete'),
      Icon: ccmInteractionStatus
        ? CallControlIcon
        : FalseMonthlyInteractionIcon,
      status: ccmInteractionStatus ? t('controls.yes') : t('controls.no'),
      loading: updateLoading,
      handleClick: () => {
        setOpenCcmInteractionModal(true);
      },
      disabled: ccmInteractionStatus || iamPatientAcceptance,
      render: !isMobile(device),
      extraAccent: !ccmInteractionStatus,
    },
    {
      key: makeid(5),
      label: t('controls.iafForm'),
      Icon: EHRControlIcon,
      loading: iafFormLoading,
      handleClick: () => {
        setOpenIafModal(true);
        onIafFormOpen?.();
      },
      disabled: disabledCreateInternalAdmissionForms,
      render: !isMobile(device),
    },
  ].filter(({ render }) => render);

  return {
    profileActionButtons,
    interactionStatus,
    openSubscriptionModal,
    subscriptionStatusLoading,
    openCcmInteractionModal,
    openIafModal,
    updateSubscriptionStatus,
    setOpenSubscriptionModal,
    setOpenCcmInteractionModal,
    setOpenIafModal,
  };
};
