import {
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';
import { useQueryFilterNew } from 'infrastructure/hooks/common/use-query-param-new';

import type { TParseFunctions } from 'infrastructure/hooks/common/use-query-param-new';

export type TUsersFilter = {
  page: number;
  doctorKey: string;
  role?: string;
  status?: string;
  guid?: string;
  doctorLabel?: string;
  clinicGuid?: string;
  clinicLabel?: string;
};

const defaultFilter: TUsersFilter = {
  page: 1,
  doctorKey: 'fullName',
};

const parseFunctions: TParseFunctions<TUsersFilter> = {
  page: parseSearchNumberValue,
  role: parseSearchStringValue,
  status: parseSearchStringValue,
  guid: parseSearchStringValue,
  clinicGuid: parseSearchStringValue,
  doctorLabel: parseSearchStringValue,
  clinicLabel: parseSearchStringValue,
  doctorKey: parseSearchStringValue,
};

export const useClinicUsersFilter = () => {
  return useQueryFilterNew<TUsersFilter>(defaultFilter, parseFunctions, [
    'page',
    'doctorLabel',
    'doctorKey',
    'clinicLabel',
  ]);
};
