import { ccmPageActionAllowedStatues } from 'constants/patient_statuses';
import { CCMStatusGroups } from 'infrastructure/consts/ccm-status-groups';
import { RoleGroups } from 'infrastructure/consts/role-groups';
import { RpmStatusGroups } from 'infrastructure/consts/rpm-status-groups';
import { Roles, UserType } from 'infrastructure/enums';

import type { CcmStatuses, RpmStatuses } from 'infrastructure/enums';

const isPatient = (userType: UserType) => userType === UserType.Patient;
const isPractice = (userType: UserType) => userType === UserType.Practice;
const isAdmin = (userType: UserType) => userType === UserType.Admin;
const isAgencyDoctor = (userType: UserType) => userType === UserType.HomeHealth;

const isRoleDefault = (roleNames: Roles[]) => roleNames.includes(Roles.Default);

const isRoleNameAdministrator = (roleNames: Roles[]) =>
  roleNames.includes(Roles.AdminPanelAccess);

const isRoleNameAdminTools = (roleNames: Roles[]) =>
  roleNames.includes(Roles.AdminTools);

const isRoleNameBillingTools = (roleNames: Roles[]) =>
  roleNames.includes(Roles.BillingTools);

const isOnboardingPatient = (
  onboardingStatuses: RpmStatuses[],
  status: RpmStatuses,
) => onboardingStatuses.includes(status);

const isCcmAgencyDoctor = (userType: UserType, roleNames: Roles[]) =>
  isAgencyDoctor(userType) &&
  roleNames.some((roleName) => roleName.startsWith('ccm-'));

const agencyDoctorWithRPMAndCCMRoles = (
  userType: UserType,
  roleNames: Roles[],
) =>
  isAgencyDoctor(userType) &&
  roleNames.some(
    (roleName) =>
      !roleName.includes('ccm-') && !roleName.includes(Roles.AdminPanelAccess),
  ) &&
  roleNames.some((roleName) => roleName.includes('ccm-'));

const isBiller = (roleNames: Roles[]) =>
  roleNames?.every(
    (roleName) => roleName === Roles.Biller || roleName === Roles.CcmBiller,
  );

const isPatientAcceptance = (roleNames: Roles[]) =>
  roleNames.includes(Roles.PatientAcceptance);

const isCcmActionAllowedStatus = (ccmStatus: CcmStatuses) =>
  ccmPageActionAllowedStatues.includes(ccmStatus);

const hasClinicalRole = (roleNames: Roles[]) =>
  roleNames.some((roleName) => RoleGroups.ClinicalRoles.includes(roleName));

const isReadOnlyRole = (roleNames: Roles[]) => {
  if (!roleNames || !roleNames.length) {
    return false;
  }
  return roleNames.every((role) => RoleGroups.ReadOnlyRoles.includes(role));
};

const isTurkUserRole = (roleNames: Roles[]) => roleNames?.includes(Roles.Turk);

const isNutritionist = (roleNames: Roles[]) =>
  roleNames.includes(Roles.Nutritionist);

const isSmsDashboardOrUnregisteredRole = (roleNames: Roles[]) => {
  if (!roleNames || !roleNames.length) {
    return false;
  }

  return RoleGroups.SmsDashboardRoles.some((role) => roleNames.includes(role));
};

const isSmsDashboardUnregisteredRole = (roleNames: Roles[]) => {
  if (!roleNames || !roleNames.length) {
    return false;
  }
  return roleNames.some((role) => role === Roles.SmsDashboardUnregistered);
};

const isRoleNameOnboarding = (roleNames: Roles[]) =>
  roleNames.includes(Roles.Onboarding);

const hasPatientProfileRpm = (rpmStatus: RpmStatuses) =>
  RpmStatusGroups.PatientProfile.includes(rpmStatus);

const hasPatientProfileCcm = (ccmStatus: CcmStatuses) =>
  CCMStatusGroups.PatientProfile.includes(ccmStatus);

export {
  isPatient,
  isPractice,
  isAdmin,
  isAgencyDoctor,
  isRoleDefault,
  isRoleNameAdministrator,
  isRoleNameAdminTools,
  isRoleNameBillingTools,
  isOnboardingPatient,
  isCcmAgencyDoctor,
  agencyDoctorWithRPMAndCCMRoles,
  isBiller,
  isPatientAcceptance,
  isCcmActionAllowedStatus,
  hasClinicalRole,
  isReadOnlyRole,
  isTurkUserRole,
  isNutritionist,
  isSmsDashboardOrUnregisteredRole,
  isSmsDashboardUnregisteredRole,
  isRoleNameOnboarding,
  hasPatientProfileRpm,
  hasPatientProfileCcm,
};
