import i18next from 'i18next';
import { ReadingType } from 'infrastructure/enums';

export interface IReadingTypeItem {
  id: ReadingType | null;
  name: string;
}

export const readingTypesDataSource = (withAll = false): IReadingTypeItem[] => {
  const dataSource: IReadingTypeItem[] = [
    {
      id: ReadingType.BloodPressure,
      name: i18next.t('readingTypes.BloodPressure'),
    },
    {
      id: ReadingType.BloodGlucose,
      name: i18next.t('readingTypes.BloodGlucose'),
    },
    {
      id: ReadingType.Weight,
      name: i18next.t('readingTypes.Weight'),
    },
    {
      id: ReadingType.SpO2,
      name: i18next.t('readingTypes.SpO2'),
    },
    {
      id: ReadingType.Temperature,
      name: i18next.t('readingTypes.Temperature'),
    },
    {
      id: ReadingType.SleepingMat,
      name: i18next.t('readingTypes.SleepingMat'),
    },
    {
      id: ReadingType.PeakFlow,
      name: i18next.t('readingTypes.PeakFlow'),
    },
  ];

  if (withAll) {
    dataSource.unshift({
      id: null,
      name: i18next.t('readingTypes.All'),
    });
  }
  return dataSource;
};
