import BaseTableNew from 'components/atoms/base-table/new';
import {
  HISTORY_PER_PAGE,
  useGetHistoryLogs,
} from 'infrastructure/hooks/doctor';
import { useState, type FC } from 'react';
import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import { endOfDate, startOfDate } from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';

import { columns } from './columns';
import s from './styles.module.scss';

import type { DateRange } from 'infrastructure/types';

interface DoctorHistoryLogsTableProps {
  guid: string;
}

const DoctorHistoryLogsTable: FC<DoctorHistoryLogsTableProps> = ({ guid }) => {
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const { historyLogs, isLoading } = useGetHistoryLogs({
    guid,
    dateRange,
    meta: {
      items: HISTORY_PER_PAGE,
      page,
    },
  });

  const changeTimeRange = async (range?: DateRange) => {
    let parsedRange: [Date, Date] | undefined;
    if (range) {
      parsedRange = [startOfDate(range[0]), endOfDate(range[1])];
    }
    setDateRange(parsedRange);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <h3 className={s.title}>{t('labels.historyLog')}</h3>
        <div className={s.controls}>
          <p>{t('labels.timeWindow')}</p>
          <BaseRangeDatepicker
            withPresets
            onChange={(dates) => {
              changeTimeRange(dates);
            }}
          />
        </div>
      </div>

      <BaseTableNew
        dataCy="doctor-history-log-table"
        rowKey="key"
        columns={columns}
        items={historyLogs?.data ?? []}
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: HISTORY_PER_PAGE,
          total: historyLogs?.count ?? 0,
          position: ['bottomCenter'],
        }}
        onChangePage={(newPage) => setPage(newPage)}
        bordered
      />
    </div>
  );
};

export default DoctorHistoryLogsTable;
