import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useSelector } from 'react-redux';

import { getSmsDashboardMessagesKey } from './useMessages';
import { showResult } from '../utils';
import { getSmsDashboardConversationsKey } from './useConversations';

import type { RootState } from 'store';
import type {
  IConversation,
  IConversationsListPages,
  IMessagesListPages,
} from 'infrastructure/interfaces';

interface Props {
  onSuccess: () => void;
}
interface FnProps {
  conversationId: number;
  message: string;
  from: string;
  patientPhone: string;
}

const sendMessageFn = async ({
  conversationId,
  message,
  from,
  patientPhone,
}: FnProps) => {
  const API_CREATE_MESSAGES = `core/conversations/messages`;

  const { data, error } = await dataService.createOne(API_CREATE_MESSAGES, {
    patientPhone,
    message,
    from,
    conversationId,
  });

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useSendMessage = ({ onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const { conversationType, filters } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: sendMessageFn,
    onSuccess(data, variables) {
      const messagesKey = getSmsDashboardMessagesKey({
        conversationId: variables.conversationId,
        nextCursor: undefined,
        searchQuery: undefined,
      });
      const conversationKey = getSmsDashboardConversationsKey({
        type: conversationType,
        read: filters.read,
        closed: filters.closed,
        lastMessageDate: filters.lastMessageDate,
        searchQuery: filters.searchQuery,
      });

      const conversations =
        queryClient.getQueryData<IConversationsListPages>(conversationKey);

      const pages = conversations?.pages ?? [];
      const [firstPage, ...restPages] = pages;

      let foundObject: IConversation | undefined;

      pages.forEach((page) => {
        const index = page.items.findIndex(
          (item: IConversation) => item.id === variables.conversationId,
        );
        if (index !== -1) {
          [foundObject] = page.items.splice(index, 1);
        }
      });

      if (foundObject) {
        firstPage.items = [
          {
            ...foundObject,
            lastMessage: variables.message,
            lastMessageDate: data.updatedAt,
          },
          ...firstPage.items,
        ];
      }

      const updatedPages = [firstPage, ...restPages];

      queryClient.setQueryData<IConversationsListPages>(
        conversationKey,
        (oldConversations) => {
          if (!oldConversations) return;
          return { ...oldConversations, pages: updatedPages };
        },
      );
      onSuccess();
      queryClient.setQueryData<IMessagesListPages>(
        messagesKey,
        (oldMessages) => {
          if (!oldMessages) return;
          return {
            ...oldMessages,
            pages: [
              {
                ...oldMessages.pages[0],
                items: [
                  {
                    ...oldMessages.pages[0].items[0],
                    ...data.message,
                    messageId: data.message.id,
                    updatedAt: new Date(data.message.createdAt),
                    createdAt: new Date(data.message.createdAt),
                    body: data.message.body,
                  },
                  ...oldMessages.pages[0].items,
                ],
              },
              ...oldMessages.pages,
            ],
          };
        },
      );
    },
  });

  return {
    sendMessage: mutate,
    sendLoading: isLoading,
    ...other,
  };
};
