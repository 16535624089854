import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

import type { ISymptomsList } from './types';

const buildSchemasForSymptoms = (symptomsList: ISymptomsList[]) => {
  const symptomsFields = symptomsList.reduce((acc, item) => {
    const required = !!item.required;

    acc[item.value] = commonValidationSchemas
      .string({
        nullable: true,
        required,
      })
      .oneOf(['true', 'false']);

    return acc;
  }, {} as Record<string, any>);

  return symptomsFields;
};

export const criticalBloodPressureValidationSchema = (
  symptomsList: ISymptomsList[],
) => {
  const symptomsFields = buildSchemasForSymptoms(symptomsList);

  return createValidationSchema({
    patient: commonValidationSchemas.string().defined(),
    other: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['patient'],
        is: (patient) => {
          return patient === null;
        },
        then: { required: true },
      },
    }),
    relationship: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['patient'],
        is: (patient) => {
          return patient === null;
        },
        then: { required: true },
      },
    }),
    AOC: commonValidationSchemas.string({
      nullable: true,
    }),
    lastAppointment: commonValidationSchemas.string({ nullable: true }),
    nextAppointment: commonValidationSchemas.string({ nullable: true }),
    bpMedications: commonValidationSchemas.string({ nullable: true }),
    details: commonValidationSchemas.string({ nullable: true }),
    factorsAssessment: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    isOneMoreReading: commonValidationSchemas
      .string({
        nullable: true,
        required: true,
      })
      .oneOf(['true', 'false']),
    bounds: commonValidationSchemas.boolean({ nullable: true }),
    symptoms: commonValidationSchemas.array(commonValidationSchemas.string(), {
      nullable: true,
    }),
    symptomsOnset: commonValidationSchemas.string({ nullable: true }),
    nursingIntervention: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      {
        when: {
          fields: ['notEscalated'],
          is: (notEscalated) => !notEscalated || notEscalated.length === 0,
          then: { required: true, min: 1 },
        },
      },
    ),
    notEscalated: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    verbalized: commonValidationSchemas.boolean({ nullable: true }),
    emergencyCare: commonValidationSchemas.boolean({ nullable: true }),
    ...symptomsFields,
  });
};

export const criticalBloodGlucoseValidationSchema = (
  symptomsList: ISymptomsList[],
) => {
  const symptomsFields = buildSchemasForSymptoms(symptomsList);

  return createValidationSchema({
    prescribed: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    bloodSugar: commonValidationSchemas.string({ nullable: true }),
    factorsAssessment: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    isOneMoreReading: commonValidationSchemas
      .string({
        nullable: true,
        required: true,
      })
      .oneOf(['true', 'false']),
    bounds: commonValidationSchemas.boolean({ nullable: true }),
    symptoms: commonValidationSchemas.array(commonValidationSchemas.string(), {
      nullable: true,
    }),
    symptomsOnset: commonValidationSchemas.string({ nullable: true }),
    nursingIntervention: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      {
        when: {
          fields: ['notEscalated'],
          is: (notEscalated) => !notEscalated || notEscalated.length === 0,
          then: { required: true, min: 1 },
        },
      },
    ),
    notEscalated: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    verbalized: commonValidationSchemas.boolean({ nullable: true }),
    emergencyCare: commonValidationSchemas.boolean({ nullable: true }),
    ...symptomsFields,
  });
};

export const criticalSpo2ValidationSchema = (symptomsList: ISymptomsList[]) => {
  const symptomsFields = buildSchemasForSymptoms(symptomsList);

  return createValidationSchema({
    patient: commonValidationSchemas.string().defined(),
    other: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['patient'],
        is: (patient) => {
          return patient === null;
        },
        then: { required: true },
      },
    }),
    relationship: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['patient'],
        is: (patient) => {
          return patient === null;
        },
        then: { required: true },
      },
    }),
    prescribed: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    otherPrescribed: commonValidationSchemas.string({ nullable: true }),
    prnMedications: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    otherPrnMedications: commonValidationSchemas.string({ nullable: true }),
    factorsAssessment: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    wearO2Home: commonValidationSchemas.boolean({ nullable: true }),
    homeO2Setting: commonValidationSchemas.number({
      nullable: true,
      min: 1,
      max: 99,
    }),
    isOneMoreReading: commonValidationSchemas
      .string({
        nullable: true,
        required: true,
      })
      .oneOf(['true', 'false']),
    bounds: commonValidationSchemas.boolean({ nullable: true }),
    symptoms: commonValidationSchemas.array(commonValidationSchemas.string(), {
      nullable: true,
    }),
    symptomsOnset: commonValidationSchemas.string({ nullable: true }),
    nursingIntervention: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      {
        when: {
          fields: ['notEscalated'],
          is: (notEscalated) => !notEscalated || notEscalated.length === 0,
          then: { required: true, min: 1 },
        },
      },
    ),
    notEscalated: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    verbalized: commonValidationSchemas.boolean({ nullable: true }),
    emergencyCare: commonValidationSchemas.boolean({ nullable: true }),
    ...symptomsFields,
  });
};

export const criticalPulseValidationSchema = (
  symptomsList: ISymptomsList[],
) => {
  const symptomsFields = buildSchemasForSymptoms(symptomsList);

  return createValidationSchema({
    patient: commonValidationSchemas.string().defined(),
    other: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['patient'],
        is: (patient) => {
          return patient === null;
        },
        then: { required: true },
      },
    }),
    relationship: commonValidationSchemas.string({
      nullable: true,
      when: {
        fields: ['patient'],
        is: (patient) => {
          return patient === null;
        },
        then: { required: true },
      },
    }),
    bpMedications: commonValidationSchemas.string({ nullable: true }),
    factorsAssessment: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    isOneMoreReading: commonValidationSchemas
      .string({
        nullable: true,
        required: true,
      })
      .oneOf(['true', 'false']),
    bounds: commonValidationSchemas.boolean({ nullable: true }),
    symptoms: commonValidationSchemas.array(commonValidationSchemas.string(), {
      nullable: true,
    }),
    symptomsOnset: commonValidationSchemas.string({ nullable: true }),
    nursingIntervention: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      {
        when: {
          fields: ['notEscalated'],
          is: (notEscalated) => !notEscalated || notEscalated.length === 0,
          then: { required: true, min: 1 },
        },
      },
    ),
    notEscalated: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    verbalized: commonValidationSchemas.boolean({ nullable: true }),
    emergencyCare: commonValidationSchemas.boolean({ nullable: true }),
    ...symptomsFields,
  });
};
