import React from 'react';
import BaseTag from 'components/atoms/base-tag';
import { useTranslation } from 'react-i18next';
import COLORS from 'assets/styles/color.module.scss';
import SlaTag from 'components/atoms/tags/SlaTag';

import ClinicStatusTag from '../status-tag';
import s from './styles.module.scss';

import type { Clinic } from 'infrastructure/classes/clinic';

interface IOptionProps {
  name: string;
  opted: boolean;
  dataCy?: string;
}
const Option: React.FC<IOptionProps> = ({ name, opted, dataCy }) => {
  const { t } = useTranslation();
  const statusLabel = opted ? t('controls.optedIn') : t('controls.optedOut');

  return (
    <span className={s.option} data-cy={dataCy}>
      {name} : <span className={s.status}>{statusLabel}</span>
    </span>
  );
};

interface IProps {
  info: Clinic;
}

const ClinicHeaderInfo: React.FC<IProps> = (props) => {
  const { info } = props;

  const { t } = useTranslation();

  return (
    <div className={s.header}>
      <div>
        <div className={s.title}>
          <span className={s.name} data-cy="clinic-header-legalname">
            {info?.legalname}
          </span>
          {!!info?.childClinicsGuid?.length && (
            <BaseTag
              dataCy="parent-tag"
              label={t('labels.parent')}
              color={COLORS.TANGERINE_HAZE}
              transparent
            />
          )}
          {info?.serviceDeliveryThresholds && (
            <SlaTag hours={info?.serviceDeliveryThresholds} small />
          )}
        </div>
        <div className={s.settings}>
          <span
            className={s.option}
            data-cy="interactiveTimeDashboardInclusive-settings"
          >
            {t('labels.interactiveTimeDashboardInclusive')} :{' '}
            <span className={s.status}>
              {String(info?.showOnInteractiveTimeDashboard)}
            </span>
          </span>
          <Option
            name={t('labels.bingoCard')}
            opted={info?.acceptBingoStatus}
            dataCy="acceptBingoStatus-settings"
          />
          <Option
            name={t('labels.adherenceSmsSubscription')}
            opted={info?.adherenceSmsSubscription}
            dataCy="adherenceSmsSubscription-settings"
          />
          <Option
            name={t('labels.doNotCall')}
            opted={info?.doNotCall}
            dataCy="doNotCall-settings"
          />
        </div>
      </div>
      <ClinicStatusTag status={info?.status} />
    </div>
  );
};

export default ClinicHeaderInfo;
