import BaseModal from 'components/atoms/base-modal';
import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import BaseSwitch from 'components/atoms/base-switch';
import React, { useEffect, useState, type FC } from 'react';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import { useFormik } from 'formik';
import BaseForm from 'components/atoms/base-form';
import BaseFormItem from 'components/atoms/base-form/item';

import s from './styles.module.scss';
import ItemHeader from './itemHeader';
import ItemBody from './itemBody';

import type { IHoldingStatusModalProps, ModalMode } from './types';
import type { DateRange } from 'infrastructure/types';

const HoldingStatusModal: FC<IHoldingStatusModalProps> = ({
  isOpen,
  patientGuid,
  patientHoldingStatus,
  nextHoldingStatus,
  holdingStatusStartTime,
  holdingStatusEndTime,
  options,
  loading,
  isCcmPage,
  ccmHoldingStatus,
  ccmHoldingStatusStartDate,
  tempHoldingStatusLoading,
  consumablesArrivingDate,
  isReadOnly,
  consumablesDateLoading,
  updateConsumablesDate,
  updateTempHoldStatus,
  updateHoldingStatus,
  toggle,
}) => {
  const [mode, setMode] = useState<ModalMode>('default');
  const [consumable, setConsumable] = useState<boolean | null>(null);
  const [temporaryHold, setTemporaryHold] = useState<boolean | undefined>(
    ccmHoldingStatus,
  );
  const [editModDates, setEditModDates] = useState<
    [string, string] | undefined
  >(undefined);
  const { t } = useTranslation();

  const isEnabled = mode === 'enabled';
  const isEdit = mode === 'edit';
  const isDefault = mode === 'default';
  const isRemoved = mode === 'removed';

  const dataRange: [string, string] | undefined =
    holdingStatusStartTime && holdingStatusEndTime
      ? [
          DateFormatter({ date: holdingStatusStartTime }),
          DateFormatter({ date: holdingStatusEndTime }),
        ]
      : undefined;

  const consumablesOrdered = !!consumablesArrivingDate;

  const changeTimeRange = async (range?: DateRange) => {
    if (range && !isEdit) {
      formik.setFieldValue('dateRange', [
        DateFormatter({ date: range[0] }),
        DateFormatter({ date: range[1] }),
      ]);
    }
    if (range && isEdit) {
      setEditModDates([
        DateFormatter({ date: range[0] }),
        DateFormatter({ date: range[1] }),
      ]);
    }
    if (!range) setEditModDates(undefined);
  };

  const handleSave = () => {
    setMode('enabled');
    if (editModDates) formik.setFieldValue('dateRange', editModDates);
    setEditModDates(undefined);
  };

  const handleRemove = async () => {
    if (holdingStatusEndTime) {
      const postData = {
        patientGuid,
        holdingStatus: null,
        startTime: undefined,
        endTime: undefined,
      };
      await updateHoldingStatus(postData);
    } else {
      setMode('default');
      formik.setFieldValue('dateRange', undefined);
      formik.setFieldValue('holdingStatus', 'active');
    }
  };
  const isExactMatch = (
    range1?: [string, string],
    range2?: [string, string],
  ) => {
    if (range1 === undefined || range2 === undefined) return false;
    const [start1, end1] = range1;
    const [start2, end2] = range2;
    return start1 === start2 && end1 === end2;
  };
  const areValuesEqual = (
    obj1: HoldingStatusValues,
    obj2: HoldingStatusValues,
  ) => {
    const areDateRangesEqual = isExactMatch(obj1.dateRange, obj2.dateRange);
    const areHoldingStatusesEqual = obj1.holdingStatus === obj2.holdingStatus;
    return areDateRangesEqual && areHoldingStatusesEqual;
  };

  type HoldingStatusValues = typeof formik.values;

  const formik = useFormik({
    initialValues: {
      holdingStatus: nextHoldingStatus || patientHoldingStatus,
      dateRange: dataRange,
    },
    onSubmit: async (values) => {
      const savedHoldingStatus: HoldingStatusValues = {
        holdingStatus: patientHoldingStatus,
        dateRange: [
          DateFormatter({ date: holdingStatusStartTime }),
          DateFormatter({ date: holdingStatusEndTime }),
        ],
      };

      const areValuesChanged = !areValuesEqual(values, savedHoldingStatus);

      if (values.holdingStatus && values.dateRange && areValuesChanged) {
        const postData = {
          patientGuid,
          holdingStatus: values.holdingStatus,
          startTime: new Date(values.dateRange[0]).getTime().toString(),
          endTime: new Date(values.dateRange[1]).getTime().toString(),
        };
        await updateHoldingStatus(postData);
      }

      if (
        temporaryHold !== undefined &&
        temporaryHold !== ccmHoldingStatus &&
        updateTempHoldStatus
      ) {
        await updateTempHoldStatus({
          patientGuid,
          ccmHoldingStatus: temporaryHold,
        });
      }

      if (consumable === false) {
        await updateConsumablesDate({ patientGuid });
      }
    },
    validateOnChange: false,
  });

  const { submitForm, values } = formik;

  const isActiveSaveButton =
    (formik.dirty && mode === 'enabled') ||
    temporaryHold !== ccmHoldingStatus ||
    consumable === false;

  useEffect(() => {
    if (patientHoldingStatus && holdingStatusStartTime && holdingStatusEndTime)
      setMode('enabled');
  }, [patientHoldingStatus, holdingStatusStartTime, holdingStatusEndTime]);

  return (
    <BaseModal
      title={t('labels.holdingStatus')}
      open={isOpen}
      onCancel={() => toggle(false)}
      width="552px"
      withFooter={[
        <BaseButton
          label={t('controls.cancel')}
          type="secondary"
          onClick={() => toggle(false)}
          key={t('controls.cancel')}
          loading={loading}
          dataCy="cancel-button"
        />,
        <BaseButton
          label={t('controls.save')}
          onClick={submitForm}
          key={t('controls.save')}
          loading={
            tempHoldingStatusLoading || loading || consumablesDateLoading
          }
          dataCy="save-button"
          disabled={!isActiveSaveButton}
        />,
      ]}
    >
      <BaseForm
        loading={loading}
        formik={formik}
        plaintext={false}
        readonly={false}
      >
        <div className={s.wrapper}>
          <div className={s.item}>
            <BaseFormItem name="holdingStatus">
              <ItemHeader
                options={options}
                values={values}
                mode={{ isEnabled, isDefault, isEdit, isRemoved }}
                nextHoldingStatus={nextHoldingStatus}
                setStatusCallback={(value) =>
                  formik.setFieldValue('holdingStatus', value)
                }
              />
            </BaseFormItem>
            <BaseFormItem name="dateRange">
              <ItemBody
                mode={{ isEnabled, isDefault, isEdit, isRemoved }}
                values={values}
                editModDates={editModDates}
                changeTimeRange={changeTimeRange}
                setMode={setMode}
                handleRemove={handleRemove}
                handleSave={handleSave}
                loading={loading}
              />
            </BaseFormItem>
          </div>

          <div className={s.item}>
            <div className={s.header}>
              {isCcmPage && (
                <>
                  <div>{t('labels.temporaryHoldStatus')}</div>
                  <div
                    className={s['header-right']}
                    data-cy="temporaryHoldStatus-switch"
                  >
                    {ccmHoldingStatus && (
                      <span className={s.till}>
                        From{' '}
                        {DateFormatter({
                          date: ccmHoldingStatusStartDate,
                          format: DateFormats['MMM dd, yyyy'],
                        })}
                      </span>
                    )}
                    <BaseSwitch
                      label={temporaryHold ? t('labels.on') : t('labels.off')}
                      onChange={(value) => setTemporaryHold(value)}
                      value={temporaryHold}
                    />
                  </div>
                </>
              )}
              {!isCcmPage && !isReadOnly && (
                <>
                  <div>{t('labels.awaitingConsumablesStatus')}</div>
                  <div
                    className={s['header-right']}
                    data-cy="awaitingConsumablesStatus-switch"
                  >
                    {!!consumablesArrivingDate && (
                      <span className={s.till}>
                        Till{' '}
                        {DateFormatter({
                          date: consumablesArrivingDate,
                          format: DateFormats['MMM dd, yyyy'],
                        })}
                      </span>
                    )}
                    <BaseSwitch
                      onChange={(value) => setConsumable(value)}
                      label={
                        consumable ?? consumablesOrdered
                          ? t('labels.on')
                          : t('labels.off')
                      }
                      disabled={!consumablesOrdered}
                      value={consumable ?? consumablesOrdered}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </BaseForm>
    </BaseModal>
  );
};

export default HoldingStatusModal;
