import {
  parseSearchNumberValue,
  parseSearchStringValue,
} from 'infrastructure/functions/parse-query-string';

import { useQueryFilterNew } from '../common/use-query-param-new';

import type { TParseFunctions } from '../common/use-query-param-new';
import type { IAgencyListFilters } from 'infrastructure/interfaces';

export const defaultValues: IAgencyListFilters = {
  page: 1,
  legalname: '',
};

const parseFunctions: TParseFunctions<IAgencyListFilters> = {
  page: parseSearchNumberValue,
  legalname: parseSearchStringValue,
};

export const useAgencyQueryFilter = () => {
  return useQueryFilterNew<IAgencyListFilters>(defaultValues, parseFunctions, [
    'page',
  ]);
};
