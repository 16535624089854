import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'reactstrap';
import {
  isPatient,
  isAdmin,
  isRoleNameAdministrator,
  isTurkUserRole,
  isPractice,
  isAgencyDoctor,
} from 'utils/userTypeHelper';
import useUser from 'utils/useUser';
import { PLATFORM_CONFIG } from 'utils/accessManager/constants';
import Logo from 'assets/img/header.svg';
import { hasAccessToMainPage } from 'utils/accessManager/helpers';
import paths from 'constants/paths';
import { ModuleType } from 'infrastructure/enums';

import NavbarDropdown from './navbarDropdown';
import NavbarProfileMenu from './navbarProfileMenu';
import RpmReportsDropdown from './rpmReportsDropdown';

const HeaderNavbar = ({
  formsOptions,
  adminPanelOptions,
  practiceOptions,
  patientsOptions,
  billingToolsOptions,
  profileOptions,
  firstName,
  lastName,
  title,
  disableNavigation,
}) => {
  const { userType, roleNames, platformMode } = useUser();

  const homeUrl = isTurkUserRole(roleNames)
    ? '/turk-dashboard'
    : PLATFORM_CONFIG[platformMode]?.dashboard;

  const showNavigation =
    !disableNavigation && !isPatient(userType) && !isTurkUserRole(roleNames);

  const isPracticeOrAhhAdmin =
    (isPractice(userType) || isAgencyDoctor(userType)) &&
    roleNames.length === 1 &&
    isRoleNameAdministrator(roleNames);

  const dashboardPathIdx = patientsOptions?.findIndex(
    ({ link }) => link === paths.dashboard,
  );

  if (!hasAccessToMainPage(roleNames) && dashboardPathIdx !== -1)
    patientsOptions?.splice(dashboardPathIdx, 1);

  return (
    <Navbar expand="md" tag="div" className="desktop-navigation-bar">
      <Link className="logo" data-cy="logged-in-logo" to={homeUrl}>
        <img src={Logo} alt="logo" width={103} height={40} />
      </Link>
      <nav
        className="ml-auto"
        aria-label="Main Navigation"
        style={{ height: '100%' }}
      >
        <Nav navbar>
          {showNavigation && (
            <>
              <NavbarDropdown
                label="Forms"
                dataCy="nav-forms"
                options={formsOptions}
              />
              {(isRoleNameAdministrator(roleNames) || isAdmin(userType)) &&
                !disableNavigation && (
                  <NavbarDropdown
                    label="Admin panel"
                    dataCy="nav-admin"
                    options={adminPanelOptions}
                  />
                )}
              {practiceOptions.length > 0 &&
                !disableNavigation &&
                platformMode === ModuleType.CCM && (
                  <NavbarDropdown
                    label="Reports"
                    dataCy="nav-practice"
                    options={practiceOptions}
                  />
                )}
              {practiceOptions.length > 0 &&
                !disableNavigation &&
                platformMode === ModuleType.RPM &&
                !isPracticeOrAhhAdmin && (
                  <RpmReportsDropdown
                    label="Reports"
                    dataCy="nav-practice"
                    options={practiceOptions}
                  />
                )}
              {patientsOptions.length > 0 &&
                !disableNavigation &&
                !isPracticeOrAhhAdmin && (
                  <NavbarDropdown
                    label="Patients"
                    dataCy="nav-patients"
                    options={patientsOptions}
                  />
                )}
              {billingToolsOptions.length > 0 && !disableNavigation && (
                <NavbarDropdown
                  label="Billing Tools"
                  dataCy="nav-billing-tools"
                  options={billingToolsOptions}
                />
              )}
            </>
          )}
          <NavbarProfileMenu
            firstName={firstName}
            lastName={lastName}
            title={title}
            options={profileOptions}
            dataCy="nav-profile"
            limitedMenu={disableNavigation}
          />
        </Nav>
      </nav>
    </Navbar>
  );
};

HeaderNavbar.propTypes = {
  adminPanelOptions: PropTypes.arrayOf(PropTypes.shape()),
  practiceOptions: PropTypes.arrayOf(PropTypes.shape()),
  billingToolsOptions: PropTypes.arrayOf(PropTypes.shape()),
  patientsOptions: PropTypes.arrayOf(PropTypes.shape()),
  profileOptions: PropTypes.arrayOf(PropTypes.shape()),
  formsOptions: PropTypes.arrayOf(PropTypes.shape()),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  disableNavigation: PropTypes.bool,
};

export default HeaderNavbar;
