import { ASSESSMENT_PREFIX } from 'infrastructure/consts/assessment-modal';

import type { TCriticalBloodPressureModal, TModalNames } from '../types';

interface Props {
  idx: string | number;
  type: TCriticalBloodPressureModal;
  modalName: TModalNames;
}
export const useStoreModalValue = <TInitValues extends object>({
  idx,
  type,
  modalName,
}: Props) => {
  const localStorageFormKey = `${ASSESSMENT_PREFIX}-${idx}_${type}-${modalName}`;
  const storedData = localStorage.getItem(localStorageFormKey);
  const parsedData: TInitValues | null = storedData
    ? JSON.parse(storedData)
    : null;

  const setModalValuesToStorage = (values: TInitValues) => {
    localStorage.setItem(localStorageFormKey, JSON.stringify(values));
  };

  return {
    parsedData,
    setModalValuesToStorage,
  };
};
