import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from 'store';
import useUser from 'utils/useUser';
import { ReadingType, ReadingStatus } from 'infrastructure/enums';
import { ASSESSMENT_PREFIX } from 'infrastructure/consts/assessment-modal';

import type { Value } from 'infrastructure/interfaces/i-rpm-patient-activity';
import type { IPatientThresholds } from 'infrastructure/interfaces';
import type { BookmarkComment } from 'infrastructure/classes/bookmark-comment';
import type { ITemplateValues, IUseCommentColumnProps } from './types';

export const useCommentColumn = ({
  addComment,
  idx,
  deviceId,
  readingId,
  currentInteractionStatus,
  aiData,
  isCommentGenerationLimitReached,
}: IUseCommentColumnProps) => {
  const [loading, setLoading] = useState(false);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openGlucoseModal, setOpenGlucoseModal] = useState(false);
  const [openPressureModal, setOpenPressureModal] = useState(false);
  const [openPressureMinModal, setOpenPressureMinModal] = useState(false);
  const [openSpo2Modal, setOpenSpo2Modal] = useState(false);
  const [openPulseModal, setOpenPulseModal] = useState(false);

  const [templateValues, setTemplateValues] = useState<ITemplateValues | {}>(
    {},
  );
  const { t } = useTranslation();
  const actions = useAppSelector((state) => state.activityActions.actions[idx]);
  const {
    activityActions: { updateAction, resetCurrentActions },
  } = useAppDispatch();
  const { isPractice, isAdmin } = useUser();

  const onSubmitComment = async () => {
    if (!actions?.message) return;

    setLoading(true);
    updateAction({ idx, action: { sending: true } });
    await addComment(
      {
        deviceId,
        readingId,
        message: actions.message,
        generatedByModel: aiData?.result.model,
        originalGeneratedText: aiData?.result.comment,
        currentInteractionStatus,
      },
      {
        onSuccess: () => {
          resetCurrentActions(idx);
          setTemplateValues({});
        },
        onSettled() {
          setLoading(false);
          updateAction({ idx, action: { sending: false } });
        },
      },
    );

    setLoading(false);
    updateAction({ idx, action: { sending: false } });
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(`${ASSESSMENT_PREFIX}-${idx}_`)) {
        localStorage.removeItem(key);
      }
    });
  };

  const disabled = loading || actions?.sending;

  const toggleOpenBookmark = () => setOpenBookmarkModal((prev) => !prev);
  const toggleOpenTemplate = () => setOpenTemplateModal((prev) => !prev);
  const toggleOpenGlucose = () => setOpenGlucoseModal((prev) => !prev);
  const toggleOpenPressure = () => setOpenPressureModal((prev) => !prev);
  const toggleOpenPressureMin = () => setOpenPressureMinModal((prev) => !prev);
  const toggleOpenSpo2 = () => setOpenSpo2Modal((prev) => !prev);
  const toggleOpenPulse = () => setOpenPulseModal((prev) => !prev);

  const onApplyBookmark = (data: BookmarkComment) => {
    updateAction({ idx, action: { message: data.message } });
    toggleOpenBookmark();
  };

  const submitCommentLockByAi =
    Boolean(actions?.message) && aiData?.result.comment === actions?.message;

  let aiButtonTitle = t('controls.aiGenerateComment');
  if (isCommentGenerationLimitReached) {
    aiButtonTitle = t('controls.aiGenerationLimitReached');
  }
  if (loading) {
    aiButtonTitle = t('controls.aiGeneratingComment');
  }
  let sendCommentButtonTitle = t('controls.submitComment');
  if (submitCommentLockByAi)
    sendCommentButtonTitle = t('controls.submitCommentLockByAi');

  const isBloodPressureMaxCritical = (
    globalStatus: ReadingStatus,
    values: Value,
    thresholds: IPatientThresholds,
  ) => {
    if (globalStatus !== ReadingStatus.Critical) return null;

    return (
      values.diastolic >= thresholds.maxCriticalDiastolic ||
      values.systolic >= thresholds.maxCriticalSystolic
    );
  };

  const isBloodPressureMinCritical = (
    globalStatus: ReadingStatus,
    values: Value,
    thresholds: IPatientThresholds,
  ) => {
    if (globalStatus !== ReadingStatus.Critical) return null;

    return (
      values.diastolic <= thresholds.minCriticalDiastolic ||
      values.systolic <= thresholds.minCriticalSystolic
    );
  };

  const isPulseMinCritical = (values: Value, thresholds: IPatientThresholds) =>
    values.pulse <= thresholds.minCriticalPulse;

  const isPulseMaxCritical = (values: Value, thresholds: IPatientThresholds) =>
    values.pulse >= thresholds.maxCriticalPulse;

  const isSpo2MinCritical = (values: Value, thresholds: IPatientThresholds) =>
    values.spo2 <= thresholds.criticalBloodoxygen;

  const getBloodPressureCriticalType = (
    values: Value,
    thresholds: IPatientThresholds,
  ): 'max' | 'min' | null => {
    if (
      values.diastolic >= thresholds.maxCriticalDiastolic ||
      values.systolic >= thresholds.maxCriticalSystolic
    ) {
      return 'max';
    }
    if (
      values.diastolic <= thresholds.minCriticalDiastolic ||
      values.systolic <= thresholds.minCriticalSystolic
    ) {
      return 'min';
    }
    return null;
  };

  const getBloodGlucoseCriticalType = (
    values: Value,
    thresholds: IPatientThresholds,
  ): 'max' | 'min' | null => {
    if (values.glucose >= thresholds.maxCriticalGlucose) return 'max';
    if (values.glucose <= thresholds.minCriticalGlucose) return 'min';
    return null;
  };

  const getPulseCriticalType = (
    values: Value,
    thresholds: IPatientThresholds,
  ): 'max' | 'min' | null => {
    if (values.pulse >= thresholds.maxCriticalPulse) return 'max';
    if (values.pulse <= thresholds.minCriticalPulse) return 'min';
    return null;
  };

  const checkReadingMinMaxType = (
    readingType: ReadingType,
    globalStatus: ReadingStatus,
    values: Value,
    thresholds: IPatientThresholds,
  ): 'max' | 'min' | 'both' | null => {
    if (globalStatus !== ReadingStatus.Critical) return null;

    switch (readingType) {
      case ReadingType.BloodPressure:
        return getBloodPressureCriticalType(values, thresholds);
      case ReadingType.BloodGlucose:
        return getBloodGlucoseCriticalType(values, thresholds);
      case ReadingType.SpO2:
        return 'min';
      case ReadingType.SleepingMatDuration:
        return getPulseCriticalType(values, thresholds);
      default:
        return null;
    }
  };

  return {
    openBookmarkModal,
    openTemplateModal,
    openGlucoseModal,
    openPressureModal,
    openPressureMinModal,
    openSpo2Modal,
    templateValues,
    showBookmarkBtn: isPractice || isAdmin,
    message: actions?.message,
    disabled,
    aiButtonTitle,
    updateAction,
    loading,
    submitCommentLockByAi,
    sendCommentButtonTitle,
    openPulseModal,
    setTemplateValues,
    toggleOpenBookmark,
    toggleOpenTemplate,
    onApplyBookmark,
    onSubmitComment,
    toggleOpenGlucose,
    toggleOpenPressure,
    toggleOpenPressureMin,
    toggleOpenSpo2,
    checkReadingMinMaxType,
    toggleOpenPulse,
    isBloodPressureMinCritical,
    isBloodPressureMaxCritical,
    isPulseMinCritical,
    isPulseMaxCritical,
    isSpo2MinCritical,
    getPulseCriticalType,
  };
};
