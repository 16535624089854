import React, { useEffect, useRef } from 'react';
import { format, isToday, isYesterday, parseISO, subDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Message from 'components/molecules/smsDashboard/message';
import InfiniteScroll from 'components/atoms/infinityScroll';
import ConversationEmptyIcon from 'components/atoms/icons/conversationEmptyIcon';
import MessagesSearchIcon from 'components/atoms/icons/messagesSearchEmptyIcon';
import { sleep } from 'utils';
import { getSmsDashboardMessagesKey } from 'infrastructure/hooks/smsDashboard/useMessages';
import {
  useQueryClient,
  type FetchNextPageOptions,
  type FetchPreviousPageOptions,
  type InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { DateFormats } from 'infrastructure/enums';

import s from './styles.module.scss';

import type {
  IConversation,
  IMessage,
  ConversationType,
  IMessagesListRes,
  IMessagesListPages,
} from 'infrastructure/interfaces';
import type { RootState } from 'store';

interface IMessagesListProps {
  currentConversation: IConversation;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<IMessagesListRes | undefined, unknown>
  >;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<IMessagesListRes | undefined, unknown>
  >;
  messages?: (IMessagesListRes | undefined)[];
  searchValue: string;
  searchDateValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchDateValue: React.Dispatch<React.SetStateAction<string>>;
  setIsSearchShown: React.Dispatch<React.SetStateAction<boolean>>;
  messagesLoading: boolean;
}

const messagesWrapperClass = s['messages-wrapper'];
export const scrollMessagesToBottom = () => {
  const el = document.querySelector(`.${messagesWrapperClass}`);
  if (!el) return;
  el.scrollTop = el.scrollHeight;
};

const MessagesList: React.FC<IMessagesListProps> = (props) => {
  const {
    currentConversation,
    setEnabled,
    messages,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    searchValue,
    searchDateValue,
    setSearchValue,
    setSearchDateValue,
    setIsSearchShown,
    messagesLoading,
  } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const containerRef = useRef(null);
  const { conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  useEffect(() => {
    scrollMessagesToBottom();
  }, [currentConversation]);

  const groupByDate = (data: (IMessagesListRes | undefined)[] | undefined) => {
    const dataByDate: { [key: string]: IMessage[] } = {};
    data?.forEach((page) => {
      page?.items.forEach((item) => {
        const messageDate = new Date(item.createdAt);
        const formattedDate = format(messageDate, DateFormats['yyyy-MM-dd']);

        if (!dataByDate[formattedDate]) {
          dataByDate[formattedDate] = [];
        }

        dataByDate[formattedDate].push(item);
      });
    });

    return Object.fromEntries(
      Object.entries(dataByDate).sort(([a], [b]) => b.localeCompare(a)),
    );
  };

  const groupedByDate = messages ? Object.entries(groupByDate(messages)) : [];

  const formatDate = (dateToFormat: Date | string) => {
    const date = new Date(dateToFormat);
    if (isToday(date)) {
      return t('smsDashboard.today');
    }
    if (isYesterday(date)) {
      return t('smsDashboard.yesterday');
    }
    return format(date, DateFormats['dd MMMM, yy']);
  };

  const messageGroupDateTitle = (date: string) =>
    format(new Date(date), DateFormats['MMMM dd, yyyy']);

  const handleJumpToComment = async (message: IMessage) => {
    setEnabled(false);
    await sleep(300);
    setSearchValue('');
    setSearchDateValue('');
    setIsSearchShown(false);

    const messagesKey = getSmsDashboardMessagesKey({
      conversationId: currentConversation.id,
      nextCursor: undefined,
      prevCursor: undefined,
      searchQuery: '',
      searchQueryDate: '',
    });
    queryClient.setQueryData<IMessagesListPages>(messagesKey, (oldData) => {
      if (!oldData) return;

      return {
        pageParams: oldData.pageParams,
        pages: [{ items: [message] }],
      };
    });

    setEnabled(true);

    await sleep(500);

    await fetchPreviousPage({
      pageParam: {
        prevCursor: message.createdAt,
        searchQuery: '',
      },
    });

    await fetchNextPage({
      pageParam: {
        nextCursor: message.createdAt,
        searchQuery: '',
      },
    });
    await sleep(500);

    const currentMessageEl = document.getElementById(message.id);
    if (currentMessageEl) {
      currentMessageEl.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  };

  const fetchPreviousPageMessages = () => {
    fetchPreviousPage().then((data) => {
      if (data.data?.pages[0] && data.data?.pages[0].items.length < 15) {
        fetchNextPage();
      }
    });
    if (searchDateValue !== '') {
      const date = parseISO(searchDateValue);
      const previousDay = subDays(date, 1);
      const dateGroup = document.getElementById(
        `${format(previousDay, DateFormats['yyyy-MM-dd'])}`,
      );
      if (dateGroup) {
        dateGroup.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
      } else {
        const dateMonthGroup = document.querySelector(
          `[id^="${format(previousDay, DateFormats['yyyy-MM'])}"]`,
        );
        if (dateMonthGroup) {
          dateMonthGroup.scrollIntoView({
            behavior: 'instant',
            block: 'center',
          });
        }
      }
    }
  };

  return (
    <div className={messagesWrapperClass} ref={containerRef}>
      <InfiniteScroll
        loadMore={fetchPreviousPageMessages}
        disabled={!hasPreviousPage || messagesLoading}
        allLoaded={!hasPreviousPage}
        uniqueId="messages-prev"
      />
      {groupedByDate.map((group) => (
        <div className={s['message-group']} key={group[0]} id={group[0]}>
          <div
            className={s['message-group-date']}
            title={messageGroupDateTitle(group[0])}
          >
            {formatDate(group[0])}
          </div>
          {(group[1] as IMessage[])
            .reverse()
            .filter((message: any, index: number, self: any) => {
              return (
                self.findIndex(
                  (otherMessage: any) => otherMessage.id === message.id,
                ) === index
              );
            })
            .map((message: IMessage, index: number) => {
              const {
                id,
                body,
                authorType,
                status,
                createdAt,
                from,
                to,
                action,
                author,
                mediaUrl,
                readBy,
                readAt,
              } = message;
              const authorName =
                authorType === 'patient'
                  ? currentConversation?.patient?.fullNameWithOnlyTitle
                  : author?.fullNameWithProfession;
              return (
                <Message
                  left={authorType === 'patient'}
                  body={body}
                  action={action}
                  authorType={authorType}
                  authorName={authorName}
                  status={status}
                  createdAt={createdAt}
                  to={to}
                  from={from}
                  conversationType={conversationType as ConversationType}
                  key={index}
                  mediaUrl={mediaUrl}
                  readBy={readBy}
                  readAt={readAt}
                  handleJumpToComment={() => handleJumpToComment(message)}
                  messageId={id}
                  isShowJumpToMsgBtn={Boolean(searchValue)}
                  searchValue={searchValue}
                />
              );
            })}
        </div>
      ))}
      {!groupedByDate?.length && messagesLoading === false && (
        <div className={s['empty-conversation']} style={{ height: '100%' }}>
          <div className={s['message-wrapper']}>
            {searchValue === undefined ? (
              <div className={s['empty-message']}>
                <ConversationEmptyIcon />
                <div className={s.message}>
                  {t('smsDashboard.conversation_is_empty')}
                </div>
              </div>
            ) : (
              <div className={`${s['empty-message']} ${s['search-empty']}`}>
                <MessagesSearchIcon />
                <div className={s.message} style={{ width: 300 }}>
                  <Trans
                    i18nKey="smsDashboard.empty_conversation_message"
                    values={{
                      description1: `${t(
                        'smsDashboard.no_result_found',
                      )},<br/>`,
                      description2: t('smsDashboard.change_filter'),
                    }}
                    components={{
                      primary: <span />,
                      secondary: <span />,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <InfiniteScroll
        loadMore={fetchNextPage}
        disabled={!hasNextPage || messagesLoading}
        allLoaded={!hasNextPage}
        uniqueId="messages-next"
      />
    </div>
  );
};

export default MessagesList;
