import React from 'react';

import s from './styles.module.scss';
import AgencyDoctorStatusTag from '../status-tag';

import type { AgencyDoctor } from 'infrastructure/classes/agency-doctor';

interface IProps {
  info: AgencyDoctor;
}

const AgencyDoctorHeaderInfo: React.FC<IProps> = (props) => {
  const { info } = props;

  return (
    <div className={s.header}>
      <span className={s.name}>{info.fullName}</span>
      <AgencyDoctorStatusTag status={info?.status} />
    </div>
  );
};

export default AgencyDoctorHeaderInfo;
