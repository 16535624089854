import dataService from 'services/dataService';
import { API_PATIENTS_BINGO_CARD_STATUS } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';

interface DataProps {
  patientGuid: string;
  acceptBingoStatus: boolean;
}

interface IResponse {
  acceptBingoStatus: boolean;
  patientGuid: string;
}

export const updateBingoCardStatusFn = async (postData: DataProps) => {
  const { error, data } = await dataService.updateOnly<IResponse>(
    API_PATIENTS_BINGO_CARD_STATUS,
    postData,
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};
