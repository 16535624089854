import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PlatformEnums } from 'infrastructure/enums/platformEnums';
import BaseModal from 'components/atoms/base-modal';
import { useCreateConversation } from 'infrastructure/hooks/smsDashboard/useCreateConversation';
import PatientSearch from 'components/molecules/smsDashboard/patientSearch';

import s from './styles.module.scss';

import type { PatientOptionType } from 'infrastructure/interfaces';
import type { RootDispatch, RootState } from 'store';

export interface INewMessageModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const NewMessageModal: React.FC<INewMessageModalProps> = (props) => {
  const { isOpen, toggle } = props;
  const { t } = useTranslation();

  const [patientData, setPatientData] = useState<PatientOptionType>({
    phoneNumber: '',
    patientGuid: null,
  });
  const resetForm = () => {
    setPatientData({
      phoneNumber: '',
      patientGuid: null,
    });
  };

  const {
    smsDashboard: { changeCurrentConversation },
  } = useDispatch<RootDispatch>();

  const { conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );

  const toggleModal = () => {
    resetForm();
    toggle();
  };

  const { createConversation, createConversationLoading } =
    useCreateConversation({
      onSuccess(data) {
        const res = data.data;
        changeCurrentConversation(res);
        toggleModal();
      },
    });

  const onSubmit = async (patient: PatientOptionType) => {
    createConversation({
      patientPhone: patient.phoneNumber,
      patientGuid:
        conversationType !== PlatformEnums.UNREGISTERED
          ? patient.patientGuid
          : undefined,
      from:
        conversationType !== PlatformEnums.UNREGISTERED
          ? conversationType
          : undefined,
    });
  };

  const modalTitle = () => {
    if (conversationType === PlatformEnums.UNREGISTERED)
      return t('smsDashboard.new_conversation');
    if (conversationType === PlatformEnums.RPM)
      return t('smsDashboard.rpm_patient_list');
    if (conversationType === PlatformEnums.CCM)
      return t('smsDashboard.ccm_patient_list');
    return '';
  };

  return (
    <BaseModal
      title={modalTitle()}
      open={isOpen}
      onCancel={toggleModal}
      width="1000px"
    >
      <div className={`${s['new-message-modal']} new-message-modal-padding`}>
        <PatientSearch
          value={patientData.phoneNumber}
          setValue={setPatientData}
          disabled={createConversationLoading}
          onClick={onSubmit}
          conversationType={conversationType}
        />
      </div>
    </BaseModal>
  );
};

export default NewMessageModal;
