import { useAppDispatch } from 'store';
import { useCallback, useState, type FC } from 'react';

import CriticalBloodGlucoseModal from './critical-blood-glucose-modal';
import CriticalBloodPressureModal from './critical-blood-pressure-modal';
import CriticalPulseModal from './critical-pulse-modal';
import CriticalSpo2Modal from './critical-spo2-modal';

import type { IRpmAlertAssessmentModalsProps } from './types';

const RpmAlertAssessmentModals: FC<IRpmAlertAssessmentModalsProps> = (
  props,
) => {
  const {
    readingId,
    createdAt,
    idx,
    patientGuid,
    values,
    openPressureModal,
    openPressureMinModal,
    openGlucoseModal,
    openSpo2Modal,
    openPulseModal,
    readingCriticalType,
    pulseMaxCritical,
    toggleOpenPressure,
    toggleOpenPressureMin,
    toggleOpenGlucose,
    toggleOpenSpo2,
    toggleOpenPulse,
  } = props;

  const [commonValues, setCommonValues] = useState({
    patient: undefined,
    other: null,
    relationship: null,
    AOC: null,
    last: null,
    next: null,
    bpMedications: null,
    routineMedications: null,
    prnMedications: null,
    details: null,
    oneMoreReading: null,
    bounds: null,
    repeatReadingWas: null,
    verbalized: null,
    isTakeMedications: null,
    isTakePrnMedications: null,
    isOneMoreReading: null,
    prescribed: null,
  });

  const {
    activityActions: { updateAction },
  } = useAppDispatch();

  const updateCommonValue = useCallback(
    (key: keyof typeof commonValues, value: any) => {
      setCommonValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  return (
    <>
      {openPressureModal && (
        <CriticalBloodPressureModal
          isOpen={openPressureModal}
          activityId={readingId}
          activityDate={createdAt}
          closeModal={toggleOpenPressure}
          handleSubmit={(msg: string) => {
            updateAction({ idx, action: { message: msg } });
          }}
          values={values}
          type="max"
          patientGuid={patientGuid}
          sharedValues={commonValues}
          updateSharedValue={updateCommonValue}
          idx={idx}
        />
      )}

      {openPressureMinModal && (
        <CriticalBloodPressureModal
          isOpen={openPressureModal}
          activityId={readingId}
          activityDate={createdAt}
          closeModal={toggleOpenPressureMin}
          handleSubmit={(msg: string) => {
            updateAction({ idx, action: { message: msg } });
          }}
          values={values}
          type="min"
          patientGuid={patientGuid}
          sharedValues={commonValues}
          updateSharedValue={updateCommonValue}
          idx={idx}
        />
      )}

      {openGlucoseModal && readingCriticalType && (
        <CriticalBloodGlucoseModal
          isOpen={openGlucoseModal}
          activityId={readingId}
          closeModal={toggleOpenGlucose}
          handleSubmit={(msg: string) => {
            updateAction({ idx, action: { message: msg } });
          }}
          type={readingCriticalType}
          patientGuid={patientGuid}
        />
      )}

      {openSpo2Modal && (
        <CriticalSpo2Modal
          isOpen={openSpo2Modal}
          activityId={readingId}
          closeModal={toggleOpenSpo2}
          handleSubmit={(msg: string) => {
            updateAction({ idx, action: { message: msg } });
          }}
          type="min"
          idx={idx}
          sharedValues={commonValues}
          updateSharedValue={updateCommonValue}
          activityDate={createdAt}
          values={values}
          patientGuid={patientGuid}
        />
      )}

      {openPulseModal && (
        <CriticalPulseModal
          isOpen={openPulseModal}
          activityId={readingId}
          closeModal={toggleOpenPulse}
          handleSubmit={(msg: string) => {
            updateAction({ idx, action: { message: msg } });
          }}
          type={pulseMaxCritical ? 'max' : 'min'}
          sharedValues={commonValues}
          updateSharedValue={updateCommonValue}
          idx={idx}
          activityDate={createdAt}
          values={values}
          patientGuid={patientGuid}
        />
      )}
    </>
  );
};

export default RpmAlertAssessmentModals;
