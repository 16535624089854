import { RpmStatuses } from 'infrastructure/enums';

export const PatientProfile = [
  RpmStatuses.Active,
  RpmStatuses.Onboarding,
  RpmStatuses.InactiveBadNoSignalConnectivity,
  RpmStatuses.InactiveChangedDoctor,
  RpmStatuses.InactiveClientRequestInconvenient,
  RpmStatuses.InactiveClientRequestMoving,
  RpmStatuses.InactiveClientRequestOther,
  RpmStatuses.InactiveExpired,
  RpmStatuses.InactiveHealthPlanRequestGoalsMet,
  RpmStatuses.InactiveHealthPlanRequestNonCompliant,
  RpmStatuses.InactiveHealthPlanRequestOther,
  RpmStatuses.InactiveHospice,
  RpmStatuses.InactiveCopay,
  RpmStatuses.InactiveLongTermCareRehab,
  RpmStatuses.InactiveLossOfEligibility,
  RpmStatuses.InactivePhysicianRequest,
  RpmStatuses.PendingReview,
];

export const Onboarding = [
  RpmStatuses.CallingQueue,
  RpmStatuses.PatientReferral,
  RpmStatuses.ReferralOnHold,
  RpmStatuses.PatientNoContact,
  RpmStatuses.PatientRefused,
  RpmStatuses.PatientRefusedConnectivityOrTechBarier,
  RpmStatuses.PatientRefusedCopayInsurance,
  RpmStatuses.PatientRefusedNotInterestedNotNecessarySuspectScam,
  RpmStatuses.PatientRefusedExpired,
  RpmStatuses.PatientRefusedNoOneToHelp,
  RpmStatuses.PatientRefusedHasNewPhysician,
  RpmStatuses.PatientRefusedWantsToDiscussWithPhysician,
  RpmStatuses.PatientRefusedWrongContactInformation,
  RpmStatuses.InsuranceRefused,
  RpmStatuses.OnboardingUnsuccessful,
];

export const RpmStatusGroups = {
  PatientProfile,
  Onboarding,
} as const;
