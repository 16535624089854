import forms from 'constants/forms';
import { useAminPanelSearchPatients } from 'infrastructure/hooks/patient/use-admin-panel-search-patient';
import useUser from 'utils/useUser';
import { useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { usePatientsFilter } from './use-patients-filter';

type TOption = { label: string; value: string | null };

export const options = forms.adminPanelPatientFilter.map((opt) => ({
  value: opt.id,
  label: opt.label,
  placeholder: opt.placeholder,
}));

const DEFAULT_PATIENT_KEY = options[0];

export const useFilter = () => {
  const { filter, updateFilter, resetFilter } = usePatientsFilter();
  const { searchPatients, searchPatientsLoading, patients } =
    useAminPanelSearchPatients();
  const { t } = useTranslation();
  const { iamPatientAcceptance } = useUser();

  const statusOptions = useMemo(() => {
    const opts: TOption[] = structuredClone(forms.patientStatuses);
    opts.unshift({ value: null, label: t('labels.all') });

    return opts;
  }, [iamPatientAcceptance]);

  const ccmStatusOptions = useMemo(() => {
    const opts: TOption[] = structuredClone(forms.ccmStatusOptions);
    opts.unshift({ value: null, label: t('labels.all') });

    return opts;
  }, [iamPatientAcceptance]);

  const placeholder = useMemo(
    () => options.find((o) => o.value === filter.patientKey)?.placeholder,
    [filter.patientKey],
  );

  const debounceSearchPatients = useCallback(debounce(searchPatients, 800), []);

  const patientOptions =
    patients?.data ??
    (filter.patientLabel && filter.patientGuid
      ? [{ label: filter.patientLabel, value: filter.patientGuid }]
      : []);

  const exclude = ['page', 'patientLabel', 'clinicLabel', 'patientKey'];

  const countOfChangedFilterField = Object.entries(filter).filter(
    ([k, v]) => !exclude.includes(k) && Boolean(v),
  ).length;

  return {
    filter,
    updateFilter,
    searchPatientsLoading,
    statusOptions,
    ccmStatusOptions,
    placeholder,
    debounceSearchPatients,
    patientOptions,
    countOfChangedFilterField,
    DEFAULT_PATIENT_KEY,
    options,
    resetFilter,
  };
};
