import React, { useEffect } from 'react';
import SmsDashboard from 'components/organisms/smsDashboard';
import { useWebsocketRoom } from 'infrastructure/hooks/websockets/use-room';
import { SocketRoutes } from 'services/wsService';
import { useSelector } from 'react-redux';
import useUser from 'utils/useUser';
import { isAdmin, isSmsDashboardUnregisteredRole } from 'utils/userTypeHelper';

import type { RootState } from 'store';

const SmsDashboardPage = () => {
  const socket = useSelector((state: RootState) => state.socket);
  const { roleNames, userType } = useUser();

  useWebsocketRoom('smsDashboard');
  useEffect(() => {
    if (
      socket.client &&
      socket.isConnected &&
      (isSmsDashboardUnregisteredRole(roleNames) || isAdmin(userType))
    ) {
      socket.client.send(SocketRoutes.JoinToRoom, {
        room: 'smsDashboardUnregistered',
      });
    }
  }, [socket.isConnected]);

  useEffect(() => {
    return () => {
      if (
        socket.client &&
        socket.isConnected === true &&
        (isSmsDashboardUnregisteredRole(roleNames) || isAdmin(userType))
      ) {
        socket.client.send(SocketRoutes.LeaveRoom, {
          room: 'smsDashboardUnregistered',
        });
      }
    };
  }, []);
  return <SmsDashboard />;
};

export default SmsDashboardPage;
