import BaseRangeDatepicker from 'components/atoms/base-range-datepicker';
import { endOfDate, startOfDate } from 'infrastructure/functions';
import { useTranslation } from 'react-i18next';
import { PresetKeyEnum } from 'infrastructure/enums';
import dayjs from 'dayjs';
import { type FC } from 'react';

import s from './styles.module.scss';

import type {
  RangePickerValue,
  TOnCalendarChange,
} from 'components/atoms/base-range-datepicker';
import type {
  Filters,
  AppendFilters,
} from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface Props {
  filters: Filters;
  appendFilters: AppendFilters;
}

const InteractionDateRange: FC<Props> = ({ appendFilters, filters }) => {
  const changeTimeRange: TOnCalendarChange = (
    dates,
    dateString,
    info,
    preset,
  ) => {
    const [start, end] = dates;
    if (!start && !end) {
      appendFilters({
        interactionFlagStartTime: undefined,
        interactionFlagEndTime: undefined,
      });
      return;
    }

    if (preset && start && end) {
      appendFilters({
        interactionFlagStartTime: startOfDate(start).getTime(),
        interactionFlagEndTime: endOfDate(end).getTime(),
      });
      return;
    }

    if (start && info.range === 'start') {
      appendFilters({
        interactionFlagStartTime: startOfDate(start).getTime(),
      });
    }
    if (end && info.range === 'end') {
      appendFilters({
        interactionFlagEndTime: endOfDate(end).getTime(),
      });
    }
  };

  const selectedRange: RangePickerValue = [
    filters.interactionFlagStartTime
      ? new Date(filters.interactionFlagStartTime)
      : null,
    filters.interactionFlagEndTime
      ? new Date(filters.interactionFlagEndTime)
      : null,
  ];
  const { t } = useTranslation();

  const excludedPresets = [
    PresetKeyEnum.PreviousMonth,
    PresetKeyEnum.TwoMonthsPast,
    PresetKeyEnum.ThreeMonthsPast,
  ];

  const currentMonthStart = dayjs().startOf('month');

  return (
    <div style={{ width: '100%' }}>
      <div className={s['interaction-date-range']}>
        <label htmlFor="interactionDateRange">
          {t('labels.interactionDaysRange')}
        </label>
        <div className={s['input-wrapper']}>
          <BaseRangeDatepicker
            withPresets
            presets={(defaultPresets) =>
              defaultPresets.filter(
                (el) => !excludedPresets.some((key) => el.key === key),
              )
            }
            inCurrentMonth
            minDate={currentMonthStart.toDate()}
            onCalendarChange={changeTimeRange}
            value={selectedRange}
          />
        </div>
      </div>
    </div>
  );
};

export default InteractionDateRange;
