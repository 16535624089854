import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { STALE_TIME } from 'infrastructure/consts/stale-time';
import { arrUniqEl } from 'infrastructure/functions';
import queryString from 'query-string';
import { DoctorAndAgencyDoctorSelect } from 'infrastructure/classes/select/doctor-and-agency-doctor';
import { RoleGroups } from 'infrastructure/consts/role-groups';

import useApi from '../use-api';

import type { Roles } from 'infrastructure/enums';
import type {
  IPaginationMeta,
  IPartialPaginationMeta,
  IDoctorAndAgencyDoctorSelectPaginatedData,
} from 'infrastructure/interfaces';

const getDoctorsAndAgencyDoctorsApi = (patientGuid: string) =>
  `core/ccm/patients/${patientGuid}/doctors-and-agency-doctors`;

export interface IUseDoctorAndAgencyDoctorMeta {
  search?: string;
  guid?: string;
  roles?: Array<Roles>;
}

type MergedMeta = IUseDoctorAndAgencyDoctorMeta & IPaginationMeta;

type UseDoctorAndAgencyDoctor = {
  loading: boolean;
  meta: MergedMeta;
  loadDoctorsAndAgencyDoctors: (
    meta: MergedMeta,
  ) => Promise<DoctorAndAgencyDoctorSelect[]>;
};

interface IUseDoctorAndAgencyDoctorSelectProps {
  patientGuid: string;
  meta?: IUseDoctorAndAgencyDoctorMeta & IPartialPaginationMeta;
}

export const useDoctorAndAgencyDoctorSelect = (
  props: IUseDoctorAndAgencyDoctorSelectProps,
): UseDoctorAndAgencyDoctor => {
  const { patientGuid, meta: metaProps = {} } = props;
  const { loading, loadData, isMounted } = useApi();
  const queryClient = useQueryClient();

  const [meta, setMeta] = useState<MergedMeta>({
    page: 1,
    items: 100,
    totalCount: 0,
    ...metaProps,
  });

  const loadDoctorsAndAgencyDoctors = async (filters: MergedMeta) => {
    const queryKey = [
      getDoctorsAndAgencyDoctorsApi(patientGuid),
      {
        guid: filters.guid,
        page: filters.page,
        search: filters.search,
        roles: filters.roles,
      },
    ];

    const data = await queryClient.fetchQuery(
      queryKey,
      () => {
        const url = getDoctorsAndAgencyDoctorsApi(patientGuid);
        const query = queryString.stringify(
          {
            sortField: JSON.stringify(filters.sortField),
            page: filters.page,
            items: filters.items,
            guid: filters.guid,
            search: filters.search,
            roles: filters.roles ?? RoleGroups.CCMRoles,
          },
          {
            arrayFormat: 'comma',
          },
        );

        return loadData<
          IDoctorAndAgencyDoctorSelectPaginatedData,
          Omit<IUseDoctorAndAgencyDoctorMeta, 'sortField'>
        >(`${url}?${query}`);
      },
      { staleTime: STALE_TIME },
    );

    if (isMounted && data) {
      const newMeta = {
        ...meta,
        ...filters,
        totalCount: data.count,
      };

      setMeta(newMeta);

      return arrUniqEl(data.items, 'guid').map(
        (el) => new DoctorAndAgencyDoctorSelect(el),
      );
    }

    return [];
  };

  return {
    loading,
    meta,
    loadDoctorsAndAgencyDoctors,
  };
};
