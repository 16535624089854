import { type FC } from 'react';
import BooleanSelectBox from 'components/molecules/boolean-select-box';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

import type { Filters } from 'infrastructure/hooks/interactiveTimeDashboard/types';

interface IProps {
  filters: Filters;
  onChange: (e?: boolean) => void;
}

const ReadingCountFilter: FC<IProps> = ({ onChange, filters }) => {
  const { t } = useTranslation();

  return (
    <div className={s['available-billable-minutes-wrapper']}>
      <label>{t('labels.readingCountFilter')}</label>
      <BooleanSelectBox
        withAll
        placeholder={t('labels.all')}
        trueLabel={t('labels.withReadings')}
        falseLabel={t('labels.withoutReadings')}
        value={filters.withReading}
        onChange={onChange}
        allowClear
      />
    </div>
  );
};

export default ReadingCountFilter;
