export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const hypertensionSymptoms = [
  { label: 'Headache', value: 'headache', required: true },
  { label: 'Chest pain', value: 'chestPain', required: true },
  { label: 'Vision changes', value: 'vision', required: true },
  {
    label: 'Numbness/tingling/weakness',
    value: 'numbness',
    required: true,
  },
  { label: 'Dizziness', value: 'dizziness', required: true },
  {
    label: 'Shortness of breath',
    value: 'shortnessOfBreath',
    required: true,
  },
  { label: 'Facial flushing', value: 'flushing' },
  { label: 'Anxiety', value: 'anxiety' },
  { label: 'Nosebleeds', value: 'nosebleeds' },
  { label: 'Blood spots in eyes', value: 'bloodSpots' },
];

export const hypotensionSymptoms = [
  {
    label: 'Dizziness/lightheadedness',
    value: 'dizziness',
    required: true,
  },
  { label: 'Confusion', value: 'confusion', required: true },
  {
    label: 'Loss of consciousness',
    value: 'consciousness',
    required: true,
  },
  { label: 'Blurred vision', value: 'blurredVision', required: true },
  { label: 'Fatigue', value: 'fatigue' },
  { label: 'Nausea', value: 'nausea' },
  { label: 'Clammy skin', value: 'clammySkin' },
  { label: 'Depression', value: 'depression' },
];

const factorsAssessment = [
  {
    label:
      'Smoked/used tobacco or nicotine products within 30 minutes of reading',
    value: 'smoked',
  },
  {
    label: 'Drank alcohol or caffeine within 30 minutes of reading',
    value: 'drank',
  },
  {
    label: 'Exercised/was physically active within 30 minutes of reading',
    value: 'activity',
  },
  {
    label: 'Took multiple readings back to back',
    value: 'multiple',
  },
  {
    label: 'Took reading over clothes',
    value: 'overClothes',
  },
  {
    label: 'Cuff misplacement',
    value: 'misplacement',
  },
];

export const rpmAlertBasedAssessmentForm = [
  {
    title: '#1 Did you speak to patient?',
    description:
      'Choose other if unlisted family member & type in their relationship. (Ex: Daughter in law) Add Name of Individual you spoke to in other.',
    type: 'radio',
    required: true,
    name: 'patient',
    options: [
      { value: 'Patient', label: 'Patient' },
      { value: 'Spouse', label: 'Spouse' },
      { value: 'Caregiver', label: 'Caregiver' },
      { value: 'Emergency Contact', label: 'Emergency Contact' },
      { value: null, label: 'Other' },
    ],
    extra: [
      {
        type: 'doubleRowInput',
        name: 'other',
        options: [
          { label: 'Other Member', value: 'other' },
          { label: 'Relationship', value: 'relationship' },
        ],
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#2 Mental Status Assessment',
    description:
      'Oriented and Coherent 1: Person 2: Person, place 3: Person, place, time 4: Person, place, time, situation?',
    type: 'radio',
    required: false,
    name: 'AOC',
    options: [
      { value: 'x1', label: 'x1' },
      { value: 'x2', label: 'x2' },
      { value: 'x3', label: 'x3' },
      { value: 'x4', label: 'x4' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#3 Appointments',
    type: 'datepicker',
    name: 'datepicker',
    required: false,
    options: [
      {
        value: 'last',
        label: "Last Doctor's Appointment",
        type: 'max',
      },
      {
        value: 'next',
        label: "Next Doctor's Appointment",
        type: 'min',
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#4 Patient is able to take another reading at time of call?',
    subtitle: 'Is the reading still out of bounds?',
    type: 'radio',
    required: true,
    name: 'isOneMoreReading',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    extra: [
      {
        type: 'radio',
        name: 'bounds',
        label: 'Is the reading still out of bounds?',
        options: booleanOptions,
        divider: true,
      },
      {
        type: 'input',
        name: 'repeatReadingWas',
        options: [
          { label: 'The repeat reading was:', value: 'repeatReadingWas' },
        ],
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#5 Signs and Symptoms of HYPERtension',
    type: 'radio-list',
    name: 'symptoms',
    required: true,
    options: hypertensionSymptoms,
    extra: [
      {
        name: 'symptomsOnset',
        type: 'text',
      },
    ],
    readingTypeFor: ['max'],
  },
  {
    title: '#5 Signs and Symptoms of HYPOtension',
    type: 'radio-list',
    name: 'symptoms',
    required: true,
    options: hypotensionSymptoms,
    extra: [
      {
        name: 'symptomsOnset',
        type: 'text',
      },
    ],
    readingTypeFor: ['min'],
  },
  {
    title: '#6 Did patient take BP medications before/after the reading?',
    type: 'radio',
    name: 'bpMedications',
    required: false,
    options: [
      { label: 'Before', value: 'before' },
      { label: 'After', value: 'after' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#7 Did patient take routine medications as prescribed?',
    type: 'radio',
    required: false,
    name: 'isTakeMedications',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#8 Did patient take any prescribed PRN medications?',
    type: 'radio',
    required: false,
    name: 'isTakePrnMedications',
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#9 Additional Details',
    type: 'text',
    required: false,
    name: 'details',
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#10 Factors Assessment',
    type: 'radio-list',
    required: false,
    options: factorsAssessment,
    name: 'factorsAssessment',
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#11 Nursing Intervention',
    type: 'checkbox',
    name: 'nursingIntervention',
    required: true,
    options: [
      {
        value: 'emergencyRoom',
        label: 'Emergency room/urgent care/911 assist',
      },
      {
        value: 'escalation',
        label: 'Escalation to clinic',
      },
      {
        value: 'nursingEducation',
        label: 'Nursing education',
      },
    ],
    extra: [
      {
        type: 'checkbox',
        name: 'notEscalated',
        options: [
          {
            value: 'notEscalatedRepeat',
            label: 'Not escalated (Repeat reading recovered)',
          },
          {
            value: 'notEscalatedError',
            label: 'Not escalated (Device/User error)',
          },
          {
            value: 'notEscalatedProtocol',
            label: 'Not escalated (Clinical Instructions)',
          },
        ],
      },
      {
        name: 'interventionDetails',
        type: 'text',
      },
    ],
    readingTypeFor: ['min', 'max'],
  },
  {
    title: '#12 Patient Understanding',
    description:
      'Patient verbalized understanding and is agreeable to nursing recommendation?',
    type: 'radio',
    required: false,
    name: 'verbalized',
    options: booleanOptions,
    readingTypeFor: ['min', 'max'],
  },
];

export const template = [
  // Did you speak to patient?
  {
    key: 'patient',
    value:
      'Nurse called patient for a critical blood pressure of {{{ reading }}} on {{{ dateOfReading }}}.',
  },
  {
    key: 'speakWithPatient',
    value:
      'Spoke with {{{ patient }}} and notified them they are on a recorded line for quality and compliance purposes.',
  },
  {
    key: 'speakWithOther',
    value:
      'Spoke with {{{ relationship }}} and notified them they are on a recorded line for quality and compliance purposes.Name of alternate contact: {{{ speakWithOther }}}, {{{ relationship }}}.',
  },
  // Mental Status Assessment
  {
    key: 'AOC',
    value: 'Patient is alert and oriented {{{ AOC }}}.\n',
  },
  // Appointments
  {
    key: 'lastAndNext',
    value:
      'Patient was last seen on {{{ last }}} and has an upcoming appointment on {{{ next }}}.',
  },
  {
    key: 'lastOnly',
    value: 'Patient was last seen by their provider on {{{ last }}}.',
  },
  {
    key: 'nextOnly',
    value: "Patient's next provider appointment is scheduled for {{{ next }}}.",
  },
  {
    key: 'hypoPreviewText',
    value: "Nurse assessed patient's critical blood pressure reading.",
  },
  {
    key: 'oneMoreReading', // is/is not
    value:
      '\nPatient {{{ oneMoreReadingText }}} able to take another reading at the time of call.',
  },
  {
    key: 'bounds', // is/is not
    value: 'The repeat reading {{{ boundsText }}} out of bounds.',
  },
  {
    key: 'repeatReadingWas',
    value: 'The repeat reading was {{{ repeatReadingWas }}}.',
  },
  {
    key: 'space',
    value: '\n',
  },
  {
    key: 'symptomsDenied',
    value: 'Patient denies any symptoms at this time.',
  },
  {
    key: 'symptomsEmergent',
    value: 'Patient confirms emergent symptoms {{{ confirmedSymptoms }}}.',
  },
  {
    key: 'symptomsRegularConfirmed',
    value: 'Patient confirms {{{ confirmedSymptoms }}}.',
  },
  {
    key: 'symptomsRegularDenied',
    value: 'Patient denies {{{ deniedSymptoms }}}.',
  },
  {
    key: 'symptomsOnset',
    value: '{{{ symptomsOnset }}}.',
  },
  {
    key: 'space',
    value: '\n',
  },
  {
    key: 'bpMedications', // before/after
    value:
      'Nurse verified patient took medications {{{ bpMedications }}} their reading.',
  },
  {
    key: 'routineMedications', // did/did not
    value:
      'Patient {{{ routineMedicationsText }}} taking routine medications as prescribed.',
  },
  {
    key: 'prnMedications', // confirms/denies
    value:
      'Patient {{{ prnMedicationsText }}} taking PRN medications as prescribed.',
  },
  {
    key: 'details',
    value: '{{{ details }}}.',
  },
  {
    key: 'confirmedFactors',
    value:
      'Nurse assessed factors that may impact blood pressure readings.Patient confirms the following: {{{ confirmedFactors }}}.',
  },
  {
    key: 'deniedFactors',
    value: 'Patient denies the following: {{{ deniedFactors }}}.',
  },
  {
    key: 'space',
    value: '\n',
  },
  {
    key: 'emergencyRoom',
    value:
      'Patient requires immediate care.Nurse provided emergency assistance to patient.',
  },
  {
    key: 'escalation',
    value:
      'Patient requires provider intervention.Nurse escalated the reading to the clinic according to run book protocol.',
  },
  {
    key: 'nursingEducation',
    value: 'Nurse provided education to patient.',
  },
  {
    key: 'notEscalated', // list of selected reasons to not escalate
    value: 'Reading not escalated due to {{{ notEscalated }}}.',
  },
  {
    key: 'interventionDetails',
    value: '{{{ interventionDetails }}}.',
  },
  {
    key: 'verbalized', // is/is not
    value:
      '\nPatient verbalized understanding and {{{ verbalizedText }}} agreeable to nursing recommendation.',
  },
];
