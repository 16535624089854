import { prepareDoctorName } from 'infrastructure/functions';

import type { LoginStatus } from 'infrastructure/enums';
import type { Nullable } from 'infrastructure/types';
import type { IAgencyDoctor } from 'infrastructure/interfaces';

export class AgencyDoctor {
  addressLine1: string;
  addressLine2: string;
  agencies: { guid: string; legalname: string }[];
  alertEmail: boolean;
  alertSms: boolean;
  birthDate: string;
  city: string;
  createdAt: Date;
  email?: Nullable<string>;
  firstName?: string;
  guid: string;
  lastName?: string;
  loginGuid: string;
  middleName?: Nullable<string>;
  mobileNumber: string;
  npiNumber: Nullable<string>;
  numberType: Nullable<string>;
  primaryLanguage: string;
  professionType: string;
  roles: { guid: string; name: string }[];
  secondaryLanguage: string;
  state: string;
  status: LoginStatus;
  timezone?: Nullable<string>;
  title?: string;
  workNumber?: Nullable<string>;
  zip: Nullable<string>;

  constructor(data: IAgencyDoctor) {
    this.addressLine1 = data.addressLine1;
    this.addressLine2 = data.addressLine2;
    this.agencies = data.agencies;
    this.alertEmail = data.alertEmail;
    this.alertSms = data.alertSms;
    this.birthDate = data.birthDate;
    this.city = data.city;
    this.createdAt = new Date(data.createdAt);
    this.email = data.email;
    this.firstName = data.firstName;
    this.guid = data.guid;
    this.lastName = data.lastName;
    this.loginGuid = data.loginGuid;
    this.middleName = data.middleName;
    this.mobileNumber = data.mobileNumber;
    this.npiNumber = data.npiNumber;
    this.numberType = data.numberType;
    this.primaryLanguage = data.primaryLanguage;
    this.professionType = data.professionType;
    this.roles = data.roles;
    this.secondaryLanguage = data.secondaryLanguage;
    this.state = data.state;
    this.status = data.status;
    this.timezone = data.timezone;
    this.title = data.title;
    this.workNumber = data.workNumber;
    this.zip = data.zip;
  }

  get fullName() {
    return prepareDoctorName({
      title: this.title,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      professionType: this.professionType,
    });
  }
}
