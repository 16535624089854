import { useMutation, useQueryClient } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { useDispatch, useSelector } from 'react-redux';

import { getSmsDashboardConversationsKey } from './useConversations';
import { showResult } from '../utils';

import type { ConversationType } from 'infrastructure/interfaces';
import type { RootDispatch, RootState } from 'store';

interface Props {
  type: ConversationType;
  read: string;
  closed: string;
  lastMessageDate: string;
  searchQuery: string;
  onSuccess: () => void;
}

interface FnProps {
  conversationId: number;
  spam: boolean;
}

const markConversationFn = async ({ conversationId, spam }: FnProps) => {
  const API_CLOSE_REOPEN_CONVERSATION = `core/conversations/spam`;

  const { data, error } = await dataService.createList(
    API_CLOSE_REOPEN_CONVERSATION,
    {
      conversationId,
      spam,
    },
  );

  if (error) {
    showResult(error);
    throw new Error(error);
  }

  return data;
};

export const useMarkConversationAsSpam = (props: Props) => {
  const queryClient = useQueryClient();
  const { currentConversation } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const {
    smsDashboard: { changeCurrentConversation },
  } = useDispatch<RootDispatch>();

  const { mutate, isLoading, ...other } = useMutation({
    mutationFn: markConversationFn,
    onSuccess(data) {
      const conversationsKey = getSmsDashboardConversationsKey({
        type: props.type,
        read: props.read,
        closed: props.closed,
        lastMessageDate: props.lastMessageDate,
        searchQuery: props.searchQuery,
      });
      props.onSuccess();
      changeCurrentConversation({
        ...currentConversation,
        spam: data.spam,
      });
      queryClient.invalidateQueries({ queryKey: conversationsKey });
    },
  });

  return {
    markConversationAsSpam: mutate,
    markConversationAsSpamLoading: isLoading,
    ...other,
  };
};
