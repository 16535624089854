export enum RpmStatuses {
  PatientReferral = 'patient_referral',
  ReferralOnHold = 'referral_on_hold',
  PatientRefused = 'patient_refused',
  ReferralWithdrawn = 'referral_withdrawn',
  CallingQueue = 'calling_queue',
  Inactive = 'inactive_patient',
  PatientNoContact = 'patient_no_contact',
  PatientNoContactDay20 = 'patient_no_contact_day_20',
  PatientNoContactDay30 = 'patient_no_contact_day_30',
  PatientNoContactClosed = 'patient_no_contact_closed',
  PatientRefusedConnectivityTechBarier = 'patient_refused_connectivity_or_tech_barier',
  PatientRefusedCopayInsurance = 'patient_refused_copay_insurance',
  PatientRefusedNotInterestedNotNecessarySuspectScam = 'patient_refused_not_interested_not_necessary_suspect_scam',
  PatientRefusedExpired = 'patient_refused_expired',
  PatientRefusedThereIsNoOneToHelp = 'patient_refused_there_is_no_one_to_help',
  PatientRefusedHasANewPhysician = 'patient_refused_has_a_new_physician',
  PatientRefusedWantsToDiscussWithPhysician = 'patient_refused_wants_to_discuss_with_physician',
  PatientRefusedWrongContactInformation = 'patient_refused_wrong_contact_information',
  PatientRefusedNoContact = 'patient_refused_no_contact',
  InsuranceRefused = 'insurance_refused',
  PatientNotified = 'patient_notified',
  Onboarding = 'onboarding',
  Active = 'active',
  InactiveBadNoSignalConnectivity = 'inactive_bad_no_signal_connectivity',
  InactiveChangedDoctor = 'inactive_changed_doctor',
  InactiveClientRequestInconvenient = 'inactive_client_request_inconvenient',
  InactiveClientRequestMoving = 'inactive_client_request_moving',
  InactiveClientRequestOther = 'inactive_client_request_other',
  InactiveExpired = 'inactive_expired',
  InactiveHealthPlanRequestGoalsMet = 'inactive_health_plan_request_goals_met',
  InactiveHealthPlanRequestNonCompliant = 'inactive_health_plan_request_non_compliant',
  InactiveHealthPlanRequestOther = 'inactive_health_plan_request_other',
  InactiveHospice = 'inactive_hospice',
  InactiveCopay = 'inactive_copay',
  InactiveLongTermCareRehab = 'inactive_long_term_care_rehab',
  InactiveLossOfEligibility = 'inactive_loss_of_eligibility',
  InactivePhysicianRequest = 'inactive_physician_request',
  OnboardingUnsuccessful = 'onboarding_unsuccessful',
  PendingReview = 'pending_review',
  PatientNoContacts = 'patient_no_contact',
  PatientRefusedConnectivityOrTechBarier = 'patient_refused_connectivity_or_tech_barier',
  PatientRefusedNoOneToHelp = 'patient_refused_there_is_no_one_to_help',
  PatientRefusedHasNewPhysician = 'patient_refused_has_a_new_physician',
  OnBoarding = 'onboarding',
}
