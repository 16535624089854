import BaseCheckbox from 'components/atoms/baseCheckbox';

import type { FC } from 'react';
import type { IFormSubItemProps } from './types';
import type { FormikValues } from 'formik';

const CheckboxItem: FC<IFormSubItemProps & { generalName: string }> = ({
  formik,
  option,
  generalName,
}) => {
  const onHandleCheckbox = <T extends FormikValues>(
    key: keyof T & string,
    name: string,
    val: boolean,
  ) => {
    const oldValues = Array.isArray(formik.values[key])
      ? formik.values[key]
      : [];

    if (val) {
      formik.setFieldValue(key, [...oldValues, name]);
    } else {
      const newValues = oldValues?.filter((value) => value !== name);
      formik.setFieldValue(key, newValues);
    }
  };

  return (
    <BaseCheckbox
      label={option.label}
      onChange={(val) => onHandleCheckbox(generalName, option.label, val)}
      checked={formik.values[generalName].includes(option.label)}
    />
  );
};

export default CheckboxItem;
