import { useInfiniteQuery } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { showResult } from 'infrastructure/hooks/utils';
import { apiUrlGenerator } from 'infrastructure/hooks/use-api';

import type { IMessagesListRes } from 'infrastructure/interfaces';

interface Props {
  conversationId: number | string;
  searchQuery?: string;
  nextCursor?: Date | string;
  prevCursor?: Date | string;
  enabled?: boolean;
  searchQueryDate?: string;
}

export const PAGE_SIZE = 15;

export const getSmsDashboardMessagesFn = async (props: Props) => {
  const url = (id: string) => `core/conversations/${id}/messages`;

  const { data, error } = await dataService.getList<IMessagesListRes>(
    apiUrlGenerator(url(String(props.conversationId)), {
      ...props,
      pageSize: PAGE_SIZE,
    }),
  );

  if (props.prevCursor) {
    data?.items.reverse();
  }

  if (error) {
    showResult('Unable to retrieve messages');
    throw new Error(error);
  }

  return data;
};

export const getSmsDashboardMessagesKey = (props: Props) => [
  'getSmsDashboardMessages',
  props,
];

export const useSmsDashboardMessages = ({ enabled, ...props }: Props) => {
  const {
    data: messages,
    refetch,
    isFetching,
    isLoading,
    isRefetching,
    ...other
  } = useInfiniteQuery({
    queryKey: getSmsDashboardMessagesKey(props),
    queryFn: async ({ pageParam }) => {
      return getSmsDashboardMessagesFn({
        ...props,
        ...pageParam,
      });
    },
    getNextPageParam: (lastPage, all) => {
      const countOfLastPageItems = lastPage?.items?.length ?? 0;
      return countOfLastPageItems === PAGE_SIZE || all.length === 1
        ? { nextCursor: lastPage?.items.at(-1)?.createdAt, searchQueryDate: '' }
        : false;
    },
    getPreviousPageParam: (prevPage, all) => {
      const countOfPrevPagePageItems = prevPage?.items?.length ?? 0;
      return countOfPrevPagePageItems === PAGE_SIZE || all.length === 1
        ? { prevCursor: prevPage?.items.at(0)?.createdAt, searchQueryDate: '' }
        : false;
    },
    keepPreviousData: true,
    enabled,
    refetchOnMount: false,
  });

  return {
    messages: messages?.pages.flat(),
    fetchMessages: refetch,
    messagesLoading: isLoading || isFetching || isRefetching,
    isLoading,
    isFetching,
    ...other,
  };
};
