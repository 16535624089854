export const ccmClinicalNotesTemplate = `Successful {{data.appttype}} appointment of a {{data.age}} year old {{data.gender}} patient. The patient is receiving {{data.program}} services for the management of their chronic conditions under the care of {{data.doctor}}. {{#conditionsYes}}The patient has been diagnosed with the following conditions: {{data.icdCodes}}.{{/conditionsYes}}{{^conditionsYes}}The patient has been diagnosed with no conditions.{{/conditionsYes}} {{#medicalHistoryYes}}The patient presents with a past medical history of {{data.dx1}}.{{/medicalHistoryYes}} {{#allergiesYes}}The patient presents with the following allergies: {{data.allergies}}.{{/allergiesYes}} The agent spoke with the {{data.patientcaregiver}} when completing this appointment.{{#mentalYes}} Patient is alert, oriented, and coherent x{{data.mentalstatus}}.{{/mentalYes}}

{{#nervousYes}}When asked if the patient is feeling nervous, anxious, or on edge, they responded {{data.nervous}}.{{/nervousYes}} {{#worryYes}}When asked if the patient is unable to stop or control worrying, they responded {{data.worry}}.{{/worryYes}} {{#interestYes}}When asked if the patient experiences little interest or pleasure in doing things, they responded {{data.interest}}.{{/interestYes}} {{#downYes}}When asked if the patient is feeling down, depressed, or hopeless, they responded {{data.down}}.{{/downYes}} {{#anxietyYes}}The patient’s anxiety score is {{data.anxiety}}.{{/anxietyYes}} {{#depressionYes}}The patient’s depression score is {{data.depression}}.{{/depressionYes}}

{{#pastAppointmentYes}}When asked about recent past appointments, the {{data.patientcaregiver}} communicated they had an appointment with their {{data.pastapptanwsers}}.{{/pastAppointmentYes}}{{^pastAppointmentYes}}The {{data.patientcaregiver}} did not communicate information about past appointments at this time.{{/pastAppointmentYes}}{{#labsYes}} Currently, they are pending {{data.labs}} results at this time.{{/labsYes}}{{#upcomingAppointmentYes}} When asked about upcoming appointments, the {{data.patientcaregiver}} communicated they have an appointment with their {{data.upcomingapptanwsers}}.{{/upcomingAppointmentYes}}{{^upcomingAppointmentYes}} The {{data.patientcaregiver}} did not communicate information about any upcoming appointments at this time.{{/upcomingAppointmentYes}} The {{data.patientcaregiver}} verbalized they {{data.medications}} taking medications as prescribed.{{#nonComplaintYes}} {{data.medwhy}}.{{/nonComplaintYes}}{{#activemedicationsYes}} The Agent reviewed and confirmed the patient is taking the following medications: {{data.patientmedications}} at this time.{{/activemedicationsYes}}{{^activemedicationsYes}} The {{data.patientcaregiver}} was not willing to review the medications they are actively taking at this time.{{/activemedicationsYes}}{{#medChangesYes}} The {{data.patientcaregiver}} verbalized the following changes in their medication: {{data.medchanges}}.{{/medChangesYes}}{{^medChangesYes}} The {{data.patientcaregiver}} verbalized there are no changes in their medication at this time.{{/medChangesYes}}{{#lastReadingYes}} Last RPM Reading: {{data.rpm}}.{{/lastReadingYes}}{{#vitalsYes}} {{data.vitals}}.{{/vitalsYes}}

{{#appetiteYes}}The patient verbalized their appetite is {{data.appetite}} over the past 30 days.{{/appetiteYes}}{{^appetiteYes}}The {{data.patientcaregiver}} was not willing to discuss appetite at this time.{{/appetiteYes}}{{#nutritionYes}} The patient verbalized they regularly consume {{data.nutrition}} over the past 30 days.{{/nutritionYes}}{{^nutritionYes}} The {{data.patientcaregiver}} was not willing to discuss nutrition at this time.{{/nutritionYes}}{{#waterYes}} The patient verbalized they usually drink {{data.water}} over the past 30 days.{{/waterYes}}{{^waterYes}} The {{data.patientcaregiver}} was not willing to discuss their water intake at this time.{{/waterYes}}{{#painYes}} The {{data.patientcaregiver}} states they are experiencing pain. The pain {{data.location}} with a severity of {{data.severity}}. The patient is taking {{data.painmeds}} to manage their pain.{{/painYes}}{{^painYes}} The {{data.patientcaregiver}} states they have no pain at this time.{{/painYes}}{{#skinYes}} The patient has the following skin concerns: {{data.skin}} at this time.{{/skinYes}}{{^skinYes}} The {{data.patientcaregiver}} was not willing to discuss skin concerns at this time.{{/skinYes}}{{#respiratoryYes}} The patient has the following respiratory concerns: {{data.respiratory}} at this time.{{/respiratoryYes}}{{^respiratoryYes}} The {{data.patientcaregiver}} was not willing to discuss respiratory concerns at this time.{{/respiratoryYes}}{{#sleepYes}} The {{data.patientcaregiver}} states they sleep on average {{data.sleep}} over the past 30 days.{{/sleepYes}}{{^sleepYes}} The {{data.patientcaregiver}} was not willing to discuss their sleep patterns at this time.{{/sleepYes}}

{{#bowelYes}}Patient has the following bowel concerns: {{data.bowel}} at this time.{{/bowelYes}}{{^bowelYes}}The {{data.patientcaregiver}} was not willing to discuss their bowel concerns at this time.{{/bowelYes}}{{#bladderYes}} Patient has the following bladder concerns: {{data.bladder}} at this time.{{/bladderYes}}{{^bladderYes}} The {{data.patientcaregiver}} was not willing to discuss their bladder concerns at this time.{{/bladderYes}}{{#dmeYes}} The patient uses the following DMEs: {{data.DME}} at this time.{{/dmeYes}}{{^dmeYes}} The {{data.patientcaregiver}} was not willing to discuss their use of DMEs or ADLs at this time.{{/dmeYes}}{{#safetyYes}} There are the following safety concerns: {{data.safety}} at this time.{{/safetyYes}}{{^safetyYes}} The {{data.patientcaregiver}} was not willing to discuss their safety concerns at this time.{{/safetyYes}} The patient verbalized they had {{data.falls}} {{data.falltype}} in the past 30 days. The patient verbalized they had {{data.emergency}} {{data.ERdetail}} in the past 30 days.

{{#prevmonthgoalsYes}}The Agent reviewed last month’s goals and the {{data.patientcaregiver}} verbalized {{data.prevmonthgoals}}.{{/prevmonthgoalsYes}} {{data.goals}}{{#resourcesYes}} The following resources were provided to the patient: {{data.resources}}.{{/resourcesYes}}{{#educationYes}} The patient would like education on {{data.education}} sent by {{data.sendEducationHow}} {{data.sendEducationHowNote}}{{/educationYes}}{{^educationYes}} The patient declined having education sent to them at this time{{/educationYes}}. The Agent educated the patient on practicing preventative care (such as diet modifications) for sustained health using the teach back method to improve patient understanding and adherence, decrease call backs and canceled appointments and to improve patient satisfaction and outcomes. The {{data.patientcaregiver}} verbalized understanding of the education and the agent was able to answer any and all questions at this time.{{#extraYes}} {{data.extra}}.{{/extraYes}} The patient agreed to follow up contact on a regular basis.{{#nextApptWillBeByPhoneYes}} Their next scheduled appointment is {{data.nextapptdate}} at {{data.nextappttime}}.{{/nextApptWillBeByPhoneYes}}{{#nextApptWillBeBySmsOrEmailYes}} Their next appointment will be by {{data.nextApptWillBeBy}}.{{/nextApptWillBeBySmsOrEmailYes}} The patient was advised to call Accuhealth at 1-866-836-7260 or text 78616 should they have any questions, concerns or medical needs before our next scheduled follow up.
`;
