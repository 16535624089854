import BannerActionMailIcon from 'components/atoms/icons/bannerActionMailIcon';
import BannerActionDownloadIcon from 'components/atoms/icons/bannerActionDownloadIcon';
import { makeid } from 'utils/helpers/makeid';
import { useTranslation } from 'react-i18next';
import { usePatientInfo } from 'infrastructure/hooks/patient/use-patient-info';
import { useClinicInfo } from 'infrastructure/hooks/clinic';
import { ClinicStatus } from 'infrastructure/enums';
import useUser from 'utils/useUser';

import { useDownloadReport } from './useDownloadReport';
import { useConsentFormReport } from './useConsentFormReport';
import { useVitalsReport } from './useDownloadVitalsReport';
import { usePlanOfCareReport } from './useDownloadPlanOfCareReport';
import { useGetIafReport } from './useGetIafReport';
import { useSendBingoCard } from './useSendBingoCard';

interface Props {
  patientGuid: string;
  disabledCreateInternalAdmissionForms?: boolean;
}

export const useActionButtons = ({
  patientGuid,
  disabledCreateInternalAdmissionForms,
}: Props) => {
  const { isPatient } = useUser();
  const { mutate: getEmailReport, isLoading: emailReportLoading } =
    useDownloadReport();
  const { mutate: getDownloadReport, isLoading: downloadReportLoading } =
    useDownloadReport();
  const { sendConsentFormReport, isLoading } = useConsentFormReport({
    patientGuid,
  });
  const { getVitalsReport, vitalsReportLoading } = useVitalsReport();
  const { getPlanOfCareReport, planOfCareReportLoading } =
    usePlanOfCareReport();
  const { getIfaReport, ifaReportLoading } = useGetIafReport();
  const { sendBingoCard, sendBingoCardLoading } = useSendBingoCard();
  const { t } = useTranslation();

  const actionButtons = [
    {
      key: makeid(5),
      label: t('controls.sendEmailReport'),
      Icon: BannerActionMailIcon,
      loading: emailReportLoading,
      dataCy: 'ccm-banner_send-email-report-action-btn',
      handleClick: () =>
        getEmailReport({
          patientGuid,
          type: 'email',
        }),
    },
    {
      key: makeid(5),
      label: t('controls.carePlan'),
      Icon: BannerActionDownloadIcon,
      loading: downloadReportLoading,
      dataCy: 'ccm-banner_care-plan-report-action-btn',
      handleClick: () =>
        getDownloadReport({
          patientGuid,
          type: null,
        }),
    },
    {
      key: makeid(5),
      label: t('controls.consentForm'),
      Icon: BannerActionDownloadIcon,
      handleClick: () => sendConsentFormReport(),
      loading: isLoading,
      dataCy: 'ccm-banner_consent-form-report-action-btn',
    },
  ];

  const actionDropdownButtons = [
    {
      key: makeid(5),
      label: t('controls.vitalsReport'),
      dataCy: 'rpm-banner_vitals-report-action-dropdown',
      loading: vitalsReportLoading,
      options: [
        {
          label: t('controls.sendOnEmail'),
          handleClick: () =>
            getVitalsReport({
              patientGuid,
              type: 'email',
            }),
          dataCy: 'rpm-banner_vitals-report-email-dropdown-btn',
        },
        {
          label: t('controls.download'),
          handleClick: () =>
            getVitalsReport({
              patientGuid,
              type: null,
            }),
          dataCy: 'rpm-banner_vitals-report-download-dropdown-btn',
        },
      ],
    },
  ];

  if (!isPatient) {
    actionDropdownButtons.push({
      key: makeid(5),
      label: 'Plan of Care',
      dataCy: 'rpm-banner_plane-of-care-action-dropdown',
      loading: planOfCareReportLoading,
      options: [
        {
          label: t('controls.sendOnEmail'),
          handleClick: () =>
            getPlanOfCareReport({
              patientGuid,
              type: 'email',
            }),
          dataCy: 'rpm-banner_plane-of-care-email-dropdown-btn',
        },
        {
          label: t('controls.download'),
          handleClick: () =>
            getPlanOfCareReport({
              patientGuid,
              type: null,
            }),
          dataCy: 'rpm-banner_plane-of-care-download-dropdown-btn',
        },
      ],
    });
  }

  const { patientInfo } = usePatientInfo({
    patientGuid,
  });
  const { clinicInfo, loading } = useClinicInfo({
    clinicGuid: patientInfo?.clinicGuid ?? '',
  });

  const adminActionButtons = [
    {
      key: makeid(5),
      label: t('controls.sendBingoCard'),
      Icon: BannerActionMailIcon,
      loading: sendBingoCardLoading,
      dataCy: 'admin-banner_send-bingo-card-action-btn',
      handleClick: () => sendBingoCard({ patientGuid }),
      disabled: loading || clinicInfo?.status === ClinicStatus.Inactive,
    },
    {
      key: makeid(5),
      label: t('controls.iaf'),
      Icon: BannerActionDownloadIcon,
      loading: ifaReportLoading,
      dataCy: 'admin-banner_ifa-report-action-btn',
      handleClick: () => getIfaReport({ patientGuid }),
      disabled: !disabledCreateInternalAdmissionForms,
    },
  ];

  return { actionButtons, actionDropdownButtons, adminActionButtons };
};
