import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Dropdown, DropdownToggle } from 'reactstrap';
import {
  isPatient,
  isAdmin,
  isRoleNameAdministrator,
  isPractice,
  isTurkUserRole,
  isAgencyDoctor,
  agencyDoctorWithRPMAndCCMRoles,
} from 'utils/userTypeHelper';
import { isCriticalOrAtRiskAlertingRole } from 'utils/accessManager/helpers';
import useUser from 'utils/useUser';
import { PLATFORM_CONFIG } from 'utils/accessManager/constants';
import Logo from 'assets/img/header.svg';
import ProfileMenuIcon from 'components/atoms/icons/profileMenuIconNew';
import LogoutIcon from 'components/atoms/icons/logoutIconNew';
import ProfileIcon from 'components/atoms/icons/profileIconNew';
import Button from 'components/atoms/button';
import { useDispatch } from 'react-redux';
import useLogout from 'utils/hooks/auth/useLogout';
import paths from 'constants/paths';
import { useAppDispatch, useAppSelector } from 'store';
import { ModuleType } from 'infrastructure/enums';

import s from './styles.module.scss';
import ExpandItem from './expand-item';

const HeaderNavbarMobileNew = ({
  formsOptions,
  adminPanelOptions,
  practiceOptions,
  patientsOptions,
  firstName,
  lastName,
  title,
  disableNavigation,
}) => {
  const { userType, roleNames, platformMode, isMobileDevice } = useUser();
  const { logout } = useLogout();
  const navigate = useNavigate();
  const isTurkUser = isTurkUserRole(roleNames);

  const {
    user: { switchPlatformMode },
  } = useDispatch();

  const homeUrl = isTurkUserRole(roleNames)
    ? '/turk-dashboard'
    : PLATFORM_CONFIG[platformMode]?.dashboard;

  const showNavigation =
    !disableNavigation && !isPatient(userType) && !isTurkUserRole(roleNames);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMiniNavOpen = useAppSelector(
    (store) => store.modalWindows.isMobileMiniNavOpen,
  );
  const {
    modalWindows: { toggleOpenMobileMiniNav },
  } = useAppDispatch();

  const toggle = () =>
    setDropdownOpen((prevState) => {
      if (isMiniNavOpen) toggleOpenMobileMiniNav();
      return !prevState;
    });

  const togglePlatformMode = () => {
    const newMode = switchPlatformMode({});
    navigate(PLATFORM_CONFIG[newMode].dashboard);
  };
  const handleSwitchPlatform = (mode) => {
    if (platformMode === mode) return;

    togglePlatformMode();
  };

  let nameString = '';
  if (title !== '' && firstName !== '' && lastName !== '') {
    nameString = `${title} ${firstName} ${lastName}`;
  }
  if (title === '' && firstName !== '' && lastName !== '') {
    nameString = `${firstName} ${lastName}`;
  }

  return (
    <>
      {isMobileDevice && (
        <Navbar className="mobile-navigation-bar" tag="div">
          <Link className="logo" data-cy="logged-in-logo" to={homeUrl}>
            <img src={Logo} alt="logo" width={103} height={40} />
          </Link>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            nav
            inNavbar
            className={s['navbar-burger']}
            tag="div"
          >
            <DropdownToggle nav data-cy="nav-profile__burger-menu">
              <ProfileMenuIcon animate={dropdownOpen} />
            </DropdownToggle>
            {dropdownOpen && (
              <div className={s['mobile-menu']}>
                <div className={s.profile}>
                  <Link
                    className={s['profile-name']}
                    to={
                      !isCriticalOrAtRiskAlertingRole(roleNames)
                        ? paths.profile
                        : '/'
                    }
                    data-cy="nav-profile__edit"
                    onClick={toggle}
                  >
                    <ProfileIcon className="profile-name-icon" />
                    <p>{nameString}</p>
                  </Link>
                </div>
                <div className={s.divider} />
                {!isTurkUser &&
                  !isPatient(userType) &&
                  !disableNavigation &&
                  PLATFORM_CONFIG[platformMode]?.switch &&
                  !disableNavigation &&
                  (isPractice(userType) ||
                    isAdmin(userType) ||
                    agencyDoctorWithRPMAndCCMRoles(userType, roleNames)) && (
                    <div className={s['switch-wrapper']}>
                      <Button
                        text={PLATFORM_CONFIG[
                          ModuleType.CCM
                        ].switch.toUpperCase()}
                        className={s['switch-btn']}
                        data-active-platformMode={
                          platformMode === ModuleType.RPM
                        }
                        onClick={() => {
                          handleSwitchPlatform(ModuleType.RPM);
                          toggle();
                        }}
                        data-cy="nav-profile__platform-mode-rpm-btn"
                        tabIndex={0}
                        role="menuitem"
                      />
                      <div />
                      <Button
                        text={PLATFORM_CONFIG[
                          ModuleType.RPM
                        ].switch.toUpperCase()}
                        className={s['switch-btn']}
                        data-active-platformMode={
                          platformMode === ModuleType.CCM
                        }
                        onClick={() => {
                          handleSwitchPlatform(ModuleType.CCM);
                          toggle();
                        }}
                        data-cy="nav-profile__platform-mode-ccm-btn"
                        tabIndex={0}
                        role="menuitem"
                      />
                    </div>
                  )}
                <div className={s.divider} />
                <nav className={s.nav}>
                  <ul>
                    {showNavigation && (
                      <>
                        <ExpandItem
                          options={formsOptions}
                          label="Forms"
                          onClick={toggle}
                        />
                        {(isRoleNameAdministrator(roleNames) ||
                          isAdmin(userType)) &&
                          !disableNavigation && (
                            <ExpandItem
                              options={adminPanelOptions}
                              label="Admin panel"
                              reactLink
                              onClick={toggle}
                            />
                          )}
                        {practiceOptions.length > 0 &&
                          !disableNavigation &&
                          platformMode === ModuleType.CCM && (
                            <ExpandItem
                              options={practiceOptions}
                              label="Reports"
                              reactLink
                              onClick={toggle}
                            />
                          )}
                        {practiceOptions.length > 0 &&
                          !disableNavigation &&
                          platformMode === ModuleType.RPM && (
                            <ExpandItem
                              options={practiceOptions}
                              label="Reports"
                              reactLink
                              onClick={toggle}
                            />
                          )}
                        {patientsOptions.length > 0 && !disableNavigation && (
                          <ExpandItem
                            options={patientsOptions}
                            label="Patients"
                            reactLink
                            onClick={toggle}
                          />
                        )}
                      </>
                    )}
                  </ul>
                </nav>
                {!isTurkUser && !isPatient(userType) && !disableNavigation && (
                  <div className={s.links}>
                    {!isPatient(userType) && (
                      <>
                        <a
                          href={paths.telmed}
                          target="_blank"
                          data-cy="nav-profile__telemed"
                          className={[s.links__item]}
                        >
                          Telemed
                        </a>
                        <div className={s.divider} />
                        <a
                          href={paths.bucketReport}
                          target="_blank"
                          data-cy="nav-profile__bucket-report"
                          className={[s.links__item]}
                        >
                          Files.com
                        </a>
                      </>
                    )}
                    {(isAdmin(userType) || isAgencyDoctor(userType)) &&
                      !disableNavigation && (
                        <>
                          <div className={s.divider} />
                          <a
                            href={paths.awsConnectURL}
                            target="_blank"
                            data-cy="nav-profile__aws-connect"
                            className={[s.links__item]}
                          >
                            Amazon connect
                          </a>
                        </>
                      )}
                  </div>
                )}
                <div
                  className={[s.logout]}
                  data-cy="nav-profile__logout"
                  onClick={() => logout()}
                >
                  <p>Log Out</p>
                  <LogoutIcon />
                </div>
              </div>
            )}
          </Dropdown>
        </Navbar>
      )}
    </>
  );
};

HeaderNavbarMobileNew.propTypes = {
  adminPanelOptions: PropTypes.arrayOf(PropTypes.shape()),
  practiceOptions: PropTypes.arrayOf(PropTypes.shape()),
  patientsOptions: PropTypes.arrayOf(PropTypes.shape()),
  formsOptions: PropTypes.arrayOf(PropTypes.shape()),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  disableNavigation: PropTypes.bool,
};

export default HeaderNavbarMobileNew;
