import BaseButton from 'components/atoms/baseButton';
import { useTranslation } from 'react-i18next';
import BaseSpinWrapper from 'components/atoms/base-spin-wrapper';
import BaseTableNew from 'components/atoms/base-table/new';
import AppointmentMethodSelectBox from 'components/molecules/appointment-method-select-box';
import BaseModal from 'components/atoms/base-modal';
import BaseForm from 'components/atoms/base-form';
import BaseDatepicker from 'components/atoms/base-datepicker';
import BaseFormItem from 'components/atoms/base-form/item';
import DoctorAndAgencyDoctorSelectBox from 'components/molecules/all-doctor-select-box';
import BaseGrid from 'components/atoms/base-grid';
import EmptyTablePlaceholder from 'components/atoms/base-empty-table-placeholder';
import { RoleGroups } from 'infrastructure/consts/role-groups';

import { columns } from './columns';
import { useMonthlyAppointments } from './hooks/use-monthly-appointments';
import s from './styles.module.scss';

import type { FC } from 'react';

interface Props {
  patientGuid: string;
}

const MonthlyAppointmentTable: FC<Props> = ({ patientGuid }) => {
  const { t } = useTranslation();

  const {
    appointmentCompletionData,
    page,
    isLoading,
    PER_PAGE,
    open,
    addingLoading,
    formik,
    disableButton,
    setPage,
    toggleOpen,
    onCancelForm,
    submitForm,
  } = useMonthlyAppointments({ patientGuid });

  return (
    <>
      <BaseSpinWrapper spinning={isLoading}>
        <div className={s.monthly}>
          <div className={s['monthly-title-wrapper']}>
            <h5 className={s.title}>
              {t('labels.monthlyAppointmentCompletionLog')}
            </h5>
            <BaseButton
              dataCy="addAppointment-button"
              className={s['monthly-title-btn']}
              label={`+ ${t('controls.addAppointment')}`}
              onClick={toggleOpen}
              disabled={disableButton}
            />
          </div>
          <div className={s['monthly-body']}>
            <BaseTableNew
              items={appointmentCompletionData?.items ?? []}
              columns={columns({ disabledAction: disableButton })}
              bordered
              pagination={{
                current: page,
                pageSize: PER_PAGE,
                total: appointmentCompletionData?.count ?? 0,
                position: ['bottomCenter'],
              }}
              onChangePage={(newPage) => {
                setPage(newPage);
              }}
              dataCy="next-appointment-table"
              // eslint-disable-next-line prettier/prettier
              emptyText={
                <EmptyTablePlaceholder
                  text={t('labels.noCompletedAppointmentsLogged')}
                />
                // eslint-disable-next-line prettier/prettier
              }
            />
          </div>
        </div>
      </BaseSpinWrapper>
      <BaseModal
        title={t('labels.logCompletedAppointment')}
        open={open}
        onCancel={toggleOpen}
      >
        <BaseForm
          formik={formik}
          withControls
          plaintext={false}
          readonly={false}
          onSave={submitForm}
          onCancel={onCancelForm}
          loading={addingLoading}
        >
          <BaseGrid rowGap={4}>
            <BaseFormItem
              required
              name="completedDate"
              label={t('labels.appointmentCompletedOn')}
              isBlackLabel
            >
              <BaseDatepicker />
            </BaseFormItem>
            <BaseFormItem
              required
              name="completedBy"
              label={t('labels.completedBy')}
              isBlackLabel
            >
              <DoctorAndAgencyDoctorSelectBox
                patientGuid={patientGuid}
                placeholder="Search"
                onChange={(value, option) => {
                  formik.setFieldValue('completedBy', value);
                  formik.setFieldValue('type', option.record?.type);
                }}
                roles={RoleGroups.AppointmentCompletedByAllowedRoles}
              />
            </BaseFormItem>
            <BaseFormItem
              required
              name="appointmentMethod"
              label={t('labels.appointmentMethod')}
              isBlackLabel
            >
              <AppointmentMethodSelectBox
                isCompletionMethods
                placeholder="Select"
              />
            </BaseFormItem>
          </BaseGrid>
        </BaseForm>
      </BaseModal>
    </>
  );
};
export default MonthlyAppointmentTable;
