import React, { useState } from 'react';
import NewMessageModal from 'components/molecules/smsDashboard/newMessageModal';
import { useSelector } from 'react-redux';
import useUser from 'utils/useUser';
import { PlatformEnums } from 'infrastructure/enums/platformEnums';
import { MESSAGING_NOT_ALLOWED } from 'constants/messages';
import { ACCUHEALTH_AGENCY_GUID_BY_ENV } from 'constants/guids';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import ChatPlusIcon from 'components/atoms/icons/chatPlusIcon';
import BaseButton from 'components/atoms/baseButton';

import s from './styles.module.scss';

import type { RootState } from 'store';

const NewConversationButton = () => {
  const { agencies, userType } = useUser();
  const { conversationType } = useSelector(
    (state: RootState) => state.smsDashboard,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isAccuHealthHha =
    userType === 'homeHealth' &&
    agencies.some(
      (agency: any) => agency.guid === ACCUHEALTH_AGENCY_GUID_BY_ENV,
    );

  const hasAccessUnregisteredTab = isAccuHealthHha || userType === 'admin';

  const createNewConversationHandler = () => {
    if (
      conversationType === PlatformEnums.UNREGISTERED &&
      !hasAccessUnregisteredTab
    ) {
      notificationController.error({ message: MESSAGING_NOT_ALLOWED });
    } else {
      setIsModalOpen(true);
    }
  };
  return (
    <div className={s['new-conversation-button']}>
      <BaseButton
        onClick={createNewConversationHandler}
        icon={<ChatPlusIcon />}
      />
      <NewMessageModal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
    </div>
  );
};

export default NewConversationButton;
