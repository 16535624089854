import { makeid } from 'utils/helpers/makeid';

import { ChangeLog } from './change-log';

import type { IChangeLog, IEditorLog } from 'infrastructure/interfaces';
import type { DateString } from 'infrastructure/types';

export class EditorLog {
  key: string;
  date: DateString;
  operation: string;
  changes: IChangeLog[];
  person: string;
  userType: string;
  section: string;

  constructor(data: IEditorLog) {
    this.key = makeid(5);
    this.date = data.createdAt;
    this.operation = data.operation;
    this.changes = data.changes.map((el) => new ChangeLog(el));
    this.person = data.author.fullName;
    this.userType = data.author.userType;
    this.section = data.section;
  }
}
