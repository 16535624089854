import { ClinicSalesForceIntegrationEnum } from 'infrastructure/enums';
import {
  commonValidationSchemas,
  createValidationSchema,
} from 'infrastructure/functions';

export const clinicDetailValidationSchema = () =>
  createValidationSchema({
    npiNumber: commonValidationSchemas.string({ nullable: true }),
    taxIdNumber: commonValidationSchemas.string({ nullable: true }),
    medicalIdTpiNumber: commonValidationSchemas.string({ nullable: true }),
    ots: commonValidationSchemas.boolean({
      nullable: true,
    }),
    otsStartDate: commonValidationSchemas.string({ nullable: true }),
    referralTypes: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    providerServiceType: commonValidationSchemas
      .array(commonValidationSchemas.string(), {
        min: 1,
        required: true,
      })
      .required(),
    serviceDeliveryThresholds: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    serviceDeliveryModel: commonValidationSchemas.string({
      required: true,
      nullable: true,
    }),
    revenueChannels: commonValidationSchemas.array(
      commonValidationSchemas.string(),
      { nullable: true },
    ),
    autoGeneratedClaimDrop: commonValidationSchemas.boolean({ nullable: true }),
    finId: commonValidationSchemas.string({ nullable: true }),
    stateIdNumber: commonValidationSchemas.string({ nullable: true }),
    taxonomyNumber: commonValidationSchemas.string({ nullable: true }),
    medicareNumber: commonValidationSchemas.string({ nullable: true }),
    officeHours: commonValidationSchemas.string({ nullable: true }),
    salesForceIntegration: commonValidationSchemas
      .string()
      .oneOf(ClinicSalesForceIntegrationEnum),
  });
