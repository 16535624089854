import React from 'react';
import randomColor from 'randomcolor';
import PersonIcon from 'components/atoms/icons/personIcon';
import ConversationClosedIcon from 'assets/img/conversation-closed.svg';

import s from './styles.module.scss';

interface AvatarProps {
  value?: string;
  closed?: boolean;
  width?: number;
}

const Avatar: React.FC<AvatarProps> = (props) => {
  const { value, closed = false, width = 28 } = props;

  const backgroundColor = randomColor({
    luminosity: 'dark',
    seed: value,
  });

  const getFirstTwoLetters = (patientFullName: string): string => {
    const patientNameArray: string[] = patientFullName?.split(' ');
    return `${patientNameArray?.[1]?.[0]}${patientNameArray?.[2]?.[0]}`;
  };

  const getLastTwoDigits = (phoneNuber: string): string =>
    `${phoneNuber.substr(phoneNuber.length - 2)}`;

  const isPhoneNumber = (name: string): boolean => {
    const phonePattern = /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s-]?)?[\d\s-]{7,}$/;
    if (phonePattern.test(name)) {
      return true;
    }
    return false;
  };
  const style = width
    ? {
        backgroundColor,
        width,
      }
    : {
        backgroundColor,
      };

  if (closed)
    return (
      <div className={s['closed-avatar']}>
        <img src={ConversationClosedIcon} alt="conversation-closed" />
      </div>
    );

  if (!value)
    return (
      <div
        className={s.avatar}
        style={{ ...style, backgroundColor: '#F0F1F2' }}
      >
        <PersonIcon color="#6F767E" width="14px" height="14px" />
      </div>
    );

  return (
    <div className={s.avatar} style={style}>
      {isPhoneNumber(value)
        ? getLastTwoDigits(value)
        : getFirstTwoLetters(value)}
    </div>
  );
};

export default Avatar;
