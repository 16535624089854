import i18next from 'i18next';
import { ActionTimesQuota } from 'infrastructure/enums';

export interface IActionTimesQuotaItem {
  id: ActionTimesQuota | null;
  name: string;
}

export const actionTimesQuotasDataSource = (
  withNone = true,
): IActionTimesQuotaItem[] => {
  const dataSource: IActionTimesQuotaItem[] = [
    {
      id: ActionTimesQuota.Over55,
      name: i18next.t('actionTimesQuotas.over55'),
    },
  ];

  if (withNone) {
    dataSource.unshift({
      id: null,
      name: i18next.t('actionTimesQuotas.all'),
    });
  }

  return dataSource;
};
