import i18next from 'i18next';
import { DateFormatter } from 'infrastructure/functions';
import { DateFormats } from 'infrastructure/enums';
import ChevronRightIcon from 'components/atoms/icons/chevron-right.svg?react';

import s from './styles.module.scss';

import type { Nullable } from 'infrastructure/types';
import type {
  IAgencyHistoryLogs,
  THistoryLogOperation,
} from 'infrastructure/interfaces';
import type { IBaseTableColumns } from 'components/atoms/base-table/types';

const pathMap = {
  [i18next.t('labels.contactInformation')]: [
    'legalname',
    'email',
    'phone',
    'fax',
  ],
  [i18next.t('labels.address')]: [
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip',
  ],
  [i18next.t('labels.registration')]: ['agencyId'],
  [i18next.t('labels.details')]: [
    'npiNumber',
    'taxIdNumber',
    'medicalIdTpiNumber',
    'stateIdNumber',
    'taxonomyNumber',
    'medicareNumber',
  ],
};

const fieldMap: Record<string, string> = {
  legalname: i18next.t('labels.legalName'),
  email: i18next.t('labels.businessEmail'),
  phone: i18next.t('labels.businessPhone'),
  fax: i18next.t('labels.businessFax'),
  addressLine1: i18next.t('labels.addressLine1'),
  addressLine2: i18next.t('labels.addressLine2'),
  city: i18next.t('labels.city'),
  state: i18next.t('labels.state'),
  zip: i18next.t('labels.zipCode'),
  agencyId: i18next.t('labels.registrationId'),
  npiNumber: i18next.t('labels.npi'),
  taxIdNumber: i18next.t('labels.taxIdNumber'),
  medicalIdTpiNumber: i18next.t('labels.medicalIdTpiNumber'),
  stateIdNumber: i18next.t('labels.stateIdNumber'),
  taxonomyNumber: i18next.t('labels.taxonomyNumber'),
  medicareNumber: i18next.t('labels.medicareNumber'),
};

const findKeyByValue = (value: string) =>
  Object.entries(pathMap).find(([_, values]) => values.includes(value))?.[0] ??
  '';

const checkValue = (value: Nullable<string>) =>
  !value || value === 'null' ? i18next.t('labels.bannerNoData') : value;

export const columns: IBaseTableColumns<IAgencyHistoryLogs> = [
  {
    label: i18next.t('labels.dateTime'),
    key: 'createdAt',
    width: '100px',
    render(date: string) {
      return (
        <p className={s.date}>
          {DateFormatter({
            date,
            format: DateFormats['MM-dd-yyyy h:mm a'],
          })}
        </p>
      );
    },
    dataCy: 'agency-history-date',
  },
  {
    label: i18next.t('labels.operationType'),
    key: 'operation',
    width: '130px',
    render(operation: THistoryLogOperation) {
      return <p className={s.operation}>{operation.toLowerCase()}</p>;
    },
    dataCy: 'agency-history-operation',
  },
  {
    label: i18next.t('labels.path'),
    key: 'changes',
    width: '310px',
    dataCy: 'agency-history-path',
    render(_, record) {
      return record.changes?.map(({ field }) => (
        <div className={s.path}>
          <p>{findKeyByValue(field)}</p>
          <ChevronRightIcon className={s.arrow} />
          <p>{fieldMap[field]}</p>
        </div>
      ));
    },
  },
  {
    label: i18next.t('labels.changes'),
    key: 'changes',
    width: '570px',
    dataCy: 'agency-history-changes',
    render(_, record) {
      return record.changes?.map(({ from, to }) => (
        <div className={s.changes}>
          <p>
            {i18next.t('labels.from')}: {checkValue(from)}
          </p>
          <ChevronRightIcon className={s.arrow} />
          <p className={s.to}>{checkValue(to)}</p>
        </div>
      ));
    },
  },
  {
    label: i18next.t('labels.user'),
    width: '230px',
    key: 'author',
    align: 'right',
    dataCy: 'agency-history-user',
    render(_, record) {
      return (
        <div className={s.author}>
          <p className={s.name}>{record.author.fullName}</p>
          <p className={s.type}>
            <span>{i18next.t('labels.userType')}:</span>{' '}
            {record.author.userType}
          </p>
        </div>
      );
    },
  },
];
