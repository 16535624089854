import { useMutation } from '@tanstack/react-query';
import dataService from 'services/dataService';
import { API_SEND_BINGO_CARD_REPORT } from 'services/dataService/resources';
import { showResult } from 'infrastructure/hooks/utils';
import { notificationController } from 'infrastructure/antd/controllers/notification-controller';
import { useTranslation } from 'react-i18next';

interface Props {
  patientGuid: string;
}

export const useSendBingoCard = () => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ patientGuid }: Props) => {
      const body = {
        patientGuid,
      };
      const { data, error } = await dataService.createOne<{
        shouldSendEmail: boolean;
        shouldSendSms: boolean;
      }>(API_SEND_BINGO_CARD_REPORT, body);

      if (error) {
        throw new Error(error);
      }
      return data;
    },
    onError: (error) => {
      if (error instanceof Error) {
        showResult(error.message);
      }
    },
    onSuccess: (data) => {
      if (!data) {
        return;
      }
      const notSent =
        data.shouldSendEmail === false && data.shouldSendSms === false;
      const bothSent =
        data.shouldSendEmail === true && data.shouldSendSms === true;
      const onlyEmail =
        data.shouldSendEmail === true && data.shouldSendSms === false;
      const onlySms =
        data.shouldSendEmail === false && data.shouldSendSms === true;

      if (notSent) {
        notificationController.info({
          message: t('messages.bingoCardNotSent'),
        });
      } else if (bothSent) {
        notificationController.success({
          message: t('messages.bingoCardSentEmailAndPhone'),
        });
      } else if (onlyEmail) {
        notificationController.success({
          message: t('messages.bingoCardSentEmail'),
        });
      } else if (onlySms) {
        notificationController.success({
          message: t('messages.bingoCardSentPhone'),
        });
      }
    },
  });

  return { sendBingoCardLoading: isLoading, sendBingoCard: mutate };
};
